import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TouchAppIcon from '@material-ui/icons/TouchApp';

import styles from './ShopNavigationButton.module.css';


/**
 * See also `src/Components/Pages/ShopPublicPage/Navigation/ShopMainPageNav.js`
 */
const ShopNavigationButton = ({
  // from parent:
  shop,
}) => {
  const { t } = useTranslation();

  const [isNavMenuOpened, setNavMenuOpened] = useState(false);

  const toggleNavMenu = () => {
    setNavMenuOpened((isNavMenuOpened) => !isNavMenuOpened);
  };

  return (
    <div
      className={`${styles.root} ${isNavMenuOpened ? styles.stateOpened : ''}`}
    >
      <button className={styles.navHandle} onClick={toggleNavMenu}>
        {t('shopPublic.tabs.navigationAnchorMainPage')}
        <TouchAppIcon fontSize="small" />
      </button>

      <div className={styles.tabAnchors}>
        <NavLink
          activeClassName={styles.active}
          className={styles.tabAnchor}
          to={`/shop/${shop.id}/price-list`}
          onClick={toggleNavMenu}
        >
          {t('shopPublic.tabs.priceList')}
        </NavLink>
        <NavLink
          activeClassName={styles.active}
          className={styles.tabAnchor}
          to={`/shop/${shop.id}/review`}
          onClick={toggleNavMenu}
        >
          {t('shopPublic.tabs.review')}
        </NavLink>
        <NavLink
          activeClassName={styles.active}
          className={styles.tabAnchor}
          to={`/shop/${shop.id}/news`}
          onClick={toggleNavMenu}
        >
          {t('shopPublic.tabs.news')}
        </NavLink>
        <NavLink
          activeClassName={styles.active}
          className={styles.tabAnchor}
          to={`/shop/${shop.id}/gallery`}
          onClick={toggleNavMenu}
        >
          {t('shopPublic.tabs.photoTabs')}
        </NavLink>
        <NavLink
          activeClassName={styles.active}
          className={styles.tabAnchor}
          to={`/shop/${shop.id}/stylist`}
          onClick={toggleNavMenu}
        >
          {t('shopPublic.tabs.stylist')}
        </NavLink>
        {/*
        <NavLink
          activeClassName={styles.active}
          className={`${styles.tabAnchor}`}
          to={`/shop/${shop.id}/product`}
          onClick={toggleNavMenu}
        >
          {t('shopPublic.tabs.product')}
        </NavLink>
        */}
      </div>
    </div>
  );
};


export default ShopNavigationButton;
