import {
    ADD_PRODUCT,
    DELETE_PRODUCT,
    GET_ALL_PRODUCTS,
    GET_PRODUCT,
    GET_RECOMMENDED,
    UPDATE_PRODUCT,
    BUY_PRODUCT,
} from "../../constants/constants";


const initialState = {
    products: [],
    recommend: [],
    product: [],
    deleted: []
};


export default (state = initialState, action) => {
    switch (action.type)  {
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };
        case DELETE_PRODUCT:
            return {
                ...state,
                deleted: action.payload
            };
        case UPDATE_PRODUCT:
            return {
                ...state,
                products: action.payload
            };
        case ADD_PRODUCT:
            return {
                ...state,
                products: action.payload
            };
        case GET_PRODUCT:
            return {
                ...state,
                product: action.payload
        };
        case BUY_PRODUCT:
            return {
                ...state,
                product: action.payload
        };
        case GET_RECOMMENDED:
                return {
                    ...state,
                    recommend: action.payload
                };
        default:
            return {
                ...state
            }
    }
}