import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


class StaffItem extends Component {
  state = {
    showField: false,
    charge: 0,
  };

  toggleInput = () => {
    const {
      staffData: { user_id },
      handleChange,
    } = this.props;
    const { charge } = this.state;
    this.setState(
      prevState => ({
        showField: !prevState.showField,
      }),
      () => handleChange(charge, user_id, this.state.showField)
    );
  };

  handleChangeCharge = ({ target: { value } }) => {
    const {
      staffData: { user_id },
      handleChange,
    } = this.props;
    const regExp = /^\d+\.?\d*$/;
    if ((Number(value) !== '' && regExp.test(value)) || value === '') {
      this.setState({
        charge: value,
      });
    }
    handleChange(value, user_id, this.state.showField);
  };

  render() {
    const {
      staffData: { nickname, user_id },
      blockNum,
      t,
    } = this.props;
    const { showField, charge } = this.state;
    return (
      <tr data-label-tr={`${t('shopPrivate.servicePriceList.staffBlock')} #${blockNum}`}>
        <td data-label={t('shopPrivate.shopLeftMenu.staff')}>
          <div className="table-check">
            <input type="checkbox" className="checkbox" id={`id${user_id}`} onChange={this.toggleInput} />
            <label htmlFor={`id${user_id}`}>
              <span>{nickname}</span>
            </label>
          </div>
        </td>
        <td data-label={t('shopPrivate.servicePriceList.extraCharge')}>
          <div className="table-field">
            <input
              type="text"
              name={`${user_id}`}
              onChange={this.handleChangeCharge}
              className="form-item"
              disabled={!showField}
              value={charge}
            />
          </div>
        </td>
      </tr>
    );
  }
}

export default withTranslation()(StaffItem);
