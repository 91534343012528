import moment from "moment";

import {
  SITE_LATEST_REVIEW_WILL_FETCH,
  SITE_LATEST_REVIEW_DID_FETCH,
} from "../../constants/constants";
import api from "../../../api";


export const fetchSiteLatestReview = () => (dispatch, getState) => {
  const prevState = getState().siteLatestReviewReducer;

  if (prevState.isFetchingSiteLatestReview) {
    // console.log('fetching site-latest-review. No need to fire a fetch request.');
    return Promise.resolve({ res: null, access: false });
  }
  else if (prevState.siteLatestReviewLastFetchedAt && moment().diff(prevState.siteLatestReviewLastFetchedAt, 'minutes', true) <= 30) {
    // console.log('site-latest-review list is still fresh. No need to fire a fetch request.');
    return Promise.resolve({ res: null, access: false });
  }

  dispatch(willFetchSiteLatestReview());

  return api.mainPage.siteLatestReviews().then(res => {
    if (res.data.status === 200) {
      dispatch(didFetchSiteLatestReview(res.data.data));
      return { res: res.data, access: true };
    }
    return { res: null, access: false };
  });
};

const willFetchSiteLatestReview = () => {
  return {
    type: SITE_LATEST_REVIEW_WILL_FETCH,
  };
};

const didFetchSiteLatestReview = (data) => {
  return {
    type: SITE_LATEST_REVIEW_DID_FETCH,
    payload: data
  }
};
