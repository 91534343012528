import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import { CustomerBadge } from '../../../Common/Customer';
import { Ellipsize } from '../../../Common/Text';


class ShopMainReviewCardboard extends Component {

  redirectToReview = id => () => {
    const { url, history } = this.props;
    history.push(`${url.slice(0, url.lastIndexOf('main'))}review/${id}`);
  };

  render() {
    const {
      className,
      reviews,
      url,
      t,
    } = this.props;

    return (
      <div className={`shop-group ${className}`}>
        <p className="theme-subtitle">{t('shopPublic.overview.latestReviews')}</p>

        {reviews.length ? (
          <>
            <div className="row">
              {reviews.slice(0, 2).map((item, index) => (
                <div className="col-lg-6" key={index}>
                  <CustomerBadge
                    avatarSize="small"
                    className="mb-2"
                    customer={item.customer}
                    horizontal
                  />

                  <Link
                    to={`${url.slice(0, url.lastIndexOf('main'))}review/${item.id}`}
                    className="review-title"
                  >
                    {item.title}
                  </Link>

                  <div
                    className="latest-review-text"
                    onClick={this.redirectToReview(item.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <p><Ellipsize text={item.review} maxlength={80} /></p>
                  </div>
                  <div className="shop-gallery">
                    {item.photos instanceof Array &&
                      item.photos.slice(0, 3).map((element, index) => (
                        <div
                          className="shop-gallery__item"
                          onClick={this.redirectToReview(item.id)}
                          key={`${element.id}${index}`}
                        >
                          <img src={element.url} alt={`review_photo_${index}`} />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>

            <div>
              <Link to={`${url.slice(0, url.lastIndexOf('main'))}review`} className="more-link">
                {t('shopPublic.overview.more')}
              </Link>
            </div>
          </>
        ) : (
          <div className="latest-review-text">{t('shopPublic.overview.nothingToShow')}</div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reviews: (state.ReviewReducer.reviews || []).filter(review => {
      return review.approved === 1;
    }),
  };
}

export default withTranslation()(
  connect(
    mapStateToProps
  )(ShopMainReviewCardboard)
);
