import { GET_BOOKINGS_FOR_CALENDAR } from '../../constants/constants';
import api from '../../../api';

export const getShopBookingsForCalendar = data => dispatch =>
  api.shopBookings.getShopBookingsForCalendar(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(GetAllShopBookingsForCalendar(res.data.data));
      return res;
    }
    return res;
  });

export const importCalendar = data => dispatch =>
  api.shopBookings.importCalendar(data).then(res => {
    if (res.data.message === 'success') {
      return { res, access: true };
    }
    return { res, access: false };
  });

export const GetAllShopBookingsForCalendar = data => ({
  type: GET_BOOKINGS_FOR_CALENDAR,
  payload: data,
});
