import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  fetchLinkableFeatureArticles,
  fetchLinkableLandingPages,
} from '../../../../../Redux/actions/shopActions/siteLinkableActions';


function Linkables({
  // fetch mapStateToProps:
  articles,
  landings,

  // redux actions:
  fetchLinkableLandingPages,
  fetchLinkableFeatureArticles,
}) {
  useEffect(() => {
    fetchLinkableFeatureArticles();

    fetchLinkableLandingPages();
  }, []);

  return (
    (landings.length > 0 || articles.length > 0) && (
      <div className="d-flex flex-wrap justify-content-center align-items-center">
        {landings.map((landingPage) => (
          <Link
            key={landingPage.alias}
            className="mx-2 my-1"
            to={`/${landingPage.alias}`}
            title={landingPage.title}
          >
            {landingPage.anchor}
          </Link>
        ))}

        {articles.map((article) => (
          <Link
            key={article.alias}
            className="mx-2 my-1"
            to={`/美容-美髮-按摩優惠/${article.alias}`}
            title={article.title}
          >
            {article.shortAnchor}
          </Link>
        ))}
      </div>
    )
  );
}

function mapStateToProps(state) {
  return {
    landings: state.siteLinkableReducer.landings,
    articles: state.siteLinkableReducer.articles,
  };
}

export default connect(mapStateToProps, {
  fetchLinkableFeatureArticles,
  fetchLinkableLandingPages,
})(Linkables);
