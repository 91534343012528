import React,{ Component } from 'react';

import { normalizePhotos, normalizePhotosFromUrls } from '../../../Functions/photoHelpers';

/**
 *
 * @param {Photo} photo
 * @returns {string}
 */
const resolveViewableImageUrl = (photo) => {
  return photo.url_full || photo.url || '';
}

class PhotoViewer extends Component {

  state = {
    normalizedPhotos: [],

    currentPhoto: {},
    currentIndex: 0,
    currentImageUrl: 0,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);

    let normalizedPhotos = [];

    if (this.props.photoUrls && this.props.photoUrls.length) {
      normalizedPhotos = normalizePhotosFromUrls(this.props.photoUrls, this.props.fullPhotoUrls);
    } else if (this.props.photos && this.props.photos.length) {
      normalizedPhotos = normalizePhotos(this.props.photos);
    }

    const currentPhoto = normalizedPhotos[this.props.initialIndex];
    this.setState({
      normalizedPhotos,
      currentPhoto,
      currentIndex: this.props.initialIndex,
      currentImageUrl: resolveViewableImageUrl(currentPhoto),
    })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleLeftward = () => {
    this.setState((prevState, props) => {
      const newIndex = (prevState.currentIndex - 1 + prevState.normalizedPhotos.length) % prevState.normalizedPhotos.length;
      const currentPhoto = prevState.normalizedPhotos[newIndex];

      return {
        currentPhoto,
        currentIndex: newIndex,
        currentImageUrl: resolveViewableImageUrl(currentPhoto),
      };
    });
  };

  handleRightward = () => {
    this.setState((prevState, props) => {
      const newIndex = (prevState.currentIndex + 1) % prevState.normalizedPhotos.length;
      const currentPhoto = prevState.normalizedPhotos[newIndex];

      return {
        currentPhoto,
        currentIndex: newIndex,
        currentImageUrl: resolveViewableImageUrl(currentPhoto),
      };
    });
  };

  handleKeyDown = ({ keyCode }) => {
    switch (keyCode) {
      case 27: // escape
        this.props.onClose();
        break;
      case 37: // left
        this.handleLeftward();
        break;
      case 39: // right
        this.handleRightward();
        break;
      default:
        break;
    }
  };

  render() {
    const {
      onClose,
    } = this.props;
    const {
      normalizedPhotos,
      // currentPhoto,
      // currentIndex,
      currentImageUrl,
    } = this.state;

    return (
      <div className="modal-wrapper">
        <div className="modal-wrapper__inner">
          <div className="open-photo">
            <button className="open-photo__close" onClick={onClose}>
              <i className="fas fa-times" />
            </button>

            <div className="open-photo__item">
              <img src={currentImageUrl} alt="" loading="lazy" />

{/* For debug */}
{/*
              <div className="text-left" style={{ position: 'fixed', width: '100%', left: 0, top: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                currentIndex: {currentIndex}
                <br /><a href={currentPhoto.url} target="_blank">{currentPhoto.url}</a> (thumb)
                <br /><a href={currentPhoto.url_full} target="_blank">{currentPhoto.url_full}</a> (full)
                <br /><a href={currentImageUrl} target="_blank">{currentImageUrl}</a> (showing)
              </div>
*/}
            </div>

            <button className={`open-photo__btn ${normalizedPhotos.length <= 1 ? 'd-none' : ''}`} onClick={() => this.handleLeftward()}>
              <i className="fas fa-arrow-left" />
            </button>

            <button className={`open-photo__btn open-photo__btn--type2 ${normalizedPhotos.length <= 1 ? 'd-none' : ''}`} onClick={() => this.handleRightward()}>
              <i className="fas fa-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PhotoViewer.defaultProps = {

  // Caller should provide either (props `photos`) or (props `photoUrls` and `fullPhotoUrls`)

  photos: [],

  // These two props are parallel arrays of strings:
  photoUrls: [],
  fullPhotoUrls: [],

  initialIndex: 0,

  onClose: () => {},
};

export default PhotoViewer;
