import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


import useAuthenticated from '../../../../../hooks/auth/useAuthenticated';

import AppButtonLink from '../../../../Common/Button/AppButtonLink';

/**
 * If user is not authenticated, this component renders nothing
 * @returns
 */
const MyBookingButton = () => {
  const { t } = useTranslation();

  const {
    isCustomer,
    isShop,
    isStaff,
  } = useAuthenticated();

  const [linkTo, setLinkTo] = useState(null);

  useEffect(() => {
    if (isCustomer) {
      setLinkTo('/customer-settings/bookings');
    } else if (isStaff) {
      setLinkTo('/staff-settings/bookings');
    } else if (isShop) {
      setLinkTo('/shop-settings/bookings');
    } else {
      setLinkTo(null);
    }
  }, [isCustomer, isShop, isStaff]);

  return (
    linkTo && (
      <AppButtonLink
        className="ml-1 ml-lg-2"
        shape="round"
        size="small"
        theme="primary"
        to={linkTo}
      >
        {t('navBar.myBooking')}
      </AppButtonLink>
    )
  );
};

export default MyBookingButton;
