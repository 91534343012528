import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { getCityList } from '../../../../../Redux/actions/definitionActions/cityActions';


const LocationFilter = ({
  // from parent
  selectedCityId,
  onChangeCity,

  selectedDistrictId,
  onChangeDistrict,

  // from mapStateToProps:
  cities,

  // redux actions:
  getCityList,
}) => {

  const { t } = useTranslation();

  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    // On component mounts

    getCityList();
  }, []);

  useEffect(() => {
    if (selectedCityId) {
      const selectedCity = cities.find(city => city.id === +selectedCityId);
      if (selectedCity) {
        setDistricts(selectedCity.districts || []);
        return;
      }
    }

    setDistricts([]);
  }, [selectedCityId, cities]);

  const handleChangeCity = ({ target }) => {
    if (target.value) {
      const selectedCity = cities.find(city => city.id === +target.value);
      if (selectedCity) {
        onChangeCity(selectedCity);
        return;
      }
    }

    onChangeCity(null);
  };

  const handleChangeDistrict = ({ target }) => {
    if (target.value) {
      const selectedDistrict = districts.find(district => district.id === +target.value);
      if (selectedDistrict) {
        onChangeDistrict(selectedDistrict);
        return;
      }
    }

    onChangeDistrict(null);
  };

  return (
    <>
      <div className="filter-item">
        <div className="filter-field">
          <div className="sidebar-title">
            <span className="d-none d-lg-block">
              {t('shopList.city')}
            </span>

            <span className="d-lg-none">
              {t('shopList.city')} / {t('shopList.district')}
            </span>

            <span className="sidebar-title__ico">
              <i className="fas fa-map-marker" />
            </span>
          </div>

          <div className="field-wrap">
            <select className="form-item" value={selectedCityId} onChange={handleChangeCity}>
              <option value="">{t('action.selectOneFromEnumeration')}</option>
              {cities.map(city => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-1 filter-field">
          <div className="sidebar-title">
            <span className="d-none d-lg-block">
              {t('shopList.district')}
            </span>
          </div>

          <div className="field-wrap">
            <select
              className="form-item"
              disabled={!districts.length}
              value={selectedDistrictId}
              onChange={handleChangeDistrict}
            >
              <option value="">{t('action.selectOneFromEnumeration')}</option>
              {districts.map(district => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}


function mapStateToProps(state) {
  return {
    cities: state.cityReducer.cityList,
  };
}

export default connect(
  mapStateToProps,
  {
    getCityList,
  }
)(LocationFilter);
