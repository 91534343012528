import {CREATE_SERVICE_TYPE, GET_ALL_SERVICE_TYPES, GET_CREATION_SERVICE_TYPES} from "../../constants/constants";
import api from "../../../api";


export const createServiceType = data =>  dispatch =>
    api.ShopServiceTypes.createServiceType(data).then((res) => {
        if(res.data.message === 'success') {
            dispatch(CreateServiceType(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
    });

export const getAllServiceTypes = data => dispatch =>
    api.ShopServiceTypes.getAllServiceTypes(data).then((res) => {
        if(res.data.message === 'success') {
            dispatch(GetAllServiceTypes(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
    });

export const getCreationServiceTypes = data => dispatch =>
api.ShopServiceTypes.getCreationServiceTypes(data).then((res) => {
    if(res.data.status === 'success') {
        dispatch(GetCreationServiceTypes(res.data.data));
        return {res, access: true}
    }
    return {res, access: false}
});   

export const GetAllServiceTypes = (data) => ({
    type: GET_ALL_SERVICE_TYPES,
    payload: data
});

export const GetCreationServiceTypes = (data) => ({
    type: GET_CREATION_SERVICE_TYPES,
    payload: data
});

export const CreateServiceType = (data) => ({
    type: CREATE_SERVICE_TYPE,
    payload: data
})