import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppButton } from '../../../Common/Button';
import { TextInputField } from '../../../Common/Form';

const ModalChangeContacts = ({ code, sendCode, onChange, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-wrapper">
      <div className="modal-wrapper__inner">
        <div className="theme-modal">
          <div className="theme-modal__header theme-modal__header--code">
            <h2>{t('customerPrivate.enterCode')}</h2>
            <button className="close-modal" onClick={onClose}>
              <i className="fas fa-times" />
            </button>
          </div>

          <div className="theme-modal__content">
            <div className="modal-inner-content">
              <TextInputField
                name="code"
                placeholder={t('customerPrivate.yourCode')}
                required
                value={code}
                onChange={onChange}
              />
            </div>
          </div>

          <div className="confirm-code-btn">
            <AppButton disabled={!(code||'').trim()} theme="primary" onClick={sendCode}>
              {t('customerPrivate.sendCode')}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalChangeContacts;
