import api from "../../../api";
import {CHOOSE_CHAT, CLOSE_CHAT, GET_ALL_CHATS, RECIEVE_MESSAGE, SEND_MESSAGE} from "../../constants/constants";



export const getAllChats = (data) => dispatch =>
    api.shopsChat.getAllChatsForUser(data).then((res) =>{
        if (res.data.message === 'success') {
            dispatch(GetAllChats(res.data.data));
            return {res, access: true}
        }
        return {res, access:false}
    });

export const sendMessage = (data) => dispatch =>
    api.shopsChat.sendMessageToChat(data).then((res) =>{
        if (res.data.message === 'success') {
            dispatch(SendMessage(res.data.data));
            return {res, access: true}
        }
        return {res, access:false}
    });


export const chooseChat = (data) => dispatch =>
    api.shopsChat.getSpecificChatsWithMessages(data).then((res) =>{
        if (res.data.message === 'success') {
            dispatch(ChooseChat(res.data.data));
            return {res, access: true}
        }
        return {res, access:false}
    });

export const closeChat = (data) => dispatch =>
    api.shopsChat.closeChat(data).then(res => {
      if(res.data.message === "success"){
          dispatch(ChooseChat(res.data.data))
      }
    });



export const GetAllChats = (data) => ({
    type: GET_ALL_CHATS,
    payload: data
});

export const ChooseChat = (data) => ({
    type: CHOOSE_CHAT,
    payload: data
});
export const SendMessage = (data) =>({
    type: SEND_MESSAGE,
    payload: data
});

export const RecieveMessage = (data) => ({
    type: RECIEVE_MESSAGE,
    payload: data
    });

export const CloseChat = (data) => ({
    type: CLOSE_CHAT,
    payload: data
})






