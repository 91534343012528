import {
  AUTH_AUTHENTICATING,
  AUTH_LOGOUT,
  SET_USER_DATA,
} from '../../constants/constants';

import api from '../../../api';
import setAuthHeader from '../../../Functions/setAuthHeader';

export const signIn = (data) => (dispatch) => {
  dispatch({ type: AUTH_AUTHENTICATING });

  return api.signIn
    .signIn(data)
    .then((res) => res.data)
    .then((response) => {
      if (response.status === 200) {
        // Sign in successfully

        localStorage.FinderJWT = response.token;

        setAuthHeader(response.token);

        dispatch(setUserData(response.data));
      }

      return response;
    })
    .catch((e) => {
      dispatch(logout());
      throw e;
    });
}

/**
 * To reauthenticate user, on app (page) launches
 * @returns
 */
export const checkToken = () => (dispatch) => {
  setAuthHeader(localStorage.FinderJWT);

  if (localStorage.FinderJWT) {
    dispatch({ type: AUTH_AUTHENTICATING });

    api.auth
      .checkToken()
      .then((res) => {
        if (res.data.message === 'success') {
          dispatch(setUserData(res.data.data));
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        dispatch(logout());
      });
  } else {
    dispatch(logout());
  }
};

export const setUserData = (user) => ({
  type: SET_USER_DATA,
  payload: user,
});

export const logout = () => (dispatch) => {
  localStorage.removeItem('FinderJWT');
  setAuthHeader(null);
  dispatch({ type: AUTH_LOGOUT });
};
