import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';


import { useConfirmationDialog } from '../../../Providers/ConfirmDialogProvider';

import notification from '../notification';

import AspectImage from 'src/Components/Common/AspectImage/AspectImage';
import Spinner from '../Spinner/Spinner';


import styles from './PhotoCell.module.css';

const PhotoCell = ({
  aspectRatio,
  photo,
  showCopyUrlButton,
  showDeleteButton,
  showEditButton,
  onClick,
  onDelete,
  onEdit,
}) => {

  const { t } = useTranslation();

  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const [isDeleting, setDeleting] = useState(false);

  const copyUrl = () => {
    try {
      navigator.clipboard.writeText(photo.url); // copy image url to clipboard
      notification(t('notifications.imageUrlCopied'));
    } catch (err) {
      notification(t('notifications.somethingWasWrong'));
    }
  }

  const deleteImage = () => {
    if (photo.id) {
      // This photo exists on remote server
      openConfirmDeleteDialog(() => {
        setDeleting(true);

        // Such props function should return an promise
        onDelete().then(() => {
          notification(t('notifications.itemDeleted'));
        })
        .finally(() => {
          setDeleting(false);
        });
      });
    }
    else {
      // This photo has not been uploaded to remote server.
      onDelete();
    }
  }

  return (
    <div className={styles.root}>
      <AspectImage aspectRatio={aspectRatio}>
        <img
          src={photo.url}
          alt=""
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        />
      </AspectImage>

      <div className={`d-flex justify-content-between ${styles.toolbarBottomLeft}`} >
        <div className="d-flex justify-content-between">

          {/* button to copy url */}
          {(showCopyUrlButton && !photo._isUploading) && (
            <Tooltip placement="top" title={t('action.copyImageUrl')}>
              <button
                className="btn btn-secondary m-1"
                type="button"
                onClick={copyUrl}
              >
                <i className="fas fa-copy" />
              </button>
            </Tooltip>
          )}

        </div>
      </div>

      <div className={`d-flex justify-content-between ${styles.toolbarBottomRight}`}>
        <div className="d-flex justify-content-between">

          {/* button to edit photo */}
          {(photo.id && showEditButton && !photo._isUploading) && (
            <Tooltip placement="top" title={t('action.edit')}>
              <button
                className="btn btn-secondary m-1"
                type="button"
                onClick={onEdit}
              >
                <i className="fas fa-pen" />
              </button>
            </Tooltip>
          )}

          {/* button to delete photo */}
          {(showDeleteButton && !photo._isUploading) && (
            <Tooltip placement="top" title={t('action.delete')}>
              <button
                className={`btn ${photo.id ? 'btn-dark' : 'btn-secondary'} m-1`}
                disabled={isDeleting}
                type="button"
                onClick={deleteImage}
              >
                {isDeleting ? (
                  <Spinner />
                ) : (
                  <i className="fas fa-trash" />
                )}
              </button>
            </Tooltip>
          )}

        </div>
      </div>

      {photo._isUploading && (
        <div className={`d-flex justify-content-center align-items-center ${styles.maskUploading}`}>
          <Spinner />
        </div>
      )}
    </div>
  );
}


PhotoCell.propTypes = {
  aspectRatio: PropTypes.string,
  photo: PropTypes.object.isRequired,
  showCopyUrlButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

PhotoCell.defaultProps = {

  aspectRatio: '1:1',

  /**
   * Its schema follow `Photo` on remote server
   */
  photo: {},

  showCopyUrlButton: true,

  showDeleteButton: true,

  showEditButton: false,

  onClick: (e) => {},

  onDelete: () => Promise.resolve(),

  onEdit: (photo) => {},

};

export default PhotoCell;
