import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import { fetchShopNews } from '../../../../Redux/actions/features/shopNewsAction';

import Spinner from '../../../Common/Spinner/Spinner';

import BookingPanel from '../Booking/BookingPanel';

import NewsItem from './NewsItem';

class NewsList extends Component {
  state = {
    pageName: '',
  };

  componentDidMount() {
    this.getPageName(this.props.match.path);

    this.props.fetchShopNews(this.props.match.params.id || this.props.user.id);
  }

  getPageName = page => {
    this.setState({
      pageName: page,
    });
  };

  render() {
    const {
      shop,
      isFetchingNews,
      newsList,
    } = this.props;

    return (
      <div className="container">
        <BookingPanel shop={shop} buttonTheme={2} />

        {isFetchingNews ? (
          <Spinner display="block" />
        ) : (
          newsList && newsList.map((news, index) => (
            <NewsItem
              key={index}
              shop={shop}
              news={news}
            />
          ))
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const shopId = props.match.params.id || state.auth.user.id;

  // adopt data from redux state only when the data is from the same shop
  const shop = state.shopNewsReducer.shopWithNewsList && state.shopNewsReducer.shopWithNewsList.id == shopId ?
    state.shopNewsReducer.shopWithNewsList : {};

  return {
    shop,
    isFetchingNews: state.shopNewsReducer.isFetchingNews,
    newsList: shop.news || [],
    user: state.auth.user,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      { fetchShopNews }
    )(NewsList)
  )
);
