import React from 'react';
import { Link } from 'react-router-dom';


import { ShopAvatar, ShopName } from '../../../Common/Shop';

import styles from './SiteMostReviewedShopCard.module.css';


const SiteMostReviewedShopCard = ({
  shop,
}) => {

  const shopData = shop.user_type_data;

  return (
    <div className={`col-6 mb-4 col-md-6 mb-lg-0 col-lg-3 ${styles.root}`}>
      <Link to={`/shop/${shop.id}`}>
        <div className={styles.avatar}>
          <ShopAvatar src={shop.avatar} />
        </div>

        <h2>
          <ShopName shop={shop} />
        </h2>

        {shopData && (
          <>
            <p>{shopData.district && shopData.district.name}</p>
            <p>{shopData.city && shopData.city.name}</p>
          </>
        )}
      </Link>
    </div>
  );
};

export default SiteMostReviewedShopCard;
