import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import api from '../../../../api';

import CheckboxGroup from '../../../Common/Form/CheckboxGroup/CheckboxGroup';


class ServiceTypes extends Component {

  _isMounted = false;

  state = {
    isLoadingDefaultService: false,
    defaultServiceOptions: [],
    selectedDefaultServiceIds: [],
  };

  componentDidMount() {
    this._isMounted = true;

    this.setState({
      isLoadingDefaultService: true,

      // set initially checked items:
      selectedDefaultServiceIds: this.props.shopAttachedDefaultServices.map(item => item.id),
    });

    // Retrieve list of default-services' options
    api.defaultService.fetchList().then(response => {
      if (!this._isMounted) {
        return;
      }

      this.setState({
        isLoadingDefaultService: false,
        defaultServiceOptions: response.data,
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t, i18n } = this.props;

    const {
      isLoadingDefaultService,
      defaultServiceOptions,
      selectedDefaultServiceIds,
    } = this.state;

    return (
      <div className="main-container__content">
        <CheckboxGroup
          idPrefix="default-service-checkable-"
          formFieldName="defaultService"
          isLoading={isLoadingDefaultService}
          items={defaultServiceOptions}
          readOnly={true}
          itemIdProp="id"
          itemLabelProp={
            i18n.language.startsWith('en') ? 'name' : 'chineseName'
          }
          defaultCheckedItemIds={selectedDefaultServiceIds}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    shopAttachedDefaultServices: state.ShopReducer.shopData.default_services,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps
  )(ServiceTypes)
);
