import React, { useState } from 'react';

import _uniqueId from 'lodash/uniqueId';


function FilterCheck({
  textForLabel,
  name,
  value,
  checked,
  onChange,
}) {

  const [checkboxId] = useState(_uniqueId(name));

  return (
    <div className="filter-check">
      <input
        id={checkboxId}
        className="checkbox checkbox--type3"
        type="checkbox"
        name={name}
        value={value}
        checked={!!checked}
        onChange={e => onChange(e.target.checked)}
      />
      <label htmlFor={checkboxId} className="m-0">
        <span>{textForLabel}</span>
      </label>
    </div>
  )
}

export default FilterCheck;
