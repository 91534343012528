
/**
 * Customer is required to make new booking not within this period from current time.
 */
export const APP_BOOKING_MIN_TIME_OFFSET_IN_HOUR = 2;

export const APP_CHECKOUT_LARGE_AMOUNT_WARNING_HKD = 15000;

export const CS_WHATSAPP_NUMBER = '85259447977';

export const GOOGLE_AD_ID = 'ca-pub-4061837737896373';

export const FACEBOOK_PAGE_URL = 'https://www.facebook.com/SalonFinderHK/';

export const INSTAGRAM_PROFILE_URL = 'https://www.instagram.com/salonfinder.com.hk/';

/**
 * Pusher API Key
 */
export const PUSHER_KEY = '999afc9b11e9ed473776';
export const PUSHER_CONFIG = {
  appId: "594586",
  cluster: "ap1",
  encrypted: true,
};

/**
 * Stripe API Public Key
 */
export const STRIPE_KEY = 'pk_live_OjWAZ35G6NP6mR1BrAFZqL8d';

/**
 * Youtube API Key
 */
export const YOUTUBE_KEY = 'AIzaSyA5cb5tLI8Pkot_WIPkx_NRn2OxQCFRM_c';
