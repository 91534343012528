import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TouchAppIcon from '@material-ui/icons/TouchApp';

import { ShopAvatar, ShopName } from '../../../Common/Shop';
import TabNavigation from '../../../Common/Navigation/TabNavigation/TabNavigation';

import styles from './ShopMainPageNav.module.css';

/**
 * See also `src/Components/Pages/ShopPublicPage/Navigation/ShopNavigationButton.js`
 */
const ShopMainPageNav = ({
  // from parent:
  isWithSetting,
  shop,
}) => {
  const { t } = useTranslation();

  const [urlMiddlePath, setUrlMiddlePath] = useState('');

  const [anchors, setAnchors] = useState([]);

  useEffect(() => {
    const urlMiddlePath = isWithSetting ? '' : `${shop.id}/`;

    setUrlMiddlePath(urlMiddlePath);

    setAnchors([
      {
        text: t('shopPublic.tabs.overview'),
        to: `/shop/${urlMiddlePath}main`,
      },
      {
        text: t('shopPublic.tabs.priceList'),
        to: `/shop/${urlMiddlePath}price-list`,
      },
      {
        text: t('shopPublic.tabs.review'),
        to: `/shop/${urlMiddlePath}review`,
      },
      {
        text: t('shopPublic.tabs.news'),
        to: `/shop/${urlMiddlePath}news`,
      },
      {
        text: t('shopPublic.tabs.photoTabs'),
        to: `/shop/${urlMiddlePath}gallery`,
      },
      {
        text: t('shopPublic.tabs.stylist'),
        to: `/shop/${urlMiddlePath}stylist`,
      },
      // {
      //   text: t('shopPublic.tabs.product'),
      //   to: `/shop/${urlMiddlePath}product`,
      // },
    ]);
  }, [isWithSetting, shop]);

  return (
    <TabNavigation
      anchors={anchors}
      heading={
        <div className={styles.shopBadge}>
          <div className={`d-xl-none ${styles.shopAvatarContainer}`}>
            {shop.avatar && <ShopAvatar src={shop.avatar} />}
          </div>
          <h2>
            <Link to={`/shop/${urlMiddlePath}main`} className={styles.shopName}>
              <ShopName shop={shop} />
            </Link>
          </h2>
        </div>
      }
      mobileLabel={
        <>
          {t('shopPublic.tabs.navigationAnchor')}
          <TouchAppIcon className="mx-1" fontSize="small" />
        </>
      }
    />
  );
};

ShopMainPageNav.defaultProps = {
  isWithSetting: false,
};

export default ShopMainPageNav;
