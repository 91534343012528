import React, { useMemo } from 'react';

import { isAdmin, isCustomer, isShop, isStaff } from '../../../Functions/authHelpers';

import { CustomerAvatar } from '../Customer';
import { ShopAvatar } from '../Shop';
import { StaffAvatar } from '../Staff';

const UserAvatar = ({ user }) => {
  const Avatar = useMemo(() => {
    if (user && user.user_type_id) {
      if (isCustomer(user.user_type_id)) {
        return <CustomerAvatar src={user.avatar} />;
      } else if (isStaff(user.user_type_id)) {
        return <StaffAvatar src={user.avatar} />;
      } else if (isShop(user.user_type_id)) {
        return <ShopAvatar src={user.avatar} />;
      } else if (isAdmin(user.user_type_id)) {
        return <StaffAvatar src={user.avatar} />;
      }
    }

    // Fallback avatar
    return <CustomerAvatar />;
  }, [user]);

  return Avatar;
};

export default UserAvatar;
