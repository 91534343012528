import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TouchAppIcon from '@material-ui/icons/TouchApp';


import useAuthenticated from '../../../../hooks/auth/useAuthenticated';

import SignInDialog from '../../../Auth/SignInDialog';

import BookingCreationDialog from './BookingCreationDialog';

import styles from './BookingPanel.module.css';

/**
 * For customer to create new booking
 */
const BookingPanel  = ({
  // from parent:
  shop,
  buttonTheme,
}) => {

  const { t } = useTranslation();

  const {
    isAuthenticated,
    isAdmin,
    isShop,
    isStaff,
  } = useAuthenticated();

  const [isBookingAllowed, setIsBookingAllowed] = useState(false);

  const [isSignInWindowOpened, setSignInWindowOpened] = useState(false);

  const [isCreateBookingDialogOpened, setIsCreateBookingDialogOpened] = useState(false);

  useEffect(() => {
    if (shop?.user_type_data?.enableBookingSystem === 1) {
      setIsBookingAllowed(!isAdmin && !isShop && !isStaff);
      return;
    }

    setIsBookingAllowed(false);
  }, [shop, isAdmin, isShop, isStaff]);

  const openBookingDialog = () => {
    if (!isAuthenticated) {
      setSignInWindowOpened(true);
      return;
    }

    setIsCreateBookingDialogOpened(true);
  }

  return (
    <>
      {isSignInWindowOpened && (
        <SignInDialog
          isPendingBooking={true}
          onClose={() => setSignInWindowOpened(false)}
        />
      )}

      {isCreateBookingDialogOpened && (
        <BookingCreationDialog
          shop={shop}
          onClose={() => setIsCreateBookingDialogOpened(false)}
        />
      )}

      {isBookingAllowed &&
        (buttonTheme === 2 ? (
          <button
            className={styles.bookingBtnTheme2}
            onClick={openBookingDialog}
          >
            <p>
              {t('shopPublic.overview.letsBook')}
              <TouchAppIcon style={{ fontSize: 26 }} />
            </p>
          </button>
        ) : (
          <button
            className={styles.bookingBtnTheme1}
            onClick={openBookingDialog}
          >
            <p>
              按此預約優惠 <TouchAppIcon style={{ fontSize: 32 }} />
            </p>
            <span>最低價保證 + 禮品 / 現金回贈 + 真實評價</span>
          </button>
        ))}
    </>
  );
}

BookingPanel.defaultProps = {
  buttonTheme: 1,
};

export default BookingPanel;
