import { SHOW_ALL_SHARINGS, SHOW_SHARING} from "../../constants/constants";

const initialState =  {
    sharings: [],
    sharing: []
};


export default  (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SHARING:
            return {
                ...state,
                sharing: action.payload
            };
        case SHOW_ALL_SHARINGS:
        return {
            ...state,
             sharings: action.payload
        };

        default: {
            return {
                ...state
            }
        }
    }
}