import moment from 'moment';

import {
  LANDING_PAGE_WILL_FETCH,
  LANDING_PAGE_DID_FETCH,
  LANDING_PAGE_WILL_INVALIDATE,
} from "../../constants/constants";
import api from "../../../api";


export const fetchLandingPage = (alias, device) => (dispatch, getState) => {
  const prevState = getState().landingPageReducer;

  if (prevState.activeParams && prevState.activeParams.alias === alias && prevState.activeParams.device === device) {
    if (prevState.isFetchingLandingPage) {
      // console.log('fetching landing page. No need to fire a fetch request.');
      return Promise.resolve({ res: null, access: false });
    }
    else if (prevState.landingPageLastFetchedAt && moment().diff(prevState.landingPageLastFetchedAt, 'minutes', true) <= 30) {
      // console.log('landing page is still fresh. No need to fire a fetch request.');
      return Promise.resolve({ res: null, access: false });
    }
  }

  dispatch(willFetchLandingPage({ alias, device }));

  return api.landingPage.get(alias, device)
    .then(res => res.data)
    .then(response => {
      if (response.status === 200) {
        dispatch(didFetchLandingPage(response.data));
      }
      else {
        dispatch(didFetchLandingPage(null));
      }
    })
    .catch(() => {
      dispatch(didFetchLandingPage(null));
    });
};

const willFetchLandingPage = ({ alias, device }) => {
  return {
    type: LANDING_PAGE_WILL_FETCH,
    payload: {
      alias,
      device,
    },
  };
};

const didFetchLandingPage = (landingPage) => {
  return {
    type: LANDING_PAGE_DID_FETCH,
    payload: landingPage,
  };
};

export const invalidateLandingPage = () => ({
  type: LANDING_PAGE_WILL_INVALIDATE,
});
