import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import api from '../../../../../api';

import PhotoGridEditable from '../../../../Common/PhotoGridEditable/PhotoGridEditable';

import {
  invalidateShopNewsListFromState,
} from '../../../../../Redux/actions/features/shopNewsAction';

class NewsImageGrid extends Component {

  _isMounted = false;

  state = {
    remoteItems: [],

    isFetchingItems: false,
  };

  componentDidMount() {
    this._isMounted = true;

    if (this.props.newsId) {
      this.fetchImageList();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchImageList = () => {
    this.setState({
      isFetchingItems: true,
    });

    api.shopNewsImage.fetch(this.props.newsId).then(response => {
      if (!this._isMounted) return;

      this.setState({
        remoteItems: response.data,
      });
    })
    .finally(() => {
      if (!this._isMounted) return;

      this.setState({
        isFetchingItems: false,
      });
    });
  }

  handleUploadImageFile = file => {
    // upload a new image
    return api.shopNewsImage.create(this.props.newsId, {
      thumbnailEncoded: file[0],
      imageEncoded: file[1],
    })
    .then(response => {
      this.props.invalidateShopNewsListFromState();
      return response.data;
    });
  }

  handleDeleteRemotePhoto = (photo) => {
    return api.shopNewsImage.destroy(this.props.newsId, photo.id).then(response => {
      this.props.invalidateShopNewsListFromState();
      return response;
    });
  }

  render() {
    const {
      newsId,
      onUploadedAll,
      t,
    } = this.props;
    const {
      remoteItems,
      isFetchingItems,
    } = this.state;

    return (
      <PhotoGridEditable
        autoUpload={newsId > 0}
        inDateAscendingOrder={false}
        headerText={t('shop.newsImage.label')}
        remoteItems={remoteItems}
        isFetchingItems={isFetchingItems}
        showCopyUrlButton={false}
        onUploadImageFile={this.handleUploadImageFile}
        onDeleteRemotePhoto={this.handleDeleteRemotePhoto}
        onUploadedAll={onUploadedAll}
      />
    );
  }
}

NewsImageGrid.defaultProps = {

  onUploadedAll: () => {},

};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        invalidateShopNewsListFromState,
      },
    )(NewsImageGrid),
  ),
);
