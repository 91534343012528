import React from 'react';

import moment from 'moment';


export const STATUS_ACTIVE = 'A';
export const STATUS_INACTIVE = 'I';
export const STATUS_UNLISTED = 'U';

export const VISIBILITY_CURRENTLY_INVISIBLE = 0;
export const VISIBILITY_CURRENTLY_VISIBLE = 1;
export const VISIBILITY_PENDING_VISIBLE = 2;


export function determineCurrentVisibility(article) {
    if ((article.status != STATUS_ACTIVE && article.status != STATUS_UNLISTED) || !article.publishedAt) {
        // not published
        return VISIBILITY_CURRENTLY_INVISIBLE;
    }

    const now = moment();
    if (moment(article.publishedAt).isBefore(now)) {
        if (!article.expiredAt || moment(article.expiredAt).isAfter(now)) {
            // currently visible
            return VISIBILITY_CURRENTLY_VISIBLE;
        }
        else {
            // expired
            return VISIBILITY_CURRENTLY_INVISIBLE;
        }
    }

    // To be visible at a future time
    return VISIBILITY_PENDING_VISIBLE;
}


export const ScheduledPublishIcon = () => {
    return <i className="fas fa-eye" style={{ opacity: 0.4 }}></i>;
}

export const CurrentlyVisibleIcon = () => {
    return <i className="fas fa-eye"></i>;
}

export const CurrentlyHiddenIcon = () => {
    return <i className="fas fa-eye-slash"></i>;
}
