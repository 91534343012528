import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { fetchPaymentMethods } from '../../../../../Redux/actions/definitionActions/paymentMethodActions';

import FilterCheck from '../Item/FilterCheck.component';


const PaymentMethodCheckables = ({
  // from parent:
  selectedItemIds,
  onChange,

  // from `mapStateToProps`:
  items,

  // redux actions:
  fetchPaymentMethods,
}) => {

  const { i18n, t } = useTranslation();

  const [isChinese, setIsChinese] = useState(false);

  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    setIsChinese(!i18n.language.startsWith('en'));
  }, [i18n.language]);

  const toggleExpanded = () => {
    setExpanded(prevVal => !prevVal);
  };

  const handleChange = (item, checked) => {
    onChange(item, checked);
  }

  return (
    <>
      <div className="shop-search-custom sidebar-title" onClick={toggleExpanded} style={{ cursor: 'pointer' }}>
        <span>
          {t('shopList.payMet')}
        </span>

        <span className="sidebar-title__ico d-none d-lg-flex">
          <i className="fas fa-angle-double-down" />
        </span>

        <i className="fas fa-angle-double-down d-lg-none" />
      </div>

      <div className={`filter-item ${isExpanded ? '' : 'd-none d-lg-block'}`}>
        {items.map(item => (
          <FilterCheck
            key={item.id}
            textForLabel={isChinese ? item.chineseName : item.name}
            name="payment_method"
            value={item.id}
            checked={(selectedItemIds || []).findIndex(s => s == item.id) > -1}
            onChange={checked => handleChange(item, checked)}
          />
        ))}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    // array of payment methods:
    items: state.paymentMethodReducer.paymentMethods,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchPaymentMethods,
  }
)(PaymentMethodCheckables);
