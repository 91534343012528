import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import api from '../../../../api';

import { extractShopStaffList, fetchShopStaff } from '../../../../Redux/actions/shopActions/shopStaffActions';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { DataTableStatus, TableHeaderCell } from '../../../Common/DataTable';
import notification from '../../../Common/notification';


import RequestedStaff from './RequestedStaff';
import StaffItem from './StaffItem';


class StaffManagement extends Component {

  state = {
    email: '',
    errorEmail: '',
    disabled: false,
  };

  componentDidMount = () => {
    this.props.fetchShopStaff({ id: this.props.auth.user.id, fields: 'staff_requests' });
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  sendRequest = e => {
    e.preventDefault();

    if (this.state.disabled) {
      return false;
    }
    this.setState({ loading: true, disabled: true });

    api.shopStaff
      .sendInviteToStaff({ email: this.state.email })
      .then(res => {
        if (res.data.message === 'success') {
          notification(res.data.data ? res.data.data : this.props.t('notifications.invitationSent'));
          this.setState({
            email: '',
            errorEmail: '',
            disabled: false,
          });
        } else if (res.data.message === 'incorrect data' && res.data.data.email) {
          this.setState({ disabled: false });
          notification(res.data.data.email[0]);
        } else if (res.data.data === 'Email already exists' || res.data.data === '這電郵失效') {
          this.setState({ disabled: false });
          notification(res.data.data);
        } else if (res.data.data === 'This email is not a staff account' || res.data.data === '沒有這個員工電郵') {
          this.setState({ disabled: false });
          notification(res.data.data);
        } else if (res.data.data === 'The staff already invited' || res.data.data === '已經向他/她發出了邀請') {
          this.setState({ disabled: false });
          notification(res.data.data);
        } else if (
          res.data.data === 'This staff is already working in this shop' ||
          res.data.data === '這位職員已經在您的商店中'
        ) {
          this.setState({ disabled: false });
          notification(res.data.data);
        } else {
          this.setState({ disabled: false });
          notification(this.props.t('notifications.somethingWentWrong'));
        }
      })
      .catch(() => notification(this.props.t('notifications.somethingWentWrong')))
      .finally(this.setState({ loading: false }));
  };

  render() {
    const {
      isFetchingShopStaffDataList,
      staffDataList,
      requestedStaffList,

      auth,
      t,
    } = this.props;

    const { email, errorEmail, disabled } = this.state;

    const routes = [{ title: t('shopPrivate.shopLeftMenu.staff'), path: window.location.pathname }];

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-users" aria-hidden="true" />
          {t('shopPrivate.shopLeftMenu.staff')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">

          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.staff.requestedStaff')}</h2>
          </div>
          <div className="mb-5 main-container__content">
            <div className="tableWrap">
              <table className="adminTable">
                <colgroup>
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '15%' }} />
                  <col />
                  <col style={{ width: 200 }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <TableHeaderCell>{t('generalFields.humanFirstName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.humanLastName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('staff.nickName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.email.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('general.label.action')}</TableHeaderCell>
                  </tr>
                </thead>
                {!isFetchingShopStaffDataList && (
                  <tbody>
                    {requestedStaffList.map((staff, index) => (
                      <RequestedStaff
                        key={index}
                        indexNum={index + 1}
                        currentShopId={auth.user.id}
                        staff={staff}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <DataTableStatus
                isLoading={isFetchingShopStaffDataList}
                itemCount={requestedStaffList.length}
              />
            </div>
          </div>

          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.staff.staffList')}</h2>
          </div>
          <div className="main-container__content">
            <div className="tableWrap">
              <table className="adminTable">
                <colgroup>
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '12%' }} />
                  <col />
                  <col style={{ width: 150 }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <TableHeaderCell>{t('generalFields.humanFirstName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.humanLastName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('staff.nickName.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.staff.phoneNumber')}</TableHeaderCell>
                    <TableHeaderCell>{t('generalFields.email.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('general.label.action')}</TableHeaderCell>
                  </tr>
                </thead>
                {!isFetchingShopStaffDataList && (
                  <tbody>
                    {staffDataList.map((staffData, index) => (
                      <StaffItem
                        key={index}
                        indexNum={index + 1}
                        currentShopId={auth.user.id}
                        staffData={staffData}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <DataTableStatus
                isLoading={isFetchingShopStaffDataList}
                itemCount={staffDataList.length}
              />
            </div>
          </div>

          <div className="mt-4 p-3 p-lg-4 invite-box">
            <h2 className="text-white">{t('shopPrivate.staff.inviteStaff')}</h2>
            <form className="p-3 bg-white d-flex flex-column flex-lg-row">
              <div className="error-field invite__field">
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  className="px-4 py-0 border invite__item"
                  placeholder={t('generalFields.email.label')}
                />
                {errorEmail && (
                  <>
                    <span className="error-ico">
                      <i className="fas fa-info-circle" />
                    </span>
                    <span className="error-text">{errorEmail}</span>
                  </>
                )}
              </div>
              <button className="mt-3 mt-lg-0 invite__btn" onClick={this.sendRequest} disabled={disabled}>
                {t('shopPrivate.staff.invite')}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const auth = state.auth;

  const shopId = auth.user.id;

  const {
    isFetchingShopStaffDataList,
    staffDataList,
    requestedStaffList,
  } = extractShopStaffList(state, shopId);

  return {
    auth,

    isFetchingShopStaffDataList,
    staffDataList,
    requestedStaffList,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    {
      fetchShopStaff,
    }
  )(StaffManagement)
);
