import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import ExternalFeedEditable from './ExternalFeedEditable';


class ExternalFeedListEditable extends Component {
  _isMounted = false;

  state = {
    isDeleting: false,

    feeds: [],
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFetchingItems && !this.props.isFetchingItems) {
      this.setState({
        feeds: this.props.remoteItems,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCreateFeed = (resourceUrl) => {
    return this.props.onCreateFeed(resourceUrl);
  }

  handleDeleteFeed = (feed) => {
    return this.props.onDeleteRemoteFeed(feed);
  }

  render() {
    const {
      maxFeedLimit,
      t,
    } = this.props;

    const {
      feeds,
    } = this.state;

    const hasVacancy = (maxFeedLimit < 0 || feeds.length < maxFeedLimit);

    return (
      <>
        <h3>
          {t('externalFeed.title')}
        </h3>

        {hasVacancy && <ExternalFeedEditable onCreate={this.handleCreateFeed} />}

        {feeds.map((feed, index) => (
          <ExternalFeedEditable
            key={index}
            feed={feed}
            onDelete={() => this.handleDeleteFeed(feed)}
          />
        ))}
      </>
    );
  }
}

ExternalFeedListEditable.defaultProps = {

  autoUpload: true,

  isFetchingItems: false,

  maxFeedLimit: -1, // negative value stands for no limit

  remoteItems: [],

  onCreateFeed: (resourceUrl) => Promise.resolve(/* A remote feed json*/),

  onDeleteRemoteFeed: (feed) => Promise.resolve(),

};

export default withTranslation()(
  withRouter(
    connect(
      null,
      {
      }
    )(ExternalFeedListEditable)
  )
);
