import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  ch: string;
  en: string;
}

const Localize = ({ ch, en }: Props) => {
  const { i18n } = useTranslation();

  const preferred = useMemo(() => {
    return (i18n.language.startsWith('en') ? en : ch) || ch || en || '';
  }, [ch, en, i18n.language]);

  return preferred;
};

export default Localize;
