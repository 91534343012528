import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { AppButtonLink } from '../Common/Button';

import styles from './SignUpDialog.module.css';

const SignUpDialog = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={onClose}
      aria-labelledby="sign-up-dialog-title"
    >
      <DialogTitle
        id="sign-up-dialog-title"
        className={styles.title}
        disableTypography
      >
        <div className="d-flex align-items-center">
          <h2 className="flex-fill m-0 text-white">{t('signUp.whoAreYou')}</h2>

          <button className="btn btn-link" onClick={onClose}>
            <i className="fas fa-times text-white" />
          </button>
        </div>
      </DialogTitle>

      <DialogContent className={`${styles.content}`}>
        <div className={styles.actions}>
          <div className="row">
            <div className="my-2 col-lg-4">
              <AppButtonLink
                to="/register-shop-member"
                shape="round"
                size="block"
                theme="muted"
                onClick={onClose}
              >
                {t('signUp.shop')}
              </AppButtonLink>
            </div>
            <div className="my-2 col-lg-4">
              <AppButtonLink
                to="/register-staff-member"
                shape="round"
                size="block"
                theme="muted"
                onClick={onClose}
              >
                {t('signUp.staff')}
              </AppButtonLink>
            </div>
            <div className="my-2 col-lg-4">
              <AppButtonLink
                to="/register-customer-member"
                shape="round"
                size="block"
                theme="muted"
                onClick={onClose}
              >
                {t('signUp.customer')}
              </AppButtonLink>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpDialog;
