import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Elements, StripeProvider } from 'react-stripe-elements';


import notification from '../../../Common/notification';
import AlbumCell from '../../../Common/AlbumCell/AlbumCell';
import Spinner from '../../../Common/Spinner/Spinner';

import { deleteAlbum, showAllAlbums } from '../../../../Redux/actions/shopActions/galleryShopActions';

import CheckoutForm from '../../../AdminPage/PaymentMethods/CheckoutForm';

import AlbumPopUpCreation from './AlbumPopUpCreation';
import { STRIPE_KEY } from '../../../../environment';
import { AppButton } from '../../../Common/Button';


class Albums extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: [],
      showAlbumCreationStatus: false,
      showPayModal: false,
      btBuyName: '',
      buyingComplete: 'not',
      photoLimit: 0,
    };
  }

  componentDidMount() {
    this.setState({
      photoLimit: this.props.shopData.photo_limit ? this.props.shopData.photo_limit.limit : 0,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shopData.photo_limit !== this.props.shopData.photo_limit) {
      this.setState({
        photoLimit: this.props.shopData.photo_limit.limit,
      });
    }
  }

  toggleAlbumCreation = () => {
    this.setState({
      showAlbumCreationStatus: !this.state.showAlbumCreationStatus,
    });
  };

  handleClickDeleteAlbum = id => {
    this.props.deleteAlbum({ id }).then(res => {
      if (res.res.data.message === 'success') {
        this.props.showAllAlbums({ id: this.props.auth.user.id });
        notification(this.props.t('notifications.albumDeleted'));
      } else {
        notification(this.props.t('notifications.somethingWasWrong'));
      }
    });
  };

  changePhotoLimit = limit => {
    this.setState({
      photoLimit: limit,
    });
  };

  changeBuyingComplete = step => {
    //statuses: 1 - not, 2 - pending, 3 - yes
    this.setState({
      buyingComplete: step,
    });
  };

  togglePayModal = e => {
    this.setState({
      showPayModal: !this.state.showPayModal,
      btBuyName: e && e.target.name ? e.target.name : '',
      buyingComplete: e ? 'not' : 'yes',
    });
  };

  render() {
    const { albums, t } = this.props;
    const reviewAlbumCoverPhoto = albums.review_album &&
      albums.review_album.data &&
      albums.review_album.data[0];
    const { showPayModal, btBuyName, buyingComplete, photoLimit } = this.state;

    return (
      <>
        <div className="album-head">
          <h2>{t('shopPrivate.photoGallery.albums')}</h2>
          <AppButton theme="primary" onClick={this.toggleAlbumCreation}>
            {t('shopPrivate.photoGallery.createAlbum')}
          </AppButton>
        </div>

        {this.state.showAlbumCreationStatus && (
          <AlbumPopUpCreation auth={this.props.auth} toggleAlbumCreation={this.toggleAlbumCreation} />
        )}

        <div className="p-3 album-info">
          <div className="album-info__count">
            {t('shopPrivate.photoGallery.youHave', { photoLimit: photoLimit })}
          </div>
          <div className="album-info__limit">
            {t('shopPrivate.photoGallery.limitedPhotos')}{' '}
            <button className="album-buy" name="shop_photoLimit" onClick={this.togglePayModal}>
              {t('shopPrivate.photoGallery.buy')}
            </button>
            {t('shopPrivate.photoGallery.spaceFor')}
          </div>
        </div>

        {buyingComplete === 'pending' && (
          <div>
            <Spinner display="block" />
          </div>
        )}

        {showPayModal && (
          <StripeProvider apiKey={STRIPE_KEY}>
            <div className="modal-wrapper">
              <div className="modal-wrapper__inner">
                <div className="payment-popup">
                  <button className="close-modal" onClick={this.togglePayModal}>
                    <i className="fas fa-times" />
                  </button>
                  <div className="example example4 pay-block">
                    <h2 className="pay-block__title">
                      {t('shopPrivate.photoGallery.youBuySpace', { photos_num: albums.amount_photos_package })}
                    </h2>
                    <Elements>
                      <CheckoutForm
                        togglePayModal={this.togglePayModal}
                        changeBuyingComplete={this.changeBuyingComplete}
                        product_id={this.props.match.params.id && this.props.match.params.id}
                        btName={btBuyName}
                        changePhotoLimit={this.changePhotoLimit}
                        price={this.props.albums.photo_package_price}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </StripeProvider>
        )}

        <div className="main-container__content">
          <div className="row">
            {albums.albums && albums.albums.map(album => (
              <AlbumCell
                key={album.id}
                album={album}
                coverPhoto={album.photos[0]}
                linkTo={`/shop-settings/gallery/${album.id}`}
                showDeleteButton={true}
                showEditButton={true}
                onDelete={() => this.handleClickDeleteAlbum(album.id)}
              />
            ))}

            {reviewAlbumCoverPhoto && (
              <AlbumCell
                albumName={t('shopPrivate.photoGallery.reviewsPhotos')}
                coverPhoto={reviewAlbumCoverPhoto}
                linkTo="/shop-settings/gallery/review"
                showViewButton={true}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    albums: state.galleryReducer.albums,
    auth: state.auth,
    shopData: state.ShopReducer.shopData,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      { deleteAlbum, showAllAlbums }
    )(Albums)
  )
);
