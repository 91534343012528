import Ellipsize from './Ellipsize';
import LineBreakable from './LineBreakable';

import styles from './EllipsizeBlock.module.css';

interface Props {
  className?: string;
  maxlength?: number;
  text?: string;
  onClick?: () => void;
}

const EllipsizeBlock = ({
  className = '',
  maxlength = 50,
  text = '',
  onClick = () => {},
}: Props) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <LineBreakable className={className}>
        <Ellipsize maxlength={maxlength} text={text} />
      </LineBreakable>
      <div className={styles.overlay} />
    </div>
  );
};

export default EllipsizeBlock;
