import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';


import PhotoViewer from '../../../Common/PhotoViewer/PhotoViewer';

function NextArrow({ onClick }) {
  return (
    <div className="mob-slider-control mob-slider-control--next">
      <button onClick={onClick}>
        <i className="fas fa-angle-right" aria-hidden="true" />
      </button>
    </div>
  );
}

function PrevArrow({ onClick }) {
  return (
    <div className="mob-slider-control">
      <button onClick={onClick}>
        <i className="fas fa-angle-left" aria-hidden="true" />
      </button>
    </div>
  );
}

class ShopPhotoSlider extends Component {

  state = {
    isPhotoViewerOpened: false,
    activePhotoIndex: 0,
  };

  openPhotoViewer = (index) => {
    this.setState({
      isPhotoViewerOpened: true,
      activePhotoIndex: index,
    });
  }

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
    });
  }

  render() {
    const {
      className,
      photos,
      url,
      t,
    } = this.props;

    const {
      isPhotoViewerOpened,
      activePhotoIndex,
    } = this.state;

    const sliderSettings = {
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: Math.min(photos.length, 3),
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
      <>
        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={photos}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        <div className={`shop-group ${className}`}>
          <div className="d-flex align-item-center justify-content-between shop-group__panel">
            <Link to={`${url.slice(0, url.lastIndexOf('main'))}gallery`}>
              <p className="theme-subtitle">{t('shopPublic.overview.photoGallery')}</p>
            </Link>
          </div>

          {photos.length > 0 && (
            <div className="mt-3 shop-mob-gallery">
              <Slider {...sliderSettings}>
                {photos.map((photo, index) => (
                  <div key={index} className="shop-gallery-slide">
                    <img
                      src={photo.url}
                      alt=""
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openPhotoViewer(index)}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(ShopPhotoSlider);
