import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import moment from 'moment';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';


import { Booking, BookingMaxlength, Shop, StaffData } from 'src/models/entity';

import {
  decrementSiteBusyCount,
  incrementSiteBusyCount,
} from 'src/Redux/actions/features/siteBusyCountActions';

import notification from 'src/Components/Common/notification';
import { equalId } from 'src/utils/api/api-utils';
import { shopAPI, shopBookingAPI } from 'src/utils/api';

import useErrorHandler from 'src/hooks/useErrorHandler';

import { AppButton } from 'src/Components/Common/Button';
import { FormField, Textarea } from 'src/Components/Common/Form';
import Spinner from 'src/Components/Common/Spinner/Spinner';
import { CurrencyAmount } from 'src/Components/Common/Text';

import BookingDateTimePicker from 'src/Components/Common/Booking/BookingDateTimePicker';


interface Props {
  booking: Booking;

  onClose: () => void;

  onEdited: () => void;

  decrementSiteBusyCount: () => void;
  incrementSiteBusyCount: () => void;
}

const BookingEditByProviderDialog = ({
  booking,

  onClose,

  onEdited,

  // redux actions:
  decrementSiteBusyCount,
  incrementSiteBusyCount,
}: Props) => {
  const { t } = useTranslation();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  const [shop, setShop] = useState<Shop>();

  /**
   * This state helps prevent customer from accidentally / unintentionally dismissing the dialog,
   * either by clicking / tapping backdrop area or pressing ESC after user has filled / selected something.
   *
   * A `true` value => User has NOT ever interacted with the form.
   */
  const [isPristine, setPristine] = useState(true);

  const [dirtyBookingAt, setDirtyBookingAt] = useState<Date>();

  const [bookingDateError, setBookingDateError] = useState('');
  const [bookingTimeError, setBookingTimeError] = useState('');

  const [isFetchingStaff, setIsFetchingStaff] = useState(true);
  const [staffDataOptions, setStaffDataOptions] = useState<StaffData[]>([]);
  const [dirtyStaffData, setDirtyStaffData] = useState<StaffData>();
  const [staffError, setStaffError] = useState('');

  const [dirtyBookingPrice, setDirtyBookingPrice] = useState('0');

  const [dirtyProviderRemarks, setDirtyProviderRemarks] = useState(booking.providerRemarks);

  const [isUpdatingBooking, setUpdatingBooking] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const customerName = useMemo(() => {
    if (!booking) {
      return '';
    }

    return booking.customer_name
      ? `${booking.customer_name.first_name} ${booking.customer_name.last_name}`
      : booking.customer_other
      ? booking.customer_other.name
      : '';
  }, [booking]);

  const customerContactNumber = useMemo(() => {
    if (!booking) {
      return '';
    }

    return booking.customer_phone
      ? booking.customer_phone.phone
      : booking.customer_other
      ? booking.customer_other.phone
      : '';
  }, [booking]);

  const isFormDataSufficient = useMemo(() => {
    if (!dirtyBookingAt) {
      return false;
    }

    if (!dirtyStaffData) {
      return false;
    }

    return true;
  }, [dirtyBookingAt, dirtyStaffData]);

  // Initialization
  useEffect(() => {
    const bookingAtMoment = moment(booking.date + ' ' + booking.time);

    setDirtyBookingAt(bookingAtMoment.toDate());

    setDirtyBookingPrice(booking.price);

    shopAPI.getShop(booking.shop_id, ['schedule']).then((shop) => {
      setShop(shop);
    });

    // Fetch staff options
    setIsFetchingStaff(true);
    shopAPI.getShopStaffList(booking.shop_id)
      .then((staffDataList) => {
        setStaffDataOptions(staffDataList);

        if (booking.staff_id) {
          // For pre-population of staff dropdown
          setDirtyStaffData(
            staffDataList.find(
              (staffData) => staffData.user_id === booking.staff_id
            )
          );
        }
      })
      .finally(() => {
        setIsFetchingStaff(false);
      });
  }, []);

  const handleDialogClose = (e: {}, reason: string) => {
    if (isPristine) {
      onClose();
    }
  };

  const handleChangeDateTime = (
    bookingAtDate: Date,
    isInitiatedByUser: boolean
  ) => {
    setDirtyBookingAt(bookingAtDate);

    if (isInitiatedByUser) {
      setPristine(false);
    } else {
      // It is probably the initial value (default value) of the dat time picker
    }

    // clear any error messages
    setBookingDateError('');
    setBookingTimeError('');
  };

  const handleChangeStaff = (value: string) => {
    const selectedStaffData = staffDataOptions.find((staff) =>
      equalId(staff.user_id, value)
    );

    setDirtyStaffData(selectedStaffData);

    if (booking.service?.price) {
      // When shop owner or site admin assigns another staff,
      // simply apply the original service price, without staff charge
      setDirtyBookingPrice(booking.service?.price);
    }

    setPristine(false);
  };

  const handleChangeProviderRemarks = (value: string) => {
    setDirtyProviderRemarks(value);

    setPristine(false);
  };

  const fireCheckTime = () => {
    // clear any error messages
    setBookingDateError('');
    setBookingTimeError('');

    const bookingAt = moment(dirtyBookingAt);

    return shopBookingAPI.checkTime({
      shopId: booking.shop_id || '',
      date: bookingAt.format('YYYY-MM-DD'),
      time: bookingAt.format('HH:mm'),
    }).catch((e) => {
      if (e.response?.status === 422) {
        const validationErrors = e.response.data.errors;
        if (validationErrors.date) {
          setBookingDateError(validationErrors.date.join('\n'));
        }

        if (validationErrors.time) {
          setBookingTimeError(validationErrors.time.join('\n'));
        }
      }

      throw e;
    });
  };

  const fireUpdateBooking = () => {
    const bookingAt = moment(dirtyBookingAt);

    const data: shopBookingAPI.UpdateBookingRequest = {
      staffId: dirtyStaffData?.user_id,
      date: bookingAt.format('YYYY-MM-DD'),
      time: bookingAt.format('HH:mm'),
      providerRemarks: dirtyProviderRemarks,
    };

    return shopBookingAPI
      .updateBooking(booking.id, data)
      .then((res) => {
        notification(t('notifications.changesSaved'));
        onEdited();
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e);
        // }
      });
  };

  const handleSubmit = () => {
    setErrorMessage('');

    setUpdatingBooking(true);
    incrementSiteBusyCount();

    fireCheckTime()
      .then(() => {
        return fireUpdateBooking();
      })
      .catch((e) => {})
      .finally(() => {
        setUpdatingBooking(false);
        decrementSiteBusyCount();
      });
  };

  return (
    <Dialog
      classes={{ paper: 'book-window' }}
      fullWidth={true}
      keepMounted={true}
      maxWidth="sm"
      // open={open}
      open={true}
      onClose={handleDialogClose}
      aria-labelledby="edit-booking-dialog-title"
    >
      <DialogTitle id="edit-booking-dialog-title">
        <div className="d-flex align-items-center">
          <h2 className="flex-fill m-0">{t('shopPrivate.bookings.edit')}</h2>

          <button className="btn btn-link" onClick={onClose}>
            <i className="fas fa-times text-muted" />
          </button>
        </div>
      </DialogTitle>

      <DialogContent>
        <FormField className="m-0" label={t('service.serviceName.label')}>
          {booking.service_name}
        </FormField>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <fieldset disabled={isUpdatingBooking}>
            {/* Booking's date time */}
            {shop ? (
              <BookingDateTimePicker
                dateError={bookingDateError}
                defaultValue={dirtyBookingAt}
                disabled={isUpdatingBooking}
                minToday
                shop={shop}
                timeError={bookingTimeError}
                onChange={handleChangeDateTime}
              />
            ) : (
              <Spinner />
            )}

            {/* Picker for staff */}
            <FormField
              errorMessage={staffError}
              label={t('shopPrivate.bookings.selectStaff')}
              required
            >
              {isFetchingStaff ? (
                <Spinner />
              ) : (
                <select
                  className="form-item"
                  value={dirtyStaffData?.user_id}
                  onChange={(e) => handleChangeStaff(e.target.value)}
                >
                  <option value="">
                    {t('action.selectOneFromEnumeration')}
                  </option>
                  {staffDataOptions.map((staffData) => (
                    <option key={staffData.user_id} value={staffData.user_id}>
                      {staffData.nickname}
                    </option>
                  ))}
                </select>
              )}
            </FormField>

            {/* Remarks by service provider (shop) */}
            <FormField
              className="m-0"
              label={t('booking.providerRemarks.label')}
              hint={
                <div
                  className={`text-muted ${
                    (dirtyProviderRemarks?.length || 0) >
                    BookingMaxlength.providerRemarks
                      ? 'text-danger'
                      : ''
                  }`}
                >
                  {t('generalFields.freeText.maxlengthHelp', {
                    textLength: dirtyProviderRemarks?.length || 0,
                    maxlength: BookingMaxlength.providerRemarks,
                  })}
                </div>
              }
            >
              <Textarea
                maxLength={BookingMaxlength.providerRemarks}
                name="message"
                value={dirtyProviderRemarks}
                onChange={(e) => handleChangeProviderRemarks(e.target.value)}
              />
            </FormField>

            <div className="event-extra">
              <p>{t('shopPublic.overview.createBooking.price')}:</p>
              <span>
                <CurrencyAmount amount={dirtyBookingPrice} />
              </span>
            </div>

            {customerName && (
              <div className="event-extra">
                <p>{t('shopPrivate.bookings.custName')}:</p>
                <span>{customerName}</span>
              </div>
            )}

            {customerContactNumber && (
              <div className="event-extra">
                <p>{t('shopPrivate.bookings.custCont')}:</p>
                <span>{customerContactNumber}</span>
              </div>
            )}
          </fieldset>
        </form>
      </DialogContent>

      <DialogActions>
        <AppButton
          disabled={!isFormDataSufficient || isUpdatingBooking}
          theme="primary"
          onClick={() => handleSubmit()}
        >
          {isUpdatingBooking ? <Spinner /> : t('action.save')}
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, {
  decrementSiteBusyCount,
  incrementSiteBusyCount,
})(BookingEditByProviderDialog);
