import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';


import { isShop } from '../../../Functions/authHelpers';

import { GetNews } from '../../../Redux/actions/features/shopNewsAction';
import { shopData } from '../../../Redux/actions/shopActions/shopData';
import { fetchShopStaff } from '../../../Redux/actions/shopActions/shopStaffActions';
import { fetchShopNews } from '../../../Redux/actions/features/shopNewsAction';
import { getRecommended } from '../../../Redux/actions/shopActions/serviceAction';
import { showAllAlbums } from '../../../Redux/actions/shopActions/galleryShopActions';
import { getAllProducts } from '../../../Redux/actions/shopActions/productAction';
import { getAllReviews } from '../../../Redux/actions/features/reviewActions';

import preloader from '../../../dist/Preloader.gif';

import ShopTypeView from './ShopTypeView';
import UserTypeView from './UserTypeView';


class ShopMainPage extends Component {

  state = {
    loading: true,
    shopData: {},
  };

  isShopId = (dirtyId) => {
    return !(dirtyId === 'main' ||
      dirtyId === 'news' ||
      dirtyId === 'review' ||
      dirtyId === 'gallery' ||
      dirtyId === 'price-list' ||
      dirtyId === 'stylist' ||
      dirtyId === 'product');
  }

  componentWillReceiveProps(nextProps) {
    if (this.isShopId(nextProps.match.params.id) && this.props.match.params.id !== nextProps.match.params.id) {
      const nextShopId = nextProps.match.params.id;

      this.setState({
        loading: true,
      });

      this.props.shopData({ id: nextShopId }).then(res => {
        if (res.res.data.message === 'success') {
          this.setState({
            shopData: res.res.data.data,
            loading: false,
          });
        }
      }).catch(() => {
        this.setState({
          loading: false,
        });
      });

      setTimeout(() => this.props.fetchShopStaff({ id: nextShopId }), 50);

      setTimeout(() => this.props.fetchShopNews(nextShopId), 100);

      setTimeout(() => this.props.getAllReviews({ id: nextShopId }), 150);

      setTimeout(() => this.props.getRecommended({ id: nextShopId }), 200);

      return false;
    }

    if (
      this.props.auth.user.user_type_id !== nextProps.auth.user.user_type_id &&
      isNaN(this.props.match.params.id) &&
      isShop(nextProps.auth.user.user_type_id)
    ) {
      this.props.shopData({ id: nextProps.auth.user.id }).then(res => {
        if (res.res.data.message === 'success') {
          this.setState({
            shopData: res.res.data.data,
            loading: false,
          });
        }
      });

      this.props.fetchShopStaff({ id: nextProps.auth.user.id });
      this.props.fetchShopNews(nextProps.auth.user.id);
      this.props.showAllAlbums({ id: nextProps.auth.user.id });
      this.props.getAllReviews({ id: nextProps.auth.user.id });
      this.props.getAllProducts({ id: nextProps.auth.user.id });
      this.props.getRecommended({ id: nextProps.auth.user.id });

      axios.get(`/api/shop-news?shop_id=${nextProps.auth.user.id}`).then(res => {
        if (res.data.message === 'success') {
          this.props.GetNews(res.data.data);
        }
      });
    }
  }

  componentDidMount() {
    const {
      match,
    } = this.props;

    const shopId = match.params.id;

    if (
      shopId !== 'main' &&
      shopId !== 'news' &&
      shopId !== 'review' &&
      shopId !== 'gallery' &&
      shopId !== 'price_list' &&
      shopId !== 'stylist' &&
      shopId !== 'product'
    ) {
      this.props.shopData({ id: shopId }).then(res => {
        if (res.res.data.message === 'success') {
          this.setState({
            shopData: res.res.data.data,
            loading: false,
          });
        }
      });

      setTimeout(() => this.props.fetchShopStaff({ id: shopId }), 50);

      setTimeout(() => this.props.fetchShopNews(shopId), 100);

      setTimeout(() => this.props.getAllReviews({ id: shopId }), 150);

      setTimeout(() => this.props.getRecommended({ id: shopId }), 200);

      // this.props.getAllProducts({ id: shopId });
      // this.props.showAllAlbums({ id: shopId });

      /*axios.get(`/api/shop-news?shop_id=${shopId}`).then(res => {
        if (res.data.message === 'success') {
          this.props.GetNews(res.data.data);
        }
      });*/
    }
    else if (this.props.auth.isShop) {
      this.props.shopData({ id: this.props.auth.user.id }).then(res => {
        if (res.res.data.message === 'success') {
          this.setState({
            shopData: res.res.data.data,
            loading: false,
          });
        }
      });

      setTimeout(() => this.props.getRecommended({ id: this.props.auth.user.id }), 90);

      setTimeout(() => this.props.fetchShopStaff({ id: this.props.auth.user.id }), 70);

      setTimeout(() => this.props.showAllAlbums({ id: this.props.auth.user.id }), 70);

      // this.props.getAllProducts({ id: this.props.auth.user.id });

      setTimeout(() => this.props.getAllReviews({ id: this.props.auth.user.id }), 70);

      /*axios.get(`/api/shop-news?shop_id=${this.props.auth.user.id}`).then(res => {
        if (res.data.message === 'success') {
          this.props.GetNews(res.data.data);
        }
      });*/
    }
  }

  render() {
    const {
      auth,
      match,
    } = this.props;
    const {
      shopData,
    } = this.state;

    const shopId = match.params.id;

    return (
      <>
        {this.state.loading ? (
          <img src={preloader} className="preloader" alt="" />
        ) : (
          <>
            {(auth.isShop && !this.isShopId(shopId)) ? (
              <ShopTypeView
                shop={shopData}
              />
            ) : (
              <UserTypeView
                shop={shopData}
              />
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      GetNews,
      shopData,
      fetchShopStaff,
      fetchShopNews,
      getAllReviews,
      getAllProducts,
      showAllAlbums,
      getRecommended,
    }
  )(ShopMainPage)
);
