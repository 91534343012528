import { lazy, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useAuthenticated } from '../hooks/auth';

import Spinner from './Common/Spinner/Spinner';

import FeatureArticleDetail from './Pages/FeatureArticle/FeatureArticleDetail';
import FeatureArticleList from './Pages/FeatureArticle/FeatureArticleList';
import LandingPage from './Pages/LandingPage/LandingPage';
import SearchShops from './Pages/MainPage/SearchShops/SearchShops.component';
import ShopMainPage from './Pages/ShopPublicPage/ShopMainPage';


/**
 * To reduce (first) bundle size, certain pages / modules are lazied load.
 * https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
 */

// Auth
const AlreadyConfirmed = lazy(() => import('./Pages/AlreadyConfirmed'));
const ResetPass = lazy(() => import('./Auth/ResetPass'));
const SignUpAsCustomer = lazy(() => import('./Auth/SignUpAsCustomer'));
const SignUpAsShop = lazy(() => import('./Auth/SignUpAsShop'));
const SignUpAsStaff = lazy(() => import('./Auth/SignUpAsStaff'));

const CustomerMainPage = lazy(() => import('./Pages/CustomerPublicPage/CustomerMainPage'));
const StaffMainPage = lazy(() => import('./Pages/StaffPublicPage/StaffMainPage'));

const GeneralPromotionServicesAndProducts = lazy(() =>
  import(
    './Pages/MainPage/GeneralPromotions/GeneralPromotionServicesAndProducts'
  )
);

const TermsOfUse = lazy(() => import('./Pages/TermsOfUse/TermsOfUse'));

const VideoChannel = lazy(() => import('./Pages/VideoChannel/VideoChannel'));

// Shop's private pages, only for shop owner
const InvoicePage = lazy(() =>
  import('./AdminPage/InvoiceManagement/InvoicePage/InvoicePage')
);
const ShopSettingPage = lazy(() =>
import('./Pages/ShopPrivatePage/ShopSettingPage')
);
const StaffRejected = lazy(() => import('./Pages/StaffRejected'));
const FastCheckoutBooking = lazy(() =>
  import('./Pages/FastCheckoutBooking/FastCheckoutBooking')
);

// Staff's private pages, only for staff
const StaffSettingPage = lazy(() =>
  import('./Pages/StaffPrivatePage/StaffSettingPage')
);

// Customer's private pages, only for customer
const CustomerSettingPage = lazy(() =>
  import('./Pages/CustomerPrivatePage/CustomerSettingPage')
);

// Admin Panel, only for Site Operator / Site Admin
const AcceptOperatorInvitation = lazy(() => import('./AdminPage/AcceptOperatorInvitation'));
const AdminPanelLayout = lazy(() => import('./AdminPage/AdminPanelLayout'));

const SiteBusySpinner = () => {
  const { t } = useTranslation();

  return (
    <div
      className="d-flex alert alert-warning"
      style={{
        position: 'fixed',
        top: '36%',
        left: '50%',
        width: 500,
        height: 100,
        transform: 'translate(-50%, -50%)',
        padding: 15,
        borderWidth: 3,
        borderColor: '#856404',
        borderRadius: 5,
        fontSize: '1.25rem',
        zIndex: 9999,
      }}
    >
      <div className="d-flex flex-row align-items-center">
        <div style={{ width: 60, marginRight: 20 }}>
          <Spinner display="block" />
        </div>
        <span>{t('notifications.siteBusy')}</span>
      </div>
    </div>
  );
};

interface Props {
  isSiteBusy: boolean;
}

const MainComponent = ({ isSiteBusy }: Props) => {
  const history = useHistory();

  const {
    isAuthInitialized,
    isCustomer,
    isStaff,
    isShop,
    isAdmin,
  } = useAuthenticated();

  const featureArticleUrlPrefix = '/美容-美髮-按摩優惠';

  useEffect(() => {
    // Let newly signed-up customer automatically redirect back to previously visiting page
    const redirectUrlAfterSignup = sessionStorage.getItem('redirectUrlAfterSignup');
    if (redirectUrlAfterSignup && localStorage.FinderJWT) {
      // The presence of a localStorage.FinderJWT means user is authenticated
      // console.log('Redirecting to ', sessionStorage.getItem('redirectUrlAfterSignup'));
      history.push(redirectUrlAfterSignup);

      sessionStorage.removeItem('redirectUrlAfterSignup');
    }
  }, []);

  return (
    <div className="wrapper">
      {isSiteBusy && <SiteBusySpinner />}

      {isAuthInitialized && (
        <Switch>
          {/* User account registration */}
          <Route path="/register-shop-member" component={SignUpAsShop} />
          <Route path="/register-staff-member" exact component={SignUpAsStaff} />
          <Route path="/register-staff-member/:token" component={SignUpAsStaff} />
          <Route path="/register-customer-member" component={SignUpAsCustomer} />
          <Route path="/staff-rejected" component={StaffRejected} />
          <Route path="/user_already_confirmed" component={AlreadyConfirmed} />
          <Route path="/password/reset/:token" component={ResetPass} />


          {/* Shop list */}
          {/* TODO (WIP) Temporarily disable the pre-specified default service filter via url param for shop list @e0dd114 */}
          {/* <Route path="/shops/:desiredDefaultServiceName?" component={SearchShops} /> */}
          <Route path="/shops" component={SearchShops} />

          {/* Shop's public pages */}
          <Route path="/shop/:id" component={ShopMainPage} />

          {/* Staff's public pages */}
          <Route path="/staff/:id" component={StaffMainPage} />

          {/* Customer's public pages */}
          <Route path="/customer/:customerAlias" component={CustomerMainPage} />

          {/* Shop's private pages */}
          {isShop && (
            <Route path="/shop-settings" component={ShopSettingPage} />
          )}

          <Route path="/checkout-booking/:checkoutToken" component={FastCheckoutBooking} />
          <Route path="/invoice/:shopId/:token" component={InvoicePage} />

          {/* Staff's private pages */}
          {isStaff && (
            <Route path="/staff-settings" component={StaffSettingPage} />
          )}

          {/* Customer's private pages */}
          {isCustomer && (
            <Route path="/customer-settings" component={CustomerSettingPage} />
          )}

          {/* Feature Article List */}
          <Redirect from="/topics" to={featureArticleUrlPrefix} />
          <Route path={featureArticleUrlPrefix} exact component={FeatureArticleList} />

          {/* Old article links, existed before "Feature Article" is introduced: */}
          <Redirect from="/BeyondMadHair" to={`${featureArticleUrlPrefix}/BeyondMadHair`} />
          <Redirect from="/HairType" to={`${featureArticleUrlPrefix}/HairType`} />
          <Redirect from="/HairWash" to={`${featureArticleUrlPrefix}/HairWash`} />
          <Redirect from="/KowloonSalon" to={`${featureArticleUrlPrefix}/KowloonSalon`} />
          <Redirect from="/OilHair" to={`${featureArticleUrlPrefix}/OilHair`} />
          <Redirect from="/SalonLunaHoliday" to={`${featureArticleUrlPrefix}/SalonLunaHoliday`} />
          <Redirect from="/SoSweetXSalonFinder" to={`${featureArticleUrlPrefix}/SoSweetXSalonFinder`} />

          {/* New article links: */}
          <Route path={`${featureArticleUrlPrefix}/:alias`} component={FeatureArticleDetail} />


          {/* Promotions */}
          <Route path="/promotion" component={GeneralPromotionServicesAndProducts} />


          {/* Videos */}
          <Route path="/video" component={VideoChannel} />


          {/* Terms and Policy */}
          <Route path="/term-policy" component={TermsOfUse} />


          {/* before becoming operator and visit Admin Panel... */}
          <Route path="/accept-operator/:token" component={AcceptOperatorInvitation} />

          {/* Admin Panel */}
          {isAdmin && <Route path="/admin" component={AdminPanelLayout} />}


          {/* Landing Page */}
          <Route path="/:landingPageAlias?" component={LandingPage} />
        </Switch>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    isSiteBusy: state.siteBusyCountReducer.busyCount > 0,
  };
}

export default connect(mapStateToProps)(MainComponent);
