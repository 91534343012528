import { useSelector } from 'react-redux';

import { User } from 'src/models/entity';

function useAuthenticated() {
  const isAuthInitialized = useSelector<boolean, boolean>(
    (state: any) => state.auth.isAuthInitialized
  );

  const isAuthenticated = useSelector<boolean, boolean>(
    (state: any) => state.auth.isAuthenticated
  );

  const isAuthenticating = useSelector<boolean, boolean>(
    (state: any) => state.auth.isAuthenticating
  );

  const isCustomer = useSelector<boolean, boolean>(
    (state: any) => state.auth.isCustomer
  );
  const isStaff = useSelector<boolean, boolean>(
    (state: any) => state.auth.isStaff
  );
  const isShop = useSelector<boolean, boolean>(
    (state: any) => state.auth.isShop
  );
  const isAdmin = useSelector<boolean, boolean>(
    (state: any) => state.auth.isAdmin
  );

  const user = useSelector<User, User>((state: any) => state.auth.user);

  return {
    isAuthInitialized,

    isAuthenticated,
    isAuthenticating,

    isCustomer,
    isStaff,
    isShop,
    isAdmin,

    user,
  };
}

export default useAuthenticated;
