import {
  isCustomer,
  isShop,
  isStaff,
} from '../../../Functions/authHelpers';
import {
  AUTH_AUTHENTICATING,
  AUTH_LOGOUT,
  SET_USER_DATA,
  UPDATE_USER_PROPERTY,
  SAVE_USER_SETTINGS,
} from '../../constants/constants';

const initialState = {
  /**
   * Expected to be changed to `true` only once during lifetime of a "page"
   */
  isAuthInitialized: false,

  isAuthenticating: false,

  isAuthenticated: false,

  /**
   * An `User` object of current user, with a extra child `user_type_data` object
   * for different user type.
   */
  user: {},

  /**
   * A quick lookup on whether current user is a customer
   */
  isCustomer: false,

  /**
   * A quick lookup on whether current user is a staff
   */
  isStaff: false,

  /**
   * A quick lookup on whether current user is a shop owner
   */
  isShop: false,

  /**
   * A quick lookup on whether current user is site admin / site operator
   *
   * TODO Rename it as `isOperator`.
   */
  isAdmin: false,

  /**
   * List of permissions granted to current user
   */
  userRights: [
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: true,
      };

    case SET_USER_DATA:
      return {
        ...state,
        isAuthInitialized: true,
        isAuthenticating: false,
        isAuthenticated: true,

        user: action.payload,

        isCustomer: isCustomer(action.payload.user_type_id),
        isStaff: isStaff(action.payload.user_type_id),
        isShop: isShop(action.payload.user_type_id),
        isAdmin: !!action.payload.isEffectiveOperator,

        userRights: action.payload.userRights || [],
      };

    case UPDATE_USER_PROPERTY:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          user_type_data: {
            ...state.user.user_type_data,
            ...action.payload.user_type_data,
          },
        },
      };

    /**
     * @deprecated Use `UPDATE_USER_PROPERTY` instead
     */
    case SAVE_USER_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          user_type_data: {
            ...state.user.user_type_data,
            ...action.payload,
          },
        },
      };

    case AUTH_LOGOUT:
      return {
        ...initialState,
        isAuthInitialized: true,
      };

    default:
      return state;
  }
};
