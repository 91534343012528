import { useMemo } from 'react';


interface Props {
  amount: number | string;
  decimalPlace?: number;
}

function CurrencyAmount({ amount, decimalPlace = 1 }: Props) {
  const formatted = useMemo(() => {
    return Number(amount).toFixed(decimalPlace);
  }, [amount, decimalPlace]);

  return <>$ {formatted}</>;
}

export default CurrencyAmount;
