import {
  SHOP_NEWS_LIST_WILL_INVALIDATE,
  SHOP_NEWS_WILL_FETCH,
  SHOP_NEWS_DID_FETCH,
  SHOP_NEWS_WILL_GET,
  SHOP_NEWS_DID_GET,
  SHOP_NEWS_DID_CREATE,
  SHOP_NEWS_DID_UPDATE,
  SHOP_NEWS_DID_DESTROY,
} from "../../constants/constants";


const initialState = {
  /**
   * Whether the client is fetching a list of news
   */
  isFetchingNews: false,

  /**
   * The last time when a list of news is fetched
   */
  lastFetchedAt: null,

  /**
   * The shop with a list of news.
   */
  shopWithNewsList: {},


  /**
   * The currently interested news
   */
  shopNews: {},
};

/**
 * To update OR delete one piece of news from the news list.
 * @param {any} state
 * @param {number} targetNewsId
 * @param {ShopNews} updatedNews
 */
const spliceNewsList = (state, targetNewsId, updatedNews = null) => {
  if (!state.shopWithNewsList || !state.shopWithNewsList.news) {
    return [];
  }

  const idx = state.shopWithNewsList.news.findIndex(news => news.id == targetNewsId);

  if (idx > -1) {
    const newsLIst = state.shopWithNewsList.news.slice(); // make a copy

    if (updatedNews) {
      newsLIst.splice(idx, 1, updatedNews); // replace 1 item
    } else {
      newsLIst.splice(idx, 1); // remove 1 item
    }

    return newsLIst;
  }

  return state.shopWithNewsList.news;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOP_NEWS_LIST_WILL_INVALIDATE:
      return {
        ...state,
        isFetchingNews: false,
        lastFetchedAt: null,
        shopWithNewsList: {},
      };

    case SHOP_NEWS_WILL_FETCH:
      return {
        ...state,
        isFetchingNews: true,
      };

    case SHOP_NEWS_DID_FETCH:
      return {
        ...state,
        isFetchingNews: false,
        lastFetchedAt: new Date(),
        shopWithNewsList: action.payload.shopWithNewsList,
      };

    case SHOP_NEWS_WILL_GET: {
      return {
        ...state,
        // try to pick the news from the list in local state
        shopNews: (
            (state.shopWithNewsList && state.shopWithNewsList.news) ?
            state.shopWithNewsList.news.find(news => news.id == action.payload.newsId) :
            {}
        ) || {},
      };
    }

    case SHOP_NEWS_DID_GET:
      return {
        ...state,
        shopWithNewsList: {
          ...state.shopWithNewsList,
          // try to update the news to the list in local state
          news: spliceNewsList(state, action.payload.news.id, action.payload.news),
        },
        shopNews: action.payload.news,
      };

    case SHOP_NEWS_DID_CREATE: {
/*
      // TODO Under current implementation, just after a news is created,
      // its photos (if any) have NOT yet been uploaded yet.
      // Therefore it is not appropriate to prepend such new news onto the news list.
      // And thus following snippet is commented... Pending for more sophisticated process on
      // handling newly created news

      if (state.lastFetchedAt) {
        let nextNewsList;

        if (state.shopWithNewsList && state.shopWithNewsList.news) {
          nextNewsList = [action.payload.news, ...state.shopWithNewsList.news]; // prepend item
        } else {
          nextNewsList = [action.payload.news];
        }

        return {
          ...state,
          shopWithNewsList: {
            ...state.shopWithNewsList,
            news: nextNewsList,
          },
        };
      }
      // else no need to put into list

      break;
*/

      // Simply invalidate any previously fetched list
      return {
        ...state,
        lastFetchedAt: null,
        shopWithNewsList: {},
      };
    }

    case SHOP_NEWS_DID_UPDATE:
      return {
        ...state,
        shopWithNewsList: {
          ...state.shopWithNewsList,
          // try to update the news to the list in local state
          news: spliceNewsList(state, action.payload.news.id, action.payload.news),
        },
      };

    case SHOP_NEWS_DID_DESTROY: {
      return {
        ...state,
        shopWithNewsList: {
          ...state.shopWithNewsList,
          news: spliceNewsList(state, action.payload.newsId),
        },
      };
    }

    default:
      break;
  }

  return state;
};
