import ConfirmDialogProvider, { useConfirmationDialog } from './ConfirmDialogProvider';
import ConfirmDeleteIconButton from './ConfirmDeleteIconButton';


export default ConfirmDialogProvider;

export {
  useConfirmationDialog,
  ConfirmDeleteIconButton,
};
