import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';


import { Booking } from 'src/models/entity';

import { shopBookingAPI } from 'src/utils/api';

import useErrorHandler from 'src/hooks/useErrorHandler';

import notification from 'src/Components/Common/notification';

import { AppButton } from 'src/Components/Common/Button';
import { FormField, Textarea } from 'src/Components/Common/Form';
import Spinner from 'src/Components/Common/Spinner/Spinner';

interface Props {
  booking: Booking;
  onClose: (isBookingCancelled: boolean) => void;
}

const BookingCancellationByProviderDialog = ({ booking, onClose }: Props) => {
  const { t } = useTranslation();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  /**
   * This state helps prevent user from accidentally / unintentionally dismissing the dialog.
   *
   * A `true` value => User has NOT ever interacted with the form.
   */
  const [isPristine, setPristine] = useState(true);

  const [providerRemarks, setProviderRemarks] = useState(booking.providerRemarks || '');

  const [isCancelling, setCancelling] = useState(false);

  const handleDialogClose = () => {
    if (isPristine) {
      onClose(false);
    }
  };

  const handleChangeMessage = (newVal: string) => {
    setProviderRemarks(newVal);

    setPristine(false);
  };

  const cancelBooking = () => {
    setCancelling(true);

    shopBookingAPI
      .cancelBooking(booking.id, { providerRemarks })
      .then(() => {
        notification(t('bookingManagement.cancelBooking.succeeded'));
        onClose(true);
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e);
        // }
      })
      .finally(() => {
        setCancelling(false);
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={handleDialogClose}
      aria-labelledby="cancel-booking-dialog-title"
    >
      <DialogTitle
        id="cancel-booking-dialog-title"
        className="app-dialog-title"
      >
        <div className="d-flex align-items-start">
          <div className="flex-fill">
            <h2 className="m-0">
              {t('bookingManagement.cancelBooking.title')}
            </h2>
          </div>

          <button className="btn btn-link" onClick={(e) => onClose(false)}>
            <i className="fas fa-times text-muted" />
          </button>
        </div>
      </DialogTitle>

      <DialogContent>
        <FormField label={t('booking.providerRemarks.label')} required>
          <Textarea
            disabled={isCancelling}
            maxLength={250}
            name="message"
            placeholder={t('shopPrivate.bookings.text')}
            value={providerRemarks}
            onChange={(e) => handleChangeMessage(e.target.value)}
          />
        </FormField>
      </DialogContent>

      <DialogActions className="app-dialog-actions">
        <AppButton
          disabled={!providerRemarks || isCancelling}
          shape="round"
          theme="danger"
          onClick={cancelBooking}
        >
          {isCancelling ? (
            <Spinner />
          ) : (
            t('bookingManagement.cancelBooking.action')
          )}
        </AppButton>
        <AppButton shape="round" onClick={(e) => onClose(false)}>
          {t('action.back')}
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

export default BookingCancellationByProviderDialog;
