import axios from 'axios';

import { BaseResponse } from 'src/models/api/response/BaseResponse';

const apiAxios = axios.create({
  baseURL: '/api/',
});

apiAxios.interceptors.request.use(function (config) {
  // To attach JWT token into request header
  if (localStorage.FinderJWT) {
    config.headers.Authorization = `Bearer ${localStorage.FinderJWT}`;
    config.headers.Accept = 'application/json';
  }

  return config;
});

apiAxios.interceptors.response.use(async function (response) {
  // Request was successful, e.g. HTTP code 200

  // Legacy data schema...
  // Previous vender tends to return response status 200 + json `{ status: 4xx }`
  // in case of error.
  if (response.data?.status && response.data?.status != 200) {
    // console.log('interceptors captured old error response scheme');
    // To due with legacy API response
    throw new Error(JSON.stringify(response.data));
  }

  return response;
});

// ========== ========== ========== Request ========== ========== ==========

export interface RequestData {
  [key: string]: any;
}

// ========== ========== GET ========== ==========

/**
 *
 * @param relativeUrl
 * @param {object} params
 * @returns
 */
export async function apiGet<T = BaseResponse>(
  relativeUrl: string,
  params?: RequestData
): Promise<T> {
  // console.log(`[GET] ${relativeUrl} with`, params);

  return apiAxios
    .get<T>(relativeUrl, { params })
    .then((axiosResponse) => axiosResponse.data);
}

// ========== ========== POST ========== ==========

/**
 *
 * @param relativeUrl
 * @param {object} data
 * @returns
 */
export async function apiPost<T = BaseResponse>(
  relativeUrl: string,
  data: RequestData
): Promise<T> {
  // console.log(`[POST] ${relativeUrl} with`, data);

  return apiAxios
    .post<T>(relativeUrl, data)
    .then((axiosResponse) => axiosResponse.data);
}

// ========== ========== PUT ========== ==========

/**
 *
 * @param relativeUrl
 * @param {object} data
 * @returns
 */
export async function apiPut<T = BaseResponse>(
  relativeUrl: string,
  data?: RequestData
): Promise<T> {
  // console.log(`[PUT] ${relativeUrl} with`, data);

  return apiAxios
    .put<T>(relativeUrl, data)
    .then((axiosResponse) => axiosResponse.data);
}

// ========== ========== DELETE ========== ==========

/**
 *
 * @param relativeUrl
 * @returns
 */
export async function apiDelete<T = BaseResponse>(
  relativeUrl: string
): Promise<T> {
  // console.log(`[DELETE] ${relativeUrl}`);

  return apiAxios
    .delete(relativeUrl)
    .then((axiosResponse) => axiosResponse.data);
}

// ========== ========== Misc ========== ==========

export function equalId(
  leftId: string | number | undefined,
  rightId: string | number | undefined
) {
  return !!leftId && !!rightId && `${leftId}` === `${rightId}`;
}
