import React from 'react';
import { Link } from 'react-router-dom';


import useAccount from '../../../../../hooks/auth/useAccount';
import useAuthenticated from '../../../../../hooks/auth/useAuthenticated';

import BookingDollarBalance from 'src/Components/Common/Booking/BookingDollarBalance';
import UserAvatar from '../../../../Common/UserAvatar/UserAvatar';

import MyBookingButton from './MyBookingButton';


function UserBadge() {
  const { user } = useAuthenticated();

  const { displayName } = useAccount();

  return (
    <>
      <span className="app-ico-btn">
        <UserAvatar user={user} />
      </span>
      <span className="d-none d-lg-block ml-1 user-profile-name">
        {displayName}
      </span>
    </>
  );
}

const UserNav = ({
  onToggleUserToolbar,
}) => {

  const { bookingDollarBalance, publicUrl } = useAccount();

  return (
    <div className="d-flex align-items-center">
      <MyBookingButton />

      {bookingDollarBalance > 0 && (
        <Link className="d-flex" to="/customer-settings/dollars/products">
          <BookingDollarBalance
            className="d-none d-lg-block"
            balance={bookingDollarBalance}
            // withLabel
            size="small"
          />
        </Link>
      )}

      {publicUrl ? (
        <Link to={publicUrl} className="ml-2 d-flex align-items-center">
          <UserBadge />
        </Link>
      ) : (
        <span className="ml-2 d-flex align-items-center">
          <UserBadge />
        </span>
      )}

      <button className="ml-2 navbar-toggle" onClick={onToggleUserToolbar}>
        <i className="fas fa-caret-down" />
      </button>
    </div>
  );
};

export default UserNav;
