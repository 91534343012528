import { Tooltip } from '@material-ui/core';

interface Props {
  iconClass: string;
  title: string;
  onClick: () => void;
}

const IconButton = ({ iconClass, title, onClick }: Props) => {
  return (
    <Tooltip placement="top" title={title}>
      <button
        className="btn btn-sm btn-outline-success mx-1"
        type="button"
        onClick={onClick}
      >
        <i className={iconClass} />
      </button>
    </Tooltip>
  );
};

export default IconButton;
