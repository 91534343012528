import { SITE_BUSY_COUNT_WILL_INCREMENT } from "../../constants/constants";


const initialState = {
  busyCount: 0,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SITE_BUSY_COUNT_WILL_INCREMENT:
      return {
        ...state,
        busyCount: state.busyCount + action.payload,
      };
    default:
      return {
        ...state
      };
  }
}