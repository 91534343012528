import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { APP_CHECKOUT_LARGE_AMOUNT_WARNING_HKD } from '../../../../environment';

import { BookingStatus } from 'src/models/enum/BookingStatus';
import { Booking, StaffData } from 'src/models/entity';
import { BaseResponse } from 'src/models/api/response/BaseResponse';

import { shopBookingAPI } from 'src/utils/api';

import { useAuthenticated } from 'src/hooks/auth';
import useErrorHandler from 'src/hooks/useErrorHandler';

import { fetchShopStaff } from '../../../../Redux/actions/shopActions/shopStaffActions';

import Breadcrumb from 'src/Components/Common/Breadcrumb/Breadcrumb';
import { AppButton } from 'src/Components/Common/Button';
import { FormField, NumberInputField } from 'src/Components/Common/Form';
import notification from 'src/Components/Common/notification';
import { RouterItem } from 'src/Components/Common/Breadcrumb/RouteItem';
import Spinner from 'src/Components/Common/Spinner/Spinner';

// import ShareModal from './ShareModal';


interface RouteParams {
  bookingId: string;
}


interface Props {
  shopStaffDataList: StaffData[];
  fetchShopStaff: (data: any) => void;
}

function Checkout({
  shopStaffDataList,
  fetchShopStaff,
}: Props) {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { bookingId } = useParams<RouteParams>();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  const { user: shop } = useAuthenticated();

  const [isFetchingBooking, setFetchingBooking] = useState(false);
  const [booking, setBooking] = useState<Booking>();

  const [checkoutNumber, setCheckoutNumber] = useState('');
  const [checkoutNumberError, setCheckoutNumberError] = useState('');

  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [staffError, setStaffError] = useState('');

  const [amount, setAmount] = useState('0');
  const [amountError, setAmountError] = useState('');

  const isLargeAmount = useMemo(
    () => +amount > APP_CHECKOUT_LARGE_AMOUNT_WARNING_HKD,
    [amount]
  );
  const [isLargeAmountConfirmed, setLargeAmountConfirmed] = useState(false);

  const [isProcessing, setProcessing] = useState(false);

  // const [toggleShareModal, setToggleShareModal] = useState(false);

  const isFormValid = useMemo(
    () =>
      !!checkoutNumber &&
      !!selectedStaffId &&
      +amount > 0 &&
      (!isLargeAmount || isLargeAmountConfirmed),
    [
      checkoutNumber,
      selectedStaffId,
      amount,
      isLargeAmount,
      isLargeAmountConfirmed,
    ]
  );

  const routes = useMemo<RouterItem[]>(
    () => [
      {
        title: t('shopPrivate.bookings.checkout'),
        path: window.location.pathname,
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    fetchBooking();

    fetchShopStaff({ id: shop.id });
  }, []);

  const fetchBooking = () => {
    setFetchingBooking(true);

    shopBookingAPI
      .getBooking(bookingId)
      .then((booking) => {
        if (booking.booking_status_id !== BookingStatus.NotCheckOut) {
          history.push('/shop-settings/bookings/have_not_checkout');
          notification(t('booking.error.notReadyForCheckout'));
          return;
        }

        setBooking(booking);
        setAmount(booking.price);
        setSelectedStaffId(booking.staff_id);
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e, { suppress: ['404'] });
        // }
      })
      .finally(() => {
        setFetchingBooking(false);
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!booking?.id) {
      return;
    }

    setProcessing(true);

    setCheckoutNumberError('');
    setStaffError('');
    setAmountError('');

    shopBookingAPI
      .checkoutBooking(booking.id, {
        checkoutNumber,
        amount,
        staffId: selectedStaffId,
      })
      .then(() => {
        setCheckoutNumber('');
        setSelectedStaffId('');
        setAmount('');

        // setToggleShareModal(false);

        notification(t('notifications.checkoutApproved'), 4000);

        history.push('/shop-settings/bookings/completed');
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e, { suppress: ['422'] });
        // }

        if (e.response?.status === 422 && e.response?.data) {
          const response = e.response.data as BaseResponse;

          if (response?.errors) {
            if (response.errors.checkoutNumber) {
              setCheckoutNumberError(
                response.errors.checkoutNumber.join('\n')
              );
            }

            if (response.errors.staffId) {
              setStaffError(response.errors.staffId.join('\n'));
            }

            if (response.errors.amount) {
              setAmountError(response.errors.amount.join('\n'));
            }
          }
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

/*
  const toggleShare = () => {
    setToggleShareModal(val => !val);
  };

  const shareCheckout = id => {
    api.shopBookings.shareCheckout({
        staff_id: id,
        booking_id: match.params.bookingId,
      })
      .then(res => {
        if (res.data.message === 'success') {
          toggleShare();
        }
      });
  };

  const handleSelect = ({ target: { value } }) => {
    setSelectedStaffId(value);
  };
*/


  return (
    <div className="mainPanel">
      <h2 className="categoryTitle">
        <i className="fas fa-money-bill-alt" aria-hidden="true" />
        {t('shopPrivate.bookings.checkout')}
      </h2>

      <Breadcrumb routes={routes} buttonExist />

      <div className="main-container">
        <div className="page-head">
          <h2 className="page-title">{t('shopPrivate.bookings.checkout')}</h2>
        </div>

        <div className="main-container__content">
          {isFetchingBooking ? (
            <Spinner display="block" />
          ) : booking ? (
            <form onSubmit={handleSubmit}>
              <fieldset disabled={isProcessing}>
                <div className="checkout">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      {/* Checkout Number */}
                      <NumberInputField
                        errorMessage={checkoutNumberError}
                        label={t('shopPrivate.checkoutNumber')}
                        name="checkoutNumber"
                        required
                        step={1}
                        value={checkoutNumber}
                        onChange={(e) => setCheckoutNumber(e.target.value)}
                      />

                      {/* Staff */}
                      <FormField
                        errorMessage={staffError}
                        label={t('shopPrivate.shopLeftMenu.staff')}
                        required
                      >
                        <select
                          className="form-item"
                          name="staffId"
                          value={selectedStaffId}
                          onChange={(e) => setSelectedStaffId(e.target.value)}
                        >
                          <option value="">
                            {t('action.selectOneFromEnumeration')}
                          </option>
                          {shopStaffDataList.map((staffData) => (
                            <option
                              key={staffData.user_id}
                              value={staffData.user_id}
                            >
                              {staffData.nickname}
                            </option>
                          ))}
                        </select>
                      </FormField>

                      {/* Paid Amount */}
                      <NumberInputField
                        errorMessage={amountError}
                        iconClass="fas fa-dollar-sign"
                        label={t('shopPrivate.bookings.totalAmountService')}
                        min={1}
                        max={1e6}
                        name="amount"
                        required
                        step={0.1}
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                          setLargeAmountConfirmed(false);
                        }}
                      />

                      {isLargeAmount && (
                        <div className="form-check">
                          <input
                            id="isLargeAmountConfirmed"
                            className="form-check-input"
                            checked={isLargeAmountConfirmed}
                            type="checkbox"
                            onChange={(e) =>
                              setLargeAmountConfirmed(e.target.checked)
                            }
                          />
                          <label
                            className="form-check-label text-danger"
                            htmlFor="isLargeAmountConfirmed"
                          >
                            {t(
                              'shopPrivate.bookings.checkoutConfirmLargeAmount',
                              { amount }
                            )}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-3 row justify-content-center">
                  <div className="col-lg-6">
                    {/*
                  <AppButton size="block" theme="secondary" onClick={toggleShare}>
                    Share to staff
                  </AppButton>
*/}
                    <AppButton
                      disabled={!isFormValid}
                      size="block"
                      theme="success"
                      type="submit"
                    >
                      {isProcessing ? (
                        <Spinner />
                      ) : (
                        t('shopPrivate.bookings.checkout')
                      )}
                    </AppButton>
                  </div>
                </div>
              </fieldset>
            </form>
          ) : (
            <div className="alert alert-danger">{t('errors.notFound')}</div>
          )}
        </div>

        {/*
        {toggleShareModal && (
          <ShareModal
            shopStaffDataList={shopStaffDataList}
            staff_id={selectedStaffId}
            select={handleSelect}
            share={shareCheckout}
            onClose={toggleShare}
          />
        )}
*/}
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    shopStaffDataList: state.GetStuffInformationReducer.shopStaffDataList,
  };
}

export default connect(
    mapStateToProps,
    {
      fetchShopStaff,
    }
  )(Checkout);
