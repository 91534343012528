import ReactPaginate from 'react-paginate';

interface Props {
  currentPage: number;
  lastPage: number;
  pageChange: (selectedItem: { selected: number }) => void;
}

const Pagination = ({ currentPage, lastPage, pageChange }: Props) => (
  <div className="pagination-box">
    <ReactPaginate
      previousLabel={<i className="fas fa-chevron-left"></i>}
      nextLabel={<i className="fas fa-chevron-right"></i>}
      forcePage={currentPage - 1}
      pageCount={lastPage}
      marginPagesDisplayed={1}
      className="pagination"
      activeClassName="active"
      previousClassName="pagination__item"
      nextClassName="pagination__item"
      activeLinkClassName="active"
      pageLinkClassName="pagination__item"
      pageRangeDisplayed={5}
      onPageChange={pageChange}
    />
  </div>
);

export default Pagination;
