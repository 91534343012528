import notification from "../Components/Common/notification";


/**
 * @deprecated Legacy API Utilities.
 */
const collectValidationError = (dataMap) => {
  return Object.values(dataMap).map(errors => errors.join('\n')).join('\n');
}

/**
 * @deprecated Legacy API Utilities.
 * Convert a object of parameters to query string in format of a=xxx&b=yyy
 * @param {object} params
 * @returns string
 */
export const queryString = (params) => new URLSearchParams(params).toString();


/**
 * @deprecated Legacy API Utilities.
 */
export const preprocess = (axiosPromise) => new Promise((resolve, reject) => {
  axiosPromise.then(result => {
    const response = result.data;
    if (response.status === 200) {
      resolve(response); // resolve with json response
    }
    else if (response.status === 400) { // bad request
      setTimeout(() => {
        response.message && alert(response.message);
      }, 0);

      throw new Error();
    }
    else if (response.status === 422) { // the code stands for validation error
      setTimeout(() => {
        alert(collectValidationError(response.data))
      }, 0);

      throw new Error();
    }
    else {
      notification(response.message || 'Somethings went wrong.');
      throw new Error();
    }
  })
    .catch(err => {
      if (err) {
        console.error('apiHelper catch err=', err);

        const result = err.response;
        if (result) {
          if (result.status === 403) {
            setTimeout(() => {
              alert('Missing permissions');
            }, 0);
          }
        }
      }

      reject(err);
    });
});
