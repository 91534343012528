import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './OverallRatingRow.module.css';

interface Props {
  className?: string;
  hideLabel?: boolean;
  rating: number;
}

function OverallRatingRow({ className = '', hideLabel = false, rating }: Props) {
  const { t } = useTranslation();

  const [formatted, setFormatted] = useState('');

  useEffect(() => {
    setFormatted(isNaN(rating) ? '' : Number(rating).toFixed(1));
  }, [rating]);

  return (
    <div className={`mb-1 ${styles.root} ${className}`}>
      {!hideLabel && (
        <span>{t('shopPublic.overview.leftBar.overallRating')}</span>
      )}
      <span>
        <i className="fas fa-star mx-1" />
        {formatted}
      </span>
    </div>
  );
}

export default OverallRatingRow;
