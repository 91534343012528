import {GET_SHOP_INFORMATION, SAVE_SHOP_DATA} from "../../constants/constants";


const initialState = {
    shopData: []
};


export default (state = initialState, action) => {
    switch(action.type){
        case SAVE_SHOP_DATA:
            return{
                ...state,
                shopData: action.payload
            };
        case GET_SHOP_INFORMATION:
            return {
                ...state,
                shopData: action.payload
            };
        default:
            return {
                ...state
            }
    }
}