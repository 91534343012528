import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { AppButtonLink } from '../../../Common/Button';

import NewsList from './Listing/NewsList';

const NewsIndex = () => {
  const { t } = useTranslation();

  const routes = [{ title: t('shopPrivate.shopLeftMenu.news'), path: window.location.pathname }];

  return (
    <div className="mainPanel">
      <h2 className="categoryTitle">
        <i className="fas fa-newspaper" aria-hidden="true" />
        {t('shopPrivate.shopLeftMenu.news')}
      </h2>

      <Breadcrumb routes={routes} buttonExist />

      <div className="main-container">
        <div className="page-head">
          <h2 className="page-title">{t('shopPrivate.shopLeftMenu.news')}</h2>
          <AppButtonLink theme="primary" to="/shop-settings/news/create">
            {t('shopPrivate.news.createNews')}
          </AppButtonLink>
        </div>

        <NewsList />
      </div>
    </div>
  );
};

export default NewsIndex;
