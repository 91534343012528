import { GET_ALL_REVIEWS, GET_REVIEW, CLEAR_REVIEW } from '../../constants/constants';
import api from '../../../api';

export const getAllReviews = data => dispatch =>
  api.shopBookings.getShopReviews(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(GetAllReviews(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

export const getReview = data => dispatch =>
  api.shopBookings.getOneShopReview(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(GetReview(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

export const clearReview = () => dispatch => {
  dispatch(ClearReview());
};

export const getAllModerationReviews = data => dispatch =>
  api.shopReviews.getReviews(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(GetAllReviews(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

export const createReview = data => api.shopBookings.leaveReview(data);

export const GetAllReviews = data => ({
  type: GET_ALL_REVIEWS,
  payload: data,
});

export const GetReview = data => ({
  type: GET_REVIEW,
  payload: data,
});

export const ClearReview = () => ({
  type: CLEAR_REVIEW,
  payload: {},
});
