import moment from 'moment';

import {
  SITE_LINKABLE_ARTICLE_DID_FETCH,
  SITE_LINKABLE_ARTICLE_WILL_FETCH,
  SITE_LINKABLE_LANDING_WILL_FETCH,
  SITE_LINKABLE_LANDING_DID_FETCH,
} from '../../constants/constants';
import api from '../../../api';

export const fetchLinkableFeatureArticles = () => (dispatch, getState) => {
  const prevState = getState().siteLinkableReducer;

  if (prevState.isFetchingArticles) {
    // console.log('fetching articles. No need to fire a fetch request.');
    return;
  } else if (
    prevState.articleLastFetchedAt &&
    moment().diff(prevState.articleLastFetchedAt, 'minutes', true) <= 30
  ) {
    // console.log('article list is still fresh. No need to fire a fetch request.');
    return;
  }

  dispatch(willFetchLinkableFeatureArticles());

  api.featureArticle.fetchLinkables().then((response) => {
    dispatch(didFetchLinkableFeatureArticles(response.data));
  });
};

export const fetchLinkableLandingPages = () => (dispatch, getState) => {
  const prevState = getState().siteLinkableReducer;

  if (prevState.isFetchingLandings) {
    // console.log('fetching landings. No need to fire a fetch request.');
    return;
  } else if (
    prevState.landingLastFetchedAt &&
    moment().diff(prevState.landingLastFetchedAt, 'minutes', true) <= 30
  ) {
    // console.log('landing list is still fresh. No need to fire a fetch request.');
    return;
  }

  dispatch(willFetchLinkableLandingPages());

  api.landingPage.fetchLinkables().then((response) => {
    dispatch(didFetchLinkableLandingPages(response.data));
  });
};

const willFetchLinkableFeatureArticles = (data) => ({
  type: SITE_LINKABLE_ARTICLE_WILL_FETCH,
});

const didFetchLinkableFeatureArticles = (data) => ({
  type: SITE_LINKABLE_ARTICLE_DID_FETCH,
  payload: data,
});

const willFetchLinkableLandingPages = (data) => ({
  type: SITE_LINKABLE_LANDING_WILL_FETCH,
  payload: data,
});

const didFetchLinkableLandingPages = (data) => ({
  type: SITE_LINKABLE_LANDING_DID_FETCH,
  payload: data,
});
