import { useMemo } from 'react';

import preloader from './../../../dist/Preloader.gif';

interface Props {
  className?: string;

  display?: 'block' | 'inline';
}

const Spinner = ({ className, display = 'inline' }: Props) => {
  const spinnerClassName = useMemo(() => {
    if (display === 'block') {
      return `app-spinner-block ${className || ''}`;
    }

    return `app-spinner-inline ${className || ''}`;
  }, [className, display]);

  return (
    <img src={preloader} alt="loading..." className={`${spinnerClassName}`} />
  );
};

export default Spinner;
