import React from 'react';
import { withTranslation } from 'react-i18next';

import { useConfirmationDialog } from '../../../../../Providers/ConfirmDialogProvider';
import { AppButton } from '../../../../Common/Button';
import { FormField, Textarea } from '../../../../Common/Form';

/**
 * @deprecated Use <BookingCancellationByProviderDialog /> instead
 */
const DeclineMessage = ({
  message,
  id,
  handleChange,
  declineBooking,
  toggleModalDecline,
  t,
}) => {
  const { openConfirmationDialog } = useConfirmationDialog();

  const handleClickDeclineBooking = (id) => {
    openConfirmationDialog({
      message: t('bookingManagement.cancelBooking.confirmation'),
      onConfirm: () => {
        declineBooking(id);
      },
    });
  };

  return (
    <div className="cancel-box active">
      <span className="triangle">▲</span>
      <button className="close-modal-btn" onClick={toggleModalDecline}>
        <i className="fas fa-times" />
      </button>

      <FormField label={t('shopPrivate.bookings.msg')} required>
        <Textarea
          name="message"
          placeholder={t('shopPrivate.bookings.text')}
          value={message}
          onChange={handleChange}
        />
      </FormField>

      <AppButton
        disabled={!message}
        shape="round"
        size="block"
        theme="muted"
        onClick={() => handleClickDeclineBooking(id)}
      >
        {t('shopPrivate.bookings.cancelSend')}
      </AppButton>
    </div>
  );
};


/**
 * @deprecated Use <BookingCancellationByProviderDialog /> instead
 */
export default withTranslation()(DeclineMessage);
