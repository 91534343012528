import React from 'react';
import { withTranslation } from 'react-i18next';


import { TableHeaderCell } from '../../../../Common/DataTable';

import StaffItem from './StaffItem';


const StaffCharge = ({ shopStaffDataList, handleChange, t }) => {
  return (
    <div>
      <div className="mt-4 page-head">
        <h2 className="page-title">
          {t('shopPrivate.servicePriceList.staffAddCharge')}
        </h2>
      </div>

      <div className="main-container__content">
        <div className="tableWrap">
          <table className="adminTable">
            <colgroup>
              <col style={{ width: '40%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <TableHeaderCell>{t('shopPrivate.shopLeftMenu.staff')}</TableHeaderCell>
                <TableHeaderCell
                  title={t('shopPrivate.servicePriceList.extraCharge')}
                />
              </tr>
            </thead>
            <tbody>
              {shopStaffDataList &&
                shopStaffDataList.map((staffData, i) => (
                  <StaffItem
                    key={i}
                    blockNum={i + 1}
                    staffData={staffData}
                    handleChange={handleChange}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(StaffCharge);
