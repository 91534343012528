import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import NotificationBadge from 'react-notification-badge';
// import { Effect } from 'react-notification-badge';

import axios from 'axios';


import searchIcon from '../../../../../dist/img/search-icon.png';

import useAuthenticated from '../../../../../hooks/auth/useAuthenticated';

import LanguageSwitch from '../../../../Common/LanguageSwitch/LanguageSwitch';
import { AppButton } from '../../../../Common/Button';

import SignInDialog from '../../../../Auth/SignInDialog';
import SignUpDialog from '../../../../Auth/SignUpDialog';

import UserNav from './UserNav';
import UserToolbar from './UserToolbar';
import SearchBox from './SearchBox';

import styles from './NavBar.module.css';

function NavBar({
  // from parents:
  clearSearchField,
  onChange,
}) {
  const { t, i18n } = useTranslation();

  const { isAuthenticated, user } = useAuthenticated();

  const [loading, setLoading] = useState(true);

  const [isSignInWindowOpened, setSignInWindowOpened] = useState(false);
  const [isSignUpDialogOpened, setSignUpDialogOpened] = useState(false);

  const [isSearchToolbarExpanded, setSearchToolbarExpanded] = useState(false);
  const [isUserToolbarExpanded, setUserToolbarExpanded] = useState(false);

  const [count, setCount] = useState(0);

  useEffect(() => {
    // const language = window.navigator.userLanguage || window.navigator.language;

    if (sessionStorage.getItem('language') === 'en' && !isAuthenticated) {
      setLoading(false);
      i18n.changeLanguage('en');
      return false;
    }

    if (isAuthenticated) {
      sessionStorage.setItem('checkLogin', true);
    } else {
      sessionStorage.setItem('checkLogin', false);
    }

    if (!isAuthenticated) {
      setLoading(false);

      i18n.changeLanguage('zh');

      return false;
    }

    if (isAuthenticated) {
      i18n.changeLanguage(
        sessionStorage.getItem('language')?.startsWith('en') ? 'en' : 'zh'
      );
      axios.get(
        `/api/lang/${
          sessionStorage.getItem('language')?.startsWith('en') ? 'en' : 'zh-HK'
        }`
      );

      setLoading(false);
      return false;
    }

    axios.get('/api/get-lang').then((res) => {
      if (res.data.status === 'success') {
        setLoading(false);
      }

      i18n.changeLanguage('zh');
    });

    // if (language !== 'en' || language !== 'en-US') {
    //   i18n.changeLanguage('zh');
    // }
  }, []);

  const getCount = () => {
    axios
      .get('/api/shop-new-bookings-count/' + user.id)
      .then(async (res) => {
        setCount(res.data.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleModalSignUp = () => {
    setSignUpDialogOpened(val => !val);
    setSignInWindowOpened(false);
    setUserToolbarExpanded(false);
  };

  const toggleSignIn = () => {
    setSignInWindowOpened(val => !val);
    setUserToolbarExpanded(false);
  };

  const toggleSearchToolbar = () => {
    setSearchToolbarExpanded((val) => !val);

    setUserToolbarExpanded((val) => (!isSearchToolbarExpanded ? false : val));
  };

  const closeUserToolbar = () => {
    setUserToolbarExpanded(false);
  };

  const toggleUserToolbar = () => {
    setUserToolbarExpanded((val) => !val);

    setSearchToolbarExpanded((val) => (!isUserToolbarExpanded ? false : val));
  };

  const closeSearchProfile = () => {
    setSearchToolbarExpanded(false);
    setUserToolbarExpanded(false);
  };

  const clearAndRedirect = (e) => {
    e.preventDefault();
    window.sessionStorage.removeItem('searchName');
    window.sessionStorage.removeItem('searchAddress');
    closeSearchProfile();
    clearSearchField();
  };

    return (
      <>
        <header className={styles.header}>
          <div className={`container ${styles.container}`}>
            <div className="row align-items-center">
              <div className="col-4 col-lg-2" onClick={clearAndRedirect}>
                <NavLink to="/" className={styles.themeLogo} />
              </div>

              <div className="col-8 col-lg-10">
                <div className="d-flex justify-content-between align-items-center">
                  {/* For screens >= lg */}
                  <div className="d-none d-lg-block">
                    <SearchBox onChange={onChange} />
                  </div>

                  {/* <NotificationBadge count={count} effect={Effect.SCALE}/> */}

                  <div className="ml-auto d-flex align-items-center">
                    <LanguageSwitch
                      className={`${
                        isAuthenticated && 'd-none d-lg-block'
                      } ml-2`}
                    />

                    {/* For screens <= md */}
                    <button
                      className="d-lg-none app-ico-btn"
                      onClick={toggleSearchToolbar}
                    >
                      <div className="app-absolute-center">
                        <img src={searchIcon} alt={t('navBar.Search')} />
                      </div>
                    </button>

                    {isAuthenticated ? (
                      <UserNav
                        onToggleUserToolbar={toggleUserToolbar}
                      />
                    ) : (
                      <>
                        {/* For screens <= md */}
                        <AppButton
                          className="d-lg-none ml-1"
                          size="small"
                          theme="primary"
                          onClick={toggleSignIn}
                        >
                          {t('account.action.signInOrSignUp')}
                        </AppButton>

                        {/* For screens >= lg */}
                        <button
                          className={`d-none d-lg-block ml-3 ${styles.signInBtn}`}
                          onClick={toggleSignIn}
                        >
                          {t('account.action.signIn')}
                        </button>

                        {/* For screens >= lg */}
                        <AppButton
                          className="d-none d-lg-block ml-3"
                          shape="round"
                          theme="primary"
                          onClick={toggleModalSignUp}
                        >
                          {t('account.action.signUp')}
                        </AppButton>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* For screens <= md */}
              <div
                className={`d-lg-none ${styles.navbarToolbar} ${
                  isSearchToolbarExpanded ? 'd-block' : 'd-none'
                }`}
              >
                <SearchBox onChange={onChange} />
              </div>

              {isAuthenticated && isUserToolbarExpanded && (
                <div className={styles.navbarToolbar}>
                  <UserToolbar onClose={closeUserToolbar} />
                </div>
              )}
            </div>
          </div>
        </header>

        {isSignInWindowOpened && (
          <SignInDialog
            onClose={toggleSignIn}
            onRequestSignUp={toggleModalSignUp}
          />
        )}

        {isSignUpDialogOpened && (
          <SignUpDialog onClose={toggleModalSignUp} />
        )}
      </>
    );
}

export default NavBar;
