import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


import { getReview, clearReview } from '../../../../Redux/actions/features/reviewActions';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import BookingReviewRatings from '../../../Common/RatingStars/BookingReviewRatings';
import { CustomerBadge } from '../../../Common/Customer';
import PhotoViewer from '../../../Common/PhotoViewer/PhotoViewer';
import Spinner from '../../../Common/Spinner/Spinner';
import { ShopAvatar } from '../../../Common/Shop';
import { LineBreakable } from '../../../Common/Text';


class ReviewDetail extends Component {

  _isMounted = false;

  state = {
    pageTitle: '',
    pageDescription: '',

    isPhotoViewerOpened: false,
    activePhotoIndex: 0,
  };

  componentDidMount() {
    this._isMounted = true;

    this.props.getReview({ id: this.props.match.params.reviewId }).then(res => {
      if (!this._isMounted) {
        return;
      }

      const response = res.res.data;
      if (response.message !== 'success') {
        this.props.history.goBack();
      }
    });
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    if (nextProps.shop.id && nextProps.shop.user_type_data && nextProps.review.id) {
      const shopChineseName = nextProps.shop.user_type_data.chineseName;
      const shopEnglishName = nextProps.shop.user_type_data.name;

      const concatedShopName = (shopChineseName === shopEnglishName) ? (shopEnglishName) : (`${shopChineseName} ${shopEnglishName}`);

      this.setState({
        pageTitle: `客人給 ${concatedShopName} 作出了評價：${nextProps.review.title} ${nextProps.review.review}`,
        pageDescription: nextProps.review.review,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    this.props.clearReview();
  }

  openPhotoViewer = (activePhotoIndex) => {
    this.setState({
      isPhotoViewerOpened: true,
      activePhotoIndex,
    });
  };

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
    });
  };

  render() {
    const {
      pageTitle,
      pageDescription,

      isPhotoViewerOpened,
      activePhotoIndex,
    } = this.state;
    const {
      review,
      shop,
      t,
    } = this.props;

    const photos = review.photos || [];

    return (
      <>
        <Helmet>
          {pageTitle && <title>{pageTitle}</title>}
          {pageTitle && <meta property="og:title" id="og-title" content={pageTitle} />}

          {pageDescription && <meta name="description" property="og:description" id="og-description" content={pageDescription} />}
        </Helmet>

        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={photos}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        <div className={this.props.match.params.id !== this.props.auth.user.id ? 'container' : ''}>
          <div className="review-container">
            {!!review.id ? (
              <div className="row">
                <div className="col-lg-2">
                  <CustomerBadge customer={review.customer} />
                </div>

                <div className="col-lg-7">
                  <h2 className="review-title">{review.title}</h2>
                  <p className="review-info">{review.created_at}</p>

                  <LineBreakable className="review-content">
                    {review.review}
                  </LineBreakable>

                  {photos.map((photo, index) => (
                    <div className="review-baner temp-cover temp-cover--no-border" key={index}>
                      <img
                        src={photo.url}
                        style={{ cursor: 'pointer' }}
                        alt=""
                        onClick={() => this.openPhotoViewer(index)}
                      />
                    </div>
                  ))}

                  {review.reply && (
                    <div className="review-answer">
                      <p className="review-answer__title">{t('shopPublic.review.shopAnswer')}</p>
                      <div className="answer__img">{shop && <ShopAvatar src={shop.avatar} />}</div>
                      <LineBreakable className="review-answer__text">
                        {review.reply}
                      </LineBreakable>

                      {/* For visually distinguishing shop's reply and customer's review rating */}
                      <hr className="d-block d-lg-none" />
                    </div>
                  )}
                </div>

                <div className="col-lg-3">
                  {review.review_average_rating && (
                    <BookingReviewRatings ratings={review.review_average_rating} />
                  )}

                  <div className="my-5 d-none d-lg-block">
                    <AdvertisementView name="review_page" />
                  </div>
                </div>
              </div>
            ) : (
              <Spinner display="block" />
              )}
          </div>
        </div>

        <div className="mt-3 d-lg-none">
          <AdvertisementView name="review_page" />
        </div>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const reviewId = props.match.params.reviewId;

  // adopt data from redux state only when the data matches the requested ID
  const review = state.ReviewReducer.review && state.ReviewReducer.review.id == reviewId ?
      state.ReviewReducer.review :
      {};

  return {
    review,
    shop: state.ShopReducer.shopData,
    auth: state.auth,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      { getReview, clearReview }
    )(ReviewDetail)
  )
);
