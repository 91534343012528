import { useMemo } from 'react';

import Linkify from 'linkify-react';

import {
  currentDomain,
  extractDomain,
  isCurrentDomain,
} from 'src/Functions/urlHelpers';

function makeLinkifyOptions(allowExternal: boolean) {
  // Only allow internal links
  const linkableDomains = [
    currentDomain(), // domain of this website
    'www.facebook.com',
    'www.instagram.com',
    'www.youtube.com',
    'youtu.be',
  ];

  return {
    validate: {
      email: false, // don't linkify emails
      url: (url: string) => {
        // Determine whether to linkify urls

        if (allowExternal) {
          return true;
        }

        const domain = extractDomain(url);
        if (domain && linkableDomains.includes(domain)) {
          return true;
        }

        return false;
      },
    },
    rel: (href: string, type: string) => {
      if (type === 'url' && isCurrentDomain(href)) {
        // Internal link
        return null;
      }

      // Treat the rest as external link
      return 'nofollow noopener noreferrer';
    },
    target: {
      url: (href: string) => {
        if (isCurrentDomain(href)) {
          return null; // open internal link in current window
        }

        return '_blank'; // open external link in new window
      },
    },
  };
}

interface Props {
  allowExternal?: boolean;
  children: React.ReactNode;
}

/**
 * It is for auto linkify content from untrusted source.
 */
function AppLinkify({ allowExternal = false, children }: Props) {

  const linkifyOptions = useMemo(
    () => makeLinkifyOptions(allowExternal),
    [allowExternal]
  );

  return <Linkify options={linkifyOptions}>{children}</Linkify>;
}

export default AppLinkify;
