import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';


import { APP_BOOKING_MIN_TIME_OFFSET_IN_HOUR } from 'src/environment';

import { Shop } from 'src/models/entity';

import { DateTimePickerField } from 'src/Components/Common/Form';

interface Props {
  dateError: React.ReactNode;
  defaultValue?: Date;
  disabled?: boolean;
  minToday?: boolean;
  shop: Shop;
  timeError: React.ReactNode;

  /**
   *
   * @param {Date} newDateTime
   * @param {boolean} isInitiatedByUser Whether the change is performed by user
   */
  onChange: (newDateTime: Date, isInitiatedByUser: boolean) => void;
}

const BookingDateTimePicker = ({
  dateError,
  defaultValue,
  disabled = false,
  minToday = false,
  shop,
  timeError,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const [initialized, setInitialized] = useState(false);

  const [defaultDateTimeValue, setDefaultDateTimeValue] = useState<Date>();

  useEffect(() => {
    // On component mounts, determine initial values of date / time pickers

    if (defaultValue) {
      setDefaultDateTimeValue(defaultValue);
      onChange(defaultValue, false);
    } else if (shop && shop.schedule) {
      const bookingAtMoment = moment(); // now
      bookingAtMoment.add(APP_BOOKING_MIN_TIME_OFFSET_IN_HOUR, 'hour'); // advance x hour
      bookingAtMoment.minute(Math.ceil(bookingAtMoment.minute() / 5) * 5); // advance to the "next 5th minute"

      const candidateBookingAt = moment(bookingAtMoment); // make a clone
      let i,
        workingDay,
        initialBookingAt = null;

      for (i = 0; i < 7; i++, candidateBookingAt.add(1, 'day')) {
        // for each day in coming 1 week
        workingDay = shop.schedule.find(
          (day) =>
            day.pivot &&
            !!day.pivot.working &&
            day.weekday === candidateBookingAt.day()
        );
        if (!workingDay) {
          continue;
        }

        // Such candidate date is a working day
        // console.log('Coming working day:', candidateBookingAt.format());

        const openingTimeParts = workingDay.pivot.time_from.split(':'); // 'hh:mm'
        const openedAt = moment(candidateBookingAt)
          .hour(+openingTimeParts[0])
          .minute(+openingTimeParts[1]);

        const closingTimeParts = workingDay.pivot.time_to.split(':'); // 'hh:mm'
        const closedAt = moment(candidateBookingAt)
          .hour(+closingTimeParts[0])
          .minute(+closingTimeParts[1]);

        if (candidateBookingAt.isBefore(openedAt)) {
          // console.log('The shop is not open at ', candidateBookingAt.format());
          // console.log('Suggest booking at opening hour ', openedAt.format());
          initialBookingAt = openedAt;
          break;
        } else if (candidateBookingAt.isBefore(closedAt)) {
          initialBookingAt = candidateBookingAt;
          break;
        }
      }

      if (initialBookingAt) {
        bookingAtMoment
          .year(initialBookingAt.year())
          .month(initialBookingAt.month())
          .date(initialBookingAt.date())
          .hour(initialBookingAt.hour())
          .minute(initialBookingAt.minute());
      }

      // clear the second-parts
      bookingAtMoment.second(0);
      bookingAtMoment.millisecond(0);

      setDefaultDateTimeValue(bookingAtMoment.toDate());
      onChange(bookingAtMoment.toDate(), false);
    }

    setInitialized(true);
  }, []);

  const handleChangeDateTime = (nextVal: Date) => {
    onChange(nextVal, true);
  };

  return initialized ? (
    <DateTimePickerField
      columnClassName="shop-book-col"
      dateError={dateError}
      dateLabel={t('booking.bookingDate.label')}
      defaultValue={defaultDateTimeValue}
      disabled={disabled}
      minToday={minToday}
      timeError={timeError}
      timeLabel={t('booking.bookingTime.label')}
      onChange={handleChangeDateTime}
    />
  ) : null;
};

export default BookingDateTimePicker;
