import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';


import { LocalizeWeekday } from '../../../../../Functions/datetimeHelpers';

import SetTime from './SetTime';

import styles from './DayField.module.css';

const DayField = ({
  day,
  openTime,
  handleChangeTime,
  handleChecked,
}) => {
  const { t, i18n } = useTranslation();

  const isChinese = useMemo(
    () => !i18n.language.startsWith('en'),
    [i18n.language]
  );

  return (
    <div className={styles.scheduleColumn}>
      <span className="field-label">
        <LocalizeWeekday name={day.name} />
      </span>

      <div className={styles.schedule}>
        <div className={styles.scheduleBox}>
          <div className={styles.scheduleGroup}>
            <p>{t('shopPrivate.schedule.from')}</p>
            <SetTime
              id={day.id}
              time={day.pivot.time_from}
              time_type="time_from"
              openTime={openTime}
              handleChangeTime={handleChangeTime}
            />
          </div>
          <div className={styles.scheduleGroup}>
            <p>{t('shopPrivate.schedule.to')}</p>
            <SetTime
              id={day.id}
              time={day.pivot.time_to}
              time_type="time_to"
              openTime={openTime}
              handleChangeTime={handleChangeTime}
            />
          </div>
        </div>

        <div className={`onoffswitch ${isChinese ? 'onoffswitch--chinese' : ''}`}>
          <input
            id={`working${day.id}`}
            className="onoffswitch-checkbox"
            type="checkbox"
            name="working"
            checked={day.pivot.working}
            onChange={(e) => handleChecked(e, day.id)}
          />
          <label className="onoffswitch-label" htmlFor={`working${day.id}`}>
            <span className="onoffswitch-inner onoffswitch-inner--type2 onoffswitch-inner--work-type" />
            <span className="onoffswitch-switch" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default DayField;
