import {
  ADMIN_DEFAULT_SERVICE_WILL_FETCH,
  ADMIN_DEFAULT_SERVICE_DID_FETCH,
  ADMIN_DEFAULT_SERVICE_WILL_CREATE,
  ADMIN_DEFAULT_SERVICE_DID_CREATE,
  ADMIN_DEFAULT_SERVICE_WILL_UPDATE,
  ADMIN_DEFAULT_SERVICE_DID_UPDATE,
} from "../../constants/constants";


/**
 * This state helps manage one particular `DefaultService`.
 */
const initialState = {
  isFetchingDefaultService: false,

  isCreatingDefaultService: false,

  isUpdatingDefaultService: false,

  defaultService: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
    case ADMIN_DEFAULT_SERVICE_WILL_FETCH:
      return {
        ...state,
        isFetchingDefaultService: true,
      };

    case ADMIN_DEFAULT_SERVICE_DID_FETCH:
      return {
        ...state,
        isFetchingDefaultService: false,
        defaultService: action.payload,
      };

    case ADMIN_DEFAULT_SERVICE_WILL_CREATE:
      return {
        ...state,
        isCreatingDefaultService: true,
      };

    case ADMIN_DEFAULT_SERVICE_DID_CREATE:
      return {
        ...state,
        isCreatingDefaultService: false,
      };

    case ADMIN_DEFAULT_SERVICE_WILL_UPDATE:
      return {
        ...state,
        isUpdatingDefaultService: true,
      };

    case ADMIN_DEFAULT_SERVICE_DID_UPDATE:
      return {
        ...state,
        isUpdatingDefaultService: false,
        defaultService: action.payload,
      };

    default:
			return {
				...state,
			};
	}
}
