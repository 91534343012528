import axios from 'axios';


import {
  queryString,
  preprocess,
} from './Functions/apiHelpers';


/**
 * @deprecated Legacy API Utilities.
 */
const apiAxios = axios.create({
  baseURL: '/api/',
});

/**
 * @deprecated Legacy API Utilities.
 */
const adminApiAxios = axios.create({
  baseURL: '/api/admin/',
});


/**
 * @deprecated Legacy API Utilities.
 * All resource in this file are deprecated.
 * It should not be adopted by any new feature.
 */
export default {
  advertisement: {
    fetchList: (data) => apiAxios.post(`advert`, data),
  },

  auth: {
    signUpBase: data => apiAxios.post('registration', data),
    sendCode: data => apiAxios.post('send-code', data),
    activationCode: data => apiAxios.post('activation', data),
    checkToken: data => apiAxios.get('check-token', data),
    // twitterAuth: data => apiAxios.get('twitter-auth', data),
    // twitterCallback: data => apiAxios.get('twitter-callback', data),
    // facebookAuth: data => apiAxios.get('facebook-auth', data),
  },
  account: {
    changeAvatar: data => preprocess(apiAxios.post('change-avatar', data)),
    changePassword: data => apiAxios.post('change-password', data),
  },

  operatorInvitation: {
    accept: data => preprocess(apiAxios.post('accept-operator-invitation', data)),
  },

  city: {
    fetchCityList: () => preprocess(apiAxios.get(`city`)),
  },

  signIn: {
    signIn: data => apiAxios.post('login', data),
  },

  logout: {
    logout: data => apiAxios.post('logout', data),
  },

  changePassword: {
    forgotPass: data => apiAxios.post('forgot-password', data),
    newPass: data => apiAxios.post('new-password', data),
  },

  defaultService: {
    fetchList: () => preprocess(apiAxios.get('default-service')),
  },

  featureArticle: {
    fetchLinkables: () => preprocess(apiAxios.get('feature-articles')),
    fetchList: (current_page) => preprocess(apiAxios.get(`feature-article?current_page=${current_page}`)),
    get: (alias) => preprocess(apiAxios.get(`feature-article/${alias}`)),
  },

  paymentMethod: {
    fetchList: () => preprocess(apiAxios.get('shop-payment')),
  },

  // Shop's api directory
  shop: {
    fetch: (queryParams) => preprocess(apiAxios.get('shop?' + queryString(queryParams))),
  },
  shopGallery: {
    showAllAlbums: data => apiAxios.get(`shop-albums?shop_id=${data.id}`, data),
    createAlbum: data => apiAxios.post('shop-albums', data),
    showSpecificAlbum: data => apiAxios.get(`shop-albums/${data.id}`),
    updateSpecificAlbum: data =>
      preprocess(apiAxios.put(`shop-albums/${data.id}`, { name: data.name, photos: { ...data.photos } })),
    deleteAlbum: data => apiAxios.delete(`shop-albums/${data.id}`, data),
    nextPageAlbum: data => apiAxios.get(`shop-albums?current_page=${data}`),
  },
  shopNews: {
    fetch: shopId => preprocess(apiAxios.get(`shop-news?shop_id=${shopId}`, shopId)),
    get: data => preprocess(apiAxios.get(`shop-news/${data.id}`, data)),

    create: data => preprocess(apiAxios.post('shop-news', data)),
    update: data => preprocess(apiAxios.put(`shop-news/${data.id}`, data)),
    destroy: newsId => preprocess(apiAxios.delete(`shop-news/${newsId}`)),
  },
  shopNewsExternalFeed: {
    fetch: (newsId) => preprocess(apiAxios.get(`shop-news/${newsId}/external-feeds`)),
    create: (newsId, data) => preprocess(apiAxios.post(`shop-news/${newsId}/external-feeds`, data)),
    destroy: (newsId, id) => preprocess(apiAxios.delete(`shop-news/${newsId}/external-feeds/${id}`)),
  },
  shopNewsImage: {
    fetch: (newsId) => preprocess(apiAxios.get(`shop-news/${newsId}/photo`)),
    create: (newsId, data) => preprocess(apiAxios.post(`shop-news/${newsId}/photo`, data)),
    destroy: (newsId, id) => preprocess(apiAxios.delete(`shop-news/${newsId}/photo/${id}`)),
  },
  shopSettings: {
    changeGeneralData: data => preprocess(apiAxios.post('change-general-settings-shop', data)),
    changeContactsData: data => apiAxios.post('change-contacts-settings-shop', data),
    changeMediaData: data => apiAxios.post('change-media-settings-shop', data),
    changeScheduleData: data => apiAxios.post('change-schedule-settings-shop', data),
    getShopInformation: data => apiAxios.get(`get-shop?id=${data.id}`, data),
    stopPromotionStatistic: data => apiAxios.post(`stopped-promotion`, data),
    deletePromotionStatistic: data => apiAxios.post(`delete-promotion`, data),
  },
  ShopServices: {
    /**
     * @deprecated
     */
    showSpecificService: data => apiAxios.get(`shop-services/${data.id}`),
    createService: data => apiAxios.post('shop-services', data),
    getAllShopServices: data => apiAxios.get(`shop-services?user_id=${data.id}`),
    updateSpecificService: data => apiAxios.put(`shop-services/${data.id}`, data),
    deleteSpecificService: data => apiAxios.delete(`shop-services/${data.id}`),
    getFixedPriceShopServices: data => apiAxios.get(`shop-services-fixed-price?user_id=${data.id}`),
    getBookingShopServices: data => apiAxios.get(`get-shop-services-for-booking?user_id=${data.id}`),
  },
  ShopServiceTypes: {
    createServiceType: data => apiAxios.post(`shop-service-types`, data),
    getAllServiceTypes: data => apiAxios.get(`shop-service-types?shop_id=${data.id}`, data),
    getCreationServiceTypes: data => apiAxios.get(`service-types-from-create-service?shop_id=${data.id}`, data),
  },

  shopBookings: {
    importCalendar: data => apiAxios.get('export-calendar', data),
    getShopBookingsForCalendar: data => apiAxios.post('get-bookings-calendar', data),
    shareCheckout: data => apiAxios.post('share-checkout', data),
    getShopReviews: data => apiAxios.get(`shop-reviews?shop_id=${data.id}`),
    getOneShopReview: data => apiAxios.get(`shop-reviews/${data.id}`),
    leaveReview: data => apiAxios.post(`shop-reviews`, data),
    getCheckoutRequests: data => apiAxios.get(`get-checkout-requests`, data),
  },
  shopStaff: {
    fetchShopStaffScaleList: () => preprocess(apiAxios.get(`shop-staff-scale`)),

    fetchPaginated: ({ id = 0, currentPage = 1 }) =>
      apiAxios.get(`get-shop-in-staff?shop_id=${id}&current_page=${currentPage}`),
    fetchAll: data => apiAxios.get(`get-shop-staff?shop_id=${data.id}&order_by=${data.order_by || ''}&fields=${data.fields || ''}`),

    sendInviteToStaff: data => apiAxios.post('send-invite-staff', data),
    deleteStaffFromShop: data => apiAxios.put('delete-staff-from-shop', data),
    // acceptDeclineStaffRequest: data => apiAxios.post('decision-request-staff', data),
    setStaffRights: data => apiAxios.post('set-staff-rights', data),
    getRights: data => apiAxios.get('get-rights'),
  },

  // get all product is getProduct but for shop private this id  = shop id
  products: {
    addProduct: data => apiAxios.post('shop-products', data),
    getAllProducts: data => apiAxios.get(`shop-products`, data),
    updateProduct: data => apiAxios.put(`shop-products/${data.id}`, data),
    deleteProduct: data => apiAxios.delete(`shop-products/${data.id}`),
    getProduct: data => apiAxios.get(`shop-products/${data.id}`),
    getRecommended: data => apiAxios.get(`get-recommends?shop_id=${data.id}`),
    buyProduct: data => apiAxios.post('buy-product', data),
  },
  //staff's directory
  staff: {
    get: data => apiAxios.get(`get-staff?staff_id=${data.id}`),
  },
  staffSettings: {
    changeProfileStaffData: data => apiAxios.post('change-general-settings-staff', data),
    fetchCandidateShops: params => preprocess(apiAxios.get('search-shops-for-request?' + queryString(params))),
    sendRequest: data => preprocess(apiAxios.post('send-request-to-shops', data)),
    getStaffWorkingPlaces: data => apiAxios.get(`get-old-working-places?id=${data.id}`),
  },
  staffServices: {
    getAllStaffServices: data =>
      apiAxios.get(`staff-services?staff_id=${data.id}&sorting_by_price=${data.sorting_by_price}`),
  },

  staffBookings: {
    // @deprecated TODO Confirm whether such API endpoint is no longer used and remove it from backend
    // getAllStaffBookings: data => apiAxios.get(`staff-bookings`, data),

    // @deprecated TODO Confirm whether such API endpoint is no longer used and remove it from backend
    // getStaffBookingsById: data => apiAxios.get(`staff-bookings?staff_id=${data.id}`),

    getStaffBookingsForCalendar: data => apiAxios.post('staff-calendar', data),
  },
  staffSharings: {
    createSharing: data => apiAxios.post('staff-sharings', data),
    showSpecificSharings: data => apiAxios.get(`staff-sharings/${data.id}`, data),
    // updateSpecificSharing: data => apiAxios.put(`staff-sharings/${data.id}`, data),
    deleteSharing: data => apiAxios.delete(`staff-sharings/${data.id}`),
    showAllSharings: data => apiAxios.get(`staff-sharings?staff_id=${data.id}`, data),
  },
  staffGallery: {
    createStaffAlbum: data => apiAxios.post('staff-albums', data),
    showSpecificStaffAlbum: data => preprocess(apiAxios.get(`staff-albums/${data.id}?staff_id=${data.staff_id}`, data)),
    updateSpecificStaffAlbum: data =>
      preprocess(apiAxios.put(`staff-albums/${data.id}`, {
        name: data.name,
        photos: { ...data.photos },
      })),
    deleteSpecificStaffAlbum: data => apiAxios.delete(`staff-albums/${data.id}`, data),
    showAllStaffAlbums: data => apiAxios.get(`staff-albums?staff_id=${data.id}`),
  },
  // customer directory
  customer: {
    getByAlias: alias => preprocess(apiAxios.get(`customer/${alias}`)),
    getReviews: (alias, params) => preprocess(apiAxios.get(`customer/${alias}/review?` + queryString(params))),
    customerBonusItems: data => apiAxios.post('get-bonus-items', data),
    customerBonusHistory: data => apiAxios.get('ordered-bonus-items', data),
    customerEarningsHistory: data => preprocess(apiAxios.get('accrual-bonus-items', data)),
    getCustomerInterests: data => apiAxios.get(`get-interests`),
    customerBuyBonusItem: data => apiAxios.post('buy-bonus-items', data),
    customerChangeReview: (id, data) => apiAxios.put(`change-review/${id}`, data),
  },
  customerBookings: {
    // getFreeStaffonService: data => apiAxios.get('check-free-staff?shop_id=4&service_id=3&date=2018-05-10&time=10:00'),
    leaveReview: data => apiAxios.post('shop-reviews', data),
    sendCheckoutRequest: data => apiAxios.put(`send-checkout-request/${data.id}`, data),
    // bookingDollars: data => apiAxios.get('booking-dollars', data),
  },
  customerSettings: {
    changeCustomerSettings: data => apiAxios.post('change-settings-customer', data),
    getAllComments: data => apiAxios.post('get-all-comments', data),
    changeVisibleComment: data => apiAxios.post('change-visible-comment', data),
    changeShowComments: data => apiAxios.post('change-show-comments', data),
  },
  getCustomerInformationBy: {
    /**
     * @deprecated Use api.customer.getByAlias(...) instead
     */
    getCustomerInformationById: data =>
      apiAxios.get(`get-customer?customer_id=${data.id}${data.per_page ? `&per_page=${data.per_page}` : ''}`),
    // getCustomerInformationByToken: data => apiAxios.get('get-customer', data),
  },

  //shop's chats directory

  shopsChat: {
    // createCommonShopsChat: data => apiAxios.post('create-common-shop-chat', data),
    getAllChatsForUser: data => apiAxios.get('shop-chats', data),
    sendMessageToChat: data => apiAxios.post('chat-messages', data),
    getSpecificChatsWithMessages: data => apiAxios.get(`shop-chats/${data.id}`, data),
    closeChat: data => apiAxios.post(`close-chat`, data),
  },

  search: {
    search: data => preprocess(apiAxios.post(`get-all-shops`, data)),
    searchByName: data => apiAxios.get(`global-shops-search?search=${data.id}`),
  },
  landingPage: {
    fetchLinkables: () => preprocess(apiAxios.get('landing-pages')),
    get: (alias, device) => apiAxios.get(`landing-page?alias=${alias}&device=${device}`),
  },
  mainPage: {
    mostReviewedShops: () => apiAxios.get(`most-reviewed-shops`),
    siteLatestReviews: () => apiAxios.get(`shop-reviews`),
  },

  notifications: {
    getNotifications: () => apiAxios.get(`get-system-messages`),
  },
  promotion: {
    sendRequest: data => apiAxios.post(`send-promotion-request`, data),
    getPromotionList: data => apiAxios.get(`get-shop-promotion-list`, data),
    getPromotionPage: data => apiAxios.get(`promotion-page?sorting_by_price=${data}`, data),
  },
  payment: {
    getCards: data => apiAxios.get(`credit-card`, data),
  },
  shopReviews: {
    getReviews: data => apiAxios.get(`review-moderation`, data),
    leaveReply: data => preprocess(apiAxios.put(`shop-reviews/${data.id}`, data)),
  },
  pagination: {
    bookingsPagination: data => apiAxios.get(`${data.user}/${data.type}?current_page=${data.page}`),
  },
  admin: {
    bannerImage: {
      fetchList: (bannerId) => preprocess(adminApiAxios.get(`banner/${bannerId}/photo`)),
      create: (bannerId, data) => preprocess(adminApiAxios.post(`banner/${bannerId}/photo`, data)),
      destroy: (bannerId, id) => preprocess(adminApiAxios.delete(`banner/${bannerId}/photo/${id}`)),
    },
    bonusItem: {
      fetchList: params => preprocess(adminApiAxios.get(`bonus-items?${queryString(params)}`)),
      create: data => adminApiAxios.post(`bonus-item`, data),
      update: data => adminApiAxios.put('bonus-item', data),
      delete: data => adminApiAxios.delete(`bonus-item/${data.id}`),
    },
    booking: {
      refreshCheckoutToken: (data) => preprocess(adminApiAxios.post(`booking-checkout-token`, data)),
      fetchList: (data) => adminApiAxios.post(`bookings`, data),
    },
    defaultService: {
      fetchTree: () => preprocess(adminApiAxios.get(`default-service-tree`)),
      fetchList: (parentId = '') => preprocess(adminApiAxios.get(`default-service?parentId=${parentId}`)),
      get: id => preprocess(adminApiAxios.get(`default-service/${id}`)),
      create: data => preprocess(adminApiAxios.post(`default-service`, data)),
      update: (id, data) => preprocess(adminApiAxios.put(`default-service/${id}`, data)),
      destroy: id => preprocess(adminApiAxios.delete(`default-service/${id}`)),
      reorder: data => preprocess(adminApiAxios.put(`default-service-reorder`, data)),
    },
    featureArticleCoverImage: {
      fetchList: (articleId) => preprocess(adminApiAxios.get(`feature-article/${articleId}/cover`)),
      create: (articleId, data) => preprocess(adminApiAxios.post(`feature-article/${articleId}/cover`, data)),
      destroy: (articleId, id) => preprocess(adminApiAxios.delete(`feature-article/${articleId}/cover/${id}`)),
    },
    featureArticleImage: {
      fetchList: (articleId) => preprocess(adminApiAxios.get(`feature-article/${articleId}/photo`)),
      create: (articleId, data) => preprocess(adminApiAxios.post(`feature-article/${articleId}/photo`, data)),
      destroy: (articleId, id) => preprocess(adminApiAxios.delete(`feature-article/${articleId}/photo/${id}`)),
    },
    invoice: {
      fetchList: data => preprocess(adminApiAxios.post('invoice-management', data)),
      updateInvoiceComment: data => preprocess(adminApiAxios.post('invoice-management/comment', data)),
      approve: data => preprocess(adminApiAxios.post('invoice-management/approve', data)),
      resendInvoice: data => preprocess(adminApiAxios.post('invoice-management/resend', data)),
    },
    permission: {
      fetchList: () => preprocess(adminApiAxios.get(`permission`)),
      fetchTree: () => preprocess(adminApiAxios.get('permission-tree')),
    },
    operator: {
      fetchList: (params) => preprocess(adminApiAxios.get(`operator?` + queryString(params))),
      get: userId => preprocess(adminApiAxios.get(`operator/${userId}`)),
      update: (userId, data) => preprocess(adminApiAxios.put(`operator/${userId}`, data)),
      destroy: userId => preprocess(adminApiAxios.delete(`operator/${userId}`)),
    },
    operatorInvitation: {
      fetchList: (params) => preprocess(adminApiAxios.get(`operator-invitation?` + queryString(params))),
      create: data => preprocess(adminApiAxios.post(`operator-invitation`, data)),
      resend: (invitationId) => preprocess(adminApiAxios.put(`operator-invitation/${invitationId}`)),
      destroy: id => preprocess(adminApiAxios.delete(`operator-invitation/${id}`)),
    },
    photo: {
      update: (id, data) => preprocess(adminApiAxios.post(`photo/${id}`, data)),
    },
    paymentMethod: {
      fetchList: () => preprocess(adminApiAxios.get('shop-payment-method')),
      get: id => preprocess(adminApiAxios.get(`shop-payment-method/${id}`)),
      create: data => preprocess(adminApiAxios.post('shop-payment-method', data)),
      update: (id, data) => preprocess(adminApiAxios.put(`shop-payment-method/${id}`, data)),
      destroy: id => preprocess(adminApiAxios.delete(`shop-payment-method/${id}`)),
      reorder: data => preprocess(adminApiAxios.put('shop-payment-method-reorder', data)),
    },
    review: {
      fetchList: data => preprocess(adminApiAxios.post(`get-reviews`, data)),
      updateComment: data => preprocess(adminApiAxios.post(`review/${data.id}/comment`, data)),
      approve: data => preprocess(adminApiAxios.post(`review/${data.id}/approve`, data)),
      destroy: id => preprocess(adminApiAxios.delete(`review/${id}`)),
    },
    shop: {
      fetchList: params => preprocess(adminApiAxios.get(`shop?` + queryString(params))),
      get: userId => preprocess(adminApiAxios.get(`shop/${userId}`)),
      update: (userId, data) => preprocess(adminApiAxios.put(`shop/${userId}`, data)),
      syncDefaultService: (shopId, data) => preprocess(adminApiAxios.put(`shop/${shopId}/default-service`, data)),
    },
    shopAlbum: {
      destroy: albumId => preprocess(adminApiAxios.delete(`shop-album/${albumId}`)),
    },
    shopProduct: {
      destroy: productId => preprocess(adminApiAxios.delete(`shop-product/${productId}`)),
    },
    shopNews: {
      destroy: newsId => preprocess(adminApiAxios.delete(`shop-news/${newsId}`)),
    },
    shopService: {
      destroy: (serviceId) => preprocess(adminApiAxios.delete(`shop-service/${serviceId}`)),
    },
    customer: {
      fetchList: params => preprocess(adminApiAxios.get(`customer?` + queryString(params))),
      get: userId => preprocess(adminApiAxios.get(`customer/${userId}`)),
      update: (userId, data) => preprocess(adminApiAxios.put(`customer/${userId}`, data)),
    },
    staff: {
      fetchList: params => preprocess(adminApiAxios.get(`staff?` + queryString(params))),
      get: userId => preprocess(adminApiAxios.get(`staff/${userId}`)),
      update: (userId, data) => preprocess(adminApiAxios.put(`staff/${userId}`, data)),
    },
    staffAlbum: {
      destroy: (staffId, albumId) => preprocess(adminApiAxios.delete(`staff/${staffId}/album/${albumId}`)),
    },
    staffSharing: {
      destroy: (staffId, sharingId) => preprocess(adminApiAxios.delete(`staff/${staffId}/sharing/${sharingId}`)),
    },
    getVideosVideoChanel: data => apiAxios.post(`get-youtube-video`, data),
    getVideos: data => adminApiAxios.get(`youtube-video?current_page=${data.page}`),
    getPublishers: () => adminApiAxios.get(`video-publishers`),
    getCategories: () => adminApiAxios.get(`video-categories`),
    createVideo: data => adminApiAxios.post(`youtube-video`, data),
    createPublisher: data => adminApiAxios.post(`video-publisher`, data),
    createCategory: data => adminApiAxios.post(`video-category`, data),
    deleteVideo: data => adminApiAxios.delete(`youtube-video/${data.id}`),
    deletePublisher: data => adminApiAxios.delete(`video-publisher/${data.id}`),
    deleteCategory: data => adminApiAxios.delete(`video-category/${data.id}`),
    updateVideo: data => adminApiAxios.put(`youtube-video`, data),
    updatePublisher: data => adminApiAxios.put(`video-publisher`, data),
    updateCategory: data => adminApiAxios.put(`video-category`, data),
    getVotingQuestions: data => adminApiAxios.get(`voting-machine?current_page=${data.page}`),
    getPromotions: data => adminApiAxios.get(`promotion-page?current_page=${data.page}`),
    getProductsServices: data => adminApiAxios.get(`shops-from-create-promotion?shop_id=${data.id}`),
    createPromotion: data => adminApiAxios.post(`create-promotion`, data),
    getAdminShopList: params => preprocess(adminApiAxios.get(`get-not-approved-shops?` + queryString(params))),
    changeShopStatus: data => adminApiAxios.post(`approved-shop`, data),
    getShopLabels: data => adminApiAxios.get(`get-shop-labels`, data),
    getShopLabelsWithoutPagination: () => adminApiAxios.get(`get-labels`),
    addShopLabel: data => adminApiAxios.post(`shop-label`, data),
    editShopLabel: data => adminApiAxios.put(`shop-label`, data),
    deleteShopLabel: data => adminApiAxios.delete(`shop-label/${data.id}`),

    Configuration: {
      get: () => preprocess(adminApiAxios.get('configuration')),
      update: data => preprocess(adminApiAxios.put('configuration', data)),
    },

    addNewCity: data => adminApiAxios.post(`city`, data),
    addNewDistrict: data => adminApiAxios.post(`district`, data),
    editCity: data => adminApiAxios.put(`city`, data),
    editDistrict: data => adminApiAxios.put(`district`, data),
    deleteDistrict: data => adminApiAxios.delete(`district/${data.id}`),
    deleteCity: data => adminApiAxios.delete(`city/${data.id}`),
    getBookingDollarsSettings: () => adminApiAxios.get(`booking-dollars-setting`),
    setBookingDollarsSettings: data => adminApiAxios.put(`booking-dollars-setting`, data),
    setBookingDollars_ShopCharge: data => adminApiAxios.put(`invoice-accrued-amount-booking`, data),
    getHomePictures2: () => apiAxios.get(`home-pictures2`),
    changeHomePictures2: data => adminApiAxios.post(`home-pictures2`, data),
    changeHomePictures2Links: data => adminApiAxios.post(`link-home-pictures2`, data),

    RedeemApplication: {
      fetch: params => preprocess(adminApiAxios.get(`redeem-application?${queryString(params)}`)),
      updateComment: data => preprocess(adminApiAxios.put(`redeem-application/comment`, data)),
      updateStatus: data => preprocess(adminApiAxios.put('redeem-application/status', data)),
    },

    defaultShopAvatar: {
      get: () => preprocess(adminApiAxios.get(`default-shop-avatar`)),
      create: data => preprocess(adminApiAxios.post(`default-shop-avatar`, data)),
      destroy: () => preprocess(adminApiAxios.delete(`default-shop-avatar`)),
    },

    storeCreditCard: data => apiAxios.post('store-credit-card', data),
    buyProduct: data => apiAxios.post('buy-product', data),
    shopBuyPhotoLimit: data => apiAxios.post('shop-buy-photo-limit', data),
    staffBuyPhotoLimit: data => apiAxios.post('staff-buy-photo-limit', data),
    shopPayInvoice: data => apiAxios.post(`pay-invoice/${data.invoice_id}`, data),
    getPaymentDetails: data => apiAxios.get('invoice/get-payment-details', data),
    changeInvoiceDiscount: data => adminApiAxios.put(`invoice-discount`, data),
    addLevel: data => adminApiAxios.post('level', data),
    getLevels: () => adminApiAxios.get(`get-levels`),
    editLevel: data => adminApiAxios.put(`level`, data),
  },
  adminUsers: {
    getUserBookings: data => adminApiAxios.get(`bookings/${data.type}/${data.id}?current_page=${data.page}`),
    staffBookings: data => apiAxios.get(`staff-bookings?staff_id=${data.id}`),
    getCustomerReviews: data =>
      preprocess(adminApiAxios.get(`user/${data.id}/review?current_page=${data.page}&sort_date=${data.date}`)),
    getInvoiceBookings: data => apiAxios.get(`invoice/${data.shopId}/${data.token}`),
    getInvoiceDetails: () => adminApiAxios.get(`invoice-payment-details`),
    setInvoiceDetails: data => adminApiAxios.put(`invoice-payment-details`, data),
    addNewInvoiceField: data => adminApiAxios.post(`invoice-other-payment`, data),
    editNewInvoiceField: (data, index) => adminApiAxios.put(`invoice-other-payment/${index}`, data),
    deleteNewInvoiceField: data => adminApiAxios.delete(`invoice-other-payment/${data.id}/${data.index}`),
    getAccuralBonuses: data =>
      preprocess(adminApiAxios.get(`user/accrual-bonus-items?user_id=${data.id}&page=${data.page}`)),
    getOrderedBonuses: data =>
      adminApiAxios.get(`user/ordered-bonus-items?user_id=${data.id}&current_page=${data.page}`),
  },
  video: {
    getPublicPublishers: () => apiAxios.get(`video-publishers`),
    getPublicCategories: () => apiAxios.get(`get-video-categories`),
  },
};
