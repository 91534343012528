import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

import notification from 'src/Components/Common/notification';


interface Props {
  children: React.ReactNode;
  className?: string;
  hint?: string;

  /**
   * text to be copied
   */
  text: string;
}
const CopyButton = ({
  children,
  className = '',
  hint = '',
  text,
}: Props) => {

  const { t } = useTranslation();

  const copy = () => {
    try {
      navigator.clipboard.writeText(text);
      notification(t('notifications.textCopied'));
    } catch (err) {
      notification(t('notifications.somethingWasWrong'));
    }
  };

  return (
    <Tooltip className={className} placement="top" title={hint}>
      <button
        className="btn btn-sm btn-outline-secondary"
        type="button"
        onClick={copy}
      >
        {children}
      </button>
    </Tooltip>
  );
}

export default CopyButton;
