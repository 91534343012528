/**
 * Potentially truncate a text and add '...' to end of the truncated text.
 */
export const ellipsize = (text: string, maxlength: number) => {
  maxlength = Math.max(3, maxlength); // The least value of maxlength should be 3
  return (text || '').length > maxlength
    ? `${text.slice(0, maxlength - 3)}...`
    : text || '';
};

/**
 * To test if there is at least one non-whitespace character.
 * @param {string} text
 */
export const isFilled = (text: string) => /[^\s]/.test(text);
