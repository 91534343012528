import {
  DEFINITION_CITY_LIST_WILL_FETCH,
  DEFINITION_CITY_LIST_DID_FETCH,
} from '../../constants/constants';

const initialState = {
  isLoadingCityList: false,
  cityList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DEFINITION_CITY_LIST_WILL_FETCH:
      return {
        ...state,
        isLoadingCityList: true,
        cityList: [],
      };
    case DEFINITION_CITY_LIST_DID_FETCH:
      return {
        ...state,
        isLoadingCityList: false,
        cityList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
