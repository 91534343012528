import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';

import axios from 'axios';


import { useConfirmationDialog } from '../../../../Providers/ConfirmDialogProvider';

import { fetchShopStaff } from '../../../../Redux/actions/shopActions/shopStaffActions';

import notification from '../../../Common/notification';
import { AppButton } from '../../../Common/Button';


const ConfirmDeclineButton = ({
  onConfirm,
}) => {

  const { t } = useTranslation();

  const { openConfirmationDialog } = useConfirmationDialog();

  const handleClick = () => {
    openConfirmationDialog({
      message: t('shopPrivate.staff.declineStaffRequest.askForConfirm'),
      onConfirm,
    });
  }

  return (
    <AppButton size="small" theme="muted" onClick={handleClick}>
      <i className="fas fa-ban mr-1" />
      {t('shopPrivate.staff.declineStaffRequest.action')}
    </AppButton>
  );
}


class RequestedStaff extends Component {

  acceptStaffRequest = () => {
    const { staff, currentShopId } = this.props;
    axios.post(`/api/staff-request`, { staff_id: staff.id, decision: 'accept' }).then(res => {
      if (res.data.message === 'success') {
        this.props.fetchShopStaff({ id: currentShopId, fields: 'staff_requests' }, true);
        notification(res.data.data ? res.data.data : this.props.t('notifications.requestAccepted'));
      } else {
        notification(res.data.message ? res.data.message : this.props.t('notifications.somethingWentWrong'));
      }
    });
  };

  declineStaffRequest = () => {
    const { staff, currentShopId } = this.props;
    axios.post(`/api/staff-request`, { staff_id: staff.id, decision: 'decline' }).then(res => {
      if (res.data.message === 'success') {
        this.props.fetchShopStaff({ id: currentShopId, fields: 'staff_requests' }, true);
        notification(res.data.data ? res.data.data : this.props.t('notifications.requestRejected'));
      } else {
        notification(res.data.message ? res.data.message : this.props.t('notifications.somethingWentWrong'));
      }
    });
  };

  redirect = staffId => {
    this.props.history.push(`/staff/${staffId}`);
  };

  render() {
    const {
      indexNum,
      staff,
      t,
    } = this.props;
    return (
      <tr data-label-tr={`${t('block')} #${indexNum}`}>
        <td
          data-label={t('generalFields.humanFirstName.label')}
          style={{ cursor: 'pointer' }}
          onClick={() => this.redirect(staff.id)}
        >
          <span>{staff.user_type_data.first_name}</span>
        </td>
        <td data-label={t('generalFields.humanLastName.label')}>
          <span>{staff.user_type_data.last_name}</span>
        </td>
        <td data-label={t('staff.nickName.label')}>
          <span>{staff.user_type_data.nickname}</span>
        </td>
        <td data-label={t('generalFields.email.label')}>
          <span>{staff.email}</span>
        </td>
        <td data-label={t('shopPrivate.reviewModer.action')}>
          <AppButton size="small" theme="success" onClick={this.acceptStaffRequest}>
            <i className="fas fa-check mr-1" />
            {t('shopPrivate.staff.accept')}
          </AppButton>
          <ConfirmDeclineButton onConfirm={this.declineStaffRequest} />
        </td>
      </tr>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      null,
      {
        fetchShopStaff,
      }
    )(RequestedStaff)
  )
);
