import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import BookingReviewRatings from '../../../Common/RatingStars/BookingReviewRatings';
import { CustomerBadge } from '../../../Common/Customer';
import { ShopAvatar } from '../../../Common/Shop';
import { LineBreakable } from '../../../Common/Text';


const ReviewItem = ({
  shopAvatar,
  review,
  onRedirect,
}) => {

  const { t } = useTranslation();

  return (
    <div className="review-container">
      <div className="row">
        <div className="col-xl-2">
          <CustomerBadge customer={review.customer} />
        </div>

        <div className="col-xl-7" onClick={() => onRedirect(review.id)}>
          <p className="review-title">{review.title}</p>
          <p className="review-info">{review.created_at}</p>

          <div onClick={() => onRedirect(review.id)}>
            <LineBreakable className="review-content">
              {review.review}
            </LineBreakable>
          </div>

          <div className="sharing-photo">
            {review.photos &&
              review.photos.map((photo, index) => (
                <div className="sharing-photo__item" key={index}>
                  <div className="news-prev-img">
                    <img src={photo.url} alt={photo.url.slice(photo.url.lastIndexOf('/') + 1)} />
                  </div>
                </div>
              ))}
          </div>

          {review.reply && (
            <div className="review-answer">
              <p className="review-answer__title">{t('shopPublic.review.shopAnswer')}</p>
              <div className="answer__img">{shopAvatar && <ShopAvatar src={shopAvatar} />}</div>
              <LineBreakable className="review-answer__text" onClick={() => onRedirect(review.id)}>
                {review.reply}
              </LineBreakable>
            </div>
          )}
        </div>

        <div className="col-xl-3">
          <BookingReviewRatings ratings={review.review_average_rating} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReviewItem);
