import api from '../../../api';
import {
  ALBUM_PHOTO_WILL_FETCH,
  GET_ALBUM_PHOTOS,
  ALBUM_WILL_FETCH,
  SHOW_ALL_ALBUMS,
  CREATE_ALBUM,
  DELETE_ALBUM,
  UPLOAD_PHOTO,
  NEXT_PAGE_ALBUM,
} from '../../constants/constants';

export const createAlbum = data => dispatch =>
  api.shopGallery.createAlbum(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(CreateAlbum(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

export const deleteAlbum = data => dispatch =>
  api.shopGallery.deleteAlbum(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(DeletePhotos(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

export const savePhotoIntoAlbum = data => dispatch =>
  api.shopGallery.updateSpecificAlbum(data).then(response => {
    dispatch(UploadPhoto(response.data));
    return { res: response, access: true };
  }).catch(() => {
    return { res: null, access: false };
  });

export const showSpecificAlbum = data => dispatch => {
  dispatch(albumPhotoWillFetch());

  return api.shopGallery.showSpecificAlbum(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(GetPhotos(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });
}

export const showAllAlbums = data => dispatch => {
  dispatch(albumWillFetch());

  return api.shopGallery.showAllAlbums(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(ShowAllAlbums(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });
}

export const nextPageAlbum = data => dispatch =>
  api.shopGallery.nextPageAlbum(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(NextPageAlbum(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

const albumPhotoWillFetch = () => ({
  type: ALBUM_PHOTO_WILL_FETCH,
});

// TODO Rename to albumPhotoDidFetch
export const GetPhotos = data => ({
  type: GET_ALBUM_PHOTOS,
  payload: data,
});

const DeletePhotos = data => ({
  type: DELETE_ALBUM,
  payload: data,
});

const albumWillFetch = () => ({
  type: ALBUM_WILL_FETCH,
});

// TODO Rename to albumDidFetch
const ShowAllAlbums = data => ({
  type: SHOW_ALL_ALBUMS,
  payload: data,
});

const UploadPhoto = data => ({
  type: UPLOAD_PHOTO,
  payload: data,
});

const CreateAlbum = data => ({
  type: CREATE_ALBUM,
  payload: data,
});

const NextPageAlbum = data => ({
  type: NEXT_PAGE_ALBUM,
  payload: data,
});
