import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { debounce } from 'lodash';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';


import {
  Booking,
  BookingMaxlength,
  CustomerData,
  Service,
  ServiceMadeByStaff,
  ServiceType,
  Shop,
} from 'src/models/entity';

import { BaseResponse } from 'src/models/api/response/BaseResponse';

import { equalId } from 'src/utils/api/api-utils';
import {
  customerAPI,
  shopAPI,
  shopBookingAPI,
  shopServiceAPI,
} from 'src/utils/api';

import useErrorHandler from 'src/hooks/useErrorHandler';


import notification from '../notification';

import { AppButton } from 'src/Components/Common/Button';
import { CurrencyAmount } from 'src/Components/Common/Text';
import { FormField, Textarea, TextInputField } from 'src/Components/Common/Form';
import Spinner from '../Spinner/Spinner';

import BookingDateTimePicker from 'src/Components/Common/Booking/BookingDateTimePicker';
import { ShopBadge } from 'src/Components/Common/Shop';


interface Props {
  shopId: string;

  onCreated: (booking: Booking) => void;

  onClose: () => void;
}

const BookingCreationByProviderDialog = ({
  shopId,
  onCreated,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  /**
   * This state helps prevent customer from accidentally / unintentionally dismissing the dialog,
   * either by clicking / tapping backdrop area or pressing ESC after user has filled / selected something.
   *
   * A `true` value => User has NOT ever interacted with the form.
   */
  const [isPristine, setPristine] = useState(true);

  const [isInitializing, setInitializing] = useState(true);

  const [shop, setShop] = useState<Shop>();

  const [serviceTypeOptions, setServiceTypeOptions] = useState<ServiceType[]>(
    []
  );
  const [dirtyServiceType, setDirtyServiceType] = useState<ServiceType>();

  const [serviceOptions, setServiceOptions] = useState<Service[]>([]);
  const [dirtyService, setDirtyService] = useState<Service>();

  const [staffOptions, setStaffOptions] = useState<ServiceMadeByStaff[]>([]);
  const [dirtyStaff, setDirtyStaff] = useState<ServiceMadeByStaff>();
  const [dirtyStaffError, setDirtyStaffError] = useState('');
  const [staffCharge, setStaffCharge] = useState(0);

  const [dirtyBookingAt, setDirtyBookingAt] = useState(moment());

  const [bookingDateError, setBookingDateError] = useState('');
  const [bookingTimeError, setBookingTimeError] = useState('');

  const [isSearchingCustomer, setIsSearchingCustomer] = useState(false);
  const [dirtyPhone, setDirtyPhone] = useState('+852');
  const [dirtyPhoneError, setDirtyPhoneError] = useState('');

  const [dirtyCustomerData, setDirtyCustomerData] = useState<CustomerData>();
  const [dirtyCustomerName, setDirtyCustomerName] = useState('');
  const [dirtyCustomerNameError, setDirtyCustomerNameError] = useState('');

  const [dirtyProviderRemarks, setDirtyProviderRemarks] = useState('');

  const [isSubmitting, setSubmitting] = useState(false);

  const isDirtyPhoneValid = useMemo(
    () => dirtyPhone.match(/^\+852\d{8}$/),
    [dirtyPhone]
  );

  const isCustomerNameDisabled = useMemo(() => {
    if (isSearchingCustomer || !!dirtyCustomerData) {
      return true;
    }

    if (!isDirtyPhoneValid) {
      return true;
    }

    return false;
  }, [dirtyCustomerData, isDirtyPhoneValid, isSearchingCustomer]);

  const originalPrice = useMemo(() => {
    if (dirtyService?.price) {
      return Number(dirtyService.price) || 0;
    }

    if (dirtyService?.price_fixed) {
      return Number(dirtyService.price_fixed) || 0;
    }

    return 0;
  }, [dirtyService]);

  const discountedPrice = useMemo(() => {
    if (dirtyService) {
      if (dirtyService.promotions?.length) {
        return (Number(dirtyService.promotions[0].price) || 0) + staffCharge;
      }

      if (dirtyService?.price) {
        return (Number(dirtyService.price) || 0) + staffCharge;
      }

      if (dirtyService?.price_fixed) {
        return (Number(dirtyService.price_fixed) || 0) + staffCharge;
      }
    }

    return 0;
  }, [dirtyService, staffCharge]);

  const isFormDataSufficient = useMemo(() => {
    if (!dirtyServiceType || !dirtyService || !dirtyBookingAt || !dirtyPhone) {
      return false;
    }

    if (!dirtyCustomerData && !dirtyCustomerName) {
      return false;
    }

    return true;
  }, [
    dirtyServiceType,
    dirtyService,
    dirtyBookingAt,
    dirtyPhone,
    dirtyCustomerData,
    dirtyCustomerName,
  ]);

  // Initialization
  useEffect(() => {
    shopAPI.getShop(shopId, ['schedule'])
      .then((remoteShop) => {
        setShop(remoteShop);

        return shopServiceAPI
          .getShopServiceTypes(remoteShop.id)
          .then((serviceTypes) => {
            setServiceTypeOptions(serviceTypes);
          });
      })
      .catch(() => {
        notification(t('notifications.somethingWentWrong'));
        onClose();
      })
      .finally(() => {
        setInitializing(false);
      });
  }, []);

  useEffect(() => {
    setServiceOptions(
      dirtyServiceType?.services?.filter(
        (service) => service.available === 1
      ) || []
    );
    setDirtyService(undefined);
  }, [dirtyServiceType]);

  useEffect(() => {
    setStaffOptions(dirtyService?.madeBy || []);
  }, [dirtyService]);

  useEffect(() => {
    setStaffCharge(Number(dirtyStaff?.pivot?.charge || 0));
  }, [dirtyStaff]);

  const handleDialogClose = (e: {}, reason: string) => {
    if (isPristine) {
      onClose();
    }
  };

  const handleChangeServiceType = (value: string) => {
    const selectedServiceType = serviceTypeOptions.find(
      (serviceType) => `${serviceType.id}` === value
    );

    setDirtyServiceType(selectedServiceType);

    setPristine(false);
  };

  const handleChangeService = (value: string) => {
    const selectedService = serviceOptions.find(
      (service) => `${service.id}` === value
    );

    setDirtyService(selectedService);

    setPristine(false);
  };

  const handleChangeStaff = (value: string) => {
    const selectedStaff = staffOptions.find((staff) =>
      equalId(staff.id, value)
    );

    setDirtyStaff(selectedStaff);

    setPristine(false);
  };

  const handleChangeDateTime = (
    bookingAtDate: Date,
    isInitiatedByUser: boolean
  ) => {
    setDirtyBookingAt(moment(bookingAtDate));

    if (isInitiatedByUser) {
      setPristine(false);
    } else {
      // It is probably the initial value (default value) of the dat time picker
    }

    // clear any error messages
    setBookingDateError('');
    setBookingTimeError('');
  };

  const handleChangePhone = (val: string) => {
    if (!val) {
      val = '+852';
    } else {
      val = val.startsWith('+852') ? val : dirtyPhone;
    }

    setDirtyPhone(val);

    setDirtyCustomerData(undefined);
    setDirtyCustomerName('');

    fireSearchCustomerByPhone(val);
  };

  const fireSearchCustomerByPhone = useCallback(
    debounce((val: string) => {
      if (!val) {
        setDirtyCustomerData(undefined);
        return;
      }

      setDirtyPhoneError('');
      setDirtyCustomerNameError('');

      if (!val.match(/^\+852\d{8}/)) {
        return;
      }

      setIsSearchingCustomer(true);

      customerAPI.searchCustomerByPhone({
        phone: val,
      })
        .then((customerData) => {
          setDirtyCustomerData(customerData);

          setDirtyCustomerName(
            `${customerData.first_name} ${customerData.last_name}`
          );
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            setDirtyCustomerNameError(
              t('booking.error.customerNotFoundByPhone')
            );
          } else if (e.response?.status === 422) {
            const response = e.response?.data as BaseResponse;

            if (response?.errors?.phone) {
              setDirtyPhoneError(response.errors?.phone?.join('\n'));
            }
          }
        })
        .finally(() => {
          setIsSearchingCustomer(false);
        });
    }, 450),
    []
  );

  const handleChangeProviderRemarks = (value: string) => {
    setDirtyProviderRemarks(value);

    setPristine(false);
  };

  const fireCheckTime = () => {
    // clear any error messages
    setBookingDateError('');
    setBookingTimeError('');

    const data: shopBookingAPI.CheckTimeRequest = {
      shopId: shop?.id ?? '',
      date: dirtyBookingAt.format('Y-MM-DD'),
      time: dirtyBookingAt.format('HH:mm'),
    };

    return shopBookingAPI.checkTime(data).catch((e) => {
      if (e.response?.status === 422) {
        const validationErrors = e.response.data.errors;
        if (validationErrors.date) {
          setBookingDateError(validationErrors.date.join('\n'));
        }

        if (validationErrors.time) {
          setBookingTimeError(validationErrors.time.join('\n'));
        }
      }

      throw e;
    });
  };

  const handleSubmit = () => {
    setSubmitting(true);

    fireCheckTime()
      .then(() => {
        const promotionId = dirtyService?.promotions?.find(
          (item, idx) => idx === 0
        )?.id;

        return shopBookingAPI
          .createBooking({
            shopId: shop?.id ?? '',
            staffId: dirtyStaff?.id || '',
            serviceId: dirtyService ? dirtyService.id : '',
            date: dirtyBookingAt.format('Y-MM-DD'),
            time: dirtyBookingAt.format('HH:mm'),
            promotionId,

            customerId: dirtyCustomerData?.user_id,
            customerPhone: dirtyPhone,
            customerName: dirtyCustomerName,
            providerRemarks: dirtyProviderRemarks,
          })
          .then((booking) => {
            notification(t('notifications.bookingCreated'));

            onCreated(booking);
          })
          .catch((e) => {
            if (e.response?.status === 422 && e.response?.data) {
              const response = e.response.data as BaseResponse;
              const validationErrors = response.errors;
              if (validationErrors?.staffId) {
                setDirtyStaffError(validationErrors.staffId.join('\n'));
              }
            }

            throw e;
          });
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e, { suppress: ['422'] });
        // }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Dialog
      classes={{ paper: 'book-window' }}
      fullWidth={true}
      keepMounted={true}
      maxWidth="md"
      open={true}
      onClose={handleDialogClose}
      aria-labelledby="create-booking-dialog-title"
    >
      <DialogTitle id="create-booking-dialog-title">
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            {shop && <ShopBadge avatarSize="small" horizontal shop={shop} />}
          </div>

          <button className="btn btn-link" onClick={onClose}>
            <i className="fas fa-times text-muted" />
          </button>
        </div>
      </DialogTitle>

      <DialogContent>
        {isInitializing ? (
          <Spinner display="block" />
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {/* Able to disable child input fields when submitting form */}
            <fieldset disabled={isSubmitting}>
              <div className="row">
                <div className="col-lg-6">
                  {/* Picker for service type */}
                  <FormField
                    className="m-0"
                    label={t('shopPublic.overview.createBooking.serviceType')}
                    required
                  >
                    <select
                      className="form-item"
                      onChange={(e) => handleChangeServiceType(e.target.value)}
                    >
                      <option value="">
                        {t('action.selectOneFromEnumeration')}
                      </option>
                      {serviceTypeOptions.map((serviceType) => (
                        <option key={serviceType.id} value={serviceType.id}>
                          {serviceType.name}
                        </option>
                      ))}
                    </select>
                  </FormField>
                </div>

                <div className="col-lg-6">
                  {/* Picker for service */}
                  <FormField
                    className="m-0"
                    label={t('shopPublic.overview.createBooking.service')}
                    required
                  >
                    <select
                      className="form-item"
                      disabled={!dirtyServiceType}
                      onChange={(e) => handleChangeService(e.target.value)}
                    >
                      <option value="">
                        {t('action.selectOneFromEnumeration')}
                      </option>
                      {serviceOptions.map((service) => (
                        <option key={service.id} value={service.id}>
                          {service.name}
                        </option>
                      ))}
                    </select>
                  </FormField>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  {/* Picker for staff */}
                  <FormField
                    className="m-0"
                    label={t('shopPublic.overview.createBooking.staffName')}
                    errorMessage={dirtyStaffError}
                    required
                  >
                    <select
                      className="form-item"
                      disabled={!dirtyService}
                      value={dirtyStaff?.id}
                      onChange={(e) => handleChangeStaff(e.target.value)}
                    >
                      <option value="">
                        {t('shopPublic.overview.createBooking.noStaff')}
                      </option>
                      {staffOptions.map((staff) => (
                        <option key={staff.id} value={staff.id}>
                          {/* {staff.user_type_data?.first_name} {staff.user_type_data?.last_name} */}
                          {staff.user_type_data?.nickname}
                        </option>
                      ))}
                    </select>
                  </FormField>
                </div>

                <div className="col-lg-6">
                  {/* Booking Price */}
                  <FormField
                    className="m-0"
                    label={t('shopPublic.overview.createBooking.price')}
                  >
                    <div className="product-price product-price--booking-type">
                      {discountedPrice < originalPrice ? (
                        <>
                          <p className="product-price__new">
                            <CurrencyAmount amount={discountedPrice} />
                          </p>

                          {dirtyStaff && (
                            <span className="mx-2 price-comment">
                              {t(
                                'shopPublic.overview.createBooking.includeCharge'
                              )}
                            </span>
                          )}

                          <span className="mx-2 product-price__old">
                            <CurrencyAmount amount={originalPrice} />
                          </span>
                        </>
                      ) : (
                        <p className="mb-0 product-price__new product-price__new--type2">
                          <CurrencyAmount amount={discountedPrice} />

                          {dirtyStaff && (
                            <span className="mx-2 price-comment">
                              {t(
                                'shopPublic.overview.createBooking.includeCharge'
                              )}
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  </FormField>
                </div>
              </div>

              {shop && (
                <BookingDateTimePicker
                  dateError={bookingDateError}
                  disabled={isSubmitting}
                  minToday
                  shop={shop}
                  timeError={bookingTimeError}
                  onChange={handleChangeDateTime}
                />
              )}

              {/* Remarks by service provider (shop) */}
              <FormField
                className="m-0"
                label={t('booking.providerRemarks.label')}
                hint={
                  <div
                    className={`text-muted ${
                      dirtyProviderRemarks.length >
                      BookingMaxlength.providerRemarks
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    {t('generalFields.freeText.maxlengthHelp', {
                      textLength: dirtyProviderRemarks.length,
                      maxlength: BookingMaxlength.providerRemarks,
                    })}
                  </div>
                }
              >
                <Textarea
                  maxLength={BookingMaxlength.providerRemarks}
                  name="message"
                  value={dirtyProviderRemarks}
                  onChange={(e) => handleChangeProviderRemarks(e.target.value)}
                />
              </FormField>

              <div className="row mb-4">
                <div className="col-lg-6">
                  {/* Customer's phone number */}
                  <TextInputField
                    className="m-0"
                    isBusy={isSearchingCustomer}
                    errorMessage={dirtyPhoneError}
                    label={t('shopPrivate.bookings.custPhone')}
                    maxLength={12}
                    name="phone"
                    required
                    type="tel"
                    value={dirtyPhone}
                    onChange={(e) => {
                      setPristine(false);
                      handleChangePhone(e.target.value);
                    }}
                  />
                </div>

                <div className="col-lg-6">
                  {/* Customer's name */}
                  <TextInputField
                    className="m-0"
                    disabled={isCustomerNameDisabled}
                    errorMessage={dirtyCustomerNameError}
                    isBusy={isSearchingCustomer}
                    label={t('shopPrivate.bookings.custName')}
                    name="name"
                    required
                    value={dirtyCustomerName}
                    onChange={(e) => {
                      setPristine(false);
                      setDirtyCustomerName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </fieldset>
          </form>
        )}
      </DialogContent>

      <DialogActions>
        <AppButton
          disabled={!isFormDataSufficient || isSubmitting}
          theme="primary"
          onClick={() => handleSubmit()}
        >
          {isSubmitting ? <Spinner /> : t('action.create')}
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

export default BookingCreationByProviderDialog;
