import {
  DEFINITION_SHOP_STAFF_SCALE_LIST_WILL_FETCH,
  DEFINITION_SHOP_STAFF_SCALE_LIST_DID_FETCH,
} from '../../constants/constants';

import api from '../../../api';

export const getShopStaffScaleList = () => (dispatch, getState) => {
  const prevState = getState().shopStaffScaleReducer;

  if (!prevState.isLoadingShopStaffScaleList && !prevState.shopStaffScaleList.length) {
    dispatch(WillFetchShopStaffScaleList());

    api.shopStaff.fetchShopStaffScaleList()
      .then(response => {
        dispatch(DidFetchShopStaffScaleList(response.data));
        return response;
      })
      .catch(err => {
        console.error(err);
      })
  }
};

const WillFetchShopStaffScaleList = () => ({
  type: DEFINITION_SHOP_STAFF_SCALE_LIST_WILL_FETCH,
});
const DidFetchShopStaffScaleList = data => ({
  type: DEFINITION_SHOP_STAFF_SCALE_LIST_DID_FETCH,
  payload: data,
});
