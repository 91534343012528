import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import Spinner from '../../../Common/Spinner/Spinner';

import { getAllServiceTypes } from '../../../../Redux/actions/features/serviceTypeAction';

import BookingPanel from '../Booking/BookingPanel';

import ServiceItem from './ServiceItem';


class PriceList extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.getPageName(this.props.match.path);
    this.props.getAllServiceTypes({ id: this.props.match.params.id || this.props.auth_user.id }).finally(
      this.setState({
        isLoading: false,
      })
    );
  }

  render() {
    const {
      serviceTypes,
      auth_isShop,
      auth_user,
      match,
      shop,
      t,
    } = this.props;
    const arr1 = serviceTypes instanceof Array && serviceTypes.filter((el, i) => i % 2 === 0);
    const arr2 = serviceTypes instanceof Array && serviceTypes.filter((el, i) => i % 2 !== 0);

    return (
      <div className={auth_isShop && match.params.id === auth_user.id ? '' : 'container'}>
        <BookingPanel shop={shop} buttonTheme={2} />

        <div className="mt-4 bg-light p-0 p-lg-4">
          {this.state.isLoading ? (
            <Spinner display="block" />
          ) : serviceTypes.length === 0 ? (
            t('notifications.noServices')
          ) : (
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12">{arr1 && arr1.map(type => <ServiceItem key={type.id} type={type} />)}</div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12">{arr2 && arr2.map(type => <ServiceItem key={type.id} type={type} />)}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const shopId = props.match.params.id || state.auth.user.id;

  // adopt data from redux state only when the data is from the same shop
  const shop =
    state.ShopReducer.shopData && state.ShopReducer.shopData.id == shopId
      ? state.ShopReducer.shopData
      : {};

  return {
    auth_isShop: state.auth.isShop,
    auth_user: state.auth.user,
    shop,
    serviceTypes: state.serviceTypesReducer.serviceTypes,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      { getAllServiceTypes }
    )(PriceList)
  )
);
