import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Staff, StaffData } from 'src/models/entity';

import StaffAvatar from './StaffAvatar';

import styles from './StaffBadge.module.css';

const nameFromStaffData = (staffData: StaffData) =>
  (
    staffData?.nickname ||
    `${staffData?.first_name || ''} ${staffData?.last_name || ''}`
  ).trim();

interface Props {
  avatarSize?: 'normal' | 'small';
  className?: string;
  horizontal?: boolean;
  staff?: Staff;
  staffData?: StaffData;
}

const StaffBadge = ({
  avatarSize = 'normal',
  className = '',
  horizontal = false,
  staff,
  staffData,
}: Props) => {
  const staffProfileUrl = useMemo(() => {
    const staffId = staff?.id || staffData?.user_id || '';
    return staffId ? `/staff/${staffId}` : '';
  }, [staff, staffData]);

  const avatarUrl = useMemo(() => {
    return staff?.avatar || '';
  }, [staff]);

  const formattedName = useMemo(() => {
    if (staff?.user_type_data) {
      const displayName = nameFromStaffData(staff.user_type_data);
      if (displayName) return displayName;
    }

    if (staffData) {
      return nameFromStaffData(staffData);
    }

    return '';
  }, [staff, staffData]);

  return (
    <div
      className={`${styles.root} ${
        horizontal ? styles.horizontal : ''
      } ${className}`}
    >
      <div
        className={`${styles.avatar} ${
          avatarSize === 'small' ? styles.smallAvatar : ''
        }`}
      >
        {staffProfileUrl ? (
          <Link to={staffProfileUrl}>
            <StaffAvatar src={avatarUrl} />
          </Link>
        ) : (
          <StaffAvatar src={avatarUrl} />
        )}
      </div>
      <div className={styles.name}>
        {staffProfileUrl ? (
          <Link to={staffProfileUrl}>{formattedName}</Link>
        ) : (
          formattedName
        )}
      </div>
    </div>
  );
};

export default StaffBadge;
