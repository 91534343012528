
export const CUSTOMER_TYPE_ID = 1;

export const STAFF_TYPE_ID = 2;

export const SHOP_TYPE_ID = 3;

/**
 *
 * @param {Number} userTypeId
 * @returns {boolean}
 */
export const isCustomer = (userTypeId) => userTypeId === CUSTOMER_TYPE_ID;

/**
 *
 * @param {Number} userTypeId
 * @returns {boolean}
 */
export const isStaff = (userTypeId) => userTypeId === STAFF_TYPE_ID;

/**
 *
 * @param {Number} userTypeId
 * @returns {boolean}
 */
export const isShop = (userTypeId) => userTypeId === SHOP_TYPE_ID;

/**
 *
 * @param {Number} userTypeId
 * @returns {boolean}
 */
export const isAdmin = (userTypeId) => userTypeId === 4;
