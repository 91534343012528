import React, { Component, Fragment } from 'react';
import { Redirect, Route, withRouter, Switch, NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';

import axios from 'axios';


import HeaderSettings from './Settings/HeaderSettings';
import ShopBookings from './Bookings/ShopBookings';
import ServiceAndPriceLists from './serviceAndPriceLists/ServiceAndPriceLists';
import PhotoGallery from './photoGallery/PhotoGallery';
import { ShopAvatar, ShopName } from '../../Common/Shop';
import StaffManagement from './Staff/StaffManagement';
// import MessageListBox from './Messages/MessageListBox';
import Calendar from './Calendar/Calendar';
import Checkout from './Checkout/Checkout';
import { getAllChats } from '../../../Redux/actions/features/chats';
// import ManageProducts from './Products/ManageProducts';

import { changeAvatar } from '../../../Redux/actions/features/accountActions';
import { shopData, changeGeneralShopData } from '../../../Redux/actions/shopActions/shopData';
import { setUserData, logout } from '../../../Redux/actions/authActions/signInActions';
import { getAllServices } from '../../../Redux/actions/shopActions/serviceAction';
import { contacts } from '../../../Redux/actions/features/contacts';
import ManageRights from './ManageRights/ManageRights.component';
// import PromotionStatistics from './PromotionStatistics/PromotionStatistics';
import { showAllAlbums } from '../../../Redux/actions/shopActions/galleryShopActions';
import { getRecommended } from '../../../Redux/actions/shopActions/serviceAction';
import { getCityList } from '../../../Redux/actions/definitionActions/cityActions';

// import ProductsEdit from './Products/ProductsEdit/ProductsEdit';
import EditService from './serviceAndPriceLists/Edit/EditService';
// import CheckoutRequest from './Checkout/CheckoutRequest';
import preloader from '../../../dist/Preloader.gif';
import Notification from '../Notification';
import ReviewModeration from './reviewModeration/ReviewModeration';

import NewsIndex from './News/NewsIndex';
import NewsCreationForm from './News/Form/NewsCreationForm';
import NewsUpdateForm from './News/Form/NewsUpdateForm';

import notification from '../../Common/notification';

import FastCheckoutLink from 'src/Components/Common/Shop/FastCheckoutLink';

import styles from './ShopSettingPageSideNav.module.css';


function ShopNavItem({ children, to, icon, text, onClick }) {
  return (
    <li className={styles.navItem}>
      {children}
      <NavLink
        to={to}
        onClick={onClick}
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        // strict={false}
      >
        {icon && (
          <span className={styles.navIconContainer}>
            <i className={icon} aria-hidden="true" />
          </span>
        )}
        <span className={styles.navLinkText}>{text}</span>
      </NavLink>
    </li>
  );
}


class ShopSettingPageSideNav extends Component {
  state = {
    baseUrl: '/shop-settings/',
    avatar: [],
    name: '',
    website: null,
    email: '',
    phone: '',
    address: '',
    about: '',
    chineseName: '',
    enableBookingSystem: 0,
    loading: true,
    lat: '',
    lng: '',
    notificationType: '',
    status: '',
    modalStatus: false,
    resize: false,
    avatarUploadStatus: false,
    message: '',
    prevWebsite: '',
    prevAddress: '',
    prevEmail: '',
    prevPhone: '',
    countStaffInShop_id: '',
    businessRegistrationNumber: '',
    code: '',
    districts: [],
    selectedDistrict: '',
    selectedCity: '',
    isUploadModal: false,
    count: 0,
  };

  setUploadStatus = () => {
    this.setState({
      avatarUploadStatus: !this.state.avatarUploadStatus,
    });
  };

  logout = () => {
    this.props.logout();
    this.props.history.push('/');
  };

  getCount = () => {
    axios
    .get('/api/shop-new-bookings-count/'+this.props.auth.user.id)
    .then( async res => {
        this.setState({
              count: res.data.message,
        })})
    .catch(error => {
      console.error(error);
    });
  }

  handleChangeAddress = address => {
    this.setState({ address });
  };

  setModalStatusClose = () => {
    this.setState({
      modalStatus: false,
      email: this.state.prevEmail,
      phone: this.state.prevPhone,
    });
  };

  handleSelectAddress = address => {
    geocodeByAddress(address).then(results => {
      getLatLng(results[0]).then(latLng =>
        this.setState({
          lat: latLng.lat,
          lng: latLng.lng,
          address: address,
        })
      );
    });
  };

  sendContactsData = () => {
    const {
      website,
      address,
      lat,
      lng,
      prevWebsite,
      prevAddress,
      phone,
      email,
      prevEmail,
      prevPhone,
      selectedCity,
      prevSelectedCity,
      selectedDistrict,
      prevSelectedDistrict,
      adminName,
      prevAdminName,
      adminPhone,
      prevAdminPhone,
    } = this.state;
    this.props
      .contacts({
        website: website ? website : website === '' ? ' ' : null,
        address: { string_address: address, coordinates: [lat, lng] },
        city_id: selectedCity,
        district_id: selectedDistrict,
        adminName: adminName,
        adminPhone: adminPhone,
      })
      .then(res => {
        if (res.res.data.status === 'success') {
          this.props.shopData({ id: this.props.auth.user.id });
          if (
            prevWebsite !== website ||
            prevAddress !== address ||
            prevSelectedCity !== selectedCity ||
            prevSelectedDistrict !== selectedDistrict ||
            prevAdminName !== adminName ||
            prevAdminPhone !== adminPhone
          ) {
            notification(this.props.t('notifications.changesSaved'));
          }
          this.setState({
            prevWebsite: website,
            prevEmail: email,
            prevPhone: phone,
            prevAddress: address,
            prevSelectedCity: selectedCity,
            prevSelectedDistrict: selectedDistrict,
            prevAdminName: adminName,
            prevAdminPhone: adminPhone,
          });
        } else if (res.res.data.message === 'incorrect data') {
          const mistakes = res.res.data.data;
          for (var key in mistakes) {
            notification(`${key}: ${mistakes[key]}`);
          }
        }
      })
      .catch(() => notification(this.props.t('notifications.somethingWentWrong')));
    if (this.state.phoneStatus && phone !== prevPhone) {
      axios
        .post(`/api/check-phone-and-email`, { phone: this.state.phone })
        .then(res => {
          if (res.data.message === 'success') {
            this.setState({
              errorPhone: '',
              errorEmail: '',
              modalStatus: true,
              // prevPhone: this.state.phone,
            });
            setTimeout(() => {
              this.setState({
                status: '',
              });
            }, 3000);
          } else if (res.data.data.phone !== null) {
            this.setState({
              status: 'error',
              message: res.data.data.phone[0],
            });
            setTimeout(() => {
              this.setState({
                status: '',
              });
            }, 3000);
          }
        })
        .catch(() => {
          this.setState({
            errorPhone: this.props.t('notifications.valueInvalid'),
          });
        });
    }
    if (this.state.emailStatus && email !== prevEmail) {
      axios
        .post(`/api/check-phone-and-email`, { email: email })
        .then(res => {
          if (res.data.message === 'success') {
            this.setState({
              errorEmail: '',
              modalStatus: true,
              message: '',
              // prevEmail: this.state.email,
            });
            setTimeout(() => {
              this.setState({
                status: '',
                message: '',
              });
            }, 3000);
          } else if (res.data.data.phone !== null) {
            this.setState({
              status: 'error',
              message: this.props.t('notifications.emailAlreadyBeen'),
            });
            setTimeout(() => {
              this.setState({
                status: '',
              });
            }, 3000);
          }
        })
        .catch(() => {
          this.setState({
            errorEmail: this.props.t('notifications.valueInvalid'),
          });
        });
    }
  };
//componentDidUpdate() {
//	  this.getCount();
//  }
  componentDidMount() {
    axios.get('/api/get-lang').then(res => {
      if (res.data.status === 'success') {
        this.props.i18n.changeLanguage(res.data.data === 'en' ? 'en' : 'zh');
      }
    });

    this.props.getCityList();

    this.props.shopData({ id: this.props.auth.user.id }).then(res => {
      if (res.res.data.message === 'success') {
        if (this.props.ShopReducer.shopData.user_type_data) {
          const shopData = this.props.ShopReducer.shopData.user_type_data;

          this.setState({
            enableBookingSystem: shopData.enableBookingSystem,
            prevEnableBookingSystem: shopData.enableBookingSystem,
            name: shopData.name,
            prevName: shopData.name,
            about: shopData.about,
            prevAbout: shopData.about,
            chineseName: shopData.chineseName,
            prevChineseName: shopData.chineseName,
            website: shopData.website || '',
            address: shopData.address.string_address,
            email: shopData.email,
            phone: shopData.phone,
            countStaffInShop_id: shopData.countStaffInShop_id,
            prevCountStaffInShop_id: shopData.countStaffInShop_id,
            businessRegistrationNumber: shopData.businessRegistrationNumber,
            prevBusinessRegistrationNumber: shopData.businessRegistrationNumber,
            prevWebsite: shopData.website,
            prevAddress: shopData.address.string_address,
            prevEmail: shopData.email,
            prevPhone: shopData.phone,
            selectedCity: shopData.city ? shopData.city.id : '',
            prevSelectedCity: shopData.city ? shopData.city.id : '',
            selectedDistrict: shopData.district ? shopData.district.id : '',
            prevSelectedDistrict: shopData.district ? shopData.district.id : '',
            adminName: shopData.adminName,
            prevAdminName: shopData.adminName,
            adminPhone: shopData.adminPhone,
            prevAdminPhone: shopData.adminPhone,

            loading: false,
          });

          this.populateDistrictListByCityId(shopData.city ? shopData.city.id : '');
        }
      }
    });
    // this.props.getAllChats();
    // this.props.getRecommended({ id: this.props.auth.user.id });
    /*this.props.showAllAlbums({ id: this.props.auth.user.id }).then(res => {
      if (res.res.data.message === 'success') {
        this.setState({
          loading: false,
        });
      }
    });*/
    // this.props.getAllProducts({ id: this.props.auth.user.id });
    // this.props.getAllServices({ id: this.props.auth.user.id }).then(res => {
    //   if (res.res.data.message === 'success') {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // });

  // window.setInterval(function () {
     //       this.getCount();
      //   }.bind(this), 5000);
  }

  sendData = () => {
    const {
      enableBookingSystem,
      about,
      name,
      chineseName,
      countStaffInShop_id,
      businessRegistrationNumber,
      prevName,
      prevAbout,
      prevChineseName,
      prevCountStaffInShop_id,
      prevBusinessRegistrationNumber,
      prevEnableBookingSystem,
    } = this.state;
    this.props.changeGeneralShopData({
        enableBookingSystem:
          enableBookingSystem !== prevEnableBookingSystem ? enableBookingSystem : prevEnableBookingSystem,
        about: about !== prevAbout ? about : prevAbout,
        name: name !== prevName ? name : null,
        chineseName: chineseName !== prevChineseName ? chineseName : null,
        countStaffInShop_id:
          countStaffInShop_id !== prevCountStaffInShop_id ? countStaffInShop_id : prevCountStaffInShop_id,
        businessRegistrationNumber:
          businessRegistrationNumber !== prevBusinessRegistrationNumber ? businessRegistrationNumber : null,
      })
      .then(response => {
        this.props.shopData({
            id: this.props.auth.user.id,
          })
          .then(res => {
            if (res.res.data.message === 'success') {
              this.setState({
                enableBookingSystem: this.props.ShopReducer.shopData.user_type_data.enableBookingSystem,
                message: this.props.t('notifications.changesSaved'),
                status: 'success',
                previousAbout: about,
                prevEnableBookingSystem: enableBookingSystem,
                prevCountStaffInShop_id: countStaffInShop_id,
                prevBusinessRegistrationNumber: businessRegistrationNumber,
                prevChineseName: chineseName,
                prevName: name,
              });

              setTimeout(() => {
                this.setState({
                  status: '',
                });
              }, 3000);
            } else if (res.res.data.message === 'incorrect data') {
              notification(this.props.t('notifications.somethingWasWrong'));
            } else {
              this.setState({
                status: 'error',
                message: this.props.t('notifications.checkData'),
              });
              setTimeout(() => {
                this.setState({
                  status: '',
                });
              }, 3000);
            }
          });
      });

    ReactDOM.findDOMNode(this).scrollTop = 0;
	  this.getCount();
  };

  handleChange = ({ target }) => {
    let regExp = /^\d+$/;

    if (target.name === 'phone' && (target.value === '+852' || regExp.test(target.value.substring(4)))) {
      this.setState({
        phoneStatus: true,
        phone: target.value,
      });
    } else if (target.name === 'adminPhone' && (target.value === '+852' || regExp.test(target.value.substring(4)))) {
      this.setState({
        [target.name]: target.value,
      });
    } else if (target.name === 'email') {
      this.setState({
        emailStatus: true,
      });
    } else if (target.name === 'website') {
      this.setState({
        website: target.value,
      });
    } else if (target.name === 'about') {
      this.setState({
        about: target.value,
      });
    } else if (target.name === 'name') {
      regExp = /^[A-Za-z-!$%^&*()_+|~=`{}[\]:";'<>?,./\d\s]+$/;
      if (target.value === '' || regExp.test(target.value)) {
        this.setState({
          [target.name]: target.value,
        });
      }
    } else if (target.name === 'chineseName' || target.name === 'adminName') {
      // Chinese
      regExp = /^[A-Za-z-!$%^&*()_+|~=`{}[\]:";'<>?,./\d[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]\s]+$/;
      if (target.value === '' || regExp.test(target.value)) {
        this.setState({
          [target.name]: target.value,
        });
      }
    } else if (target.name === 'email') {
      regExp = /^[a-zA-Z0-9@._()-/#]+$/;
      if (target.value === '' || regExp.test(target.value)) {
        this.setState({
          email: target.value,
        });
      }
    } else if (target.name === 'businessRegistrationNumber') {
      regExp = /^\d+$/;
      if (target.value === '' || regExp.test(target.value)) {
        this.setState({
          [target.name]: target.value,
        });
      }
    } else if (target.name === 'countStaffInShop_id') {
      this.setState({
        [target.name]: target.value,
      });
    }
    else if (target.name) {
      this.setState({
        [target.name]: target.value,
      });
    }
  };

  populateDistrictListByCityId = (cityId) => {
    if (cityId) {
      const selectedCity = this.props.cityList.find(city => city.id === +cityId);
      if (selectedCity) {
        this.setState((prevState) => {
          return {
            selectedCity: selectedCity.id,
            districts: selectedCity.districts || [],
            // selectedDistrict,
          };
        });
        return;
      }
    }

    this.setState({
      selectedCity: '',
      districts: [],
      selectedDistrict: '',
    });
  }

  handleClickOnCity = ({ target }) => {
    this.populateDistrictListByCityId(target.value);
  };

  setCitiesDistricts = () => {
    this.populateDistrictListByCityId(this.state.selectedCity);
  };

  handleClickOnDistrict = ({ target }) => {
    this.setState({
      selectedDistrict: +target.value,
    });
  };

  getImage = file => {
    this.setState({
      avatar: file,
      errorPhoto: false,
      avatarUploadStatus: false,
    });

    this.props.changeAvatar({ avatar: file })
      .then(() => {
        // TODO Better visual clue on avatar upload
        this.setState({
          status: 'success',
          message: this.props.t('notifications.avatarWillLoad'),
        });

        setTimeout(() => {
          this.setState({
            status: '',
          });
        }, 3000);
      });
  };

  toggleUploadModal = () => {
    this.setState(prevState => ({
      isUploadModal: !prevState.isUploadModal,
      avatarUploadStatus: false,
    }));
  };

  handleClick = () => {
    this.setState({
      enableBookingSystem: !this.state.enableBookingSystem,
    });
  };

  toggleMobileLeftMenu = () => {
    this.setState({
      resize: !this.state.resize,
    });
  };

  setStatusClosed = () => {
    this.setState({
      status: '',
    });
  };
  sendCode = () => {
    axios
      .post(`/api/change-phone-and-email`, {
        code: this.state.code,
        user_id: this.props.auth.user.id,
        phone: this.state.phone,
      })
      .then(res => {
        if (res.data.message === 'success') {
          this.setState({
            status: 'success',
            message: this.props.t('notifications.changesSaved'),
            modalStatus: false,
          });
          setTimeout(() => {
            this.setState({
              status: '',
            });
          }, 3000);
        } else {
          this.setState({
            status: 'error',
            message: this.props.t('notifications.codeIncorrect'),
          });
          setTimeout(() => {
            this.setState({
              status: '',
            });
          }, 3000);
        }
      });
  };

  render() {
    const {
      ShopReducer,
      t,
    } = this.props;
    const {
      baseUrl,
    } = this.state;

    // const baseUrl = '/shop-settings/';

    return (
      <Fragment>
        {this.state.loading ? (
          <img src={preloader} className="preloader" alt={''} />
        ) : (
          <Fragment>
            <div className={styles.sidePanel}>
              {this.state.status === 'success' && (
                <Notification text={this.state.message} onClose={this.setStatusClosed} />
              )}
              {this.state.status === 'error' && (
                <Notification text={t('notifications.somethingWasWrong')} onClose={this.setStatusClosed} />
              )}

              <div className={styles.siteLogoWrap}>
                <button className={styles.shopNavHandle} onClick={this.toggleMobileLeftMenu}>
                  <i className="fas fa-bars" />
                </button>
                <NavLink className={styles.siteLogo} to="/" />
              </div>

              <div className={`${styles.shopNavContainer} ${this.state.resize ? styles.shopNavContainerOpened : ''}`}>
                <div className={`d-flex flex-column align-items-center ${styles.shopBadge}`}>
                  <div className={styles.shopAvatarContainer}>
                    <NavLink to={`/shop/${this.props.auth.user.id}`}>
                      {this.state.avatar.length > 0 ? (
                        <ShopAvatar src={this.state.avatar[0]} />
                      ) : (
                        <ShopAvatar src={this.props.ShopReducer.shopData.avatar} />
                      )}
                    </NavLink>
                  </div>

                  {!this.state.loading &&
                    this.props.ShopReducer.shopData &&
                    this.props.ShopReducer.shopData.user_type_data && (
                      <h2 className={styles.shopName}>
                        <ShopName shop={this.props.ShopReducer.shopData} />
                      </h2>
                  )}

                  <div className="d-flex flex-row align-items-center">
                    <FastCheckoutLink shop={ShopReducer.shopData} withCopyButton />
                  </div>
                </div>

                <nav className={styles.shopNav}>
                  <ul className="list-unstyled">
                    <ShopNavItem
                      to={baseUrl + 'bookings'}
                      icon="fas fa-calendar-check"
                      text={t('shopPrivate.shopLeftMenu.bookings')}
                      onClick={this.toggleMobileLeftMenu}
                    >
                      <NotificationBadge count={this.state.count} effect={Effect.SCALE} />
                    </ShopNavItem>

                    <ShopNavItem
                      to={baseUrl + 'moderation'}
                      icon="fas fa-star"
                      text={t('shopPrivate.shopLeftMenu.reviewMod')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'calendar'}
                      icon="fas fa-calendar-alt"
                      text={t('shopPrivate.shopLeftMenu.calendar')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'news'}
                      icon="fas fa-newspaper"
                      text={t('shopPrivate.shopLeftMenu.news')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'gallery'}
                      icon="fas fa-image"
                      text={t('shopPrivate.shopLeftMenu.photoGallery')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'service-price'}
                      icon="fas fa-wrench"
                      text={t('shopPrivate.shopLeftMenu.serviceList')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'general'}
                      icon="fas fa-cog"
                      text={t('shopPrivate.shopLeftMenu.settings')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'staff'}
                      icon="fas fa-users"
                      text={t('shopPrivate.shopLeftMenu.staff')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'manage-rights'}
                      icon="fas fa-flag"
                      text={t('shopPrivate.shopLeftMenu.manageRights')}
                      onClick={this.toggleMobileLeftMenu}
                    />

{/* Hidden since 2020/12/18.
                    <ShopNavItem
                      to={baseUrl + 'messages'}
                      icon="fas fa-comment-dots"
                      text={t('shopPrivate.shopLeftMenu.messages')}
                      onClick={this.toggleMobileLeftMenu}
                    />
*/}

{/*
                    <ShopNavItem
                      to={baseUrl + 'statistics'}
                      icon="fas fa-chart-bar"
                      text={t('shopPrivate.shopLeftMenu.promStat')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'products'}
                      icon="fas fa-th-large"
                      text={t('shopPrivate.shopLeftMenu.manageProds')}
                      onClick={this.toggleMobileLeftMenu}
                    />

                    <ShopNavItem
                      to={baseUrl + 'checkout-requested'}
                      icon="fas fa-money-bill-alt"
                      text="Checkout requests"
                      onClick={this.toggleMobileLeftMenu}
                    />
*/}
                    <li className={`mt-3 ${styles.navItem}`}>
                      <a
                        className={`text-muted ${styles.navLink}`}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.logout();
                        }}
                      >
                        <span className={styles.navIconContainer}>
                          <i className="fas fa-sign-out-alt" aria-hidden="true" />
                        </span>
                        <span className={styles.navLinkText}>{t('account.action.signOut')}</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <Switch>
              <Route
                path={baseUrl + 'general'}
                render={() => (
                  <HeaderSettings
                    chineseName={this.state.chineseName}
                    name={this.state.name}
                    onClose={this.setModalStatusClose}
                    errorEmail={this.state.errorEmail}
                    resize={this.state.resize}
                    status={this.state.status}
                    message={this.state.message}
                    setAvatarStatus={this.setUploadStatus}
                    errorPhone={this.state.errorPhone}
                    handleClick={this.handleClick}
                    code={this.state.code}
                    sendCode={this.sendCode}
                    enableBookingSystem={this.state.enableBookingSystem}
                    avatar={this.state.avatar}
                    website={this.state.website}
                    email={this.state.email}
                    address={this.state.address}
                    modalStatus={this.state.modalStatus}
                    sendContactsData={this.sendContactsData}
                    phone={this.state.phone}
                    sendData={this.sendData}
                    handleChangeAddress={this.handleChangeAddress}
                    handleSelectAddress={this.handleSelectAddress}
                    loading={this.state.loading}
                    sendContactsDataPhone={this.sendContactsDataPhone}
                    auth={this.props.auth}
                    about={this.state.about}
                    avatarUploadStatus={this.state.avatarUploadStatus}
                    ShopReducer={this.props.ShopReducer}
                    handleChange={this.handleChange}
                    upload={this.upload}
                    errorPhoto={this.state.errorPhoto}
                    prevWebsite={this.state.prevWebsite}
                    prevAddress={this.state.prevAddress}
                    prevEmail={this.state.prevEmail}
                    prevPhone={this.state.prevPhone}
                    businessRegistrationNumber={this.state.businessRegistrationNumber}
                    countStaffInShop_id={this.state.countStaffInShop_id}
                    prevName={this.state.prevName}
                    prevAbout={this.state.prevAbout}
                    prevChineseName={this.state.prevChineseName}
                    prevCountStaffInShop_id={this.state.prevCountStaffInShop_id}
                    prevBusinessRegistrationNumber={this.state.prevBusinessRegistrationNumber}
                    prevEnableBookingSystem={this.state.prevEnableBookingSystem}
                    cities={this.props.cityList}
                    districts={this.state.districts}
                    handleClickOnCity={this.handleClickOnCity}
                    handleClickOnDistrict={this.handleClickOnDistrict}
                    selectedCity={this.state.selectedCity}
                    selectedDistrict={this.state.selectedDistrict}
                    setCitiesDistricts={this.setCitiesDistricts}
                    adminName={this.state.adminName}
                    adminPhone={this.state.adminPhone}
                    prevAdminName={this.state.prevAdminName}
                    prevAdminPhone={this.state.prevAdminPhone}
                    prevSelectedCity={this.state.prevSelectedCity}
                    prevSelectedDistrict={this.state.prevSelectedDistrict}
                    isUploadModal={this.state.isUploadModal}
                    getImage={this.getImage}
                    toggleUploadModal={this.toggleUploadModal}
                  />
                )}
              />

              <Route path={baseUrl + 'bookings'} component={ShopBookings} />

              <Route exact path={baseUrl + 'news'} component={NewsIndex} />
              <Route exact path={baseUrl + 'news/create'} component={NewsCreationForm} />
              <Route path={baseUrl + 'news/:newsId'} component={NewsUpdateForm} />

              <Route path={baseUrl + 'service-price'} component={ServiceAndPriceLists} />
              <Route path={baseUrl + 'service/:serviceId'} component={EditService} />

              <Route path={baseUrl + 'staff'} component={StaffManagement} />

{/* Hidden since 2020/12/18.
              <Route path={baseUrl + 'messages'} component={MessageListBox} />
*/}
              <Route path={baseUrl + 'gallery'} component={PhotoGallery} />
              <Route path={baseUrl + 'calendar'} component={Calendar} />

              <Route
                path={baseUrl + 'checkout/:bookingId'}
                component={Checkout}
              />

              <Route
                path={baseUrl + 'manage-rights'}
                component={ManageRights}
              />

              {/*
              <Route path={baseUrl + 'statistics'} component={PromotionStatistics} />

              <Route exact path={baseUrl + 'products'} component={ManageProducts} />
              <Route exact path={baseUrl + 'products/:productId'} component={ProductsEdit} />

              <Route path={baseUrl + 'checkout-requested'} component={CheckoutRequest} />
              */}

              <Route path={baseUrl + 'moderation'} component={ReviewModeration} />

              {/* Default / Fallback route */}
              <Route
                path={baseUrl}
                render={() => <Redirect to={baseUrl + 'general'} />}
              />
            </Switch>
            <div />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    ShopReducer: state.ShopReducer,
    auth: state.auth,
    cityList: state.cityReducer.cityList,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        logout,
        changeGeneralShopData,
        changeAvatar,
        shopData,
        contacts,
        getAllChats,
        getAllServices,
        showAllAlbums,
        getRecommended,
        setUserData,
        getCityList,
      }
    )(ShopSettingPageSideNav)
  )
);
