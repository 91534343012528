/**
 * @param {string} dirtyImageUrl
 * @returns {string|null}
 */
const normalizeImageUrl = (dirtyImageUrl) => {
    if (dirtyImageUrl) {
        if (/^https:.*\.(jpg|jpeg|png)/i.test(dirtyImageUrl)) { // url of a remote image
            return dirtyImageUrl;
        }
        else if (/^data:image/.test(dirtyImageUrl)) { // a not-yet-uploaded image
            return dirtyImageUrl;
        }
    }

    return null;
}

/**
 *
 * @param {string[]} imageUrls
 * @param {string[]} fullImageUrls
 * @returns {Photo[]}
 */
export const normalizePhotosFromUrls = (imageUrls, fullImageUrls) => {
    return (imageUrls || []).map((photoUrls, idx) => {
        // normalize to `Photo` struct, having same schema to the `Photo` from remote api.
        return {
            id: 0,
            url: normalizeImageUrl(photoUrls),
            url_full: normalizeImageUrl(fullImageUrls ? fullImageUrls[idx]: ''),
        };
    });
}

/**
 *
 * @param {Photo[]} photos
 * @returns {Photo[]}
 */
export const normalizePhotos = (photos) => {
    return (photos || []).map((photo) => {
        // normalize to `Photo` struct, having same schema to the `Photo` from remote api.
        return {
            ...photo,
            url: normalizeImageUrl(photo.url),
            url_full: normalizeImageUrl(photo.url_full),
        };
    });
}
