import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { AppButton } from '../../../Common/Button';
import OverallRatingRow from '../../../Common/RatingStars/OverallRatingRow';
import { LineBreakable } from '../../../Common/Text';


const ModerationItem = ({
  blockNum,
  review,
  shopId,
  isEditingReply,
  onEnableEditReply,
  onSubmitReply,
  onReplyChange,
}) => {
  const { t } = useTranslation();

  const [formattedDate, setFormattedDate] = useState('');
  const [formattedTime, setFormattedTime] = useState('');

  useEffect(() => {
    if (review && review.created_at) {
      const createdAt = moment(review.created_at);
      setFormattedDate(createdAt.format('DD-MM-YYYY'));
      setFormattedTime(createdAt.format('hh:mm A'));
    } else {
      setFormattedDate('');
      setFormattedTime('');
    }
  }, [review]);


  return (
    <tr data-label-tr={`${t('block')} #${blockNum}`}>
      <td data-label={t('shopPrivate.reviewModer.date')}>
        <span>{formattedDate}</span>
      </td>
      <td data-label={t('datetime.time.label')}>
        <span>{formattedTime}</span>
      </td>
      <td
        data-label={t('shopPrivate.reviewModer.customer')}
      >
        {review.customer_full_name}
      </td>
      <td data-label={t('shopPrivate.reviewModer.rate')}>
        {review.review_average_rating && (
          <OverallRatingRow
            hideLabel
            rating={review.review_average_rating.overall_rating}
          />
        )}
      </td>
      <td data-label={t('shopPrivate.reviewModer.customerReview')}>
        <Link to={`/shop/${shopId}/review/${review.id}`} target="_blank">
          {review.title} <i className="fas fa-external-link-alt" />
        </Link>
        <LineBreakable>{review.review}</LineBreakable>
      </td>
      <td className="reply-area" data-label={t('shopPrivate.reviewModer.yourReply')}>
        {isEditingReply ? (
          <textarea
            name="reply"
            onChange={onReplyChange(review.id)}
            className="form-item form-item--area"
            style={{ width: 120 }}
            value={review.reply || ''}
          />
        ) : (
          <LineBreakable>{review.reply}</LineBreakable>
        )}
      </td>
      <td data-label={t('shopPrivate.reviewModer.action')}>
        {!isEditingReply ? (
          <AppButton size="small" theme="secondary" onClick={() => onEnableEditReply(review.id)}>
            <i className="fas fa-pen mx-1" />
            {t('shopPrivate.reviewModer.editReply')}
          </AppButton>
        ) : (
          <AppButton size="small" theme="primary" onClick={() => onSubmitReply(review.id, review.reply)}>
            <i className="fas fa-save mr-1" />
            {t('action.save')}
          </AppButton>
        )}
      </td>
    </tr>
  );
};

export default ModerationItem;
