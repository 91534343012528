import {
  GET_BOOKINGS_FOR_CALENDAR,
  GET_STAFF_BOOKINGS_FOR_CALENDAR,
} from '../../constants/constants';

const initialState = {
  bookingForCalendar: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS_FOR_CALENDAR:
      return {
        ...state,
        bookingForCalendar: action.payload,
      };
    case GET_STAFF_BOOKINGS_FOR_CALENDAR:
      return {
        ...state,
        bookingForCalendar: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
