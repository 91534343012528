import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';


import {
  BookingMaxlength,
  Customer,
  Service,
  ServiceMadeByStaff,
  ServiceType,
  Shop,
  Staff,
} from 'src/models/entity';
import { BaseResponse } from 'src/models/api/response/BaseResponse';

import { currentUserAPI, shopBookingAPI } from 'src/utils/api';

import { getAllServiceTypes } from '../../../../Redux/actions/features/serviceTypeAction';

import useErrorHandler from 'src/hooks/useErrorHandler';

import notification from 'src/Components/Common/notification';

import { AppButton } from 'src/Components/Common/Button';
import { FormField, Textarea, TextInputField } from 'src/Components/Common/Form';
import Spinner from 'src/Components/Common/Spinner/Spinner';
import { CurrencyAmount } from 'src/Components/Common/Text';

import BookingDateTimePicker from 'src/Components/Common/Booking/BookingDateTimePicker';

import BookingCreatedDialog from './BookingCreatedDialog';
import { ShopBadge } from 'src/Components/Common/Shop';


interface Props {
  // from parent:
  shop: Shop;
  onClose: () => void;

  // from mapStateToProps:
  user: Customer;
  serviceTypeOptions: ServiceType[];

  // redux actions:
  getAllServiceTypes: (data: any) => Promise<any>;
}

/**
 * For current user (as a customer) to create a booking
 */
const BookingCreationDialog = ({
  // from parent:
  shop,
  onClose,

  // from mapStateToProps:
  user,
  serviceTypeOptions,

  // redux actions:
  getAllServiceTypes,
}: Props) => {

  const { t } = useTranslation();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  /**
   * This state helps prevent customer from accidentally / unintentionally dismissing the dialog,
   * either by clicking / tapping backdrop area or pressing ESC after user has filled / selected something.
   *
   * A `true` value => User has NOT ever interacted with the form.
   */
  const [isPristine, setPristine] = useState(true);

  const [isLoadingServiceTypes, setIsLoadingServiceTypes] = useState(true);


  const [serviceOptions, setServiceOptions] = useState<Service[]>([]);

  const [staffOptions, setStaffOptions] = useState<ServiceMadeByStaff[]>([]);


  // customer's preference
  const [dirtyServiceType, setDirtyServiceType] = useState<ServiceType>();

  // customer's preference
  const [dirtyService, setDirtyService] = useState<Service>();

  // customer's preference
  const [dirtyStaff, setDirtyStaff] = useState<Staff>();
  const [dirtyStaffError, setDirtyStaffError] = useState('');


  // customer's preference
  const [bookingAt, setBookingAt] = useState(moment());

  const [bookingDateError, setBookingDateError] = useState('');
  const [bookingTimeError, setBookingTimeError] = useState('');

  // customer's message to staff
  const [message, setMessage] = useState('');


  const [isFormDataSufficient, setIsFormDataSufficient] = useState(false);

  const [isCreatingBooking, setCreatingBooking] = useState(false);

  const [isCreatedDialogOpened, setCreatedDialogOpened] = useState(false);

  const servicePrice = useMemo(() => {
    return (
      Number(dirtyService?.price) ||
      Number(dirtyService?.price_fixed) ||
      0
    );
  }, [dirtyService]);

  const staffCharge = useMemo(() => {
    return Number(dirtyStaff?.pivot?.charge) || 0;
  }, [dirtyStaff]);


  const finalPrice = useMemo(() => {
    if (dirtyService?.promotions?.length) {
      return (Number(dirtyService.promotions[0].price) || 0) + staffCharge;
    }

    return servicePrice + staffCharge;
  }, [dirtyService, staffCharge]);

  // Initialization
  useEffect(() => {
    getAllServiceTypes({ id: shop.id })
      .then((res) => {
        const serviceTypes = res.res.data.data;
        if (serviceTypes instanceof Array && serviceTypes.length === 0) {
          notification(t('notifications.noServiceTypes'));
        }
      })
      .finally(() => {
        setIsLoadingServiceTypes(false);
      });
  }, []);

  useEffect(() => {
    setServiceOptions(
      dirtyServiceType?.services?.filter(
        (service) => service.available === 1
      ) || []
    );
    setDirtyService(undefined);
  }, [dirtyServiceType]);

  useEffect(() => {
    setStaffOptions(dirtyService?.madeBy || []);
  }, [dirtyService]);



  useEffect(() => {
    setIsFormDataSufficient(
      !!dirtyServiceType && !!dirtyService && !!bookingAt
    );
  }, [dirtyServiceType, dirtyService, bookingAt]);

  const handleDialogClose = () => {
    if (isPristine) {
      onClose();
    }
  };

  const handleChangeServiceType = (value: string) => {
    const selectedServiceType = serviceTypeOptions.find(
      (serviceType) => `${serviceType.id}` === value
    );

    setDirtyServiceType(selectedServiceType);

    setPristine(false);
  };

  const handleChangeService = (value: string) => {
    const selectedService = serviceOptions.find(
      (service) => `${service.id}` === value
    );

    setDirtyService(selectedService);

    setPristine(false);
  };

  const handleChangeStaff = (value: string) => {
    const selectedStaff = staffOptions.find(
      (staff) => `${staff.id}` === value
    );

    setDirtyStaff(selectedStaff);

    setPristine(false);
  };

  const handleChangeDateTime = (
    bookingAtDate: Date,
    isInitiatedByUser: boolean
  ) => {
    setBookingAt(moment(bookingAtDate));

    if (isInitiatedByUser) {
      setPristine(false);
    } else {
      // It is probably the initial value (default value) of the dat time picker
    }

    // clear any error messages
    setBookingDateError('');
    setBookingTimeError('');
  };

  const handleChangeMessage = (value: string) => {
    setMessage(value);

    setPristine(false);
  };

  const fireCheckTime = () => {
    // clear any error messages
    setBookingDateError('');
    setBookingTimeError('');

    const data: shopBookingAPI.CheckTimeRequest = {
      shopId: shop.id,
      date: bookingAt.format('Y-MM-DD'),
      time: bookingAt.format('HH:mm'),
    };

    return shopBookingAPI.checkTime(data).catch((e) => {
        if (e.response?.status === 422) {
          const validationErrors = e.response.data.errors;
          if (validationErrors.date) {
            setBookingDateError(validationErrors.date.join('\n'));
          }

          if (validationErrors.time) {
            setBookingTimeError(validationErrors.time.join('\n'));
          }
        }

        throw e;
      });
  };

  const handleSubmit = () => {
    setCreatingBooking(true);

    fireCheckTime()
      .then(() => {
        const promotionId = dirtyService?.promotions?.length
          ? dirtyService.promotions[0].id
          : undefined;

        return currentUserAPI
          .createMyBooking({
            shopId: shop.id,
            staffId: dirtyStaff ? dirtyStaff.id : '',
            serviceId: dirtyService ? dirtyService.id : '',
            date: bookingAt.format('Y-MM-DD'),
            time: bookingAt.format('HH:mm'),
            promotionId,
            message,
          })
          .catch((e) => {
            if (e.response?.status === 422 && e.response?.data) {
              const response = e.response.data as BaseResponse;
              const validationErrors = response.errors;
              if (validationErrors?.staffId) {
                setDirtyStaffError(validationErrors.staffId.join('\n'));
              }
            }

            throw e;
          });
      })
      .then((booking) => {
        setCreatedDialogOpened(true);
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e, { suppress: ['422'] });
        // }
      })
      .finally(() => {
        setCreatingBooking(false);
      });
  };

  const handleCloseCreatedDialog = () => {
    setCreatedDialogOpened(false);

    onClose();
  };

  return (
    <>
      <Dialog
        classes={{ paper: 'book-window' }}
        fullWidth={true}
        keepMounted={true}
        maxWidth="md"
        // open={open}
        open={true}
        onClose={handleDialogClose}
        aria-labelledby="create-booking-dialog-title"
      >
        <DialogTitle id="create-booking-dialog-title">
          <div className="d-flex align-items-center">
            <ShopBadge avatarOnly avatarSize="small" horizontal shop={shop} />
            <h2 className="m-0 mx-2">{t('booking.title')}</h2>

            <div className="flex-fill"></div>

            <button className="btn btn-link" onClick={onClose}>
              <i className="fas fa-times text-muted" />
            </button>
          </div>
        </DialogTitle>

        <DialogContent>
          {isLoadingServiceTypes ? (
            <Spinner display="block" />
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <fieldset disabled={isCreatingBooking}>
                {/* Able to disable child input fields when submitting form */}
                <div className="container-fluid">
                  <div className="row">
                    {/* Picker for service type */}
                    <div className="col-lg-6">
                      <FormField
                        className="m-0"
                        label={t(
                          'shopPublic.overview.createBooking.serviceType'
                        )}
                        required
                      >
                        <select
                          className="form-item"
                          onChange={(e) =>
                            handleChangeServiceType(e.target.value)
                          }
                        >
                          <option value="">
                            {t('action.selectOneFromEnumeration')}
                          </option>
                          {serviceTypeOptions.map((serviceType) => (
                            <option key={serviceType.id} value={serviceType.id}>
                              {serviceType.name}
                            </option>
                          ))}
                        </select>
                      </FormField>
                    </div>

                    {/* Picker for service */}
                    <div className="col-lg-6">
                      <FormField
                        className="m-0"
                        label={t('shopPublic.overview.createBooking.service')}
                        required
                      >
                        <select
                          className="form-item"
                          disabled={!dirtyServiceType}
                          onChange={(e) => handleChangeService(e.target.value)}
                        >
                          <option value="">
                            {t('action.selectOneFromEnumeration')}
                          </option>
                          {serviceOptions.map((service) => (
                            <option key={service.id} value={service.id}>
                              {service.name}
                            </option>
                          ))}
                        </select>
                      </FormField>
                    </div>
                  </div>

                  <div className="row">
                    {/* Picker for staff */}
                    <div className="col-lg-6">
                      <FormField
                        className="m-0"
                        label={t('shopPublic.overview.createBooking.staffName')}
                        required
                      >
                        <select
                          className="form-item"
                          disabled={!dirtyService}
                          value={dirtyStaff?.id}
                          onChange={(e) => handleChangeStaff(e.target.value)}
                        >
                          <option value="">
                            {t('shopPublic.overview.createBooking.noStaff')}
                          </option>
                          {staffOptions.map((staff) => (
                            <option key={staff.id} value={staff.id}>
                              {/* {staff.first_name} {staff.last_name} */}
                              {staff.user_type_data?.nickname}
                            </option>
                          ))}
                        </select>
                      </FormField>
                    </div>

                    <div className="col-lg-6">
                      <FormField
                        className="m-0"
                        label={t('shopPublic.overview.createBooking.price')}
                      >
                        <div className="product-price product-price--booking-type">
                          {dirtyService?.promotions?.length ? (
                            <>
                              <p className="product-price__new">
                                <CurrencyAmount amount={finalPrice} />
                              </p>

                              {dirtyStaff && (
                                <span className="mx-2 price-comment">
                                  {t(
                                    'shopPublic.overview.createBooking.includeCharge'
                                  )}
                                </span>
                              )}

                              <span className="mx-2 product-price__old">
                                <CurrencyAmount amount={servicePrice} />
                              </span>
                            </>
                          ) : (
                            <p className="mb-0 product-price__new product-price__new--type2">
                              <CurrencyAmount amount={finalPrice} />
                              {dirtyStaff && (
                                <span className="mx-2 price-comment">
                                  {t(
                                    'shopPublic.overview.createBooking.includeCharge'
                                  )}
                                </span>
                              )}
                            </p>
                          )}
                        </div>
                      </FormField>
                    </div>
                  </div>

                  <BookingDateTimePicker
                    dateError={bookingDateError}
                    minToday
                    shop={shop}
                    timeError={bookingTimeError}
                    onChange={handleChangeDateTime}
                  />

                  <FormField
                    className="m-0"
                    label={t(
                      'shopPublic.overview.createBooking.messageToStaff'
                    )}
                    hint={
                      <div
                        className={`text-muted ${
                          message.length > BookingMaxlength.message
                            ? 'text-danger'
                            : ''
                        }`}
                      >
                        {t('generalFields.freeText.maxlengthHelp', {
                          textLength: message.length,
                          maxlength: BookingMaxlength.message,
                        })}
                      </div>
                    }
                  >
                    <Textarea
                      maxLength={BookingMaxlength.message}
                      name="message"
                      placeholder="e.g. Bob Style Hair"
                      value={message}
                      onChange={(e) => handleChangeMessage(e.target.value)}
                    />
                  </FormField>

                  <div className="row">
                    <div className="col-lg-6">
                      <TextInputField
                        className="m-0"
                        disabled
                        label={t('shopPublic.overview.createBooking.name')}
                        value={user.user_type_data?.first_name}
                      />
                    </div>

                    <div className="col-lg-6">
                      <TextInputField
                        className="m-0"
                        disabled
                        label={t('generalFields.email.label')}
                        type="email"
                        value={user.email}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-lg-6">
                      <TextInputField
                        className="m-0"
                        disabled
                        label={t('shopPublic.overview.createBooking.phone')}
                        type="tel"
                        value={user.user_type_data?.phone}
                      />
                    </div>

                    {/* Visible on screen >= lg */}
                    <div className="col-lg-6 d-none d-lg-block align-self-end">
                      <AppButton
                        className="my-0"
                        disabled={!isFormDataSufficient}
                        size="block"
                        theme="primary"
                        type="submit"
                      >
                        {isCreatingBooking ? (
                          <Spinner />
                        ) : (
                          t('shopPublic.overview.letsBook')
                        )}
                      </AppButton>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          )}
        </DialogContent>

        <DialogActions className="d-lg-none">
          {/* Visible on screen < lg */}
          <AppButton
            disabled={!isFormDataSufficient}
            size="block"
            theme="primary"
            onClick={() => handleSubmit()}
          >
            {isCreatingBooking ? (
              <Spinner />
            ) : (
              t('shopPublic.overview.letsBook')
            )}
          </AppButton>
        </DialogActions>
      </Dialog>

      {isCreatedDialogOpened && (
        <BookingCreatedDialog onClose={handleCloseCreatedDialog} />
      )}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    user: state.auth.user,
    serviceTypeOptions: (state.serviceTypesReducer.serviceTypes || []).filter(
      (serviceType: ServiceType) => serviceType.services?.length
    ),
  };
}

export default connect(mapStateToProps, {
  getAllServiceTypes,
})(BookingCreationDialog);
