import {
  ADMIN_DEFAULT_SERVICE_DID_CREATE,
  ADMIN_DEFAULT_SERVICE_DID_UPDATE,
  ADMIN_DEFAULT_SERVICE_TREE_WILL_FETCH,
  ADMIN_DEFAULT_SERVICE_TREE_DID_FETCH,
  ADMIN_DEFAULT_SERVICES_DID_REORDER,
} from '../../constants/constants';


const initialState = {
  isFetchingTree: false,

  /**
   * It contains *all* default services (including visible and invisible).
   */
  defaultServiceTreeNodes: [],

  /**
   * An array of ID of all `DefaultService`
   */
  allDefaultServiceIds: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
    case ADMIN_DEFAULT_SERVICE_TREE_WILL_FETCH:
      return {
        ...state,
        isFetchingDefaultService: true,
      };

    case ADMIN_DEFAULT_SERVICE_TREE_DID_FETCH:
      return {
        ...state,
        isFetchingDefaultService: false,

        defaultServiceTreeNodes: action.payload.defaultServiceTreeNodes || [],
        allDefaultServiceIds: action.payload.allIds || [],
      };

    case ADMIN_DEFAULT_SERVICE_DID_CREATE:
    case ADMIN_DEFAULT_SERVICE_DID_UPDATE:
    case ADMIN_DEFAULT_SERVICES_DID_REORDER:
      return {
        ...state,

        // Important! When any default service is created or updated,
        // invalidate any previously fetched data:
        defaultServiceTreeNodes: [],
        allDefaultServiceIds: [],
      };

    default:
			return {
				...state,
			};
	}
}
