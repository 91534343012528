import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { showSpecificAlbum, GetPhotos } from '../../../../Redux/actions/shopActions/galleryShopActions';

import PhotoGrid from '../../../Common/PhotoGrid/PhotoGrid';

class AlbumDetail extends Component {

  componentDidMount() {
    this.props.showSpecificAlbum({ id: this.props.match.params.albumId }).then(res => {
      if (res.res.data.message === 'error') {
        axios.get(`/api/shop-albums/${this.props.match.params.albumId}?shop_id=${this.props.shopId}`).then(res => {
          if (res.data.message === 'success') {
            this.props.GetPhotos(res.data.data);
          }
        });
      }
    });
  }

  render() {
    const {
      photos,
      albums,
    } = this.props;

    return (
      <div className="container">
        {this.props.match.params.albumId !== 'reviews' ? (
          photos.photos &&
            <PhotoGrid aspectRatio="1:1" photos={photos.photos} />
        ) : (
          albums.review_album &&
            <PhotoGrid aspectRatio="1:1" photos={albums.review_album} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    photos: state.galleryReducer.photos,
    albums: state.galleryReducer.albums,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { showSpecificAlbum, GetPhotos }
  )(AlbumDetail)
);
