import { UPDATE_USER_PROPERTY } from '../../constants/constants';

import api from '../../../api';

export const changeAvatar = (data) => (dispatch) =>
  api.account.changeAvatar(data).then((response) => {
    // Successfully uploaded avatar

    // Expected server returning the updated user data (having a new avatar url)
    dispatch(didUpdateAvatar(response.data));

    return response;
  });

const didUpdateAvatar = (user) => ({
  type: UPDATE_USER_PROPERTY,
  payload: user,
});
