import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Booking } from 'src/models/entity';

import { apiErrorSweeper, shopBookingAPI } from 'src/utils/api';

import { incrementSiteBusyCount, decrementSiteBusyCount } from 'src/Redux/actions/features/siteBusyCountActions';
import { getShopBookingsPagination } from 'src/Redux/actions/features/paginationAction';

import notification from 'src/Components/Common/notification';
import { DataTableStatus } from 'src/Components/Common/DataTable';
import Pagination from 'src/Components/Common/Pagination';

import BookingCancellationByProviderDialog from 'src/Components/Common/Booking/BookingCancellationByProviderDialog';

import ShopBookingItem from './ShopBookingItem';

interface Props {
  // from parent:
  bookingType: string;
  onRequestEdit?: (booking: Booking, type: string) => void;

  // redux props:
  bookings: Booking[];

  // redux actions:
  getShopBookingsPagination: (data: any) => Promise<any>;
  decrementSiteBusyCount: () => void;
  incrementSiteBusyCount: () => void;
}

function ShopBookingList({
  // from parent:
  bookingType,
  onRequestEdit,

  // redux props:
  bookings,

  // redux actions:
  getShopBookingsPagination,
  decrementSiteBusyCount,
  incrementSiteBusyCount,
}: Props) {
  const { t } = useTranslation();

  const [isFetchingItems, setFetchingItems] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [
    isBookingCancellationDialogOpened,
    setBookingCancellationDialogOpened,
  ] = useState(false);
  const [subjectBooking, setSubjectBooking] = useState<Booking>();

  useEffect(() => {
    fetchBookings(1);
  }, [bookingType]);

  const fetchBookings = (page: number) => {
    const data = {
      user: 'shop-bookings',
      type: bookingType,
      page: page,
    };

    setFetchingItems(true);

    getShopBookingsPagination(data)
      .then((res) => {
        if (res.data.status === 'success') {
          setFetchingItems(false);
          setCurrentPage(res.data.data.current_page);
          setLastPage(res.data.data.last_page);
        }
      })
      .catch((error) => {
        notification(t('notifications.userIsNotShop'));
        setFetchingItems(false);
      });
  };

  const handleConfirmBooking = (booking: Booking) => {
    incrementSiteBusyCount();

    shopBookingAPI
      .confirmBooking(booking.id)
      .then(() => {
        notification(t('bookingManagement.confirmBooking.succeeded'));

        fetchBookings(1); // refresh first page
      })
      .catch(apiErrorSweeper)
      .finally(() => {
        decrementSiteBusyCount();
      });
  };

  const handleRequestCancelBooking = (booking: Booking) => {
    setBookingCancellationDialogOpened(true);
    setSubjectBooking(booking);
  };

  const handleCloseCancelBookingDialog = (isBookingCancelled: boolean) => {
    setBookingCancellationDialogOpened(false);
    setSubjectBooking(undefined);

    if (isBookingCancelled) {
      fetchBookings(1); // refresh first page
    }
  };

  const handleRequestMarkAbsent = (booking: Booking) => {
    incrementSiteBusyCount();

    shopBookingAPI
      .markBookingAsAbsent(booking.id)
      .then(() => {
        fetchBookings(1); // refresh first page
        notification(t('bookingManagement.markAbsent.succeeded'));
      })
      .catch(apiErrorSweeper)
      .finally(() => {
        decrementSiteBusyCount();
      });
  };

  const handlePageChange = (selected: number) => {
    fetchBookings(selected + 1);
  };

  return (
    <>
      <DataTableStatus
        isLoading={isFetchingItems}
        itemCount={bookings.length}
      />
      <div className="row no-gutters align-items-stretch px-1">
        {bookings.map((booking) => (
          <div className="col-xl-6 mb-2" key={booking.id}>
            <ShopBookingItem
              booking={booking}
              bookingType={bookingType}
              onRequestCancelBooking={handleRequestCancelBooking}
              onConfirmBooking={handleConfirmBooking}
              onRequestMarkAbsent={handleRequestMarkAbsent}
              onRequestEdit={onRequestEdit}
            />
          </div>
        ))}
      </div>

      {lastPage > 1 && (
        <Pagination
          currentPage={currentPage}
          lastPage={lastPage}
          pageChange={(e: any) => handlePageChange(e.selected)}
        />
      )}

      {isBookingCancellationDialogOpened && subjectBooking && (
        <BookingCancellationByProviderDialog
          booking={subjectBooking}
          onClose={handleCloseCancelBookingDialog}
        />
      )}
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    bookings: state.paginationReducer.items?.data || [],
    currentPage: state.paginationReducer.current_page,
    lastPage: state.paginationReducer.last_page,
  };
}

export default connect(mapStateToProps, {
  decrementSiteBusyCount,
  getShopBookingsPagination,
  incrementSiteBusyCount,
})(ShopBookingList);
