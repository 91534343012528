import moment from 'moment';

import {
  DELETE_STAFF,
  GET_SHOP_STUFF,
  SHOP_STAFF_LIST_DID_FETCH,
  SHOP_STAFF_LIST_WILL_FETCH,
} from '../../constants/constants';
import api from '../../../api';


export const fetchShopStaff = (data, forceFetch = false) => (dispatch, getState) => {
  if (!forceFetch) {
    const prevState = getState().GetStuffInformationReducer;

    if (prevState.shopId == data.id) {
      // The specified shop staff list has been fetched previously and is still in state.

      if (prevState.isFetchingShopStaffDataList) {
        // console.log('fetching shop staff list. No need to fire a fetch request.');
        return Promise.resolve({ res: null, access: false });
      }
      else if (prevState.shopStaffDataListLastFetchedAt && moment().diff(prevState.shopStaffDataListLastFetchedAt, 'minutes', true) <= 15) {
        // console.log('shop staff list is still fresh. No need to fire a fetch request.');
        return Promise.resolve({ res: null, access: false });
      }
    }
  }

  dispatch(shopStaffListWillFetch(data.id));

  return api.shopStaff.fetchAll(data)
    .then(res => res.data)
    .then(response => {
      if (response.status === 200) {
        dispatch(shopStaffListDidFetch(data.id, response.data));
        return { res: response, access: true };
      }
      return { res: response, access: false };
  });
}

export const extractShopStaffList = (state, shopId) => {
  let isFetchingShopStaffDataList = false;
  let staffDataList = [];
  let requestedStaffList = [];

  if (shopId == state.GetStuffInformationReducer.shopId) {
    isFetchingShopStaffDataList = state.GetStuffInformationReducer.isFetchingShopStaffDataList;
    staffDataList = state.GetStuffInformationReducer.shopStaffDataList;
    requestedStaffList = state.GetStuffInformationReducer.requestedStaffList;
  }

  return {
    isFetchingShopStaffDataList,
    staffDataList,
    requestedStaffList,
  };
}

export const fetchShopStaffPaginated = data => dispatch =>
  api.shopStaff.fetchPaginated(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(GetShopStaff(res.data.data));
    }
    return res;
  });

export const deleteStuffFromShop = data => dispatch =>
  api.shopStaff.deleteStaffFromShop(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(DeleteStaff(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

const shopStaffListWillFetch = shopId => ({
  type: SHOP_STAFF_LIST_WILL_FETCH,
  payload: {
    shopId,
  },
});

const shopStaffListDidFetch = (shopId, responseData) => ({
  type: SHOP_STAFF_LIST_DID_FETCH,
  payload: {
    shopId,
    responseData,
  },
});

const GetShopStaff = data => ({
  type: GET_SHOP_STUFF,
  payload: data,
});

const DeleteStaff = data => ({
  type: DELETE_STAFF,
  payload: data,
});
