import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { createShopNews } from '../../../../../Redux/actions/features/shopNewsAction';

import Breadcrumb from '../../../../Common/Breadcrumb/Breadcrumb';
import notification from '../../../../Common/notification';
import { AppButton } from '../../../../Common/Button';
import { FormField, Textarea, TextInputField } from '../../../../Common/Form';
import Spinner from '../../../../Common/Spinner/Spinner';

import NewsImageGrid from './NewsImageGrid';

class NewsCreationForm extends React.Component {
  state = {
    masterNews: {},

    title: '',
    description: '',

    isCreatingNews: false,
    isUploadingPhotos: false,

    error_title: false,
    error_description: false,
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidUpdate(prevProps) {
    const { title, description } = this.props;
    if (
      title &&
      description !== prevProps.title &&
      prevProps.description
    ) {
      this.setState({
        title: prevProps.user.user_type_data.title,
        description: prevProps.user.user_type_data.description,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { title, description } = this.state;

    this.setState({
      error_title: !title ? true : false,
      error_description: !description ? true : false,
    });

    if (title && description) {
      this.setState({
        isCreatingNews: true,
      });

      this.props.createShopNews({
        description,
        title,
      })
      .then(result => {
        if (result && result.res) { // result.res is ajax response
          this.setState({
            masterNews: result.res.data, // such change in props will trigger the <NewsImageGrid /> to upload pending items

            isUploadingPhotos: true,
          });
        }
      })
      .finally(() => {
        this.setState({
          isCreatingNews: false,
        });
      });
    }
  }

  handleUploadedAllPhoto = () => {
    notification(this.props.t('notifications.itemCreated'));

    this.props.history.push('/shop-settings/news');
  }

  render() {
    const { t } = this.props;
    const routes = [
      { title: t('shopPrivate.shopLeftMenu.news'), path: '/shop-settings/news' },
      { title: t('shopPrivate.news.createNews'), path: this.props.match.path },
    ];

    const {
      masterNews,

      title,
      description,

      isCreatingNews,
      isUploadingPhotos,

      error_title,
      error_description,
    } = this.state;

    const isFormBusy = isCreatingNews || isUploadingPhotos;

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-newspaper" aria-hidden="true" />
          {t('shopPrivate.shopLeftMenu.news')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">
          <div className="page-head">
            <h2 className="page-title">
              {t('shopPrivate.shopLeftMenu.news')}
              {isFormBusy && <Spinner />}
            </h2>
          </div>

          <div className="main-container__content">
            <form onSubmit={this.handleSubmit}>
              <fieldset disabled={isFormBusy}>{/* Able to disable child input fields when saving form */}

                {/* Title */}
                <TextInputField
                  errorMessage={error_title ? t('shopPrivate.news.reqField') : ''}
                  label={t('shopPrivate.news.title')}
                  name="title"
                  required
                  value={title}
                  onChange={this.handleChange}
                />

                {/* Description */}
                <FormField
                  errorMessage={error_description ? t('shopPrivate.news.reqField') : ''}
                  label={t('shopPrivate.news.descr')}
                  required
                >
                  <Textarea
                    className="content-field__item"
                    name="description"
                    value={description}
                    onChange={this.handleChange}
                  />
                </FormField>
              </fieldset>

              <NewsImageGrid
                newsId={masterNews.id}
                onUploadedAll={this.handleUploadedAllPhoto}
              />

              <AppButton className="mt-4" theme="primary" type="submit" disabled={isFormBusy}>
                {isFormBusy ? <Spinner /> : t('action.create')}
              </AppButton>
            </form>

          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      null,
      {
        createShopNews,
      }
    )(NewsCreationForm)
  )
);
