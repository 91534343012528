import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


import PhotoViewer from '../../../Common/PhotoViewer/PhotoViewer';

class ShopPhotoCardboard extends Component {

  state = {
    isPhotoViewerOpened: false,
    activePhotoIndex: 0,
  }

  openPhotoViewer = (activePhotoIndex) => {
    this.setState({
      isPhotoViewerOpened: true,
      activePhotoIndex,
    });
  }

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
    });
  }

  render() {
    const {
      className,
      photos,
      t,
    } = this.props;

    const {
      isPhotoViewerOpened,
      activePhotoIndex,
    } = this.state;

    return (
      <>
        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={photos}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        <div className={`shop-group ${className}`}>
          <p className="theme-subtitle">{t('shopPublic.overview.photoGallery')}</p>
          <div className="shop-gallery">
            {photos.map((photo, index) => (
              <div key={index} className="shop-gallery__item temp-cover temp-cover--no-border">
                <img
                  src={photo.url}
                  alt=""
                  loading="lazy"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.openPhotoViewer(index)} />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ShopPhotoCardboard);
