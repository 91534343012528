import React from 'react';

import modStyles from './AlertTemplate.module.css';

const AlertTemplate = ({
  classNames,
  condition,
  id,
  styles,
  customFields: { title, message },
  handleClose,
}) => (
  <div className={modStyles.window}>
    <div className={modStyles.container}>
      <div className={modStyles.iconContainer}>
        <i className="fas fa-bell" />
      </div>
      <div className={modStyles.message}>
        {message}
      </div>

      <button className={modStyles.closeBtn}>
        <i className="fas fa-times" onClick={handleClose} />
      </button>
    </div>
  </div>
);

export default AlertTemplate;
