import { Shop } from 'src/models/entity';

import styles from './ShopStatusDot.module.css';

interface Props {
  shop: Shop;
}

const ShopStatusDot = ({ shop }: Props) => {
  return (
    <span
      className={`mx-1 ${styles.dot} ${
        shop.working_now === 1 ? '' : styles.off
      }`}
    />
  );
};

export default ShopStatusDot;
