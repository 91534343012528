import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import api from '../../../../api';

import { fetchShopStaffPaginated } from '../../../../Redux/actions/shopActions/shopStaffActions';

import { AppButton } from '../../../Common/Button';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { TableHeaderCell } from '../../../Common/DataTable';
import notification from '../../../Common/notification';
import Pagination from '../../../Common/Pagination';


import ManageRightsItem from './ManageRightsItem.component';

class ManageRights extends Component {
  state = {
    loading: false,
    rights: [],
    staff: [],
    dataToBack: {},
    prevoiusDataToBack: {},
  };

  componentDidMount = () => {
    this.setState({
      loading: true,
    });

    this.props.fetchShopStaffPaginated({ id: this.props.auth.user.id })
    .then(res => {
      const dataToBack = {};
      res.data.data.data.map(staff => {
        dataToBack[staff.user_id] = staff.rights.map(right => right.id);
      });
      this.setState({
        staff: res.data.data.data,
        dataToBack: dataToBack,
        prevoiusDataToBack: dataToBack,
      });
    });

    api.shopStaff.getRights()
      .then(res => {
        if (res.data.message === 'success') {
          this.setState({ rights: res.data.data });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleChangeOnCheckbox = ({ staff, rightId }) => ({ target }) => {
    this.setState({
      dataChanged: true,
    });

    if (target.checked) {
      this.setState(prevState => ({
        dataToBack: {
          ...prevState.dataToBack,
          [staff.user_id]: [...prevState.dataToBack[staff.user_id], rightId],
        },
      }));
    } else {
      this.setState(prevState => ({
        dataToBack: {
          ...prevState.dataToBack,
          [staff.user_id]: prevState.dataToBack[staff.user_id].filter(id => rightId !== id),
        },
      }));
    }
  };

  handleClickOnSave = () => {
    const { dataToBack } = this.state;
    this.setState({
      loading: true,
    });

    api.shopStaff.setStaffRights({ staff_users: dataToBack })
      .then(res => {
        if (res.data.message === 'success') {
          notification(this.props.t('notifications.dataChanged'));
        } else if (res.data.message === 'error') {
          console.error(res.data.data.message);
          notification(this.props.t('notifications.somethingWentWrong'));
        } else {
          console.error(res.data.data.message);
          notification(this.props.t('notifications.somethingWentWrong'));
        }
      })
      .catch(error => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handlePageChange = props => {
    this.setState({
      loading: true,
    });

    this.props.fetchShopStaffPaginated({ id: this.props.auth.user.id, currentPage: props.selected + 1 })
      .then(res => {
        const dataToBack = {};
        res.data.data.data.map(staff => {
          dataToBack[staff.user_id] = staff.rights.map(right => right.user_id);
        });
        this.setState({
          staff: res.data.data.data,
          dataToBack: dataToBack,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  checkDataToBackChanges = () => {
    if (JSON.stringify(this.state.prevoiusDataToBack) === JSON.stringify(this.state.dataToBack)) {
      notification(this.props.t('notifications.noChanges'));
    } else {
      this.handleClickOnSave();
      this.setState({
        prevoiusDataToBack: this.state.dataToBack,
      });
    }
  };

  render() {
    const {
      staff,
      rights,
      dataToBack,
    } = this.state;
    const { lastPage, currentPage, t } = this.props;
    const routes = [{ title: t('shopPrivate.manageRights.manageRights'), path: window.location.pathname }];

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-flag" aria-hidden="true" />
          {t('shopPrivate.manageRights.manageRights')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">
          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.manageRights.manageRights')}</h2>
          </div>
          <div className="main-container__content">
            <div className="tableWrap">
              <table className="adminTable">
                <colgroup>
                  <col style={{ width: '40%' }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <TableHeaderCell>{t('shopPrivate.manageRights.user')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.manageRights.rights')}</TableHeaderCell>
                  </tr>
                </thead>
                <tbody>
                  {staff.map((staff, index) => (
                    <ManageRightsItem
                      key={+index}
                      blockNum={index + 1}
                      staff={staff}
                      rights={rights}
                      staffRight={dataToBack[staff.user_id]}
                      handleChangeOnCheckbox={this.handleChangeOnCheckbox}
                    />
                  ))}
                </tbody>
              </table>
            </div>

            {lastPage > 1 && (
              <Pagination currentPage={currentPage} lastPage={lastPage} pageChange={this.handlePageChange} />
            )}

            <div className="mt-2 mx-1">
              <AppButton theme="primary" onClick={this.checkDataToBackChanges}>
                {t('action.save')}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    currentPage: state.GetStuffInformationReducer.shopStaff.current_page,
    lastPage: state.GetStuffInformationReducer.shopStaff.last_page,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    { fetchShopStaffPaginated }
  )(ManageRights)
);
