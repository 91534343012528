import React from 'react';
import { withTranslation } from 'react-i18next';

import { AppButton } from '../../../Common/Button';

const ModalError = ({ onClose, t }) => {
  return (
    <div className="modal-wrapper">
      <div className="modal-wrapper__inner">
        <div className="theme-modal">
          <div className="theme-modal__header theme-modal__header--code">
            <h2>{t('shopPrivate.servicePriceList.error')}</h2>
            <button className="close-modal" onClick={onClose}>
              <i className="fas fa-times" />
            </button>
          </div>
          <div className="theme-modal__content">
            <div className="modal-inner-content">{t('shopPrivate.servicePriceList.errText')}</div>
          </div>
          <div className="confirm-code-btn">
            <AppButton theme="muted" onClick={onClose}>
              {t('shopPrivate.servicePriceList.close')}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ModalError);
