import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './BookingDollarBalance.module.css';

interface Props {
  balance?: number;
  className?: string;
  size?: 'normal' | 'small';
  withLabel?: boolean;
}

const BookingDollarBalance = ({
  balance,
  className = '',
  size = 'normal',
  withLabel = false,
}: Props) => {
  const { t } = useTranslation();

  const isLoaded = useMemo(
    () => typeof balance !== 'undefined' && !isNaN(balance),
    [balance]
  );

  return isLoaded ? (
    <div
      className={`${styles.root} ${
        size === 'small' ? styles.small : ''
      } ${className}`}
    >
      {withLabel && (
        <div className={styles.label}>
          {t('customerPrivate.bookingDollar.balance')}
        </div>
      )}
      <div className={styles.flag}>
        <span>$ {balance}</span>
      </div>
    </div>
  ) : null;
};

export default BookingDollarBalance;
