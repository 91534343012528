import {
	CREATE_SERVICE,
	DELETE_SERVICE,
    GET_ALL_SERVICES,
    GET_BOOKING_SERVICES,
	GET_RECOMMENDED,
    SHOW_SERVICE,
    GET_FIXED_PRICE_SERVICES,
} from "../../constants/constants";
import api from "../../../api";


export const createService = (data) => dispatch =>
    api.ShopServices.createService(data).then((res) => {
        if(res.data.message === 'success') {
            dispatch(CreateService(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
    });


export const getAllServices = (data) => dispatch =>
    api.ShopServices.getAllShopServices(data).then((res) => {
        if(res.data.message === 'success') {
            dispatch(GetAllServices(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
    });

export const getBookingServices = (data) => dispatch =>
api.ShopServices.getBookingShopServices(data).then((res) => {
    if(res.data.message === 'success') {
        dispatch(GetAllServices(res.data.data));
        return {res, access: true}
    }
    return {res, access: false}
});

export const getFixedPriceServices = (data) => dispatch =>
    api.ShopServices.getFixedPriceShopServices(data).then((res) => {
        if(res.data.message === 'success') {
            dispatch(GetFixedPriceServices(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
    });


export const showService = (data) => dispatch =>
    api.ShopServices.showSpecificService(data).then((res) => {
        if(res.data.message === 'success') {
            dispatch(ShowService(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
    });


export const deleteService = (data) => dispatch =>
    api.ShopServices.deleteSpecificService(data).then(res => {
        if(res.data.message === "success"){
            dispatch(DeleteService(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
});


export const getRecommended = (data)  => dispatch =>
    api.products.getRecommended(data).then(res => {
        if(res.data.message === 'success') {
            dispatch(GetRecommended(res.data.data));
            return {res, access: true}
        }
        return {res, access: false}
    })

export const GetAllServices = (data) => ({
    type: GET_ALL_SERVICES,
    payload: data
});

export const GetBookingServices = (data) => ({
    type: GET_BOOKING_SERVICES,
    payload: data
});

export const GetFixedPriceServices = (data) => ({
    type: GET_FIXED_PRICE_SERVICES,
    payload: data
});

export const ShowService = (data) => ({
    type: SHOW_SERVICE,
    payload: data
});

export const DeleteService = (data) => ({
    type: DELETE_SERVICE,
    payload: data
});

export const GetRecommended = (data) => ({
    type: GET_RECOMMENDED,
    payload: data
});

export const CreateService = data => ({
  type: CREATE_SERVICE,
  payload: data
})


