import {
    STAFF_ALBUM_WILL_FETCH,
    STAFF_ALBUM_DID_FETCH,
    STAFF_ALBUM_DID_CREATE,
    STAFF_ALBUM_DID_DELETE,
    STAFF_ALBUM_PHOTO_WILL_FETCH,
    STAFF_ALBUM_PHOTO_DID_FETCH,
    GET_STAFF_ALBUMS,
} from "../../constants/constants";


const initialState = {
    isFetchingAlbum: false,
    albumMeta: {},
    albums: [],

    isFetchingStaffAlbumPhotos: false,
    album: {},
    photos: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STAFF_ALBUMS:
            return {
                ...state,
                albums: action.payload
            };
        case STAFF_ALBUM_WILL_FETCH:
            return {
                ...state,
                isFetchingAlbum: true,
            };
        case STAFF_ALBUM_DID_FETCH:
            return {
                ...state,
                isFetchingAlbum: false,
                albumMeta: action.payload.albumMeta,
                albums: action.payload.albums,
            };
        case STAFF_ALBUM_PHOTO_WILL_FETCH:
            return {
                ...state,
                isFetchingStaffAlbumPhotos: true,
            };
        case STAFF_ALBUM_PHOTO_DID_FETCH:
            return {
                ...state,
                isFetchingStaffAlbumPhotos: false,
                album: action.payload.album,
                photos: action.payload.photos,
            };
        case STAFF_ALBUM_DID_CREATE:
            return {
                ...state,
            };
        case STAFF_ALBUM_DID_DELETE:
            return {
                ...state,
            };
        default:
            return {
                ...state
            };
    }
}


