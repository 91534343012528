import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import AlbumCell from '../../../Common/AlbumCell/AlbumCell';
import Spinner from '../../../Common/Spinner/Spinner';

import { showAllAlbums } from '../../../../Redux/actions/shopActions/galleryShopActions';


class Albums extends Component {
  state = {
    albums: [],
    loading: true,
    error: false
  };

  componentDidMount() {
    const {
      getPageName,
      match: { path },
      shopId,
    } = this.props;
    getPageName(path);

    this.props.showAllAlbums({ id: shopId })
      .then(res => {
        if (res.res.data.message === 'success') {
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, error: true });
        };
      });
  }

  render() {
    const {
      albums,
      shopId,
      match: { params },
      auth,
      t,
    } = this.props;

    return (
      <div className={auth.isShop && params.id === auth.user.id ? '' : 'container'}>
        {this.state.loading ? (
          <Spinner display="block" />
        ) : (
          <div className="row">
            {albums.albums && albums.albums.map(album => (
              <AlbumCell
                key={album.id}
                album={album}
                coverPhoto={album.photos[0]}
                linkTo={`/shop${shopId === 'gallery' ? '' : `/${shopId}`}/gallery/${album.id}`}
              />
            ))}

            {albums.review_album[0] && (
              <AlbumCell
                albumName={t('shopPrivate.photoGallery.reviewsPhotos')}
                coverPhoto={albums.review_album[0]}
                linkTo={`/shop${shopId === 'gallery' ? '' : `/${shopId}`}/gallery/reviews`}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    albums: state.galleryReducer.albums,
  };
}

export default withTranslation() (
  withRouter(
    connect(
      mapStateToProps,
      { showAllAlbums }
    )(Albums)
  )
);
