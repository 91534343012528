import moment from 'moment';


import {
  DEFINITION_PAYMENT_METHODS_WILL_FETCH,
  DEFINITION_PAYMENT_METHODS_DID_FETCH,

  ADMIN_PAYMENT_METHOD_DID_CREATE,
  ADMIN_PAYMENT_METHOD_DID_UPDATE,
  ADMIN_PAYMENT_METHODS_DID_REORDER,
} from "../../constants/constants";


const initialState = {
  /**
   * Whether the client is fetching array of Payment Methods
   */
  isFetchingPaymentMethods: false,

  /**
   * The last time when the array of Payment Methods is fetched
   */
  paymentMethodsLastFetchedAt: null,

  /**
   * Array of Payment Methods
   */
  paymentMethods: [],
};


export default (state = initialState, action) => {
  switch (action.type) {
    case DEFINITION_PAYMENT_METHODS_WILL_FETCH:
      return {
        ...state,
        isFetchingPaymentMethods: true,
      };

    case DEFINITION_PAYMENT_METHODS_DID_FETCH:
      return {
        ...state,
        isFetchingPaymentMethods: false,
        paymentMethods: action.payload.paymentMethods,
        paymentMethodsLastFetchedAt: moment(),
      };

    case ADMIN_PAYMENT_METHOD_DID_CREATE:
    case ADMIN_PAYMENT_METHOD_DID_UPDATE:
    case ADMIN_PAYMENT_METHODS_DID_REORDER:
      // Important! When any payment method is created or updated,
      // invalidate any previously fetched data:
      return {
        ...state,
        paymentMethods: [],
        paymentMethodsLastFetchedAt: null,
      };

    default:
      return {
        ...state,
      };
  }
}
