import { useEffect, useState } from 'react';

/**
 * The suggested dimensions stated in this file also appear at below css files:
 * @see also `src/Components/Common/Advertisement/GoogleAdvertisement.module.css`
 * @see also `src/Components/Common/Advertisement/LocalAdvertisement.module.css`
 */

const landscapeSuggestedDimensions = {
  desktop: {
    width: 728,
    height: 90,
  },
  tablet: {
    width: 690,
    height: 90,
  },
  mobile: {
    width: 320,
    height: 100,
  },
};

const portraitSuggestedDimensions = {
  desktop: {
    width: 160,
    height: 600,
  },
  tablet: {
    width: 690,
    height: 90,
  },
  mobile: {
    width: 320,
    height: 100,
  },
};

function gcd(a, b) {
  return b ? gcd(b, a % b) : a;
}

function evalAspectRatio(width, height) {
  const d = gcd(width, height);
  return `${width / d}:${height / d}`;
}


export const useAdvertisementDimensions = (advertisement) => {

  const [aspectRatio, setAspectRatio] = useState('');

  const [width, setWidth] = useState(320);
  const [height, setHeight] = useState(100);

  useEffect(() => {
    let resolutions = landscapeSuggestedDimensions;

    switch (advertisement.name) {
      case 'review_page':
      case 'shop_list_page':
      case 'shop_news_page':
      case 'shop_page':
      case 'staff_page':
      case 'stylist_sharing_page':
      case 'video_channel_page':
        resolutions = portraitSuggestedDimensions;
        break;
    }

    let formFactor = 'mobile';

    if (advertisement.device === 'desktop') {
      formFactor = 'desktop';
    } else if (advertisement.device === 'tablet') {
      formFactor = 'tablet';
    }

    setAspectRatio(
      evalAspectRatio(
        resolutions[formFactor].width,
        resolutions[formFactor].height
      )
    );

    setWidth(resolutions[formFactor].width);
    setHeight(resolutions[formFactor].height);
  }, [advertisement]);

  return {
    aspectRatio,
    isLandscape: width > height,
    width,
    height,
  };
};
