import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import {
  deleteService,
  getAllServices,
} from '../../../../../Redux/actions/shopActions/serviceAction';

import { AppButtonLink } from '../../../../Common/Button';
import { DataTableStatus, TableHeaderCell } from '../../../../Common/DataTable';
import notification from '../../../../Common/notification';

import ModalError from '../ModalError';
import ServiceItem from './ServiceItem';


const ServiceList = ({ shopId, services, getAllServices, deleteService }) => {
  const { t, i18n } = useTranslation();

  const isChinese = useMemo(
    () => !i18n.language.startsWith('en'),
    [i18n.language]
  );

  const [isLoading, setLoading] = useState(false);

  const [toggleError, setToggleError] = useState(false);

  useEffect(() => {
    setLoading(true);

    getAllServices({ id: shopId }).finally(() => {
      setLoading(false);
    });
  }, []);

  const deleteServiceFn = (id) => {
    deleteService({ id }).then((res) => {
      if (res.res.data.message === 'success') {
        getAllServices({ id: shopId });
        notification(t('notifications.serviceDeleted'));
      } else if (res.res.data.message === 'error') {
        notification(res.res.data.data);
      } else {
        setToggleError(true);
      }
    });
  };

  const toggleErrorWindow = () => {
    setToggleError((val) => !val);
  };

  return (
    <>
      {toggleError && <ModalError onClose={toggleErrorWindow} />}

      <div className="page-head">
        <div className="page-add">
          <AppButtonLink
            theme="primary"
            to="/shop-settings/service-price/service/create"
          >
            {t('shopPrivate.servicePriceList.createService')}
          </AppButtonLink>
        </div>
      </div>

      <div className="tableWrap">
        <table className="adminTable">
          <colgroup>
            <col style={{ minWidth: 200 }} />
            <col style={{ minWidth: 120 }} />
            <col style={{ minWidth: 100 }} />
            <col style={{ minWidth: 300 }} />

            <col style={{ minWidth: 90 }} />
            <col style={{ minWidth: 120 }} />
            <col style={{ minWidth: 120 }} />
            <col style={{ minWidth: 100 }} />
          </colgroup>
          <thead>
            <tr>
              <TableHeaderCell>{t('shopPrivate.calendar.serviceName')}</TableHeaderCell>
              <TableHeaderCell>
                {t('shopPrivate.servicePriceList.serviceType')}
              </TableHeaderCell>
              <TableHeaderCell>
                {isChinese ? (
                  '所需時間'
                ) : (
                  <>
                    {t('shopPrivate.calendar.time')}
                    <br />
                    {t('shopPrivate.servicePriceList.process')}
                  </>
                )}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('shopPrivate.servicePriceList.service_descr')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('shopPrivate.servicePriceList.availability')}
              </TableHeaderCell>
              <TableHeaderCell>{t('shopPublic.product.price')}</TableHeaderCell>
              <TableHeaderCell>{t('shopPrivate.servicePriceList.staff')}</TableHeaderCell>
              <TableHeaderCell>{t('general.label.action')}</TableHeaderCell>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {services.map((service, index) => (
                <ServiceItem
                  key={service.id}
                  index={index + 1}
                  shopId={shopId}
                  service={service}
                  deleteService={deleteServiceFn}
                />
              ))}
            </tbody>
          )}
        </table>

        <DataTableStatus isLoading={isLoading} itemCount={services?.length} />
      </div>
    </>
  );
};

function mapSateToProps(state) {
  return {
    shopId: state.auth.user.id,
    services: state.servicesReducer.services || [],
  };
}
export default connect(mapSateToProps, {
  getAllServices,
  deleteService,
})(ServiceList);
