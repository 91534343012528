import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DetailsIcon from '@material-ui/icons/Details';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { AppButton } from '../../../../Common/Button';


const SearchPanel = ({
  // from parent:
  isOpenNowFilter,

  isBookingEnabledFilter,

  paymentMethodFilter,

  locationFilter,
}) => {

  const { t } = useTranslation();

  const [isFilterPanelExpanded, setFilterPanelExpanded] = useState(false);

  const toggleFilter = () => {
    setFilterPanelExpanded(prevVal => !prevVal);
  };

  const handleSearch = () => {
    window.scrollTo(0, 0);
    toggleFilter();
  }

  return (
    // The `d-flex flex-column` helps achieve ordering of children
    <div className="mt-3 mt-lg-4 bg-white rounded pb-1 d-flex flex-column search-panel">
      <div className="sidebar-group order-1 order-lg-1">
        <div className="shop-search-custom sidebar-title" onClick={toggleFilter}>
          {t('shopList.filter')}
          <div className="d-lg-none text-light">
            <TouchAppIcon />
          </div>
          <div className="d-none d-lg-block">
            <span className="sidebar-title__ico">
              <DetailsIcon />
            </span>
          </div>
        </div>
      </div>

      <div className={`sidebar-group order-2 order-lg-1 ${isFilterPanelExpanded ? '' : 'd-none d-lg-block'}`}>
        {isOpenNowFilter}
      </div>

      <div className={`sidebar-group order-3 order-lg-1 ${isFilterPanelExpanded ? '' : 'd-none d-lg-block'}`}>
        {isBookingEnabledFilter}
      </div>

      <div className={`sidebar-group order-4 order-lg-1 ${isFilterPanelExpanded ? '' : 'd-none d-lg-block'}`}>
        {paymentMethodFilter}

        <div className="d-lg-none text-center">
          <AppButton
            elevated
            theme="primary"
            shape="round"
            onClick={handleSearch}
          >
            {t('navBar.Search')}
          </AppButton>
        </div>
      </div>

      <div className={`sidebar-group order-3 order-lg-1 ${isFilterPanelExpanded ? '' : 'd-none d-lg-block'}`}>
        {locationFilter}
      </div>
    </div>
  );
}

export default SearchPanel;
