import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import ShopCard from './ShopCard';


class MainSearch extends Component {
  render() {
    const { shops, t } = this.props;

    return (
      <section>
        <div className="container">
          <div className="p-3 font-weight-bold">
            {t('shopList.showRes', { num: shops.length })}
          </div>

          <div className="d-flex flex-row flex-wrap justify-content-start border-top">
            {shops.map((shop) => (
              <ShopCard key={shop.id} shop={shop} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    shops: state.searchReducer.results && state.searchReducer.results.length ? state.searchReducer.results : [],
  };
}

export default withTranslation()(
  connect(
    mapStateToProps
  )(MainSearch)
);
