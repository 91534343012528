import React, { useMemo } from 'react';

export interface TextInputProps {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  hasIcon?: boolean;
  iconAtRight?: boolean;
  maxLength?: number;
  name?: string;
  placeholder?: string;
  required?: boolean;
  type?: 'email' | 'password' | 'tel' | 'text' | 'url';
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = ({
  autoComplete,
  className = '',
  disabled = false,
  hasIcon = false,
  iconAtRight = false,
  maxLength,
  name = '',
  placeholder = '',
  required = false,
  type = 'text',
  value,
  onChange = () => {},
}: TextInputProps) => {
  const resolvedAutoComplete = useMemo(() => {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete

    if (!autoComplete) {
      // Provide proper default value if not specified by parent
      if (type === 'email') {
        return 'email';
      } else if (type === 'tel') {
        return 'tel';
      }
    }

    return autoComplete;
  }, [autoComplete, type]);

  const inputClassName = useMemo(() => {
    return `${className} form-item ${
      hasIcon
        ? iconAtRight
          ? 'form-item--ico-right'
          : 'form-item--ico-left'
        : ''
    }`;
  }, [className, hasIcon, iconAtRight]);

  const resolvedPlaceholder = useMemo(() => {
    if (disabled) return '';

    if (!placeholder) {
      if (type === 'url') {
        return 'https://...';
      }
    }

    return placeholder;
  }, [disabled, placeholder, type]);

  return (
    <input
      autoComplete={resolvedAutoComplete}
      className={inputClassName}
      disabled={disabled}
      maxLength={maxLength}
      name={name}
      placeholder={resolvedPlaceholder}
      // required={required}
      type={type}
      value={value || ''}
      onChange={onChange}
    />
  );
};

export default TextInput;
