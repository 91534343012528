import React from 'react';

const ServiceItem = ({ type }) => (
  <div className="mb-4 border bg-white p-2 service-block" key={type.id}>
    <h2 className="service-block__title">{type.name}</h2>
    <div className="px-0 px-lg-3 pt-3">
      {type.services instanceof Array &&
        type.services.map((service) => (
          <div key={service.id}>
            <div className="mb-3 row align-items-center">
              <div className="col-7">{service.name}</div>
              <div className="col-5">
                <div className="border rounded py-3 single-service-price">
                  {service.price_fixed !== null ? (
                    service.price_fixed
                  ) : (
                    <>
                      {service.price_from} - {service.price_to}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

export default ServiceItem;
