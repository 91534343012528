import {
  DEFINITION_CITY_LIST_WILL_FETCH,
  DEFINITION_CITY_LIST_DID_FETCH,
} from '../../constants/constants';

import api from '../../../api';

export const getCityList = () => (dispatch, getState) => {
  const prevState = getState().cityReducer;

  if (!prevState.isLoadingCityList && !prevState.cityList.length) {
    dispatch(willFetchCityList());

    api.city.fetchCityList()
      .then(response => {
        dispatch(didFetchCityList(response.data));
        return response;
      })
      .catch(err => {
        console.error(err);
      })
  }
};

const willFetchCityList = () => ({
  type: DEFINITION_CITY_LIST_WILL_FETCH,
});
const didFetchCityList = data => ({
  type: DEFINITION_CITY_LIST_DID_FETCH,
  payload: data,
});
