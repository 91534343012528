import { GET_ITEM_LIST } from '../../constants/constants';
import api from '../../../api';

export const getShopBookingsPagination = data => dispatch =>
  api.pagination
    .bookingsPagination(data)
    .then(res => {
      if (res.data.status === 'success') {
        dispatch(GetItemList(res.data.data));
        return res;
      } else {
        console.error(res.data.message);
      }
    })
    .catch(err => {
      console.error(err);
    });

const GetItemList = data => ({
  type: GET_ITEM_LIST,
  payload: data,
});
