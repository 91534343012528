import styles from './AppButtonTheme.module.css';

export type ButtonShape = 'rect' | 'round';

export type ButtonSize = 'block' | 'normal' | 'small';

export type ButtonTheme = 'primary' | 'secondary' | 'success' | 'danger' | 'muted';

export type ButtonVariant = 'outlined' | 'filled';

export const shapeClass = (shape: ButtonShape) => {
  switch (shape) {
    case 'round':
      return styles.shapeRound;

    case 'rect':
    default:
      return '';
  }
};

export const sizeClass = (size: ButtonSize) => {
  switch (size) {
    case 'block':
      return styles.sizeBlock;

    case 'small':
      return styles.sizeSmall;

    case 'normal':
    default:
      return '';
  }
};

export const themeClass = (theme: ButtonTheme) => {
  switch (theme) {
    case 'primary':
      return styles.themePrimary;

    case 'secondary':
      return styles.themeSecondary;

    case 'success':
      return styles.themeSuccess;

    case 'danger':
      return styles.themeDanger;

    case 'muted':
    default:
      return styles.themeMuted;
  }
};

export const variantClass = (variant: ButtonVariant) => {
  switch (variant) {
    case 'outlined':
      return styles.variantOutlined;

    case 'filled':
    default:
      return styles.variantFilled;
  }
};
