import {
  ADMIN_BOOKINGS_DID_FETCH,
} from "../../constants/constants";


const initialState = {
  bookings: [],
  bookingPagination: {
    currentPage: 1,
    lastPage: 1,
    total: 0,
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_BOOKINGS_DID_FETCH:
      return {
        ...state,
        bookings: action.payload.bookings,
        bookingPagination: action.payload.bookingPagination,
      };
    default:
      return {
        ...state
      }
  }
};
