interface Props {
  children: React.ReactNode;
  label: string;
}

/**
 * It offer a responsive container for form control.
 *
 * For >= lg, label and control are rendered inline horizontally
 * For < lg, rendered vertically
 */
function FormControlBox({ children, label }: Props) {
  return (
    <div className="mb-2 d-flex flex-column align-items-stretch flex-lg-row align-items-lg-center">
      <label className="mb-2 mb-lg-0 mx-2 font-weight-bold">{label}</label>
      <div className="flex-fill">{children}</div>
    </div>
  );
}

export default FormControlBox;
