import { saveUserSettings } from './changeMedia';
import api from '../../../api';

export const updateSchedule = (data) => (dispatch) =>
  api.shopSettings.changeScheduleData(data).then((res) => {
    if (res.data.message === 'success') {
      dispatch(saveUserSettings(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });
