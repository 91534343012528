import { useMemo } from 'react';

import { Booking, Shop } from 'src/models/entity';

import ShopAnchor from './ShopAnchor';

interface Props {
  booking: Booking;
  className?: string;
}

const ShopAnchorFromBooking = ({ booking, className = '' }: Props) => {
  const shop = useMemo<Shop>(
    () => {
      if (booking.shop) {
        return booking.shop;
      }

      return {
        id: booking.shop_id,
        active: 1, // TODO
        user_type_data: {
          chineseName: booking.shop_name?.chinese_name || '',
          name: booking.shop_name?.name || '',
        },
      } as Shop;
    },
    [booking]
  );

  return shop ? <ShopAnchor className={className} shop={shop} /> : null;
};

export default ShopAnchorFromBooking;
