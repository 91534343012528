import { CustomerData } from 'src/models/entity';

import { GetResponse } from 'src/models/api/response/GetResponse';

import { apiPost } from '../api-utils';

interface CheckPhoneRequest {
  phone: string;
}

export const searchCustomerByPhone = (request: CheckPhoneRequest) =>
  apiPost<GetResponse<CustomerData>>('check-phone', request).then(
    (response) => response.data
  );
