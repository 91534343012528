import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Tooltip,
} from '@material-ui/core';


const CreateIconButton = ({
  className,
  disabled,
  name,
  onClick,
}) => {

  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={t('action.create')}>
      <button
        className={`mx-1 btn btn-primary ${className}`}
        disabled={disabled}
        name={name}
        onClick={onClick}
      >
        <i className="fas fa-plus"></i>
      </button>
    </Tooltip>
  );
}

CreateIconButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

CreateIconButton.defaultProps = {
  className: '',
  disabled: false,
  name: '',
  onClick: () => {},
};

export default CreateIconButton;
