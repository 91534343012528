import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Booking } from 'src/models/entity';
import { BookingStatus } from 'src/models/enum/BookingStatus';

import { equalId } from 'src/utils/api/api-utils';

import { useAuthenticated } from 'src/hooks/auth';

import { CustomerAvatar } from 'src/Components/Common/Customer';

import MessageBubble from '../Message/MessageBubble';

interface Props {
  booking: Booking;
  onRequestEditMessage?: () => void;
}

const BookingCustomerMessageBubble = ({
  booking,
  onRequestEditMessage,
}: Props) => {
  const { t } = useTranslation();

  const { user } = useAuthenticated();

  /*
  const reviewContent = useMemo(() => {
    if (booking.booking_status_id === BookingStatus.Completed) {
      if (booking.reviews?.length) {
        return booking.reviews[0].review;
      }
    }

    return '';
  }, [booking]);
  */

  const isAuthor = useMemo(
    () => equalId(booking.customer_id, user.id),
    [booking.customer_id, user.id]
  );

  const canEditMessage = useMemo(
    () =>
      isAuthor &&
      (booking.booking_status_id === BookingStatus.New ||
        booking.booking_status_id === BookingStatus.Confirmed),
    [isAuthor, booking.booking_status_id]
  );

  return booking.message ? (
    <MessageBubble
      authorBadge={<CustomerAvatar customer={booking.customer} />}
      caption={t('shopPrivate.bookings.msgToStylist')}
      isAuthor={isAuthor}
      message={booking.message}
      onRequestEdit={canEditMessage ? onRequestEditMessage : undefined}
    />
  ) : null;
};

export default BookingCustomerMessageBubble;
