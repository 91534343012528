import {GET_CARDS} from "../../constants/constants";
import api from "../../../api";


export const getCard  = data => dispatch =>
	api.payment.getCards(data).then(res => {
		if(res.data.message === 'success') {
			dispatch(GetCard(res.data.data));
			return {res, access: true}
		}
		return {res, access: false}
	})


export const GetCard = data => ({
	type: GET_CARDS,
	payload: data
})
