import visa from '../../../dist/img/d-1.png';
import masterCard from '../../../dist/img/d-2.png';
import cash from '../../../dist/img/d-3.png';


import styles from './PaymentMethodList.module.css';

function PaymentMethodList({ className }) {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <div className={`mx-1 ${styles.ico}`}>
        <img src={visa} alt="" />
      </div>
      <div className={`mx-1 ${styles.ico}`}>
        <img src={masterCard} alt="" />
      </div>
      <div className={`mx-1 ${styles.ico}`}>
        <img src={cash} alt="" />
      </div>
    </div>
  );
}

PaymentMethodList.defaultProps = {
  className: '',
};

export default PaymentMethodList;
