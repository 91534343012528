import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


import AspectImage from '../../Common/AspectImage/AspectImage';
import DataTableStatus from '../../Common/DataTable/DataTableStatus';
import { LineBreakable } from '../../Common/Text';
import Pagination from '../../Common/Pagination';

import api from '../../../api';

import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';

import SiteContainer from '../Global/SiteContainer/SiteContainer';


import styles from './FeatureArticleList.module.css';

const _pageTitle = '價目資訊及評價 | 最新美容優惠 | 髮型屋優惠 | 按摩推薦 | 美甲 Gel甲優惠 | 植眼睫毛優惠 | SalonFinder 邊間好';

const _pageDescription =
  'SalonFinder邊間Beauty Salon好為您搜羅各種扮靚優惠，明碼實價，支持單次收費項目，而且服務後您都可以作出評價，經SalonFinder與您確認及鎖定號碼，大大減低打手出現！服務做得好唔好您都可以作出評價，唔駛中左伏都無仇報啦！';

function FeatureArticleList() {
  const { t, i18n } = useTranslation();

  const [routes, setRoutes] = useState([]);

  const [isFetchingItems, setIsFetchingItems] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  // a list of FeatureArticle
  const [items, setItems] = useState([]);

  useEffect(() => {
    getData(currentPage);
  }, []);

  useEffect(() => {
    setRoutes([
      { title: t('featureArticle.title'), path: window.location.pathname },
    ]);
  }, [i18n.language]);

  /**
   *
   * @param {number} page an 1-based page number
   */
  const getData = (page) => {
    setIsFetchingItems(true);

    api.featureArticle.fetchList(page).then(response => {
      setCurrentPage(response.meta.current_page);
      setLastPage(response.meta.last_page);
      setItems(response.data);
    })
    .finally(() => {
      setIsFetchingItems(false);
    });
  }

  const handlePageChange = data => {
    getData(data.selected + 1);
  }

  return (
    <SiteContainer>
      <Helmet>
        <title>{_pageTitle}</title>
        <meta property="og:title" id="og-title" content={_pageTitle} />
        <meta name="description" property="og:description" id="og-description" content={_pageDescription} />
      </Helmet>

      <div className="container">
        <Breadcrumb routes={routes} />

        <DataTableStatus isLoading={isFetchingItems} itemCount={items.length} />

        <div className="d-flex flex-row flex-wrap justify-content-start mx-auto">
          {items.map(item => (
            <div key={item.id} className={`card align-self-stretch ${styles.articleCard}`}>
              {item.coverImgUrl && (
                <AspectImage className="card-img-top" aspectRatio="3:2">
                  <img src={item.coverImgUrl} alt={item.title} />
                </AspectImage>
              )}

              <div className="card-body">
                <h2 className="card-title">{item.title}</h2>
                <LineBreakable className="card-text">{item.abstract}</LineBreakable>
                <div className="text-center">
                  <Link to={`/美容-美髮-按摩優惠/${item.alias}`} className="btn btn-link">{item.anchor}</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {lastPage > 1 && (
          <Pagination
            pageChange={handlePageChange}
            currentPage={currentPage}
            lastPage={lastPage}
          />
        )}
      </div>
    </SiteContainer>
  );
}

export default FeatureArticleList;
