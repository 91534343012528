import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { useConfirmationDialog } from '../../../../../Providers/ConfirmDialogProvider';

import AspectImage from '../../../../Common/AspectImage/AspectImage';
import ExternalFeedThumbnail from '../../../../Common/ExternalFeed/ExternalFeedThumbnail';
import { AppButton, AppButtonLink } from '../../../../Common/Button';
import { ShopBadge } from '../../../../Common/Shop';
import { Ellipsize, LineBreakable } from '../../../../Common/Text';


const NewsItem = ({
  shop,
  news,
  onDelete,
  history,
}) => {

  const { t } = useTranslation();

  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const redirectView = () => {
    history.push(`/shop/news/${news.id}`);
  };

  const handleClickEdit = e => {
    e.stopPropagation();
  };

  const handleDelete = e => {
    e.stopPropagation();
    openConfirmDeleteDialog(() => onDelete(news.id));
  };

  return (
    <div className="news-item-container" style={{ cursor: 'pointer' }} onClick={redirectView}>
      <div className="row">
        <div className="col-xl-2">
          <ShopBadge shop={shop} />
        </div>
        <div className="col-xl-10">
          <a className="news-title">{news.title}</a>

          <div className="row">
            <div className="col-lg-4 col-xl-3">
              <p className="news-info">{news.created_at}</p>
            </div>
            <div className="col-lg-4 col-xl-3">
              <p className="news-info">
                <i className="fas fa-eye" /> {t('general.viewable.viewCount', { count: news.views })}
              </p>
            </div>
          </div>

          <LineBreakable className="news-content">
            <Ellipsize text={news.description} maxlength={255} />
          </LineBreakable>

          <div className="mt-3 row align-items-center">
            <div className="col-xl-6">
              <div className="row align-items-center">
                {news.externalFeeds && news.externalFeeds.map(feed => (
                  <div className="col-6 col-md-3 mb-2" key={feed.id}>
                    <ExternalFeedThumbnail feed={feed} />
                  </div>
                ))}

                {news.photos && news.photos.map(photo => (
                  <div className="col-6 col-md-3 mb-2" key={photo.id}>
                    <AspectImage aspectRatio="1:1">
                      <img src={photo.url} alt="news image" />
                    </AspectImage>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row justify-content-start justify-content-xl-end align-items-center">
            <div className="col col-md-auto">
              <AppButtonLink theme="secondary" to={`/shop-settings/news/${news.id}`} onClick={handleClickEdit}>
                {t('action.edit')}
              </AppButtonLink>
            </div>
            <div className="col col-md-auto">
              <AppButton theme="danger" variant="outlined" onClick={handleDelete}>
                {t('action.delete')}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewsItem);
