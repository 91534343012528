import {
  GET_SHOP_INFORMATION,
  SAVE_SHOP_DATA,
} from '../../constants/constants';

import api from '../../../api';

export const shopData = (data) => dispatch =>
    api.shopSettings.getShopInformation(data).then(res => {
        if(res.data.message === 'success') {
            dispatch(GetShopInformation(res.data.data))
            return {res, access: true}
        }
        return {res, access: false}
    });

export const changeGeneralShopData = (data) => (dispatch) =>
  api.shopSettings
    .changeGeneralData(data)
    .then((response) => {
      dispatch(SaveShopData(response.data));

      return response;
    });

const GetShopInformation = (data) => ({
  type: GET_SHOP_INFORMATION,
  payload: data,
});

const SaveShopData = (data) => ({
  type: SAVE_SHOP_DATA,
  payload: data,
});
