import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import { useConfirmationDialog } from '../../../Providers/ConfirmDialogProvider';


import notification from '../notification';
import Spinner from '../Spinner/Spinner';
import ExternalFeed from '../ExternalFeed/ExternalFeed';


const ConfirmDeleteButton = ({
  isDeleting,
  onConfirmDelete,
}) => {

  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const handleClick = () => {
    openConfirmDeleteDialog(onConfirmDelete);
  }

  return (
    <button type="button" className="btn btn-outline-secondary" onClick={handleClick}>
      {isDeleting ? <Spinner /> : <i className="fas fa-trash" />}
    </button>
  );
}


class ExternalFeedEditable extends Component {
  _isMounted = false;

  state = {
    isCreating: false,

    isDeleting: false,

    resourceUrl: '',
  }

  componentDidMount() {
    this._isMounted = true;

    this.setState({
      resourceUrl: this.props.feed.resourceUrl || '',
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  createFeed = () => {
    this.setState({
      isCreating: true,
    });

    // Such props function should return an promise
    this.props.onCreate(this.state.resourceUrl).then((feed) => {
      notification(this.props.t('notifications.itemCreated'));
    })
      .finally(() => {
        if (!this._isMounted) return;

        this.setState({
          isCreating: false,
        });
      });
  }

  deleteFeed = () => {
    this.setState({
      isDeleting: true,
    });

    // Such props function should return an promise
    this.props.onDelete()
      .then(() => {
        notification(this.props.t('notifications.itemDeleted'));
      })
      .finally(() => {
        if (!this._isMounted) return;

        this.setState({
          isDeleting: false,
        });
      });
  }

  render() {
    const {
      feed,
      t,
    } = this.props;

    const {
      isCreating,

      isDeleting,

      resourceUrl,
    } = this.state;

    const isPersisted = !!feed.id;

    return (
      <div className="mb-4">

        <label>
          {t('externalFeed.resourceUrl.label')}
          {/* TODO help on adding resource url
          <i className="fas fa-question-circle" />
          */}
        </label>

        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              type="url"
              name="resourceUrl"
              value={resourceUrl}
              placeholder="https://..."
              disabled={isPersisted}
              onChange={this.handleChange}
            />
            <div className="input-group-append">
              {!isPersisted && (
                <button type="button" className="btn btn-outline-secondary" onClick={this.createFeed}
                  disabled={!resourceUrl}>
                  {isCreating ? <Spinner /> : <i className="fas fa-plus" />}
                </button>
              )}

              {isPersisted && (
                <ConfirmDeleteButton isDeleting={isDeleting} onConfirmDelete={this.deleteFeed} />
              )}
            </div>
          </div>

        </div>

        {feed.id && (
          <div className="row justify-content-around">
            <div className="col col-lg-8 col-xl-6">
              <ExternalFeed feed={feed} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ExternalFeedEditable.defaultProps = {

  feed: {},

  onCreate: () => Promise.resolve(),

  onDelete: () => Promise.resolve(),

};

export default withTranslation()(
  withRouter(
    connect(
      null,
      {
      }
    )(ExternalFeedEditable)
  )
);
