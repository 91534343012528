import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _uniqueId from 'lodash/uniqueId';

import styles from './ChildCheckable.module.css';
import Localize from '../../../../Common/LanguageSwitch/Localize';

const ChildCheckable = ({
  checked,
  item, // an object of DefaultService

  onChange,
}) => {
  const [checkboxId] = useState(_uniqueId('default-service-'));

  const handleChange = (e) => {
    onChange(item, e.target.checked);
  };

  return (
    <div className={`${styles.container}`}>
      <input
        id={checkboxId}
        className={styles.checkbox}
        type="checkbox"
        name="defaultService"
        value={item.id}
        checked={!!checked}
        onChange={handleChange}
      />

      <label htmlFor={checkboxId} className={styles.txt}>
        <Localize ch={item.chineseName} en={item.name} />
      </label>
    </div>
  );
};

ChildCheckable.propTypes = {
  checked: PropTypes.bool.isRequired,

  item: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

ChildCheckable.defaultProps = {};

export default ChildCheckable;
