import {
  ADMIN_INVOICES_DID_FETCH,
  ADMIN_INVOICE_DID_UPDATE,
  ADMIN_INVOICE_WILL_FETCH_AMOUNT,
  ADMIN_INVOICE_DID_FETCH_AMOUNT,
} from '../../constants/constants';

const initialState = {
  invoices: [],
  invoicePagination: {
    currentPage: 1,
    lastPage: 1,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_INVOICES_DID_FETCH:
      return {
        ...state,
        invoices: action.payload.invoices,
        invoicePagination: action.payload.invoicePagination,
      };

    case ADMIN_INVOICE_DID_UPDATE:
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === action.payload.invoice.id
            ? action.payload.invoice
            : invoice
        ),
      };

    case ADMIN_INVOICE_WILL_FETCH_AMOUNT:
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === action.payload.invoiceId
            ? { ...invoice, _isFetchingAmount: true } // add a local property
            : invoice
        ),
      };

    case ADMIN_INVOICE_DID_FETCH_AMOUNT:
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === action.payload.invoiceId
            ? {
                ...invoice,
                _isFetchingAmount: false, // update a local property
                totalAmount: action.payload.totalAmount, // add a property
              }
            : invoice
        ),
      };

    default:
      return {
        ...state,
      };
  }
};
