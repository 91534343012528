import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer, Shop, Staff } from 'src/models/entity';

import useAuthenticated from './useAuthenticated';

function useAccount() {
  const { i18n } = useTranslation();

  const { isAdmin, isCustomer, isShop, isStaff, user } = useAuthenticated();

  const [displayName, setDisplayName] = useState('');

  /**
   * URL of public profile
   */
  const [publicUrl, setPublicUrl] = useState('');

  /**
   * URL of private profile (a setting page)
   */
  const [privateUrl, setPrivateUrl] = useState('');

  const [adminPanelUrl, setAdminPanelUrl] = useState('');

  const [bookingDollarBalance, setBookingDollarBalance] = useState(0);

  useEffect(() => {
    let nextDisplayName = '';
    let nextPublicUrl = '';
    let nextPrivateUrl = '';

      setBookingDollarBalance(0);

      if (isCustomer) {
      const customerData = (user as Customer).user_type_data;
      nextDisplayName = customerData?.first_name || customerData?.last_name || '';
      nextPublicUrl = `/customer/${user.alias}`;
      nextPrivateUrl = '/customer-settings';

      setBookingDollarBalance(customerData?.customer_balance || 0);
    } else if (isStaff) {
      const staffData = (user as Staff).user_type_data;
      nextDisplayName =
        staffData?.nickname ||
        staffData?.first_name ||
        staffData?.last_name ||
        '';
      nextPublicUrl = `/staff/${user.id}`;
      nextPrivateUrl = '/staff-settings';
    } else if (isShop) {
      const shopData = (user as Shop).user_type_data;
      nextDisplayName = shopData
        ? (i18n.language === 'zh' ? shopData.chineseName : '') || shopData.name
        : '';
      nextPublicUrl = `/shop/${user.id}`;
      nextPrivateUrl = '/shop-settings';
    } else {
      nextDisplayName = user.email?.replace(/@.*/, '') || '';
    }

    setDisplayName(nextDisplayName);
    setPublicUrl(nextPublicUrl);
    setPrivateUrl(nextPrivateUrl);
  }, [isCustomer, isShop, isStaff, user, i18n.language]);

  useEffect(() => {
    if (isAdmin) {
      setAdminPanelUrl('/admin');
    } else {
      setAdminPanelUrl('');
    }
  }, [isAdmin]);

  return {
    displayName,

    bookingDollarBalance,

    publicUrl,

    privateUrl,

    adminPanelUrl,
  };
}

export default useAccount;
