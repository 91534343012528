import {GET_CARDS} from "../../constants/constants";


const initialState = {
	cards: []
};


export default (state = initialState, action) =>  {
	switch (action.type) {
		case GET_CARDS:
			return {
				...state,
				cards: action.payload
			};
		default:
			return {
				...state
			}

	}
}