import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import TabNavigation from '../../../Common/Navigation/TabNavigation/TabNavigation';

const ShopBasicInfoNav = () => {
  const { t, i18n } = useTranslation();

  const anchors = useMemo(() => {
    return [
      {
        to: '/shop-settings/general/main',
        text: t('shopPrivate.tabs.general'),
      },
      {
        to: '/shop-settings/general/contacts',
        text: t('shopPrivate.tabs.contacts'),
      },
      {
        to: '/shop-settings/general/media',
        text: t('shopPrivate.tabs.media'),
      },
      {
        to: '/shop-settings/general/schedule',
        text: t('shopPrivate.tabs.schedule'),
      },
      {
        to: '/shop-settings/general/service-types',
        text: t('shopPrivate.tabs.serviceTypes'),
      },
      {
        to: '/shop-settings/general/payments',
        text: t('shopPrivate.tabs.payments'),
      },
      // { to: '/shop-settings/general/add-card', text: 'Add Card'},
      // { to: '/shop-settings/general/recommended', text: t('shopPrivate.tabs.recommended') },
    ];
  }, [i18n.language]);

  return (
    <TabNavigation
      anchors={anchors}
      mobileLabel={
        <>
          {t('shopPrivate.tabs.menu')}
          <ArrowDropDownIcon />
        </>
      }
    />
  );
};

export default ShopBasicInfoNav;
