import defaultAvatar from '../../../dist/img/default-avatar.png';

import AspectImage from 'src/Components/Common/AspectImage/AspectImage';

interface Props {
  src?: string;
}

/**
 * For displaying staff avatar image.
 */
const StaffAvatar = ({ src }: Props) => {
  return (
    <AspectImage className="rounded-circle">
      {src ? (
        <img src={src} alt="staff avatar" />
      ) : (
        <img src={defaultAvatar} alt="staff avatar" />
      )}
    </AspectImage>
  );
};

export default StaffAvatar;
