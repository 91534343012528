import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@material-ui/core';

import useAccount from '../../../../../hooks/auth/useAccount';
import useAuthenticated from '../../../../../hooks/auth/useAuthenticated';

import { logout } from '../../../../../Redux/actions/authActions/signInActions';

import UserAvatar from '../../../../Common/UserAvatar/UserAvatar';
import { AppButton, AppButtonLink } from '../../../../Common/Button';
import BookingDollarBalance from 'src/Components/Common/Booking/BookingDollarBalance';
import LanguageSwitch from '../../../../Common/LanguageSwitch/LanguageSwitch';

function UserBadge() {
  const { user } = useAuthenticated();

  const { displayName } = useAccount();

  return (
    <>
      <span className="app-ico-btn">
        <UserAvatar user={user} />
      </span>
      <span className="ml-1 user-profile-name">{displayName}</span>
    </>
  );
}

const UserToolbar = ({
  // from parent:
  onClose,

  // redux action:
  logout,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { bookingDollarBalance, publicUrl, privateUrl, adminPanelUrl } =
    useAccount();

  const fullLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <ClickAwayListener touchEvent={false} onClickAway={onClose}>
      <div>
        <div className="d-lg-none mb-3">
          <div className="d-flex justify-content-between">
            {publicUrl ? (
              <Link
                className="d-flex align-items-center"
                to={publicUrl}
                onClick={(e) => onClose()}
              >
                <UserBadge />
              </Link>
            ) : (
              <span className="d-flex align-items-center">
                <UserBadge />
              </span>
            )}

            {bookingDollarBalance > 0 && (
              <Link className="d-flex" to="/customer-settings/dollars/products">
                <BookingDollarBalance
                  balance={bookingDollarBalance}
                  withLabel
                  size="small"
                />
              </Link>
            )}
          </div>
        </div>

        <div className="mb-3 d-flex align-items-center">
          <LanguageSwitch className="d-lg-none mx-1" />

          {privateUrl && (
            <div className="flex-grow-1">
              <AppButtonLink
                size="block"
                theme="muted"
                to={privateUrl}
                onClick={(e) => onClose()}
              >
                <i className="fas fa-cog mr-1" />
                {t('account.setting')}
              </AppButtonLink>
            </div>
          )}
        </div>

        {adminPanelUrl && (
          <AppButtonLink
            className="mb-3"
            size="block"
            theme="muted"
            to={adminPanelUrl}
            onClick={(e) => onClose()}
          >
            <i className="fas fa-warehouse mr-1" />
            Admin Panel
          </AppButtonLink>
        )}

        <AppButton size="block" theme="muted" onClick={fullLogout}>
          {t('account.action.signOut')}
        </AppButton>
      </div>
    </ClickAwayListener>
  );
};

export default connect(null, {
  logout,
})(UserToolbar);
