import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

/**
 * This component helps display plain text with line-break '\n',
 * such that replacement to `<br/>` is not necessary.
 *
 * It adopts these css to achieve such purpose:
 * ```
 * word-break: break-word;
 * white-space: pre-wrap;
 *  ```
 */
function LineBreakable({ children, className = '' }: Props) {
  return <div className={`${className} app-line-breakable`}>{children}</div>;
};

export default LineBreakable;
