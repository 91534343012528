import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Localize from '../../../Common/LanguageSwitch/Localize';
import OverallRatingRow from '../../../Common/RatingStars/OverallRatingRow';
import RatingRow from '../../../Common/RatingStars/RatingRow';
import { Ellipsize, LineBreakable } from '../../../Common/Text';

import CustomerServiceButton from './CustomerServiceButton';
import ScheduleDay from './ScheduleDay';


class LeftSideBar extends Component {
  state = {
    isPaymentMethodExpanded: false,
    isWorkingScheduleExpanded: false,
  };

  togglePaymentMethod = () => {
    this.setState(prevState => ({
      isPaymentMethodExpanded: !prevState.isPaymentMethodExpanded,
    }));
  };

  toggleWorkingSchedule = () => {
    this.setState(prevState => ({
      isWorkingScheduleExpanded: !prevState.isWorkingScheduleExpanded,
    }));
  };

  render() {
    const {
      shop,
      aboutFull,
      showMore,
      t,
    } = this.props;
    const {
      isPaymentMethodExpanded,
      isWorkingScheduleExpanded,
    } = this.state;

    const {
      payment_methods,
      schedule,
    } = shop;

    const {
      address,
      about,
      average_rating,
      // email,
      // phone,
      rating,
      // website,
    } = shop.user_type_data;

    return (
      <>
        <div className="border rounded mt-2 mt-lg-0">
          <div className="sidebar-group">
            <div className="sidebar-title sidebar-title--full">
              <h2>{address.string_address}</h2>
              <span className="sidebar-title__ico">
                <i className="fas fa-map-marker" />
              </span>
            </div>
          </div>

          {/* Phone number */}
          {/*
          <div className="sidebar-group" style={{ display: 'none' }}>
            <div className="sidebar-title">
              <h2>
                <a href={`tel:${phone}`} style={{ color: 'black' }}>
                  {phone}
                </a>
              </h2>
              <span className="sidebar-title__ico">
                <i className="fas fa-phone-alt" />
              </span>
            </div>
          </div>
*/}

          {/* Email */}
          {/*
          <div className="sidebar-group d-none d-lg-block">
            <div className="sidebar-title">
              <h2>{email}</h2>
              <span className="sidebar-title__ico">
                <i className="fas fa-envelope" />
              </span>
            </div>
          </div>
*/}

          {/* Website and hyperlink */}
          {/*
           {!!website && (
            <div className="sidebar-group">
              <div className="sidebar-title">
                <h2>
                  <a href={website} target="_blank" style={{ color: 'black' }}>
                    {website}
                  </a>
                </h2>
                <span className="sidebar-title__ico">
                  <i className="fas fa-external-link-square-alt" />
                </span>
              </div>
            </div>
          )}
*/}

          {/* Payment Methods */}
          <div className="sidebar-group">
            <div className="sidebar-title" onClick={this.togglePaymentMethod} style={{ cursor: 'pointer' }}>
              <h2>{t('shopPublic.overview.leftBar.payMets')}</h2>
              <span className="sidebar-title__link">
                {t('shopPublic.product.details')}
                <span className="sidebar-title__ico ml-1">
                  <i className="fas fa-angle-double-down" />
                </span>
              </span>
            </div>
            <div className={`sidebar-content ${isPaymentMethodExpanded ? '' : 'd-none d-lg-block'}`}>
              {payment_methods && payment_methods.map((method) => (
                <div key={method.id} style={{ fontSize: '0.75em' }}>
                  <Localize ch={method.chineseName} en={method.name} />
                </div>
              ))}
            </div>
          </div>

          {/* About Shop */}
          <div className="sidebar-group d-none d-lg-block">
            <div className="sidebar-title">
              <h2>{t('shopPublic.overview.leftBar.aboutShop')}</h2>
              <span className="sidebar-title__ico">
                <i className="fas fa-file" />
              </span>
            </div>
            <div className="sidebar-content">
              <LineBreakable className="about-shop">
                {!about ? t('shopPublic.overview.nothingToShow') : aboutFull ? about : <Ellipsize text={about} maxlength={50} />}

                {about &&
                  about.length > 50 && (
                  <a href="" onClick={showMore} className="more-link">
                    {aboutFull ? t('shopPublic.overview.less') : t('shopPublic.overview.more')}
                  </a>
                )}
              </LineBreakable>
            </div>
          </div>

          {/* Working Hours */}
          <div className="sidebar-group">
            <div className="sidebar-title" onClick={this.toggleWorkingSchedule} style={{ cursor: 'pointer' }}>
              <h2>{t('shopPublic.overview.leftBar.schedule')}</h2>
              <span className="sidebar-title__link">
                {t('shopPublic.product.details')}
                <span className="sidebar-title__ico ml-1">
                  <i className="fas fa-suitcase" />
                </span>
              </span>
            </div>
            <div className={`sidebar-content ${isWorkingScheduleExpanded ? '' : 'd-none d-lg-block'}`}>
              {schedule.map(day => (
                <ScheduleDay
                  key={day.weekday}
                  name={day.name}
                  time_from={day.pivot.time_from}
                  time_to={day.pivot.time_to}
                  working={day.pivot.working}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Customer's ratings */}
        <div className="row align-items-center no-gutters">
          <div className="col-6 col-sm-7 col-lg-12">
            <div className="sidebar-group pt-1">
              <p className="theme-subtitle d-none d-lg-block">
                {t('shopPublic.overview.leftBar.avRate')}
              </p>

              <OverallRatingRow rating={average_rating} />

              <RatingRow
                label={t('shopPublic.overview.leftBar.environment')}
                rating={rating.environment}
              />

              <RatingRow
                label={t('shopPublic.overview.leftBar.skill')}
                rating={rating.skill}
              />

              <RatingRow
                label={t('shopPublic.overview.leftBar.attitude')}
                rating={rating.attitude}
              />

              <RatingRow
                label={t('shopPublic.overview.leftBar.idea')}
                rating={rating.idea}
              />
            </div>
          </div>

          <div className="col-6 col-sm-5 col-lg-12 mt-lg-4">
            <CustomerServiceButton />
          </div>
        </div>
      </>
    );
  }
}

LeftSideBar.defaultProps = {
  shop: { user_type_data: {} },
  aboutFull: '',
};

export default withTranslation()(LeftSideBar);
