import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import moment from 'moment';


import { getShopBookingsForCalendar, importCalendar } from '../../../../Redux/actions/shopActions/shopBookings';
import { fetchShopStaff } from '../../../../Redux/actions/shopActions/shopStaffActions';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import Spinner from '../../../Common/Spinner/Spinner';

import CalendarMonthItem from './CalendarMonthItem';
import CalendarWeekItem from './CalendarWeekItem';
import CalendarDayItem from './CalendarDayItem';


class Calendar extends Component {

  state = {
    loading: false,
    dateContext: moment(),
    eventId: '',
    selectedStaff: '',
    selectedStaffName: '',
    toggleMobileSizeEvent: false,
    // csvData: [],
    view: '',
    bookings: [],
  };

  componentDidMount() {
    const storagedView = sessionStorage.getItem('view');

    // moment.locale(this.props.i18n.language === 'en' ? 'en' : 'zh-hk');
    this.setState(
      {
        loading: true,
        view: storagedView ? storagedView : 'month',
      },
      () => {
        /*this.props.importCalendar().then(res => {
          this.setState({
            csvData: res.res.data,
          });
        });*/
        this.props.fetchShopStaff({ id: this.props.auth.user.id });
        this.props.getShopBookingsForCalendar({
            filter: { month: { month: this.state.dateContext.month() + 1, year: this.state.dateContext.year() } },
          })
          .then(res => {
            this.setState({ bookings: res.data.data });
            return res;
          })
          .then(() => this.setState({ loading: false }));
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { view, dateContext } = this.state;
    if (this.state.view !== prevState.view && !this.state.loading) {
      this.setState({ loading: true });
      if (view === 'week') {
        this.props.getShopBookingsForCalendar({
            filter: { week: { week: dateContext.week(), year: dateContext.year() } },
          })
          .then(res => {
            this.setState({ bookings: res.data.data, loading: false });
          });
      } else if (view === 'day') {
        this.props.getShopBookingsForCalendar({
            filter: { day: { day: dateContext.dayOfYear(), year: dateContext.year() } },
          })
          .then(res => {
            this.setState({ bookings: res.data.data, loading: false });
          });
      } else {
        this.props.getShopBookingsForCalendar({
            filter: { month: { month: dateContext.month() + 1, year: dateContext.year() } },
          })
          .then(res => {
            this.setState({ bookings: res.data.data, loading: false });
          });
      }
    }
  }

  toggleEventWindow = id => () => {
    if (id !== this.state.eventId) {
      this.setState({
        eventId: id,
      });
    }
  };

  handleChange = e => {
    this.setState({
      selectedStaff: e.target.value,
      selectedStaffName: e.target.options[e.target.selectedIndex].text,
    });
  };

  closeEventWindow = () => {
    if (this.state.eventId !== null)
      this.setState({
        eventId: null,
      });
    // this.props.getShopBookingsForCalendar({ staff_id: this.state.selectedStaff });
  };

  setMonth = ({ target }) => {
    const monthNum = moment.monthsShort().indexOf(target.name);
    const dateContext = moment(this.state.dateContext).set('month', monthNum);
    this.setState({
      dateContext: dateContext,
    });
  };

  prevDateContext = () => {
    const { view } = this.state;
    const dateContext = moment(this.state.dateContext).subtract(1, this.state.view);
    this.setState({
      dateContext: dateContext,
      loading: true,
    });
    if (view === 'week') {
      // this.props.getShopBookingsForCalendar({
      //   staff_id: this.state.selectedStaff,
      //   filter: { week: { week: dateContext.week(), year: dateContext.year() } },
      // });
      this.props.getShopBookingsForCalendar({
          filter: { week: { week: dateContext.week(), year: dateContext.year() } },
        })
        .then(res => {
          this.setState({ bookings: res.data.data, loading: false });
        });
    } else if (view === 'day') {
      // this.props.getShopBookingsForCalendar({
      //   staff_id: this.state.selectedStaff,
      //   filter: { day: { day: dateContext.dayOfYear(), year: dateContext.year() } },
      // });
      this.props.getShopBookingsForCalendar({
          filter: { day: { day: dateContext.dayOfYear(), year: dateContext.year() } },
        })
        .then(res => {
          this.setState({ bookings: res.data.data, loading: false });
        });
    } else {
      // this.props.getShopBookingsForCalendar({
      //   staff_id: this.state.selectedStaff,
      //   filter: { month: { month: dateContext.month() + 1, year: dateContext.year() } },
      // });
      this.props.getShopBookingsForCalendar({
          filter: { month: { month: dateContext.month() + 1, year: dateContext.year() } },
        })
        .then(res => {
          this.setState({ bookings: res.data.data, loading: false });
        });
    }
  };

  nextDateContext = () => {
    const { view } = this.state;
    const dateContext = moment(this.state.dateContext).add(1, view);
    this.setState({
      dateContext: dateContext,
      loading: true,
    });
    if (view === 'week') {
      // this.props.getShopBookingsForCalendar({
      //   staff_id: this.state.selectedStaff,
      //   filter: { week: { week: dateContext.week(), year: dateContext.year() } },
      // });

      this.props.getShopBookingsForCalendar({
          filter: { week: { week: dateContext.week(), year: dateContext.year() } },
        })
        .then(res => {
          this.setState({ bookings: res.data.data, loading: false });
        });
    } else if (view === 'day') {
      // this.props.getShopBookingsForCalendar({
      //   staff_id: this.state.selectedStaff,
      //   filter: { day: { day: dateContext.dayOfYear(), year: dateContext.year() } },
      // });
      this.props.getShopBookingsForCalendar({
          filter: { day: { day: dateContext.dayOfYear(), year: dateContext.year() } },
        })
        .then(res => {
          this.setState({ bookings: res.data.data, loading: false });
        });
    } else {
      // this.props.getShopBookingsForCalendar({
      //   staff_id: this.state.selectedStaff,
      //   filter: { month: { month: dateContext.month() + 1, year: dateContext.year() } },
      // });
      this.props.getShopBookingsForCalendar({
          filter: { month: { month: dateContext.month() + 1, year: dateContext.year() } },
        })
        .then(res => {
          this.setState({ bookings: res.data.data, loading: false });
        });
    }
  };

  firstDayOfMonth = () =>
    moment(this.state.dateContext)
      .startOf('month')
      .format('d');

  resize = () => {
    this.setState({ toggleMobileSizeEvent: window.innerWidth <= 991 });
  };

  handleChangeView = ({ target }) => {
    this.setState({ [target.name]: target.value });
    sessionStorage.setItem('view', target.value);
  };

  getWeekDayNum = i => {
    const { dateContext } = this.state;
    const prevMonth = moment(dateContext).subtract(1, 'month');
    const dateNum = Number(dateContext.format('D'));
    const weekDayNum = dateContext.day();

    if (i === weekDayNum) {
      return dateNum;
    } else if (i < weekDayNum) {
      let date = dateNum - (weekDayNum - i);
      if (date > dateContext.daysInMonth()) {
        date = date - dateContext.daysInMonth();
      } else if (date <= 0) {
        return prevMonth.daysInMonth() + date;
      }
      return date;
    } else if (i > weekDayNum) {
      let date = dateNum - (weekDayNum - i);
      if (date > dateContext.daysInMonth()) {
        date = date - dateContext.daysInMonth();
      }
      return date;
    }
  };

  numTimeToAmPm = time => {
    return time < 12
      ? time === 0
        ? '12am'
        : time < 10
          ? `0${time}am`
          : `${time}am`
      : time - 12 === 0
        ? '12pm'
        : time - 12 < 10
          ? `0${time - 12}pm`
          : `${time - 12}pm`;
  };

  convertTimeToMinutes = time => {
    const hours = Number(time.slice(0, time.indexOf(':')));
    const minutes = Number(time.slice(time.indexOf(':') + 1));
    return hours * 60 + minutes;
  };

  convertTimeToHours = time => {
    const hours = Math.trunc(time / 60).toString();
    const minutes = (time % 60).toString();
    const hoursZerofied = hours.length === 1 ? `0${hours}` : hours;
    const minutesZerofied = minutes.length === 1 ? `0${minutes}` : minutes;
    return `${hoursZerofied}:${minutesZerofied}`;
  };

  render() {
    const {
      shopStaffDataList,
      i18n,
      t,
    } = this.props;

    const currentLanguage = i18n.language;

    const routes = [{ title: t('shopPrivate.shopLeftMenu.calendar'), path: window.location.pathname }];
    const { eventId, view, dateContext, toggleMobileSizeEvent, selectedStaff } = this.state;
    let blanks = [];

    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(
        <td className="number-td" key={i * 80}>
          <div className="calendar-inner" />
        </td>
      );
    }

    const dataFromFilter = this.state.bookings.filter(elem => {
      if (this.state.selectedStaff === '') {
        return elem;
      } else {
        return elem.staff_id === +this.state.selectedStaff;
      }
    });

    let daysInMonth = [];
    for (let days = 1; days <= dateContext.daysInMonth(); days++) {
      daysInMonth.push(
        <CalendarMonthItem
          key={days}
          days={days}
          eventId={eventId}
          toggleMobileSizeEvent={toggleMobileSizeEvent}
          bookings={dataFromFilter}
          dateContext={dateContext}
          toggleEventWindow={this.toggleEventWindow}
          closeWindow={this.closeEventWindow}
          view={view}
          getShopBookingsForCalendar={this.props.getShopBookingsForCalendar}
          staffList={shopStaffDataList}
          selectedStaff={selectedStaff}
        />
      );
    }

    const totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];
    if (view === 'month') {
      totalSlots.forEach((row, i) => {
        if (i % 7 !== 0) {
          cells.push(row);
        } else {
          rows.push(cells.slice());
          cells = [];
          cells.push(row);
        }
        if (i === totalSlots.length - 1) {
          rows.push(cells.slice());
        }
      });
    }

    if (view === 'week') {
      for (let i = 0; i < 200; i++) {
        let time = rows.length;

        const amPmTime = this.numTimeToAmPm(time);
        if (i % 8 !== 0) {
          cells.push(
            <CalendarWeekItem
              key={i}
              time={time - 1}
              bookings={dataFromFilter}
              date={this.getWeekDayNum(cells.length - 1)}
              month={dateContext.month() + 1}
              eventId={eventId}
              toggleEventWindow={this.toggleEventWindow}
              closeWindow={this.closeEventWindow}
              toggleMobileSizeEvent={toggleMobileSizeEvent}
              getShopBookingsForCalendar={this.props.getShopBookingsForCalendar}
              convertTimeToMinutes={this.convertTimeToMinutes}
              convertTimeToHours={this.convertTimeToHours}
              dateContext={dateContext}
              view={view}
              staffList={shopStaffDataList}
              selectedStaff={selectedStaff}
            />
          );
        } else {
          rows.push(cells.slice());
          cells = [];
          cells.push(
            <td key={i} className="number-td">
              <div className="calendar-inner calendar-inner--week">
                <span className="calendar-time">{amPmTime}</span>
              </div>
            </td>
          );
        }
      }
    }

    if (view === 'day') {
      for (let i = 0; i < 75; i++) {
        let time = rows.length;

        const amPmTime = this.numTimeToAmPm(time);
        if (i % 3 !== 0) {
          cells.push(
            <td className="day-table" key={i}>
              <table>
                <tbody>
                  <tr>
                    <CalendarDayItem
                      // key={i}
                      time={time - 1}
                      bookings={dataFromFilter}
                      date={Number(dateContext.format('D'))}
                      note={i % 2 !== 0 ? (time % 2 !== 0 ? 'time' : 'note') : time % 2 !== 0 ? 'note' : 'time'}
                      eventId={eventId}
                      toggleEventWindow={this.toggleEventWindow}
                      closeWindow={this.closeEventWindow}
                      toggleMobileSizeEvent={toggleMobileSizeEvent}
                      getShopBookingsForCalendar={this.props.getShopBookingsForCalendar}
                      convertTimeToMinutes={this.convertTimeToMinutes}
                      convertTimeToHours={this.convertTimeToHours}
                      view={view}
                      dateContext={dateContext}
                      staffList={shopStaffDataList}
                      selectedStaff={selectedStaff}
                    />
                  </tr>
                </tbody>
              </table>
            </td>
          );
        } else {
          rows.push(cells.slice());
          cells = [];
          cells.push(
            <td key={i} className="number-td number-td--fixed">
              <div className="calendar-inner calendar-inner--day">
                <span className="calendar-time">{amPmTime}</span>
              </div>
            </td>
          );
        }
      }
    }

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-calendar-alt" aria-hidden="true" />
          {t('shopPrivate.shopLeftMenu.calendar')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">
          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.shopLeftMenu.calendar')}</h2>
          </div>

          <div className="main-container__content">
            {this.state.loading ? (
              <Spinner display="block" />
            ) : (
              <div className="history-calendar">
                <div className={`calendar-head ${view !== 'month' && 'calendar-head--type2'}`}>
                  <div className="month-select">
                    <button className="month-select__btn month-select__btn--left" onClick={this.prevDateContext}>
                      <i className="fas fa-angle-left" />
                    </button>
                    <button className="month-select__btn month-select__btn--right" onClick={this.nextDateContext}>
                      <i className="fas fa-angle-right" />
                    </button>
                    <div className="month-select__box">
                      <div className="month-select__item">
                        {view === 'month' && dateContext.locale(currentLanguage === 'en' ? 'en' : 'zh-hk').format('MMMM YYYY')}
                        {view === 'week' &&
                          `${dateContext.locale(currentLanguage === 'en' ? 'en' : 'zh-hk').format('MMM')} ${this.getWeekDayNum(
                            0
                          )} - ${this.getWeekDayNum(6)}, ${dateContext
                            .locale(currentLanguage === 'en' ? 'en' : 'zh-hk')
                            .format('YYYY')}`}
                        {view === 'day' && dateContext.locale(currentLanguage === 'en' ? 'en' : 'zh-hk').format('MMM D, YYYY')}
                      </div>
                    </div>
                  </div>

                  <div className="table-select">
                    <div className="select-group">
                      <p>{t('action.selectOneFromEnumeration')}</p>
                      <div className="cust-select-box">
                        <select
                          type="text"
                          className="cust-select-field"
                          name="selectedStaff"
                          onClick={this.handleChange}
                        >
                          <option className="cust-select__option" value="">
                            {t('shopPrivate.calendar.allStaff')}
                          </option>
                          {shopStaffDataList && shopStaffDataList.map((staffData, index) => (
                              <option
                                key={+index}
                                className="cust-select__option"
                                value={staffData.user_id}
                                selected={+staffData.user_id === +selectedStaff}
                              >
                                {staffData.nickname}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="select-group">
                      <p>{t('action.view')}</p>
                      <div className="cust-select-box cust-select-box--less-index">
                        <select
                          type="text"
                          name="view"
                          className="cust-select-field"
                          onChange={this.handleChangeView}
                          value={view ? view : 'month'}
                        >
                          <option className="cust-select__option" value="month">
                            {t('shopPrivate.calendar.month')}
                          </option>
                          <option className="cust-select__option" value="week">
                            {t('shopPrivate.calendar.week')}
                          </option>
                          <option className="cust-select__option" value="day">
                            {t('shopPrivate.calendar.day')}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="calendar-head__hints">
                    <p>
                      <button className="btn" style={{ backgroundColor: '#8dc63f' }} />{' '}
                      {t('shopPrivate.calendar.createdByShop')}
                    </p>
                    <p>
                      <button className="btn" style={{ backgroundColor: 'yellow' }} />{' '}
                      {t('shopPrivate.calendar.otherByShop')}
                    </p>
                    <p>
                      <button className="btn" style={{ backgroundColor: '#1b5bed' }} />{' '}
                      {t('shopPrivate.calendar.createdByCust')}
                    </p>
                    <p>
                      <button className="btn" style={{ backgroundColor: '#ec008c' }} />{' '}
                      {t('shopPrivate.calendar.createdByStaff')}
                    </p>
                  </div>
                </div>

                {view === 'month' && currentLanguage && (
                  <div className="month">
                    {moment.locale(currentLanguage === 'en' ? 'en' : 'zh-hk') &&
                      moment.monthsShort().map(month => (
                        <button
                          key={month}
                          className={`month__item ${dateContext.format('MMM') === month ? 'active' : ''}`}
                          onClick={this.setMonth}
                          name={month}
                        >
                          {month}
                        </button>
                      ))}
                  </div>
                )}

                <div className={`calendar-wrap ${view !== 'month' ? `calendar-wrap--${view}` : ''}`}>
                  <table className={`calendar-table ${view !== 'month' ? `calendar-table--${view}` : ''}`}>
                    <thead>
                      <tr>
                        {view === 'week' && (
                          <td>
                            <span className="head-title" />
                          </td>
                        )}
                        {view === 'week' &&
                          moment.weekdaysShort().map((day, i) => (
                            <td key={i}>
                              <span className="head-title">
                                {day} {this.getWeekDayNum(i)}
                              </span>
                            </td>
                          ))}
                        {view === 'month' &&
                          moment.weekdaysMin().map(day => (
                            <td key={day}>
                              <span className="head-title">{day}</span>
                            </td>
                          ))}
                        {view === 'day' && (
                          <>
                            <td className="number-td number-td--fixed" />
                            <td className="day-table">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="number-td number-td--day">
                                      <div className="calendar-inner calendar-inner--day">
                                        <span className="head-title">{dateContext.format('MMM D')}</span>
                                      </div>
                                    </td>
                                    {/* <td className="number-td number-td--day">
                                      <div className="calendar-inner calendar-inner--day" />
                                    </td> */}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td className="day-table">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="number-td number-td--day">
                                      <div className="calendar-inner calendar-inner--day" />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((d, i) => (
                        <tr key={i * 100}>{d}</tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* <div className="calendar-control">
                  <button className="calendar-control__item">Save</button>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    bookings: state.bookingsReducer.bookingForCalendar,
    shopStaffDataList: state.GetStuffInformationReducer.shopStaffDataList,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        getShopBookingsForCalendar,
        fetchShopStaff,
        importCalendar,
       }
    )(Calendar)
  )
);
