import {
  SITE_MOST_REVIEWED_SHOP_WILL_FETCH,
  SITE_MOST_REVIEWED_SHOP_DID_FETCH,
} from "../../constants/constants";


const initialState = {
  /**
   * Whether the client is fetching a list of most-reviewed-shop
   */
  isFetchingMostReviewedShop: false,

  /**
   * The last time when a list of most-reviewed-shop is fetched
   */
  mostReviewedShopLastFetchedAt: null,

  /**
   * a list of most-reviewed-shop
   */
  mostReviewedShops: [],
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SITE_MOST_REVIEWED_SHOP_WILL_FETCH:
      return {
        ...state,
        isFetchingMostReviewedShop: true,
      };

    case SITE_MOST_REVIEWED_SHOP_DID_FETCH:
      return {
        ...state,
        isFetchingMostReviewedShop: false,
        mostReviewedShopLastFetchedAt: new Date(),
        mostReviewedShops: action.payload,
      };

    default:
      break;
  }

  return state;
};
