import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';


import { BaseResponse } from 'src/models/api/response/BaseResponse';

import { collectValidationError } from 'src/utils/api/api-response-handlers';


type SuppressibleError = 'badNetwork' | '401' | '403' | '404' | '422';

interface PromptErrorMessageOptions {
  suppress?: SuppressibleError[];
}

function useErrorHandler() {
  const { t } = useTranslation();

  const collectErrorFromResponse = (response?: BaseResponse) => {
  if (response) {
    if (response.errors) {
      const errorMessage = collectValidationError(response.errors);
      if (errorMessage) {
        return errorMessage;
      }
    }

    if (response.message) {
      return response.message;
    }
  }

  return t('errors.epicFailure');
}

  const promptErrorMessageFromAxiosError = (
    e: AxiosError,
    options?: PromptErrorMessageOptions
  ) => {
    // TODO Upgrade axios and import CanceledError from 'axios
    // if (e instanceof CanceledError) {
    //   // Cancelled. Simply skip this error
    //   return;
    // }

    if (!e.response?.status) {
      if (!options?.suppress?.includes('badNetwork')) {
        simpleAlert(t('errors.badOrMissingNetwork'));
      }
    } else {
      switch (e.response.status) {
        case 401:
          if (!options?.suppress?.includes('401')) {
            simpleAlert(t('errors.unauthenticated'));
          }
          break;

        case 403:
          if (!options?.suppress?.includes('403')) {
            simpleAlert(t('errors.forbidden'));
          }
          break;

        case 404:
          if (!options?.suppress?.includes('404')) {
            const response = e.response.data as BaseResponse;

            if (response?.message) {
              simpleAlert(response.message);
            } else {
              simpleAlert(t('errors.notFound'));
            }
          }
          break;

        case 422:
          if (!options?.suppress?.includes('422')) {
            const response = e.response.data as BaseResponse;

            if (response) {
              simpleAlert(collectErrorFromResponse(response));
            }
          }
          break;

        default:
          const response: any = e.response.data;
          simpleAlert(collectErrorFromResponse(response));
          break;
      }
    }
  };

  const promptErrorMessageFromHttpStatusCode = (
    statusCode: number,
    options?: PromptErrorMessageOptions
  ) => {
    if (!statusCode) {
      if (!options?.suppress?.includes('badNetwork')) {
        simpleAlert(t('errors.badOrMissingNetwork'));
      }
    } else {
      switch (statusCode) {
        case 401:
          if (!options?.suppress?.includes('401')) {
            simpleAlert(t('errors.unauthenticated'));
          }
          break;

        case 403:
          if (!options?.suppress?.includes('403')) {
            simpleAlert(t('errors.forbidden'));
          }
          break;

        case 404:
          if (!options?.suppress?.includes('404')) {
            simpleAlert(t('errors.notFound'));
          }
          break;

        default:
          simpleAlert(t('errors.epicFailure'));
          break;
      }
    }
  };

  const simpleAlert = (message: string) => {
    alert(message);
  };

  return {
    promptErrorMessageFromAxiosError,
    promptErrorMessageFromHttpStatusCode,
  };
}

export default useErrorHandler;
