import { Booking } from 'src/models/entity';

import { BaseResponse } from 'src/models/api/response/BaseResponse';
import { GetResponse } from 'src/models/api/response/GetResponse';

import { apiGet, apiPost, apiPut, apiDelete } from '../api-utils';


export interface CheckTimeRequest {
  shopId: string;
  date: string;
  time: string;
}

export const checkTime = (request: CheckTimeRequest) =>
  apiGet<BaseResponse>('check-time', request);

// ========== ========== View booking ========== ==========

export const getBooking = (bookingId: string, fields?: string) =>
  apiGet<GetResponse<Booking>>(`booking/${bookingId}`, { fields }).then(
    (response) => response.data
  );

// ========== ========== To create booking for a customer ========== ==========

export interface CreateBookingRequest {
  shopId: string;
  serviceId: string;
  staffId?: string;
  date: string;
  time: string;
  promotionId?: string;

  /**
   * To be specified if the customer is a member of the system
   */
  customerId?: string;

  /**
   * To be specified if the customer is NOT a member of the system
   */
  customerPhone?: string;

  customerName?: string;

  /**
   * Remarks by service provider (shop)
   */
  providerRemarks?: string;
}

export const createBooking = (request: CreateBookingRequest) =>
  apiPost<GetResponse<Booking>>('booking-by-provider', request).then(
    (response) => response.data
  );

// ========== ========== Update booking ========== ==========

export interface UpdateBookingRequest {
  staffId?: string;
  date: string;
  time: string;
  providerRemarks?: string;
}

export const updateBooking = (bookingId: string, request: UpdateBookingRequest) =>
  apiPut<GetResponse<Booking>>(`booking/${bookingId}`, request).then(
    (response) => response.data
  );

// ========== ========== Confirm booking ========== ==========

export const confirmBooking = (bookingId: string) =>
  apiPut<GetResponse<Booking>>(`booking/${bookingId}/confirm`).then(
    (response) => response.data
  );

// ========== ========== Checkout booking ========== ==========

interface CheckoutBookingRequest {
  checkoutNumber: string;
  amount: string;
  staffId: string;
}

export const checkoutBooking = (bookingId: string, request: CheckoutBookingRequest) =>
  apiPut<BaseResponse>(`booking/${bookingId}/checkout`, request);

// ========== ========== Fast Checkout booking ========== ==========

interface FastCheckoutBookingRequest {
  checkoutToken: string;
  checkoutNumber: string;
  amount: string;
  staffId: string;
}

export const fastCheckoutBooking = (
  request: FastCheckoutBookingRequest
) => apiPost<BaseResponse>('fast-checkout', request);

// ========== ========== Cancel booking ========== ==========

interface CancelBookingRequest {
  providerRemarks: string;
}

export const cancelBooking = (
  bookingId: string,
  request: CancelBookingRequest
) =>
  apiPut<GetResponse<Booking>>(`booking/${bookingId}/cancel`, request).then(
    (response) => response.data
  );

// ========== ========== Mark as absent ========== ==========

export const markBookingAsAbsent = (bookingId: string) =>
  apiPut<BaseResponse>(`booking/${bookingId}/absent`);

// ========== ========== Delete booking ========== ==========

export const deleteBooking = (bookingId: string) =>
  apiDelete<BaseResponse>(`booking/${bookingId}`);
