import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import api from '../../../../api';

import { getAllModerationReviews } from '../../../../Redux/actions/features/reviewActions';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import { DataTableStatus, TableHeaderCell } from '../../../Common/DataTable';
import notification from '../../../Common/notification';
import Pagination from '../../../Common/Pagination';

import ModerationItem from './ModerationItem';


class ReviewModeration extends Component {
  state = {
    loading: false,
    currentPage: 1,
    lastPage: 1,
    reviews: [],
    activeReviewId: '',
  };

  componentDidMount() {
    this._apiGetAllModerationReviews(this.state.currentPage);
  }

  _apiGetAllModerationReviews = page => {
    this.setState({
      loading: true,
    });

    this.props.getAllModerationReviews({
        params: {
          current_page: page,
        },
      })
      .then(res => {
        if (res.res.data.message === 'success') {
          this.setState({
            currentPage: res.res.data.data.current_page,
            lastPage: res.res.data.data.last_page,
            reviews: res.res.data.data.data,
          });
        } else {
          notification(this.props.t('notifications.somethingWentWrong'));
        }
      })
      .catch(error => {
        console.error(error);
        notification(this.props.t('notifications.userIsNotShop'));
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleEnableEditReply = reviewId => {
    const activeReviewId = this.state.activeReviewId === reviewId ? null : reviewId;
    this.setState({
      activeReviewId,
    });
  };

  handleReplyChange = reviewId => ({ target }) => {
    this.setState(prevState => ({
      reviews: prevState.reviews.map(review => {
        if (review.id === reviewId) {
          review.reply = target.value;
        }
        return review;
      }),
    }));
  };

  handlePageChange = props => {
    this._apiGetAllModerationReviews(props.selected + 1);
  };

  submitReply = (reviewId, reply) => {
    api.shopReviews.leaveReply({
        id: reviewId,
        reply,
      })
      .then(() => {
        notification(this.props.t('notifications.changesSaved'));
      });
    this.handleEnableEditReply(reviewId);
  };

  render() {
    const {
      auth,
      t,
    } = this.props;
    const routes = [{ title: t('shopPrivate.reviewModer.reviewModer'), path: window.location.pathname }];
    const { currentPage, lastPage, reviews, loading } = this.state;

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-star" aria-hidden="true" />
          {t('shopPrivate.reviewModer.reviewModer')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">
          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.reviewModer.reviewModer')}</h2>
          </div>
          <div className="main-container__content">
            <div className="tableWrap">
              <table className="adminTable">
                <colgroup>
                  <col style={{ minWidth: 120, width: '10%' }} />
                  <col style={{ minWidth: 120, width: '10%' }} />
                  <col style={{ minWidth: 100, width: '12%' }} />
                  <col style={{ minWidth: 100, width: 100 }} />
                  <col style={{ minWidth: 200 }} />
                  <col style={{ minWidth: 200 }} />
                  <col style={{ width: 150 }} />
                </colgroup>
                <thead>
                  <tr>
                    <TableHeaderCell>{t('shopPrivate.reviewModer.date')}</TableHeaderCell>
                    <TableHeaderCell>{t('datetime.time.label')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.reviewModer.customer')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.reviewModer.rate')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.reviewModer.customerReview')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.reviewModer.yourReply')}</TableHeaderCell>
                    <TableHeaderCell>{t('shopPrivate.reviewModer.action')}</TableHeaderCell>
                  </tr>
                </thead>
                {!loading && (
                  <tbody>
                    {reviews.map((review, index) => (
                      <ModerationItem
                        key={index}
                        blockNum={index + 1}
                        review={review}
                        shopId={auth.user.id}
                        isEditingReply={this.state.activeReviewId === review.id}
                        onEnableEditReply={this.handleEnableEditReply}
                        onReplyChange={this.handleReplyChange}
                        onSubmitReply={this.submitReply}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <DataTableStatus
                isLoading={loading}
                itemCount={reviews?.length}
              />
            </div>
          </div>

          {lastPage > 1 && (
            <Pagination currentPage={currentPage} lastPage={lastPage} pageChange={this.handlePageChange} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    { getAllModerationReviews }
  )(ReviewModeration)
);
