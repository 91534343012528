import {
    CHOOSE_CHAT,
    CLOSE_CHAT,
    GET_ALL_CHATS,
    GET_NOTIFICATIONS,
    RECIEVE_MESSAGE,
    SEND_MESSAGE
} from "../../constants/constants";


const initialState = {
    allShopUsersExceptThis: [],
    commonGroup: {},
    messages: [],
    closeChat:[],
    notifications: []
};


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CHATS:
            return {
                ...state,
                ...action.payload
            };
        case CHOOSE_CHAT:
            return {
                ...state,
                 messages: action.payload
            };
        case SEND_MESSAGE:
            return {
                ...state,
                ...action.payload
            };
        case RECIEVE_MESSAGE:
            return {
                ...state,
                ...action.payload
            };
        case CLOSE_CHAT:
            return {
                ...state,
                closeChat: action.payload
            };
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            };
        default:
            return {
                ...state
            }

    }
}