import React, { useEffect, useState } from 'react';

import ic_play from '../../../dist/img/ic_play.png';

import styles from './AspectImage.module.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
  aspectRatio?: string;
  withPlayIcon?: boolean;
}

/**
 * For displaying image with defined aspect ratio: 3:2 or 16:9.
 */
const AspectImage = ({
  className = '',
  children,
  aspectRatio = '1:1',
  withPlayIcon = false,
}: Props) => {

  const [rootStyles, setRootStyles] = useState({});

  useEffect(() => {
    const matches = aspectRatio.match(/^(\d+):(\d+)$/);
    if (matches) {
      if (matches[1] !== matches[2]) {
        const w = parseFloat(matches[1]);
        const h = parseFloat(matches[2]);
        const heightToWidthRatio = ((100 * h) / w).toFixed(2);
        setRootStyles({
          paddingBottom: `${heightToWidthRatio}%`,
        });
        return;
      }
    }

    setRootStyles({});
  }, [aspectRatio]);

  return (
    <div className={`${styles.root} ${className}`} style={rootStyles}>
      {children}

      {withPlayIcon && (
        <div
          className={`d-flex flex-column justify-content-center align-items-center ${styles.imgOverlay}`}
        >
          <div className={styles.imageBtnPlay}>
            <img src={ic_play} alt="Play" />
          </div>
        </div>
      )}
    </div>
  );
}

export default AspectImage;
