import { Booking } from 'src/models/entity';

import { GetResponse } from 'src/models/api/response/GetResponse';

import { apiPost, apiPut } from '../api-utils';


// ========== ========== Create booking ========== ==========

export interface CreateMyBookingRequest {
  shopId: string;
  date: string;
  time: string;
  serviceId: string;

  staffId?: string;

  /**
   * Remarks by customer
   */
  message?: string;

  promotionId?: string;
}

export const createMyBooking = (request: CreateMyBookingRequest) =>
  apiPost<GetResponse<Booking>>('me/booking', request).then(
    (response) => response.data
  );

// ========== ========== Update booking ========== ==========

export interface UpdateMyBookingRequest {
  /**
   * Remarks by customer
   */
  message?: string;
}

export const updateMyBooking = (bookingId: string, request: UpdateMyBookingRequest) =>
  apiPut<GetResponse<Booking>>(`me/booking/${bookingId}`, request).then(
    (response) => response.data
  );

// ========== ========== Cancel booking ========== ==========

interface CancelMyBookingRequest {
  message: string;
}

export const cancelMyBooking = (
  bookingId: string,
  request: CancelMyBookingRequest
) =>
  apiPut<GetResponse<Booking>>(`me/booking/${bookingId}/cancel`, request).then(
    (response) => response.data
  );
