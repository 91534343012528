import React, { useMemo } from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  hasIcon?: boolean;
  iconAtRight?: boolean;
  maxLength?: number;
  name?: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea = ({
  className = '',
  disabled = false,
  hasIcon = false,
  iconAtRight = false,
  maxLength,
  name,
  placeholder,
  required = false,
  value,
  onChange = () => {},
}: Props) => {
  const textareaClassName = useMemo(() => {
    return `${className} form-item form-item--area ${
      hasIcon
        ? iconAtRight
          ? 'form-item--ico-right'
          : 'form-item--ico-left'
        : ''
    }`;
  }, [className, hasIcon, iconAtRight]);

  return (
    <textarea
      className={textareaClassName}
      disabled={disabled}
      maxLength={maxLength}
      name={name}
      placeholder={(!disabled && placeholder) || ''}
      // required={required}
      value={value || ''}
      onChange={onChange}
    />
  );
};

export default Textarea;
