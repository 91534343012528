import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  GET_ALL_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  BUY_PRODUCT,
} from '../../constants/constants';
import api from '../../../api';

export const addProduct = data => dispatch =>
  api.products
    .addProduct(data)
    .then(res => {
      if (res.data.message === 'success') {
        dispatch(AddProduct(res.data.data));
        return res;
      } else {
        console.error(res.data.message);
      }
    })
    .catch(err => {
      console.error(err);
    });

export const getAllProducts = data => dispatch =>
  api.products
    .getAllProducts(data)
    .then(res => {
      if (res.data.message === 'success') {
        dispatch(GetAllProducts(res.data.data));
        return 'success';
      } else {
        console.error(res.data.message);
      }
    })
    .catch(err => {
      console.error(err);
    });

export const updateProduct = data => dispatch =>
  api.products
    .updateProduct(data)
    .then(res => {
      if (res.data.message === 'success') {
        dispatch(UpdateProduct(res.data.data));
        return res;
      } else {
        console.error(res.data.message);
      }
    })
    .catch(err => {
      console.error(err);
    });

export const deleteProduct = data => dispatch =>
  api.products
    .deleteProduct(data)
    .then(res => {
      if (res.data.message === 'success') {
        dispatch(DeleteProduct(res.data));
        return res;
      } else {
        console.error(res.data.message);
      }
    })
    .catch(err => {
      console.error(err);
    });

export const getProduct = data => dispatch =>
  api.products
    .getProduct(data)
    .then(res => {
      if (res.data.message === 'success') {
        dispatch(GetProduct(res.data.data));
        return res;
      } else {
        console.error(res.data.message);
      }
    })
    .catch(err => {
      console.error(err);
    });

export const buyProduct = data => dispatch =>
  api.products
    .buyProduct(data)
    .then(res => {
      if (res.data.message === 'success') {
        dispatch(BuyProduct(res.data.data));
        return res;
      } else {
        console.error(res.data.message);
      }
    })
    .catch(err => {
      console.error(err);
    });

export const DeleteProduct = data => ({
  type: DELETE_PRODUCT,
  payload: data,
});

export const UpdateProduct = data => ({
  type: UPDATE_PRODUCT,
  payload: data,
});

export const AddProduct = data => ({
  type: ADD_PRODUCT,
  payload: data,
});

export const GetAllProducts = data => ({
  type: GET_ALL_PRODUCTS,
  payload: data,
});

export const GetProduct = data => ({
  type: GET_PRODUCT,
  payload: data,
});

export const BuyProduct = data => ({
  type: BUY_PRODUCT,
  payload: data,
});
