import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './TabBar.module.css';
import { AppButton } from '../../Button';

export interface Anchor {
  text: string;
  to: string;
}

interface Props {
  anchors: Anchor[];
  className?: string;
}

const TabBar = ({
  // from parent:
  anchors,
  className = '',
}: Props) => {
  const [isNavMenuOpened, setNavMenuOpened] = useState(false);

  const isLong = useMemo(() => anchors?.length > 3, [anchors]);

  const toggleNavMenu = () => {
    setNavMenuOpened((isNavMenuOpened) => !isNavMenuOpened);
  };

  return (
    <div
      className={`${className} ${styles.root} ${isLong ? styles.isLong : ''} ${
        isNavMenuOpened ? styles.stateOpened : ''
      }`}
    >
      <AppButton
        className={`${isLong ? 'd-xl-none' : 'd-none'} ${styles.mobileHandle}`}
        elevated
        theme="primary"
        onClick={toggleNavMenu}
      >
        Tabs
        <span>
          <i className="fas fa-ellipsis-h mx-1" />
        </span>
      </AppButton>

      <div className={`${styles.tabs}`}>
        {anchors.map((anchor) => (
          <NavLink
            key={anchor.text}
            activeClassName={`${styles.active}`}
            className={styles.tab}
            to={anchor.to}
          >
            {anchor.text}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default TabBar;
