import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import { changeMediaData } from '../../../../Redux/actions/features/changeMedia';
import { shopData } from '../../../../Redux/actions/shopActions/shopData';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import Spinner from '../../../Common/Spinner/Spinner';

import GeneralPage from './GeneralPage';
import Media from './Media/Media';
import Payments from './Payments';
import ServiceTypes from './ServiceTypes';
import Contacts from './Contacts';
import Schedule from './Schedule/Schedule';
import Recommended from './Recommended/Recommended';
import AddCreditCard from './AddCard/AddCreditCard';
import ShopBasicInfoNav from './ShopBasicInfoNav';


class HeaderSettings extends Component {
  state = {
    baseUrl: '/shop-settings/general/',
    CVV: '',
    MMYY: '',
    isUploadModal: false,
    loading: false,
  };

  // uploadOther = ({ target }) => {
  //   let file = target.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     this.setState(
  //       {
  //         [target.name]: reader.result,
  //       },
  //       () => {
  //         this.sendOther(target.name);
  //       }
  //     );
  //   };
  //   reader.readAsDataURL(file);
  // };

  getOtherImage = file => {
    const { imageName } = this.state;
    this.setState(
      {
        [imageName]: file,
      },
      () => {
        this.sendOther(this.state.imageName);
      }
    );
  };

  toggleUploadModal = () => {
    this.setState(prevState => ({
      isUploadModal: !prevState.isUploadModal,
    }));
  };

  openUploadModal = ({ target }) => {
    this.toggleUploadModal();
    this.setState({
      imageName: [target.name],
    });
  };

  sendOther = name => {
    this.setState({ loading: true });
    this.props.changeMediaData({ photos: { [name]: this.state[name] } }).then(res => {
      if (res.res.data.message === 'success') {
        this.props.shopData({ id: this.props.auth.user.id });
      }
      this.setState({ loading: false });
    });
  };

  deleteOther = (index) => {
    this.props.changeMediaData({ photos: { [index]: null } }).then(res => {
      if (res.res.data.message === 'success') {
        this.props.shopData({ id: this.props.auth.user.id });
      }
    });
  };

  handleChangeMMYY = ({ target: { value } }) => {
    let regExp = /[0-9/]$/;
    if (this.state.MMYY === '') {
      this.setState({
        MMYY: value,
      });
    } else if (regExp.test(this.state.MMYY)) {
      this.setState({
        MMYY: value.substr(0, 5),
      });
    } else {
      this.setState({
        MMYY: '',
      });
    }
  };

  handleChangeCVV = ({ target: { value } }) => {
    let regExp = /[0-9]$/;
    if (this.state.CVV === '') {
      this.setState({
        CVV: value,
      });
    } else if (regExp.test(this.state.CVV)) {
      this.setState({
        CVV: value.substr(0, 3),
      });
    } else {
      this.setState({
        CVV: '',
      });
    }
  };

  render() {
    const { t } = this.props;
    const path = window.location.pathname;
    const lastI = path.lastIndexOf('/');
    const routes = [
      { title: t('shopPrivate.shopLeftMenu.settings'), path: `${path.slice(0, lastI)}/main` },
      {
        title: path.slice(lastI + 1),
        path: path,
      },
    ];
    const {
      baseUrl,
      loading,
    } = this.state;

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-cog" aria-hidden="true" />
          {t('shopPrivate.shopLeftMenu.settings')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">
          <ShopBasicInfoNav />

          {loading ? (
            <Spinner display="block" />
          ) : (
            <Switch>
              <Route
                exact
                path={baseUrl + 'main'}
                render={() => (
                  <GeneralPage
                    upload={this.props.upload}
                    avatar={this.props.avatar}
                    sendData={this.props.sendData}
                    avatarUploadStatus={this.props.avatarUploadStatus}
                    chineseName={this.props.chineseName}
                    name={this.props.name}
                    setAvatarStatus={this.props.setAvatarStatus}
                    address={this.props.address}
                    about={this.props.about}
                    handleClick={this.props.handleClick}
                    handleChange={this.props.handleChange}
                    enableBookingSystem={this.props.enableBookingSystem}
                    errorPhoto={this.props.errorPhoto}
                    businessRegistrationNumber={this.props.businessRegistrationNumber}
                    countStaffInShop_id={this.props.countStaffInShop_id}
                    prevAbout={this.props.prevAbout}
                    prevName={this.props.prevName}
                    prevChineseName={this.props.prevChineseName}
                    prevCountStaffInShop_id={this.props.prevCountStaffInShop_id}
                    prevBusinessRegistrationNumber={this.props.prevBusinessRegistrationNumber}
                    prevEnableBookingSystem={this.props.prevEnableBookingSystem}
                    isUploadModal={this.props.isUploadModal}
                    getImage={this.props.getImage}
                    toggleUploadModal={this.props.toggleUploadModal}
                  />
                )}
              />

              <Route
                path={baseUrl + 'contacts'}
                render={() => (
                  <Contacts
                    handleChange={this.props.handleChange}
                    website={this.props.website}
                    status={this.props.status}
                    message={this.props.message}
                    onClose={this.props.onClose}
                    code={this.props.code}
                    sendCode={this.props.sendCode}
                    errorEmail={this.props.errorEmail}
                    errorPhone={this.props.errorPhone}
                    sendContactsData={this.props.sendContactsData}
                    email={this.props.email}
                    modalStatus={this.props.modalStatus}
                    handleChangeAddress={this.props.handleChangeAddress}
                    handleSelectAddress={this.props.handleSelectAddress}
                    address={this.props.address}
                    sendContactsDataPhone={this.props.sendContactsDataPhone}
                    phone={this.props.phone}
                    sendData={this.props.sendData}
                    prevWebsite={this.props.prevWebsite}
                    prevAddress={this.props.prevAddress}
                    prevEmail={this.props.prevEmail}
                    prevPhone={this.props.prevPhone}
                    cities={this.props.cities}
                    districts={this.props.districts}
                    handleClickOnCity={this.props.handleClickOnCity}
                    handleClickOnDistrict={this.props.handleClickOnDistrict}
                    selectedCity={this.props.selectedCity}
                    selectedDistrict={this.props.selectedDistrict}
                    setCitiesDistricts={this.props.setCitiesDistricts}
                    adminName={this.props.adminName}
                    adminPhone={this.props.adminPhone}
                    prevAdminName={this.props.prevAdminName}
                    prevAdminPhone={this.props.prevAdminPhone}
                    prevSelectedCity={this.props.prevSelectedCity}
                    prevSelectedDistrict={this.props.prevSelectedDistrict}
                  />
                )}
              />
              <Route
                path={baseUrl + 'media'}
                render={() => (
                  <Media
                    deleteOther={this.deleteOther}
                    uploadOther={this.uploadOther}
                    isUploadModal={this.state.isUploadModal}
                    openUploadModal={this.openUploadModal}
                    toggleUploadModal={this.toggleUploadModal}
                    getOtherImage={this.getOtherImage}
                  />
                )}
              />

              <Route
                path={baseUrl + 'schedule'}
                render={() => (
                  <Schedule
                    ShopReducer={this.props.ShopReducer}
                    loading={this.props.loading}
                  />
                )}
              />

              <Route
                path={baseUrl + 'payments'}
                render={() => <Payments />}
              />

              <Route
                path={baseUrl + 'service-types'}
                render={() => <ServiceTypes />}
              />

              <Route
                path={baseUrl + 'add-card'}
                render={() => (
                  <AddCreditCard {...this.state} changeMMYY={this.handleChangeMMYY} changeCVV={this.handleChangeCVV} />
                )}
              />

              <Route
                path={baseUrl + 'recommended'}
                render={() => <Recommended />}
              />

              {/* Default / Fallback route */}
              <Redirect to={baseUrl + 'main'} />
            </Switch>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    defaultServices: state.ShopReducer.shopData.default_services,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      { changeMediaData, shopData }
    )(HeaderSettings)
  )
);
