import React from 'react';


import { timeToAmPm } from '../../../../Functions/datetimeHelpers';

import CalendarEventWindow from './CalendarEventWindow';


const CalendarWeekItem = ({
  time,
  dateContext,
  view,
  bookings,
  date,
  eventId,
  toggleMobileSizeEvent,
  toggleEventWindow,
  closeWindow,
  getShopBookingsForCalendar,
  convertTimeToMinutes,
  convertTimeToHours,
  staffList,
  selectedStaff,
}) => {
  return (
    <td className={`number-td ${date ? '' : 'empty'}`}>
      <div className="calendar-inner calendar-inner--week">
        {bookings &&
          bookings.map((item, i) => {
            if (
              Number(item.time.slice(0, 2)) === time &&
              Number(item.date.slice(item.date.lastIndexOf('-') + 1)) === date
              // Number(item.date.slice(item.date.lastIndexOf('-') - 2, item.date.lastIndexOf('-'))) === month
            ) {
              const time = timeToAmPm(item.time);
              const endTime = timeToAmPm(
                convertTimeToHours(convertTimeToMinutes(item.time) + convertTimeToMinutes(item.service.time))
              );

              return (
                <div
                  className={`calendar-event
                    ${
                      item.creator_type_id === 3 && item.type_booking === 'basic_booking' ? 'calendar-event--type2' : ''
                    }
                    ${
                      item.creator_type_id === 2 && item.type_booking === 'other_booking' ? 'calendar-event--type3' : ''
                    }
                    ${
                      item.creator_type_id === 3 && item.type_booking === 'other_booking' ? 'calendar-event--type4' : ''
                    }
                  `}
                  id={item.id}
                  onClick={toggleMobileSizeEvent ? toggleEventWindow(item.id) : null}
                  key={i}
                >
                  <p onClick={toggleEventWindow(item.id)}>
                    {time} - {endTime}
                  </p>
                  <span onClick={toggleEventWindow(item.id)}>
                    {item.staff_nickname ? `${item.service_name} (${item.staff_nickname})` : item.service_name}
                  </span>
                  {eventId === item.id && (
                    <CalendarEventWindow
                      serviceName={item.service_name}
                      price={item.price}
                      time={item.time}
                      staff_name={item.staff_nickname}
                      staff_id={item.staff_id}
                      closeWindow={closeWindow}
                      id={item.id}
                      customerFirstName={
                        (item.customer && item.customer.user_type_data && item.customer.user_type_data.first_name) ||
                        (item.customer_other && item.customer_other.name)
                      }
                      customerLastName={
                        item.customer && item.customer.user_type_data && item.customer.user_type_data.last_name
                      }
                      getShopBookingsForCalendar={getShopBookingsForCalendar}
                      dateContext={dateContext}
                      view={view}
                      booking_status_id={item.booking_status_id}
                      staffList={staffList}
                      selectedStaff={selectedStaff}
                      event_date={item.date}
                    />
                  )}
                </div>
              );
            }
          })}
      </div>
    </td>
  );
};

export default CalendarWeekItem;
