import {GET_ITEM_LIST} from "../../constants/constants";


const initialState = {
	/**
	 * A list of bookings
	 */
	items: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ITEM_LIST:
			return {
				...state,
				items: action.payload
			};
		default :
				return {
					...state
		}
	}
}