import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';


import { CustomerBadge } from '../../../Common/Customer';
import PhotoViewer from '../../../Common/PhotoViewer/PhotoViewer';

import BookingReviewRatings from '../../../Common/RatingStars/BookingReviewRatings';
import { Ellipsize } from '../../../Common/Text';


function NextArrow({ onClick }) {
  return (
    <div className="mob-slider-control mob-slider-control--next">
      <button onClick={onClick}>
        <i className="fas fa-angle-right" aria-hidden="true" />
      </button>
    </div>
  );
}

function PrevArrow({ onClick }) {
  return (
    <div className="mob-slider-control">
      <button onClick={onClick}>
        <i className="fas fa-angle-left" aria-hidden="true" />
      </button>
    </div>
  );
}

class ShopMainReviewSlider extends Component {

  state = {
    isPhotoViewerOpened: false,
    viewablePhotos: [],
    activePhotoIndex: 0,
  };

  sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  openPhotoViewer = (reviewIdx, photoIdx) => {
    this.setState({
      isPhotoViewerOpened: true,
      viewablePhotos: this.props.reviews[reviewIdx].photos,
      activePhotoIndex: photoIdx,
    });
  }

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
      viewablePhotos: [],
      activePhotoIndex: 0,
    });
  }

  redirectToReview = id => {
    const { url, history } = this.props;
    history.push(`${url.slice(0, url.lastIndexOf('main'))}review/${id}`);
  };

  render() {
    const {
      className,
      reviews,
      url,
      t,
    } = this.props;

    const {
      isPhotoViewerOpened,
      viewablePhotos,
      activePhotoIndex,
    } = this.state;

    return (
      <>
        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={viewablePhotos}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        <div className={`shop-group ${className}`}>
          <div className="d-flex align-item-center justify-content-between shop-group__panel">
            <Link to={`${url.slice(0, url.lastIndexOf('main'))}review`}>
              <p className="theme-subtitle">{t('shopPublic.overview.latestReviews')}</p>
            </Link>
          </div>

          <div className="mt-3 mt-lg-0 shop-mob-review">
            <Slider {...this.sliderSettings}>
              {reviews.map((review, reviewIdx) => (
                <div key={review.id} className="mt-0 mt-lg-4 p-2 p-lg-3 review-container">
                  <div className="d-flex align-items-center mb-2 shop-review-header">
                    <CustomerBadge
                      avatarSize="small"
                      customer={review.customer}
                      horizontal
                    />
                    <div className="flex-fill">
                      {/* TODO Properly format date-only */}
                      <p className="m-0 text-right review-info">{review.created_at.slice(0, review.created_at.indexOf(' '))}</p>
                    </div>
                  </div>

                  <Link to={`${url.slice(0, url.lastIndexOf('main'))}review/${review.id}`} className="review-title">
                    {review.title}
                  </Link>

                  <div className="mt-1 review-content">
                    <p><Ellipsize text={review.review} maxlength={110} /></p>
                    <Link to={`${url.slice(0, url.lastIndexOf('main'))}review/${review.id}`} className="more-link">
                      {t('shopPublic.product.details')}
                    </Link>
                  </div>

                  <div className="mt-3 row">
                    <div className="col-lg-8">
                      <div className="row less-gutter-row">
                        {review.photos.slice(0, 3).map((photo, photoIdx) => (
                          <div key={photoIdx} className="col-4">
                            <div className="news-prev-img">
                              <img
                                src={photo.url}
                                alt={photo.url}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.openPhotoViewer(reviewIdx, photoIdx)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <BookingReviewRatings ratings={review.review_average_rating} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    reviews: (state.ReviewReducer.reviews || []).filter(review => {
      return review.approved === 1;
    }),
  };
}

export default withTranslation()(
  connect(
    mapStateToProps
  )(ShopMainReviewSlider)
);
