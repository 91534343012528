import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
// import { getAllServices, getRecommended } from '../../../../../Redux/actions/shopActions/serviceAction';
// import { getAllProducts } from '../../../../../Redux/actions/shopActions/productAction';
import ReacommendedItemServicesUnchecked from './RecommenedServiceItemUnchecked';
import RecommendedProductItemUnchecked from './RecommendedProductItemUnchecked';
import Spinner from '../../../../Common/Spinner/Spinner';
import api from '../../../../../api';

class Recommended extends Component {
  state = {
    service_id: [],
    product_id: [],
    checkedService: false,
    checkedProduct: false,
    editStatus: 0,
    recommendedDataServices: [],
    recommendedDataProducts: [],
    loading: true,
  };

  // onChangeServices = ({ target: { checked } }, id) => {
  //   this.setState(prevState => ({
  //     recommendedDataServices: {
  //       ...prevState.recommendedDataServices,
  //       [id]: checked,
  //     },
  //   }));
  //   axios.post(`/api/recommend`, { id: id, type: 'service', checked: checked });
  // };

  onChangeServices = async ({ target: { checked } }, i, id) => {
    let newRecommendedDataServices = this.state.recommendedDataServices;
    await newRecommendedDataServices.forEach((service, index) => {
      if (index === i) {
        service.recommended = service.recommended ? 0 : 1;
      }
    });
    await this.setState({
      recommendedDataServices: newRecommendedDataServices,
    });
    await axios.post(`/api/recommend`, { id: id, type: 'service', checked: checked });
  };

  onChangeProduct = async ({ target: { checked } }, i, id) => {
    let newRecommendedDataProducts = this.state.recommendedDataProducts;
    await newRecommendedDataProducts.forEach((product, index) => {
      if (index === i) {
        product.recommended = product.recommended ? 0 : 1;
      }
    });
    await this.setState({
      recommendedDataProducts: newRecommendedDataProducts,
    });
    // this.setState(prevState => ({
    //   recommendedDataProducts: {
    //     ...prevState.recommendedDataProducts,
    //     [id]: checked,
    //   },
    // }));
    await axios.post(`/api/recommend`, { id: id, type: 'product', checked: checked });
  };

  componentDidMount() {
    const { auth } = this.props;
    // getRecommended({ id: auth.user.id });
    api.ShopServices.getBookingShopServices({ id: auth.user.id })
      .then(res => {
        if (res.data.message === 'success') {
          // let data = {};
          // const { recommend } = this.props;
          // res.res.data.data.map((elem, index) => {
          //   if (recommend[index] && recommend[index].type === 'service') {
          //     data = {
          //       ...data,
          //       [recommend[index].id]: true,
          //     };
          //   }
          //   data = {
          //     ...data,
          //     [elem.id]: false,
          //   };
          // });
          this.setState({
            recommendedDataServices: res.data.data,
            loading: false,
          });
        } else {
          console.error(res.data.data);
        }
      })
      .catch(err => {
        console.error(err);
      });

    api.products
      .getAllProducts({ params: { id: auth.user.id } })
      .then(res => {
        if (res.data.message === 'success') {
          // let data = {};
          // const { recommend } = this.props;
          // res.res.data.data.map((elem, index) => {
          //   if (recommend[index] && recommend[index].type === 'product') {
          //     data = {
          //       ...data,
          //       [recommend[index].id]: true,
          //     };
          //   }
          //   data = {
          //     ...data,
          //     [elem.id]: false,
          //   };
          // });
          this.setState({
            recommendedDataProducts: res.data.data,
          });
        } else {
          console.error(res.data.data);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  // saveRecommended = () => {
  //   notification('Your changes were saved');
  // };

  render() {
    // const { services, products } = this.props;
    const { recommendedDataServices, recommendedDataProducts, loading } = this.state;
    return (
      <div className="main-container__content">
        {loading ? (
          <Spinner display="block" />
        ) : (
          <div className="d-flex flex-wrap recommended-slider">
            {recommendedDataServices?.map((service, index) => (
              <ReacommendedItemServicesUnchecked
                key={service.id}
                i={index}
                service={service}
                onChangeServices={this.onChangeServices}
                // recommendedDataService={recommendedDataServices[index]}
              />
            ))}

            {recommendedDataProducts?.map((product, index) => (
              <RecommendedProductItemUnchecked
                key={product.id}
                i={index}
                product={product}
                // recommendedDataProduct={recommendedDataProducts[product.id]}
                onChangeProduct={this.onChangeProduct}
              />
            ))}
          </div>
        )}
        {/*
          <AppButton theme="primary" onClick={this.saveRecommended}>
            Save
          </AppButton>
        */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    // services: state.servicesReducer.services,
    // recommend: state.shopProductReducer.recommend,
    // products: state.shopProductReducer.products,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        // getAllServices,
        // getRecommended,
        // getAllProducts,
      }
    )(Recommended)
  )
);
