import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Booking } from 'src/models/entity';

import { ShopAvatar } from 'src/Components/Common/Shop';

import { equalId } from 'src/utils/api/api-utils';

import { useAuthenticated } from 'src/hooks/auth';

import MessageBubble from '../Message/MessageBubble';

interface Props {
  booking: Booking;
}

const BookingProviderRemarkBubble = ({ booking }: Props) => {
  const { t } = useTranslation();

  const { user, isAdmin } = useAuthenticated();

  const isAuthor = useMemo(
    () => isAdmin || equalId(booking.shop_id, user.id),
    [isAdmin, booking.shop_id, user.id]
  );

  return booking.providerRemarks ? (
    <MessageBubble
      authorBadge={<ShopAvatar shop={booking.shop} />}
      caption={t('booking.providerRemarks.label')}
      isAuthor={isAuthor}
      message={booking.providerRemarks}
    />
  ) : null;
};

export default BookingProviderRemarkBubble;
