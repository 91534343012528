import { SITE_BUSY_COUNT_WILL_INCREMENT } from "../../constants/constants";


export const incrementSiteBusyCount = () => dispatch =>
  dispatch({
    type: SITE_BUSY_COUNT_WILL_INCREMENT,
    payload: 1,
  });

export const decrementSiteBusyCount = () => dispatch =>
  dispatch({
    type: SITE_BUSY_COUNT_WILL_INCREMENT,
    payload: -1,
  });
