import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

import { useConfirmationDialog } from '../../../../../Providers/ConfirmDialogProvider';

import { EditIconLink } from '../../../../AdminPage/Common/Button';

const ConfirmDeleteButton = ({ serviceId, deleteService }) => {
  const { t } = useTranslation();

  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const handleClickOnDelete = () => {
    openConfirmDeleteDialog(() => {
      deleteService(serviceId);
    });
  };

  return (
    <Tooltip placement="top" title={t('action.delete')}>
      <button
        className="btn btn-sm btn-outline-danger mx-1"
        onClick={handleClickOnDelete}
      >
        <i className="fas fa-trash-alt" />
      </button>
    </Tooltip>
  );
};

const ServiceItem = ({
  index,

  shopId,

  service,

  deleteService,
}) => {
  const { t } = useTranslation();

  return (
    <tr data-label-tr={`${t('block')} #${index}`}>
      <td data-label={t('shopPrivate.calendar.serviceName')}>{service.name}</td>
      <td data-label={t('shopPrivate.servicePriceList.serviceType')}>
        {service.service_type?.name}
      </td>
      <td data-label={t('shopPrivate.servicePriceList.serviceTime')}>
        {service.time}
      </td>
      <td data-label={t('shopPrivate.servicePriceList.service_descr')}>
        {service.description}
      </td>
      <td data-label={t('shopPrivate.servicePriceList.availability')}>
        {service.available === 1
          ? t('shopPrivate.servicePriceList.available')
          : t('shopPrivate.servicePriceList.closed')}
      </td>
      <td data-label={t('shopPublic.product.price')}>
        {service.price_fixed !== null
          ? service.price_fixed
          : `${service.price_from} - ${service.price_to}`}
      </td>
      <td data-label={t('shopPrivate.shopLeftMenu.staff')}>
        {service.madeBy?.map((staff, index) => (
          <Fragment key={index}>
            {staff.user_type_data?.nickname} <br />
          </Fragment>
        ))}
      </td>
      <td>
        <EditIconLink to={`/shop-settings/service/${service.id}`} />
        <ConfirmDeleteButton
          shopId={shopId}
          deleteService={deleteService}
          serviceId={service.id}
        />
      </td>
    </tr>
  );
};

export default ServiceItem;
