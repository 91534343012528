import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReactAvatarEditor from 'react-avatar-editor';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';

import notification from '../notification';
import { AppButton } from '../Button';

import styles from './PhotoPickerDialog.module.css';


function ControlRow({ label, children }) {
  return (
    <div className="row align-items-center no-gutters">
      <div className="col-12 col-lg-4">
        {label && <span className="field-label">{label}</span>}
      </div>
      <div className="col col-lg-8">{children}</div>
    </div>
  );
}

const PhotoPickerDialog = ({
  imageTypes,

  imageWidth,
  imageHeight,

  imageAdaptiveWidth,
  imageAdaptiveHeight,

  propsImage,

  text,

  /**
   * @deprecated TODO
   */
  type,

  getImage,
  onClose,
}) => {
  const { t } = useTranslation();

  const editorRef = useRef(null);
  const fileInputRef = useRef(null);

  const [allowZoomOut, setAllowZoomOut] = useState(false);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [color, setColor] = useState([255, 255, 255, 0.6]);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [border, setBorder] = useState(0); // window.innerWidth < 768 ? 0 : 50

  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [imageURLFull, setImageURLFull] = useState('');
  const [imageURLFullRotate, setImageURLFullRotate] = useState('');

  const [initWidth, setInitWidth] = useState(0);
  const [initHeight, setInitHeight] = useState(0);

  const [imageLimit, setImageLimit] = useState(9500000000000000);

  const [validImageTypes, setValidImageTypes] = useState([]);

  const [isImageChanged, setIsImageChanged] = useState(false);

  const [isPreviewDialogOpened, setPreviewDialogOpened] = useState(false);

  useEffect(() => {
    setWidth(imageWidth || 250);
    setHeight(imageHeight || 250);
    setValidImageTypes(
      imageTypes || ['image/png', 'image/jpeg', 'image/gif', 'image/bmp']
    );
  }, []);

  const handleDialogClose = (e, reason) => {
    if (!isImageChanged) {
      onClose();
    }
  };

  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      cb(reader.result);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const rotateBase64Image = (base64Image) => {
    var offScreenCanvas = document.createElement('canvas');
    const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

    // create Image
    var img = new Image();
    img.src = base64Image;

    // set its dimension to rotated size
    if (rotate === 0) {
      setImageURLFullRotate(base64Image);
      return;
    }

    if (rotate % 180 !== 0) {
      offScreenCanvas.height = img.width;
      offScreenCanvas.width = img.height;
    } else {
      offScreenCanvas.height = img.height;
      offScreenCanvas.width = img.width;
    }

    offScreenCanvasCtx.clearRect(
      0,
      0,
      offScreenCanvas.width,
      offScreenCanvas.height
    );

    if (rotate % 180 !== 0) {
      offScreenCanvasCtx.translate(img.height / 2, img.width / 2);
    } else {
      offScreenCanvasCtx.translate(img.width / 2, img.height / 2);
    }

    offScreenCanvasCtx.rotate((rotate * Math.PI) / 180);
    offScreenCanvasCtx.drawImage(img, -img.width / 2, -img.height / 2);

    // rotate and draw source image into the off-screen canvas:
    /* if (rotate > 0) {
      offScreenCanvasCtx.rotate(rotate * Math.PI / 180);
      offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
    } else if(rotate < 0) {
      offScreenCanvasCtx.rotate(rotate * Math.PI / 180);
      offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
    } else if(rotate === -180) {
      offScreenCanvasCtx.rotate(-180 * Math.PI / 180);
      offScreenCanvasCtx.translate(-offScreenCanvas.width *2, -offScreenCanvas.height);
    }

    offScreenCanvasCtx.drawImage(img, 0, 0);*/

    // encode image to data-uri with base64
    setImageURLFullRotate(offScreenCanvas.toDataURL('image/jpeg'));
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      // const canvasInit = editorRef.current.getImage();
      // const crop = editorRef.current.getCroppingRect();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(canvasScaled, 0, 0, canvas.width, canvas.height);

      const imageURL =
        rotate === 0
          ? canvas.toDataURL('image/jpeg')
          : canvasScaled.toDataURL('image/jpeg');
      setImageURL(imageURL);

      getImage(
        type === 'review'
          ? {
              id: 'new',
              url: imageURL,
              fullUrl:
                imageURLFullRotate === '' ? imageURLFull : imageURLFullRotate,
            }
          : [
              imageURL,
              imageURLFullRotate === '' ? imageURLFull : imageURLFullRotate,
            ]
      );

      onClose();
    }
  };

  const handleFileChange = (e) => {
    if (!e.target.files.length) {
      return;
    }

    const file = e.target.files[0];
    const fileType = file && file['type'];
    const imageTypes = validImageTypes
      .map((item) => item.slice(item.indexOf('/') + 1))
      .join(', ');

    getBase64(file, (result) => {
      setImageURLFull(result);
    });

    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    if (!validImageTypes.includes(fileType)) {
      notification(
        t('notifications.supportedImageTypes', {
          imageTypes: imageTypes,
        })
      );
    } else if (file.size > imageLimit) {
      notification(
        t('notifications.photoMaxSize', {
          imageLimitSize: Math.floor(imageLimit / 1024 / 1024),
        })
      );
    } else {
      img.src = _URL.createObjectURL(file);
      img.onload = () => {
        setImage(file);

        setWidth(
          imageWidth ||
            (imageAdaptiveWidth && imageAdaptiveWidth < img.naturalWidth) ||
            img.naturalWidth
        );
        setHeight(
          imageHeight ||
            (imageAdaptiveHeight && imageAdaptiveHeight < img.naturalHeight) ||
            img.naturalHeight
        );

        setInitWidth(img.naturalWidth);
        setInitHeight(img.naturalHeight);
      };

      setIsImageChanged(true);
    }
  };

  const handleRotate = (direction) => {
    const degree = direction === 'right' ? 90 : -90;
    const canvas = editorRef.current.getImageScaledToCanvas();
    if (degree + Number(rotate) === 360 || degree + Number(rotate) === -360) {
      setRotate(0);
      setWidth(canvas.width);
      setHeight(canvas.height);

      rotateBase64Image(imageURLFull);

      return;
    }

    setRotate(degree + Number(rotate));
    setWidth(canvas.width);
    setHeight(canvas.height);
    rotateBase64Image(imageURLFull);
  };

  const handleScrollDown = () => {
    const scrolling_el = document.getElementById('scrolling_el');
    scrolling_el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const handleChangePosition = ({ x, y }) => {
    const canvas = editorRef.current.getImage();
    if (canvas.width > width) {
      setPosition({ x: 0.5, y: 0.5 });
    } else {
      setPosition({ x, y });
    }
  };

  const togglePreviewDialog = () => {
    if (editorRef.current) {
      if (isPreviewDialogOpened) {
        setPreviewDialogOpened(false);
        return;
      }

      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(canvasScaled, 0, 0, canvas.width, canvas.height);

      const imageURL = rotate === 0 ? canvas.toDataURL() : canvasScaled.toDataURL();

      setImageURL(imageURL);
      setPreviewDialogOpened(true);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        open={true}
        maxWidth="lg"
        onClose={handleDialogClose}
        aria-labelledby="upload-image-dialog-title"
      >
        <DialogTitle
          id="upload-image-dialog-title"
          className="app-dialog-title"
        >
          <div className="d-flex align-items-center">
            <div className="flex-fill d-flex align-items-center">
              <h2 className="m-0">{t('uploadImg.dialogTitle')}</h2>
              <Tooltip placement="top" title={t('uploadImg.scroll')}>
                <button
                  className="btn btn-sm btn-outline-secondary mx-1"
                  type="button"
                  onClick={handleScrollDown}
                >
                  <i className="fas fa-arrow-down" />
                </button>
              </Tooltip>
            </div>

            <button className="btn btn-link" onClick={onClose}>
              <i className="fas fa-times text-muted" />
            </button>
          </div>
        </DialogTitle>

        <DialogContent className="p-0 p-lg-2">
          <div className="container-fluid pb-5 pb-lg-0">
            <div className="row">
              <div className="my-3 col-lg-8">
                <div className={`border ${styles.canvasBox}`}>
                  {/* upload editor */}
                  <ReactAvatarEditor
                    ref={editorRef}
                    image={image || propsImage}
                    width={width}
                    height={height}
                    border={border}
                    color={color}
                    scale={scale}
                    rotate={rotate}
                    // position={position}
                    disableHiDPIScaling={false}
                    onLoadFailure={(e) => notification(e)}
                    // onPositionChange={handleChangePosition}
                    style={{ objectFit: 'contain' }}
                  />

                  {!isImageChanged && (
                    <div
                      role="button"
                      className={styles.uploadOverlay}
                      onClick={() => fileInputRef.current.click()}
                    >
                      <p>
                        <i className="fas fa-cloud-upload-alt mr-1" />
                        {t('uploadImg.chooseImage')}
                      </p>
                    </div>
                  )}
                </div>

                <input
                  accept="image/*"
                  className="d-none"
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                />

                <AppButton
                  className="d-block mt-3 mx-auto"
                  theme="success"
                  variant={isImageChanged ? 'outlined' : 'filled'}
                  onClick={() => fileInputRef.current.click()}
                >
                  <i className="fas fa-cloud-upload-alt mr-1" />
                  {t('uploadImg.chooseImage')}
                </AppButton>
              </div>

              <div className="col-lg-4">
                {/* This fieldset help disable child form controls */}
                <fieldset disabled={!isImageChanged}>
                  {text && <p>{text}</p>}

                  <ControlRow label={t('uploadImg.zoom')}>
                    <input
                      className="form-item"
                      type="range"
                      name="scale"
                      min={allowZoomOut ? '0.1' : '1'}
                      max="2"
                      step="0.01"
                      defaultValue="1"
                      onChange={(e) => setScale(Number(e.target.value))}
                    />
                  </ControlRow>

                  {/* Border width */}
                  {/*
                  (width < initWidth || height < initHeight) && window.innerWidth > 768 && (
                    <ControlRow label={t('uploadImg.frameWidth')}>
                      <input
                        className="form-item"
                        type="range"
                        name="border"
                        max="100"
                        step="1"
                        defaultValue="50"
                        onChange={(e) => setBorder(Number(e.target.value))}
                      />
                    </ControlRow>
                  )
                  */}

                  <ControlRow label={t('uploadImg.rotate')}>
                    <div className="row align-items-center no-gutters">
                      <div className="col col-lg-6">
                        <AppButton
                          size="block"
                          theme="secondary"
                          onClick={(e) => handleRotate('left')}
                        >
                          {t('uploadImg.left')}
                          <i className="fas fa-undo mx-1" />
                        </AppButton>
                      </div>
                      <div className="col col-lg-6">
                        <AppButton
                          size="block"
                          theme="secondary"
                          onClick={(e) => handleRotate('right')}
                        >
                          {t('uploadImg.right')}
                          <i className="fas fa-redo mx-1" />
                        </AppButton>
                      </div>
                    </div>
                  </ControlRow>

                  {/* Width */}
                  {/*
                  <ControlRow label="Width">
                    <input
                      className="form-item"
                      type="number"
                      name="width"
                      value={width}
                      onChange={(e) => setWidth(Number(e.target.value))}
                    />
                  </ControlRow>
                  */}

                  {/* Height */}
                  {/*
                  <ControlRow label="Height">
                    <input
                      className="form-item"
                      type="number"
                      name="height"
                      value={height}
                      onChange={(e) => setHeight(Number(e.target.value))}
                    />
                  </ControlRow>
                  */}

                  {/* Preview button */}
                  {/*
                  <ControlRow>
                    <AppButton
                      className="mt-3"
                      disabled={!isImageChanged}
                      size="block"
                      theme="secondary"
                      variant="outlined"
                      onClick={togglePreviewDialog}
                    >
                      {t('preview')}
                    </AppButton>
                  </ControlRow>
                  */}
                </fieldset>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <div className="container-fluid">
            <div className="row no-gutters justify-content-md-end">
              <div className="col-12 col-md-3 col-lg-2">
                <div id="scrolling_el">
                  <AppButton
                    size="block"
                    theme="primary"
                    onClick={handleConfirm}
                    disabled={!isImageChanged}
                  >
                    {t('action.confirm')}
                  </AppButton>
                </div>
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <AppButton size="block" theme="muted" onClick={onClose}>
                  {t('uploadImg.cancel')}
                </AppButton>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>

      {/* Dialog for preview */}
      {/*
      {isPreviewDialogOpened && (
        <Dialog
          fullWidth={true}
          open={true}
          maxWidth="lg"
          aria-labelledby="preview-image-dialog-title"
        >
          <DialogTitle id="preview-image-dialog-title">
            <div className="d-flex align-items-center">
              <div className="flex-fill"></div>
              <button className="btn btn-link" onClick={togglePreviewDialog}>
                <i className="fas fa-times text-muted" />
              </button>
            </div>
          </DialogTitle>
          <DialogContent className="text-center">
            <img src={imageURL} />
          </DialogContent>
        </Dialog>
      )}
*/}
    </>
  );
};

PhotoPickerDialog.defaultProps = {
  text: '',
};

export default PhotoPickerDialog;
