import { GET_ALL_REVIEWS, GET_REVIEW, GET_REVIEWS, CLEAR_REVIEW } from '../../constants/constants';

const initialState = {
  reviews: [],
  review: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case GET_REVIEW:
      return {
        ...state,
        review: action.payload,
      };
    case CLEAR_REVIEW:
      return {
        ...state,
        review: {},
      };
    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
