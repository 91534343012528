import React, { useEffect } from 'react';
import { connect } from 'react-redux';


import { fetchDefaultServices } from '../../../../../Redux/actions/definitionActions/defaultServiceActions';

import RootCheckable from './RootCheckable';
import ChildCheckable from './ChildCheckable';

const DefaultServiceCheckables = ({
  // from parent:
  selectedItemIds,
  onChange,

  // from `mapStateToProps`:
  items,

  // redux actions:
  fetchDefaultServices,
}) => {

  useEffect(() => {
    fetchDefaultServices();
  }, []);

  const handleRootChange = (item, checked) => {
    onChange(item, checked);
  };

  const handleChildChange = (item, checked) => {
    onChange(item, checked);
  };

  return (
    <div className="row">
      {items.map((item) => (
        <div className="col-2 px-1" key={item.id}>
          <RootCheckable
            checked={selectedItemIds.includes(item.id)}
            item={item}
            onChange={handleRootChange}
          />

          {/* TODO (WIP) Temporarily disable filtering for child default service @994eb9c */}
          {/*
          {selectedItemIds.includes(item.id) &&
            item.children &&
            item.children.map((child) => (
              <ChildCheckable
                checked={selectedItemIds.includes(child.id)}
                item={child}
                onChange={handleChildChange}
              />
            ))}
           */}
        </div>
      ))}
    </div>
  );
};

function mapStateToProps(state) {
  const items = state.defaultServiceReducer.defaultServices;

  return {
    // array of default services:
    items,
  };
}

export default connect(mapStateToProps, {
  fetchDefaultServices,
})(DefaultServiceCheckables);
