import React, { Component, Fragment } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';


import visa from '../../../dist/img/visa.png';
import mastercard from '../../../dist/img/MasterCard.png';

import api from '../../../api';

import notification from '../../Common/notification';

import './style.css';
import { AppButton } from '../../Common/Button';


class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = { complete: false };
    this.submit = this.submit.bind(this);
  }

  closeModal = (e) => {
    if (e.keyCode === 27) this.props.togglePayModal();
  };

  componentDidMount() {
    document.addEventListener('keyup', this.closeModal);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.closeModal);
  }

  buy = (data, btName) => {
    if (btName === 'shop_photoLimit') {
      api.admin
        .shopBuyPhotoLimit()
        .then(res => {
          if (res.data.data.stripe_response.message === 'success') {
            notification('Purchase Complete!');
            this.props.togglePayModal(undefined, 'success');
            this.props.changePhotoLimit(res.data.data.photo_limit.limit);
            this.props.changeBuyingComplete('yes');
          } else {
            console.error('photoLimit: ', res.data.stripe_response.message);
          }
        })
        .catch(error => {
          notification('Refresh page and try again latter');
          console.error(error);
        });
    } else if (btName === 'staff_photoLimit') {
      api.admin
        .staffBuyPhotoLimit()
        .then(res => {
          if (res.data.message === 'success') {
            notification('Purchase Complete!');
            this.props.togglePayModal(undefined, 'success');
            this.props.changePhotoLimit(res.data.data.photo_limit.limit);
            this.props.changeBuyingComplete('yes');
          } else {
            console.error('photoLimit: ', res);
          }
        })
        .catch(error => {
          notification('Refresh page and try again latter');
          console.error(error);
        });
    } else if (btName === 'shop_product') {
      api.admin
        .buyProduct(data)
        .then(res => {
          if (res.data.message === 'success') {
            notification('Purchase Complete!');
            this.props.togglePayModal();
            this.props.changeBuyingComplete('yes');
          } else {
            console.error('product: ', res);
          }
        })
        .catch(error => {
          notification('Refresh page and try again latter');
          console.error(error);
        });
    }
  };

  async submit(ev) {
    // // User clicked submit
    // // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // // Within the context of `Elements`, this call to createToken knows which Element to
    // // tokenize, since there's only one in this group.
    // this.props.stripe.createToken({ name: 'Jenny Rosen' }).then(({ token }) => {
    //   console.log('Received Stripe token:', token);
    // });

    const { btName } = this.props;
    this.props.changeBuyingComplete('pending');
    let { token } = await this.props.stripe
      .createToken()
      .then(res => {
        if (res.error && res.error.message) {
          notification(res.error.message);
          this.props.changeBuyingComplete('not');
        }
        return res;
      })
      .catch(error => {
        console.error('Error: ', error);
        this.props.changeBuyingComplete('not');
      });
    let data;
    if (token && (btName === 'shop_photoLimit' || btName === 'staff_photoLimit')) {
      data = {
        token: token.id,
        data: token,
      };
    } else if (token && btName === 'shop_product') {
      data = {
        token: token.id,
        product_id: Number(this.props.product_id),
        data: token,
      };
    } else if (token && btName === 'shop_payInvoice') {
      data = {
        token: token.id,
        invoice_id: Number(this.props.service_id),
        data: token,
      };
    }
    if (token && btName !== 'shop_payInvoice') {
      await api.admin.storeCreditCard(data)
        .then(res => {
          if (res.data.message === 'success') {
            this.buy(data, btName);
          } else if (res.data.message === 'stripe error') {
            notification('Purchase Error!');
            this.props.changeBuyingComplete('not');
          } else {
            return res;
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else if (token && btName === 'shop_payInvoice') {
      api.admin.shopPayInvoice(data)
        .then(res => {
          if (res.data.message === 'success') {
            notification('Purchase Complete!');
            this.props.togglePayModal();
          } else {
            this.props.changeBuyingComplete('not');
            notification(res.data.message);
          }
        })
        .catch(error => {
          notification('Refresh page and try again latter');
        });
    }
  }

  render() {
    const { price = 1 } = this.props;
    if (this.state.complete) return <h1>Purchase Complete</h1>;
    return (
      <Fragment>

        <div className="payment-popup__header">
          <div className="row">
            <div className="col-md-6">
              <div className="payment-text">
                <p>
                  使用信用咭支付賬單
                </p>
                <p>
                  Pay by credit card
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="payment-logo">
                <div className="payment-logo__item">
                  <img src={visa} alt=""/>
                </div>
                <div className="payment-logo__item">
                  <img src={mastercard} alt=""/>
                </div>

              </div>
              <p className="payment-value">
                ${price} HKD
              </p>
            </div>
          </div>
        </div>
        <div className="payment-popup__body">
          <form onSubmit={this.submit}>

            <div className="row">
              <div className="col-12">
                <CardNumberElement
                  placeholder="卡號 / Credit card"
                  className="field-wrap"
                  {...createOptions(this.props.fontSize)}
                />
              </div>
            </div>

            <div className="row">
              <div className="mt-2 col-md-6">
                <CardExpiryElement
                  className="field-wrap"
                  placeholder="到期日 / Expiry Date"
                  {...createOptions(this.props.fontSize)}
                />
              </div>
              <div className="mt-2 col-md-6">
                <CardCVCElement
                  className="field-wrap"
                  placeholder="安全碼 / (CSC)"
                  {...createOptions(this.props.fontSize)}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <input name="name" className="stripe-name-input" type="text" placeholder="持咭人姓名 / Full Name" required/>
              </div>
            </div>

            <div className="mt-4">
              <AppButton size="block" theme="primary" type="submit">
                傳送 / Confirms
              </AppButton>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default injectStripe(CheckoutForm);


const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize: '24px',
        color: '#272c2b',
        height: '100%',
        letterSpacing: '0.025em',
        fontFamily: 'Arial',
        border: '2px solid #dedede !important',
        '::placeholder': {
          color: '#aab7c4',
          fontSize: '12px',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};