import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import AspectImage from 'src/Components/Common/AspectImage/AspectImage';
import PhotoCell from '../PhotoCell/PhotoCell';
import PhotoViewer from '../PhotoViewer/PhotoViewer';
import Spinner from '../Spinner/Spinner';
import PhotoPickerDialog from '../Photo/PhotoPickerDialog';


import styles from './PhotoGridEditable.module.css';

class PhotoGridEditable extends Component {

  state = {
    items: [],
    isUploadingPendingItems: false,

    isPhotoViewerOpened: false,
    activePhotoIndex: 0,

    isModalUploaderVisible: false,
  };

  componentDidMount() {
    this.setState({
      items: this.props.remoteItems,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFetchingItems && !this.props.isFetchingItems) {
      this.setState({
        items: this.props.remoteItems,
      });
    }

    if (!prevProps.autoUpload && this.props.autoUpload) { // autoUpload is just turned on
      this.uploadAllPendingItems();
    }
  }

  openPhotoViewer = (photoIdx) => {
    this.setState({
      isPhotoViewerOpened: true,
      activePhotoIndex: photoIdx,
    });
  }

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
      activePhotoIndex: 0,
    });
  }

  toggleModalUploaderVisibility = () => {
    this.setState(prevState => ({
      isModalUploaderVisible: !prevState.isModalUploaderVisible,
    }));
  };

  handleGrabImage = file => {
    const localPhoto = {
      // generate a random string as unique id
      _id: Math.random().toString(36).substr(2, 6), // Note: this props is used only locally
      _isPendingUpload: !this.props.autoUpload, // Note: this props is used only locally
      _isUploading: false, // Note: this props is used only locally

      url: file[0],
      url_full: file[1],
    };

    if (this.props.inDateAscendingOrder) {
      this.setState(prevState => {
        return {
          items: [...prevState.items, localPhoto], // append the new image to local image list
        };
      });
    }
    else {
      this.setState(prevState => {
        return {
          items: [localPhoto, ...prevState.items], // prepend the new image to local image list
        };
      });
    }

    if (this.props.autoUpload) {
      this.uploadOne(localPhoto);
    }
  }

  /**
   *
   * @param {{id, _id}} param0 The id (or _id) of a (local) photo
   * @param {Photo} remotePhoto Probably a photo json from remote server
   */
  replacePhotoInState = ({ id, _id }, remotePhoto = null) => {
    return this.setState(prevState => {
      const idx = prevState.items.findIndex(item => (
        (id && id == item.id) ||
        (_id && _id == item._id)
      ));
      if (idx > -1) {
        const nextItems = prevState.items.slice(); // make a copy

        if (remotePhoto) {
          nextItems.splice(idx, 1, remotePhoto); // replace 1 item
        } else {
          nextItems.splice(idx, 1); // remove 1 item
        }

        return {
          items: nextItems,
        };
      }

      return {
      };
    });
  }

  uploadOne = (localPhoto) => {
    this.setState(prevState => {
      const idx = prevState.items.findIndex(item => item._id == localPhoto._id);
      if (idx > -1) {
        const nextItems = prevState.items.slice(); // make a copy
        nextItems[idx]._isUploading = true;

        return {
          items: nextItems,
        };
      }

      return {
      };
    });

    const file = [
      localPhoto.url,
      localPhoto.url_full,
    ];

    return this.props.onUploadImageFile(file)
      .then((remotePhoto) => {
        // successfully uploaded the image => replace the photo entry in local state
        this.replacePhotoInState({ _id: localPhoto._id }, remotePhoto);
      })
      .catch(() => {
        // failed to upload the image => remove the photo entry from local state
        this.replacePhotoInState({ _id: localPhoto._id });
      });
  }

  uploadAllPendingItems = async () => {
    let items = this.state.items.slice(); // make a copy
    let i;

    this.setState({
      isUploadingPendingItems: true,
    });

    if (this.props.inDateAscendingOrder) {
      // The photos are listed in ascending order of creation time
      for (i = 0; i < items.length; i++) {
        if (items[i]._isPendingUpload) {
          await this.uploadOne(items[i]);
        }
      }
    } else {
      // The photos are listed in descending order of creation time
      for (i = items.length - 1; i >= 0; i--) {
        if (items[i]._isPendingUpload) {
          await this.uploadOne(items[i]);
        }
      }
    }

    this.setState({
      isUploadingPendingItems: false,
    });

    this.props.onUploadedAll();
  }

  handleEditPhoto = (photo) => {
    this.props.onEditPhoto(photo);
  }

  handleDeletePhoto = (photo) => {
    if (photo.id) {
      // This photo exists on remote server
      return this.props.onDeleteRemotePhoto(photo).then(() => {
        return this.replacePhotoInState({ id: photo.id });
      });
    } else if (photo._id) {
      // This photo has not been uploaded to remote server.
      return this.replacePhotoInState({ _id: photo._id });
    }
  }

  render() {
    const {
      aspectRatio,
      cellMaxWidth,
      headerText,
      helpText,
      inDateAscendingOrder,
      isFetchingItems,
      maxPhotoLimit,
      showCopyUrlButton,
      showDeleteButton,
      showEditButton,
    } = this.props;
    const {
      items,
      isUploadingPendingItems,

      isPhotoViewerOpened,
      activePhotoIndex,

      isModalUploaderVisible,
    } = this.state;

    const cellStyle = {
      ...(cellMaxWidth > 0
        ? {
            flex: '0 1 auto',
            maxWidth: cellMaxWidth,
            padding: 0,
          }
        : {}),
    };

    const isOnlyOneCell = maxPhotoLimit === 1 && cellMaxWidth > 0;

    return (
      <>
        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={items}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        {/* image uploader */}
        {isModalUploaderVisible && (
          <PhotoPickerDialog
            onClose={this.toggleModalUploaderVisibility}
            getImage={this.handleGrabImage}
            imageAdaptiveWidth={window.innerWidth < 768 ? 250 : window.innerWidth - 120}
            imageAdaptiveHeight={window.innerHeight < 768 ? 250 : window.innerHeight - 400}
            imageWidth={600}
            imageHeight={600}
          />
        )}

        <div className={`p-3 mb-2 ${styles.root}`}>
          {headerText && <h3>{headerText}</h3>}
          {helpText && <div className="text-secondary mb-2">{helpText}</div>}

          {isFetchingItems ? (
            <Spinner />
          ) : (
            <div className="row">
              {/* list of uploaded images */}
              {items &&
                items.map((photo, photoIdx) => (
                  <div
                    key={photoIdx}
                    className={`col mb-4 order-1 ${styles.cellContainer} ${
                      isOnlyOneCell ? styles.onlyOneCell : ''
                    }`}
                    style={cellStyle}
                  >
                  <PhotoCell
                    aspectRatio={aspectRatio}
                    photo={photo}
                    showCopyUrlButton={showCopyUrlButton}
                    showDeleteButton={showDeleteButton}
                    showEditButton={showEditButton}
                    onClick={() => this.openPhotoViewer(photoIdx)}
                    onDelete={() => this.handleDeletePhoto(photo)}
                    onEdit={() => this.handleEditPhoto(photo)}
                  />
                </div>
              ))}

              {/* button to open an image uploader */}
              {(maxPhotoLimit < 0 || items.length < maxPhotoLimit) && (
                <div
                  className={`col mb-4 ${styles.cellContainer} ${
                    isOnlyOneCell ? styles.onlyOneCell : ''
                  } ${inDateAscendingOrder ? 'order-2' : 'order-0'}`}
                  style={cellStyle}
                >
                  <div className={styles.uploaderTriggerCell}>
                    <AspectImage aspectRatio={aspectRatio}>
                      {/* This empty component aims to occupy and fill necessary space for the uploader trigger cell. */}
                    </AspectImage>
                    <button
                      className={styles.uploaderTriggerAnchor}
                      disabled={isUploadingPendingItems}
                      type="button"
                      onClick={this.toggleModalUploaderVisibility}
                    >
                      <div className="app-absolute-center">
                        {isUploadingPendingItems ? (
                          <Spinner />
                        ) : (
                          <i className="fas fa-plus-circle" />
                        )}
                      </div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

PhotoGridEditable.propTypes = {
  aspectRatio: PropTypes.string,
  autoUpload: PropTypes.bool,
  cellMaxWidth: PropTypes.number,
  headerText: PropTypes.node,
  helpText: PropTypes.node,
  inDateAscendingOrder: PropTypes.bool,
  isFetchingItems: PropTypes.bool,
  maxPhotoLimit: PropTypes.number,
  remoteItems: PropTypes.array.isRequired,
  showCopyUrlButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  onDeleteRemotePhoto: PropTypes.func,
  onEditPhoto: PropTypes.func,
  onUploadedAll: PropTypes.func,
  onUploadImageFile: PropTypes.func,
};

PhotoGridEditable.defaultProps = {

  aspectRatio: '1:1',

  autoUpload: true,

  /**
   * Positive value make the grid display cells not exceeding such width
   */
  cellMaxWidth: 0,

  headerText: '',

  helpText: '',

  inDateAscendingOrder: true,

  isFetchingItems: false,

  /**
   * negative value stands for no limit
   */
  maxPhotoLimit: -1,

  /**
   * Schema of each item follows `Photo` on remote server
   */
  remoteItems: [],

  showCopyUrlButton: true,

  showDeleteButton: true,

  showEditButton: false,

  onDeleteRemotePhoto: (photo) => Promise.resolve(),

  onEditPhoto: (photo) => {},

  onUploadedAll: () => {},

  onUploadImageFile: (file) => Promise.resolve(/* A remote photo json */),
};

export default withTranslation()(
  withRouter(PhotoGridEditable)
);
