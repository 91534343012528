import { useEffect } from 'react';


import { Advertisement } from 'src/models/entity';

import { GOOGLE_AD_ID } from 'src/environment';

import { useAdvertisementDimensions } from 'src/Functions/advertisementHelpers';


import styles from './GoogleAdvertisement.module.css';


interface Props {
  advertisement: Advertisement;
  timeout?: number;
}

function GoogleAdvertisement({ advertisement, timeout = 200 }: Props) {
  const { isLandscape } = useAdvertisementDimensions(advertisement);

  useEffect(() => {
    let googleInit: any = null;

    if (advertisement.externalId) {
      googleInit = setTimeout(() => {
        if (typeof window !== 'undefined') {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
      }, timeout);
    }

    return () => {
      if (googleInit) {
        clearTimeout(googleInit);
      }
    };
  }, []);

  return advertisement.externalId ? (
    <ins
      className={`adsbygoogle mx-auto ${styles.adContainer} ${
        isLandscape ? styles.landscape : styles.portrait
      }`}
      style={{ display: 'block' }}
      data-ad-client={GOOGLE_AD_ID}
      data-ad-slot={advertisement.externalId}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  ) : null;
}

export default GoogleAdvertisement;
