import React from 'react';
import { withTranslation } from 'react-i18next';


import { TableHeaderCell } from '../../../../Common/DataTable';

import StaffItemEdit from './StaffItemEdit';


const StaffChargeEdit = ({ shopStaffDataList, modalStatus, handleChange, service_id, t }) => {
  return (
    <div>
      <div className="mt-4 page-head">
        <h2 className="page-title">
          {t('shopPrivate.servicePriceList.staffAddCharge')}
        </h2>
      </div>
      <div className="main-container__content">
        <div className="tableWrap">
          <table className="adminTable">
            <colgroup>
              <col style={{ width: '40%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <TableHeaderCell>{t('shopPrivate.shopLeftMenu.staff')}</TableHeaderCell>
                <TableHeaderCell>{t('shopPrivate.servicePriceList.extraCharge')}</TableHeaderCell>
              </tr>
            </thead>
            <tbody>
              {shopStaffDataList &&
                shopStaffDataList.map((staffData, i) => {
                  const filteredCharge = staffData.charge.filter(
                    (service) => service.service_id === service_id
                  );
                  const staff_charge =
                    filteredCharge.length > 0 && filteredCharge[0].charge;
                  return (
                    <StaffItemEdit
                      key={i}
                      blockNum={i + 1}
                      nickname={staffData.nickname}
                      modalStatus={modalStatus}
                      staff_id={staffData.user_id}
                      handleChange={handleChange}
                      service_id={service_id}
                      staff_charge={staff_charge}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(StaffChargeEdit);
