import api from "../../../api";
import {SAVE_USER_SETTINGS} from "../../constants/constants";

export const contacts = (data) => dispatch =>
    api.shopSettings.changeContactsData(data).then((res) =>{
    if (res.data.message === 'success') {
        dispatch(saveUserSettings(res.data.data));
        return {res, access: true}
    }
    return {res, access:false}
});


export const saveUserSettings = (data) => ({
    type: SAVE_USER_SETTINGS,
    payload: data
});
;
