import { Shop } from 'src/models/entity';

import ShopAnchor from './ShopAnchor';
import ShopAvatar from './ShopAvatar';

import styles from './ShopBadge.module.css';

interface Props {
  avatarOnly?: boolean;
  avatarSize?: 'normal' | 'small' | 'tiny';
  className?: string;
  horizontal?: boolean;
  shop: Shop;
}

const ShopBadge = ({
  avatarOnly = false,
  avatarSize = 'normal',
  className = '',
  horizontal = false,
  shop,
}: Props) => {
  return (
    <div
      className={`${styles.root} ${
        horizontal ? styles.horizontal : ''
      } ${className}`}
    >
      <div
        className={`${styles.avatar} ${
          avatarSize === 'small'
            ? styles.smallAvatar
            : avatarSize === 'tiny'
            ? styles.tinyAvatar
            : ''
        }`}
      >
        <ShopAvatar src={shop.avatar} />
      </div>
      <div hidden={avatarOnly} className={styles.name}>
        <ShopAnchor shop={shop} />
      </div>
    </div>
  );
};

export default ShopBadge;
