import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';


import { getShopNews } from '../../../../Redux/actions/features/shopNewsAction';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import AspectImage from '../../../Common/AspectImage/AspectImage';
import ExternalFeed from '../../../Common/ExternalFeed/ExternalFeed';
import AppLinkify from '../../../Common/Linkify/AppLinkify';
import PhotoViewer from '../../../Common/PhotoViewer/PhotoViewer';
import ShopBadge from '../../../Common/Shop/ShopBadge';
import { LineBreakable } from '../../../Common/Text';

import BookingPanel from '../Booking/BookingPanel';


class NewsDetail extends Component {

  state = {
    isPhotoViewerOpened: false,
    activePhotoIndex: 0,
  };

  componentDidMount() {
    this.props.getShopNews({ id: this.props.match.params.newsId });

    // When user clicks a piece of news row on news list, the browser just stays on that row's position, causing bad UX for viewing the news detail.
    // This statement scroll to very top of the page:
    window.scrollTo(0, 0);
  }

  openPhotoViewer = (activePhotoIndex) => {
    this.setState({
      isPhotoViewerOpened: true,
      activePhotoIndex,
    });
  };

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
    });
  }

  render() {
    const {
      shop,
      shopNews,
    } = this.props;
    const {
      isPhotoViewerOpened,
      activePhotoIndex,
    } = this.state;

    const newsPhotos = shopNews.photos || [];

    return (
      <>
        <Helmet>
          <title>{shopNews.title}</title>
          <meta property="og:title" id="og-title" content={shopNews.title} />
        </Helmet>

        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={newsPhotos}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        <div className="container">
          <BookingPanel shop={shop} buttonTheme={2} />

          <div className="mt-4 mx-2 border pt-4 px-3 px-lg-4">
            <div className="row">
              <div className="col-lg-2">
                {/* The `user` refers to the shop. */}
                {shopNews.user && (
                  <ShopBadge shop={shopNews.user} />
                )}
              </div>

              <div className="col-lg-7">
                <h2 className="news-title">{shopNews.title}</h2>
                <p className="news-info">{shopNews.created_at}</p>

                <LineBreakable className="mt-4 news-content">
                  <AppLinkify>{shopNews.description}</AppLinkify>
                </LineBreakable>

                {shopNews.externalFeeds &&
                  shopNews.externalFeeds.map((feed, index) => (
                    <div className="mb-3" key={index}>
                      <ExternalFeed feed={feed} />
                    </div>
                  ))}

                {newsPhotos.map((photo, index) => (
                  <AspectImage aspectRatio="1:1" className="mb-3" key={index}>
                    <img
                      src={photo.url}
                      alt="news image"
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openPhotoViewer(index)}
                    />
                  </AspectImage>
                ))}
              </div>

              <div className="col-lg-3 d-none d-lg-block">
                <div className="my-5">
                  <AdvertisementView name="shop_news_page" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 d-lg-none">
          <AdvertisementView name="shop_news_page" />
        </div>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const shopId = props.match.params.id || state.auth.user.id;

  const shopNewsId = props.match.params.newsId;
  const shopNews = state.shopNewsReducer.shopNews.id == shopNewsId ? state.shopNewsReducer.shopNews : {};

  // adopt data from redux state only when the data is from the same shop
  const shop = state.shopNewsReducer.shopWithNewsList && state.shopNewsReducer.shopWithNewsList.id == shopId ?
  state.shopNewsReducer.shopWithNewsList : {};

  return {
    shop,
    shopNews,
  };
}

export default withTranslation()(withRouter(
  connect(
    mapStateToProps,
    { getShopNews }
  )(NewsDetail)
));
