import React from 'react';


import { useConfirmationDialog } from '../../../../../Providers/ConfirmDialogProvider';

import AspectImage from '../../../../Common/AspectImage/AspectImage';
import SinglePhotoViewable from '../../../../Common/PhotoViewer/SinglePhotoViewable';


const MediaItem = ({
  index,
  thumbnailUrl,
  fullUrl,
  onDelete,
  openUploadModal,
}) => {

  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const handleClickConfirmDelete = () => {
    openConfirmDeleteDialog(() => onDelete(index));
  }

  return (
    <div className="mb-3 col-md-6 col-lg-6 col-xl-3">
      {thumbnailUrl ? (
        <>
          <SinglePhotoViewable
            thumbnailUrl={thumbnailUrl}
            fullUrl={fullUrl}
          >
            <AspectImage aspectRatio="16:9">
              <img src={thumbnailUrl} />
            </AspectImage>
          </SinglePhotoViewable>

          <button className="remove-img" onClick={handleClickConfirmDelete}>
            <i className="fas fa-trash" />
          </button>
        </>
      ) : (
        <>
          <AspectImage aspectRatio="16:9">
            <label className="gallery-btn app-absolute-center" htmlFor={index}>
              <i className="fas fa-plus-circle" />
            </label>
          </AspectImage>

          <input
            // type="file"
            type="text"
            style={{ display: 'none' }}
            className="gallery-btn"
            // accept="image/*"
            id={index}
            name={index}
            onClick={openUploadModal}
          />
        </>
      )}
    </div>
  );
};

export default MediaItem;
