import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';


import { AppButton } from 'src/Components/Common/Button';

type OnConfirmFunction = () => void;

interface ConfirmationOption {
  message: string;
  onClose?: () => void;
  onConfirm: OnConfirmFunction;
}

interface ContextData {
  openConfirmationDialog: (confirmationOption: ConfirmationOption) => void;
  openConfirmDeleteDialog: (onConfirm: OnConfirmFunction) => void;
}

/**
 * To open a "Confirmation Dialog" imperatively/programmatically.
 *
 * Reference:
 * https://stackoverflow.com/a/63737527/2058976
 * https://codesandbox.io/s/dialogprovider-bz1j1?file=/src/DialogProvider.tsx
 * https://reactjs.org/docs/context.html
 *
 *
 *
 * Example 1 (in a function component):
  const { openConfirmationDialog } = useConfirmationDialog();

  const onClickSomeButton = () => {
    // This hook value accepts a object as first argument:
    openConfirmationDialog({
      message: 'Are you sure...',
      onClose: () => {
        // User declined to perform some actions...
      },
      onConfirm: () => {
        // User confirmed to perform some actions...
      },
    });
  }
 *
 *
 *
 * Example 2 (in a function component):
  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const onClickSomeButton = () => {
    // This hook value accepts a `onConfirm` callback as first argument
    openConfirmDeleteDialog(() => {
      // User confirmed to perform the deletion...
    });
  }
 */
const DialogContext = React.createContext<ContextData>({
  openConfirmationDialog: (confirmationOption: ConfirmationOption) => {},
  openConfirmDeleteDialog: (onConfirm: OnConfirmFunction) => {},
});


interface DialogContainerProps {
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

const DialogContainer = ({
  message,
  onClose,
  onConfirm,
}: // ...dialogParams
DialogContainerProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={() => onClose()}
    >
      <DialogContent className="p-4 theme-modal__header--code">
        <h2 className="m-3 text-center">{message}</h2>
      </DialogContent>

      <DialogActions className="p-0">
        <div className="flex-grow-1 d-flex flex-row justify-content-center confirm-code-btn">
          <AppButton shape="round" theme="primary" onClick={() => onConfirm()}>
            {t('action.confirm')}
          </AppButton>

          <AppButton shape="round" theme="muted" onClick={() => onClose()}>
            {t('action.cancel')}
          </AppButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

interface ProviderProps {
  children: React.ReactNode;
}

function ConfirmDialogProvider({ children }: ProviderProps) {
  const { t } = useTranslation();

  const [confirmationOptions, setConfirmationOptions] =
    React.useState<ConfirmationOption[]>([]);

  const openConfirmationDialog = (confirmationOption: ConfirmationOption) => {
    setConfirmationOptions((confirmationOptions) => [
      ...confirmationOptions,
      confirmationOption,
    ]);
  };

  /**
   *
   * @param {Function} onConfirm
   */
  const openConfirmDeleteDialog = (onConfirm: OnConfirmFunction) => {
    const confirmationOption = {
      message: t('general.confirmation.itemDeletion'),
      onConfirm,
    };

    setConfirmationOptions((confirmationOptions) => [
      ...confirmationOptions,
      confirmationOption,
    ]);
  };

  // Exported hook methods
  const contextValue = React.useRef<ContextData>({
    openConfirmationDialog,
    openConfirmDeleteDialog,
  });

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}

      {confirmationOptions.map((confirmationOption, i) => {
        const {
          message,
          onClose,
          onConfirm,
          // ...dialogParams
        } = confirmationOption;

        const handleClose = () => {
          if (onClose) onClose();

          // remove *last* option
          setConfirmationOptions((confirmationOptions) =>
            confirmationOptions.slice(0, confirmationOptions.length - 1)
          );
        };

        const handleConfirm = () => {
          if (onConfirm) onConfirm();

          // remove *last* option
          setConfirmationOptions((confirmationOptions) =>
            confirmationOptions.slice(0, confirmationOptions.length - 1)
          );
        };

        return (
          <DialogContainer
            key={i}
            message={message}
            onClose={handleClose}
            onConfirm={handleConfirm}
            // {...dialogParams}
          />
        );
      })}
    </DialogContext.Provider>
  );
}

export const useConfirmationDialog = () => React.useContext(DialogContext);

export default ConfirmDialogProvider;
