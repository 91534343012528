import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FACEBOOK_PAGE_URL,
  INSTAGRAM_PROFILE_URL,
} from '../../../../environment';

import PaymentMethodList from '../../../Common/Payment/PaymentMethodList';

import Linkables from './Footer/Linkables';


import styles from './Footer.module.css';

const SocialLink = ({ href, iconClass }) => (
  <a
    className={styles.socialAnchor}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    <i className={iconClass} />
  </a>
);

function SocialList() {
  return (
    <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
      <SocialLink href={FACEBOOK_PAGE_URL} iconClass="fab fa-facebook-f" />
      <SocialLink href={INSTAGRAM_PROFILE_URL} iconClass="fab fa-instagram" />
    </div>
  );
}

/*
function Subscribe() {
  return (
    <div className={styles.subscribe}>
      <input
        type="text"
        className={`border-bottom ${styles.subscribeField}`}
        placeholder="Subscribe for news"
      />
      <button className={styles.subscribeBtn}>
        <i className="fas fa-arrow-right" />
      </button>
    </div>
  );
}
*/

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className={styles.root}>
      <div className="container border-top pt-4">
        <div className="row align-items-center">
          <div className="col-12 mb-3 mb-lg-0 col-lg">
            <SocialList />
          </div>
          <div className="col-12 mb-3 mb-lg-0 col-lg">
            <Linkables />
          </div>
          <div className="col-12 mb-3 mb-lg-0 col-lg">
            <PaymentMethodList className="justify-content-center justify-content-lg-end" />
          </div>
        </div>

        <div className="mt-3 text-center">
          <Link className={styles.termLink} target="_blank" to="/term-policy">
            {t('homePage.footerTermsPrivacy')}
          </Link>

          <p>©2022 SalonFinder.com.hk 版權所有</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
