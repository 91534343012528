import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

export const formatDate = (date) => {
  return date ? moment(date).format('YYYY/MM/DD') : '';
}

export const formatDateTime = (date, fallbackValue = '') => {
  return date ? moment(date).format('YYYY/MM/DD A hh:mm') : fallbackValue;
}

export const translateWeekdayName = (t, englishName) => {
  switch (englishName) {
    case 'Monday':
      return t('datetime.weekday.monday');
    case 'Tuesday':
      return t('datetime.weekday.tuesday');
    case 'Wednesday':
      return t('datetime.weekday.wednesday');
    case 'Thursday':
      return t('datetime.weekday.thursday');
    case 'Friday':
      return t('datetime.weekday.friday');
    case 'Saturday':
      return t('datetime.weekday.saturday');
    case 'Sunday':
      return t('datetime.weekday.sunday');
    default:
      break;
  }

  return '';
}

export const LocalizeWeekday = ({ name }) => {
  const { t, i18n } = useTranslation();
  const weekdayName = useMemo(
    () => translateWeekdayName(t, name),
    [i18n.language, name]
  );

  return <>{weekdayName}</>;
}

/**
 *
 * @param {string} time a string in format "HH:MM", where HH is in range 0..23; MM is in range0..59.
 * @returns {string}
 */
export const timeToAmPm = time => {
  const tokens = time.split(':');

  const hourIn24 = Number(tokens[0]);
  const minutes = Number(tokens[1]);

  const hourIn12 = (hourIn24 % 12 > 0) ? hourIn24 % 12 : 12;

  const paddedHour = (hourIn12).toString().padStart(2, '0');
  const paddedMinute = (minutes).toString().padStart(2, '0');

  return `${paddedHour}:${paddedMinute} ${hourIn24 < 12 ? 'AM' : 'PM'}`;
};
