import React from 'react';
import { useTranslation } from 'react-i18next';


import {
  Tooltip,
} from '@material-ui/core';

import EditIconButton from '../../../../AdminPage/Common/Button/EditIconButton';


const ServiceTypeItem = ({
  indexNum,
  item,
  changeStatus,
  onEdit,
}) => {
  const { t } = useTranslation();

  const toggleEdit = () => {
    onEdit('edit', item.name, item.id);
  };

  const changeVisibility = () => {
    changeStatus(item.id, item.visible === 1 ? 0 : 1);
  };

  return (
    <tr data-label-tr={`${t('block')} #${indexNum}`}>
      <td data-label={t('customerPrivate.id')}>
        {item.id}
      </td>
      <td data-label={t('shopList.payMet')}>
        {item.name}
      </td>
      <td data-label={t('shopPrivate.servicePriceList.visibility')}>
        {item.visible ? t('shopPrivate.servicePriceList.visible') : t('shopPrivate.servicePriceList.hidden')}      </td>
      <td data-label={t('shopPrivate.servicePriceList.action')}>
        <EditIconButton outlined onClick={toggleEdit} />

        <Tooltip placement="top" title={item.visible === 1 ? t('shopPrivate.servicePriceList.hide') : t('shopPrivate.servicePriceList.show')}>
          <button
            className="btn btn-sm btn-outline-secondary mx-1"
            type="button"
            onClick={changeVisibility}
          >
            <i className="fas fa-eye" />
          </button>
        </Tooltip>
      </td>
    </tr>
  );
};

export default ServiceTypeItem;
