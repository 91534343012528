import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";


import { STRIPE_KEY } from "../../../../../environment";
import PaymentDetail from "../../../StaffPrivatePage/generalSettings/PaymentDetail";


class AddCreditCard extends Component {
  render() {
    return (
      <div className="main-container main-container__content">
        <div className="row">
          <div className="col-12">
            <StripeProvider apiKey={STRIPE_KEY}>
              <Elements>
                <PaymentDetail {...this.props} />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default AddCreditCard;
