import {
  DEFINITION_SHOP_STAFF_SCALE_LIST_WILL_FETCH,
  DEFINITION_SHOP_STAFF_SCALE_LIST_DID_FETCH,
} from '../../constants/constants';

const initialState = {
  isLoadingShopStaffScaleList: false,
  shopStaffScaleList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DEFINITION_SHOP_STAFF_SCALE_LIST_WILL_FETCH:
      return {
        ...state,
        isLoadingShopStaffScaleList: true,
        shopStaffScaleList: [],
      };
    case DEFINITION_SHOP_STAFF_SCALE_LIST_DID_FETCH:
      return {
        ...state,
        isLoadingShopStaffScaleList: false,
        shopStaffScaleList: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
