import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import _pull from 'lodash/pull';


import Spinner from '../../Spinner/Spinner';

import { AppButton } from '../../Button';

import styles from './CheckboxGroup.module.css';

class CheckboxGroup extends Component {

  _isMounted = false;

  _checkedItemIds = [];

  componentDidMount() {
    this._isMounted = true;
    this._checkedItemIds = this.props.defaultCheckedItemIds;
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.defaultCheckedItemIds) != JSON.stringify(this.props.defaultCheckedItemIds)) {
      // Only sync with the new props
      this._checkedItemIds = nextProps.defaultCheckedItemIds;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCheckboxChange = (e, item) => {
    const {
      itemIdProp,
    } = this.props;

    if (e.target.checked) {
      if (this._checkedItemIds.indexOf(item[itemIdProp]) < 0) {
        this._checkedItemIds.push(item[itemIdProp]);
      }
    } else {
      _pull(this._checkedItemIds, item[itemIdProp]);
    }

    this._checkedItemIds.sort(); // sort in ascending order
  }

  handleSave = (e) => {
    if (this.props.onSave) {
      this.props.onSave(this._checkedItemIds);
    }
  }

  render() {
    const {
      title,

      idPrefix = '',
      formFieldName = '',
      readOnly = false,

      isLoading = false,
      isSaving = false,

      items = [],
      itemIdProp = 'id',
      itemLabelProp = 'name',
      defaultCheckedItemIds = [], // Note that this props should work with props.`items`

      t,
    } = this.props;

    return (
      <>
        {title && <h3>{title}</h3>}

        {isLoading && <Spinner display="block" />}

        <div className="row mt-3">
          {items.map((item) => (
            <div
              className="col-12 col-md-6 col-lg-4 col-xl-3"
              key={`${idPrefix}${item[itemIdProp]}`}
            >
              <input
                id={`${idPrefix}${item[itemIdProp]}`}
                className={styles.checkable}
                type="checkbox"
                name={formFieldName}
                defaultChecked={
                  defaultCheckedItemIds.indexOf(item[itemIdProp]) > -1
                }
                disabled={readOnly || isSaving}
                onChange={(e) => this.handleCheckboxChange(e, item)}
              />
              <label htmlFor={`${idPrefix}${item[itemIdProp]}`}>
                <span>{item[itemLabelProp]}</span>
              </label>
            </div>
          ))}
        </div>

        {!readOnly && (
          <div className="mt-2">
            <AppButton
              disabled={isLoading || isSaving}
              theme="primary"
              onClick={this.handleSave}
            >
              {isSaving ? <Spinner /> : t('action.save')}
            </AppButton>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(CheckboxGroup);
