import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { showAllAlbums } from '../../../../Redux/actions/shopActions/galleryShopActions';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';

import Albums from './Albums';
import UpdateAlbum from './UpdateAlbum';

function PhotoGallery({
  // from mapStateToProps:
  auth,
  albums,

  // redux actions:
  showAllAlbums,
}) {
  const { t, i18n } = useTranslation();

  const baseUrl = '/shop-settings/gallery/';

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    showAllAlbums({ id: auth.user.id });
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    const lastI = path.lastIndexOf('/');

    if (
      path.slice(path.slice(0, lastI).lastIndexOf('/') + 1, lastI) === 'gallery'
    ) {
      setRoutes([
        {
          title: t('shopPrivate.shopLeftMenu.photoGallery'),
          path: `${path.slice(0, path.lastIndexOf('/'))}`,
        },
        {
          title: t('shopPrivate.photoGallery.album'),
          path: path,
        },
      ]);
    } else {
      setRoutes([
        {
          title: t('shopPrivate.shopLeftMenu.photoGallery'),
          path: path,
        },
      ]);
    }
  }, [i18n.language]);

  return (
    <div className="mainPanel">
      <h2 className="categoryTitle">
        <i className="fas fa-image" aria-hidden="true" />
        {t('shopPrivate.shopLeftMenu.photoGallery')}
      </h2>

      <Breadcrumb routes={routes} buttonExist />

      <div className="main-container">
        <div className="page-head">
          <h2 className="page-title">
            {t('shopPrivate.shopLeftMenu.photoGallery')}
          </h2>
        </div>

        {/* TODO Revise it */}
        <Switch>
          <Route exact path={baseUrl} component={Albums} />

          {albums.map((album) => (
            <Route
              key={album.id}
              path={baseUrl + album.id}
              render={() => <UpdateAlbum id={album.id} name={album.name} />}
            />
          ))}

          <Route path={baseUrl + 'review'} render={() => <UpdateAlbum />} />
        </Switch>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    albums: state.galleryReducer.albums?.albums || [],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showAllAlbums,
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PhotoGallery)
);
