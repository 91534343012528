import { Link } from 'react-router-dom';

import { Shop } from 'src/models/entity';

import ShopName from './ShopName';

import styles from './ShopAnchor.module.css';


interface Props {
  className?: string;
  shop: Shop;
}

const ShopAnchor = ({ className = '', shop }: Props) => {
  return shop && shop.active ? (
    <Link to={`/shop/${shop.id}`} className={`${styles.anchor} ${className}`}>
      <ShopName shop={shop} />
    </Link>
  ) : (
    <span className={className}>
      <ShopName shop={shop} />
    </span>
  );
};

export default ShopAnchor;
