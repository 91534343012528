import React, { useState } from 'react';
import PropTypes from 'prop-types';

import _uniqueId from 'lodash/uniqueId';

import Localize from '../../../../Common/LanguageSwitch/Localize';

import styles from './RootCheckable.module.css';

const RootCheckable = ({
  checked,
  item, // an object of DefaultService

  onChange,
}) => {

  const [checkboxId] = useState(_uniqueId('default-service-'));

  const handleChange = (e) => {
    onChange(item, e.target.checked);
  };

  return (
    <div
      className={`${styles.container} ${checked ? styles.stateChecked : ''}`}
    >
      <label htmlFor={checkboxId} className={styles.icon}>
        {item.iconUrl && <img src={item.iconUrl} />}
      </label>

      <div>
        <input
          id={checkboxId}
          type="checkbox"
          name="defaultService"
          value={item.id}
          checked={!!checked}
          onChange={handleChange}
        />

        <label htmlFor={checkboxId} className={styles.txt}>
          <Localize ch={item.chineseName} en={item.name} />
        </label>
      </div>
    </div>
  );
};

RootCheckable.propTypes = {
  checked: PropTypes.bool.isRequired,

  item: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

RootCheckable.defaultProps = {};

export default RootCheckable;
