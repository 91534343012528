import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';


import { useConfirmationDialog } from 'src/Providers/ConfirmDialogProvider';


interface Props {
  confirmationMessage?: string;
  title?: string;
  onConfirm: () => void;
}

/**
 * This is a fast hand button for launching a confirmation dialog before actually trigger a `Approve` procedure.
 */
const ConfirmApproveIconButton = ({
  confirmationMessage,
  title,
  onConfirm,
}: Props) => {
  const { t, i18n } = useTranslation();

  const { openConfirmationDialog } = useConfirmationDialog();

  const tooltipTitle = useMemo(
    () => title || t('action.approve'),
    [title, i18n.language]
  );

  const handleClick = () => {
    openConfirmationDialog({
      message: confirmationMessage || t('general.confirmation.itemApproval'),
      onConfirm,
    });
  };

  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <button
        className="btn btn-sm btn-outline-success mx-1"
        type="button"
        onClick={handleClick}
      >
        <i className="fas fa-check" />
      </button>
    </Tooltip>
  );
};

export default ConfirmApproveIconButton;
