import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import TabBar from '../../../Common/Navigation/TabBar/TabBar';

import ServiceTypeList from './ServiceType/ServiceTypeList';

import ServiceList from './Service/ServiceList';
import Creation from './Creation/Creation';

function ServiceAndPriceLists({}) {
  const { t, i18n } = useTranslation();

  const baseUrl = '/shop-settings/service-price/';

  const anchors = useMemo(() => {
    return [
      {
        text: t('shopPrivate.servicePriceList.services'),
        to: baseUrl + 'service',
      },
      {
        text: t('shopPrivate.servicePriceList.serviceTypes'),
        to: baseUrl + 'category',
      },
    ];
  }, [i18n.language]);

  const routes = useMemo(() => {
    return [
      {
        title: t('shopPrivate.servicePriceList.servicePriceList'),
        path: window.location.pathname,
      },
    ];
  }, [i18n.language]);

  return (
    <div className="mainPanel">
      <h2 className="categoryTitle">
        <i className="fas fa-wrench" aria-hidden="true" />
        {t('shopPrivate.servicePriceList.servicePriceList')}
      </h2>

      <Breadcrumb routes={routes} buttonExist />

      <div className="main-container">
        <TabBar anchors={anchors} />

        <Switch>
          <Route path={baseUrl + 'service/list'} component={ServiceList} />
          <Route path={baseUrl + 'service/create'} component={Creation} />
          <Route path={baseUrl + 'category'} component={ServiceTypeList} />

          {/* Default / Fallback route */}
          <Redirect to={baseUrl + 'service/list'} />
        </Switch>
      </div>
    </div>
  );
}

export default ServiceAndPriceLists;
