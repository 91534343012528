import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';


import DateInput from './DateInput';
import FormField from './FormField';
import TimePickerField from './TimePickerField';


interface Props {
  columnClassName?: string;
  dateError?: React.ReactNode;
  dateLabel?: React.ReactNode;
  defaultValue?: Date;
  disabled?: boolean;
  minToday?: boolean;
  timeError?: React.ReactNode;
  timeLabel?: React.ReactNode;
  onChange: (val: Date) => void;
}

const DateTimePickerField = ({
  // from parent:
  columnClassName = '',
  dateError,
  dateLabel,
  defaultValue,
  disabled = false,
  minToday = false,
  timeError,
  timeLabel,
  onChange,
}: Props) => {
  const DATE_FORMAT = 'YYYY-MM-DD';

  const [initialized, setInitialized] = useState(false);

  const [dateTimeValue, setDateTimeValue] = useState<Date>();

  const [dateString, setDateString] = useState('');

  const minDateConstraint = useMemo(() => {
    if (minToday) {
      return moment().format(DATE_FORMAT);
    }
    return undefined;
  }, [minToday]);

  useEffect(() => {
    // On component mounts, determine initial values of date / time pickers

    if (defaultValue) {
      setDateTimeValue(defaultValue);
      setDateString(moment(defaultValue).format(DATE_FORMAT));
    } else {
      const atMoment = moment(); // now

      atMoment.minute(Math.ceil(atMoment.minute() / 5) * 5); // advance to the "next 5th minute"

      setDateTimeValue(atMoment.toDate());
      setDateString(atMoment.format(DATE_FORMAT));
    }

    setInitialized(true);
  }, []);

  /**
   *
   * @param {string} nextDate It may be empty string if user clicks "Clear" at browser's native calender
   */
  const handleChangeDate = (nextDate: string) => {
    // In case of empty value, we adopt today:
    const nextDateMoment = nextDate ? moment(nextDate) : moment();

    const nextAtMoment = moment(dateTimeValue); // make a clone

    // Adopt the date-parts
    nextAtMoment.year(nextDateMoment.year());
    nextAtMoment.month(nextDateMoment.month());
    nextAtMoment.date(nextDateMoment.date());

    const nextValue = nextAtMoment.toDate();
    setDateTimeValue(nextValue);
    setDateString(nextAtMoment.format(DATE_FORMAT));

    onChange(nextValue);
  };

  const handleChangeTime = (nextTime: Date) => {
    const nextTimeMoment = moment(nextTime);

    const nextAtMoment = moment(dateTimeValue); // make a clone

    // Adopt the time-parts
    nextAtMoment.hour(nextTimeMoment.hour());
    nextAtMoment.minute(nextTimeMoment.minute());

    const nextValue = nextAtMoment.toDate();
    setDateTimeValue(nextValue);

    onChange(nextValue);
  };

  return (
    <div className="row align-items-center">
      {/* Date Picker */}
      <div className={`col-12 col-lg-6 ${columnClassName} mb-1 mb-lg-0`}>
        <FormField
          className="m-0"
          errorMessage={dateError}
          iconClass="fas fa-calendar"
          label={dateLabel}
          required
        >
          {initialized && (
            <DateInput
              disabled={disabled}
              min={minDateConstraint}
              value={dateString}
              onChange={(e) => handleChangeDate(e.target.value)}
            />
          )}
        </FormField>
      </div>

      {/* Time Picker */}
      <div className={`col-12 col-lg-6 ${columnClassName} mb-1 mb-lg-0`}>
        {initialized && (
          <TimePickerField
            className="m-0"
            disabled={disabled}
            errorMessage={timeError}
            defaultValue={dateTimeValue}
            label={timeLabel}
            required
            onChange={handleChangeTime}
          />
        )}
      </div>

      {/* For debug */}
      {/*
      <div className="col-12 my-2 py-1 text-center bg-info">
        {dateTimeValue.format('Y/MM/DD hh:mm:ss A')}
      </div>
      */}
    </div>
  );
};

export default DateTimePickerField;
