import { CREATE_SERVICE_TYPE, GET_ALL_SERVICE_TYPES, GET_CREATION_SERVICE_TYPES } from '../../constants/constants';

const initialState = {
  serviceTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SERVICE_TYPES:
      return {
        ...state,
        serviceTypes: action.payload,
      };
    case GET_CREATION_SERVICE_TYPES:
      return {
        ...state,
        serviceTypes: action.payload,
      };
    case CREATE_SERVICE_TYPE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
