import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import { updateSchedule } from '../../../../../Redux/actions/features/schedule';
import { saveUserSettings } from '../../../../../Redux/actions/features/changeMedia';
import { shopData } from '../../../../../Redux/actions/shopActions/shopData';

import { AppButton } from '../../../../Common/Button';
import Notification from '../../../Notification';

import DayField from './DayField';

class Schedule extends Component {
  state = {
    schedule: [],
    status: '',
    openTime: false,
  };

  componentDidMount() {
    const { user_id, getShopInformation, t } = this.props;
    const default_schedule = [
      {
        id: 1,
        name: t('datetime.weekday.monday'),
        pivot: {
          day_id: 1,
          time_from: '00:00',
          time_to: '23:55',
          user_id: 5,
          working: 0,
        },
      },
      {
        id: 2,
        name: t('datetime.weekday.tuesday'),
        pivot: {
          day_id: 1,
          time_from: '00:00',
          time_to: '23:55',
          user_id: 5,
          working: 0,
        },
      },
      {
        id: 3,
        name: t('datetime.weekday.wednesday'),
        pivot: {
          day_id: 1,
          time_from: '00:00',
          time_to: '23:55',
          user_id: 5,
          working: 0,
        },
      },
      {
        id: 4,
        name: t('datetime.weekday.thursday'),
        pivot: {
          day_id: 1,
          time_from: '00:00',
          time_to: '23:55',
          user_id: 5,
          working: 0,
        },
      },
      {
        id: 5,
        name: t('datetime.weekday.friday'),
        pivot: {
          day_id: 1,
          time_from: '00:00',
          time_to: '23:55',
          user_id: 5,
          working: 0,
        },
      },
      {
        id: 6,
        name: t('datetime.weekday.saturday'),
        pivot: {
          day_id: 1,
          time_from: '00:00',
          time_to: '23:55',
          user_id: 5,
          working: 0,
        },
      },
      {
        id: 7,
        name: t('datetime.weekday.sunday'),
        pivot: {
          day_id: 1,
          time_from: '00:00',
          time_to: '23:55',
          user_id: 5,
          working: 0,
        },
      },
    ];

    getShopInformation({ id: user_id }).then(res => {
      if (res.res.data.message === 'success') {
        if (res.res.data.data.schedule.length > 0) {
          this.setState({
            schedule: res.res.data.data.schedule,
          });
        } else {
          this.setState({
            schedule: default_schedule,
          });
        }
      }
    })
  }

  sendData = () => {
    const scheduleToSend = {};
    const { schedule } = this.state;
    for (let i = 1; i < schedule.length + 1; i++) {
      scheduleToSend[i] = {};
      scheduleToSend[i].time_from = schedule[i - 1].pivot.time_from;
      scheduleToSend[i].time_to = schedule[i - 1].pivot.time_to;
      scheduleToSend[i].working = schedule[i - 1].pivot.working;
    }
    this.props
      .updateSchedule({
        schedule: scheduleToSend,
      })
      .then(res => {
        if (res.res.data.message === 'success') {
          this.setState({
            loading: true,
            status: 'success',
          });
          setTimeout(() => {
            this.setState({
              status: '',
            });
          }, 3000);
        } else {
          this.setState({
            status: 'error',
          });
          setTimeout(() => {
            this.setState({
              status: '',
            });
          }, 3000);
          this.props.getShopInformation({ id: this.props.user_id }).then(res => {
            if (res.res.data.message === 'success') {
              this.setState({
                loading: false,
              });
            }
          });
        }
      });
  };

  setStatusClosed = () => {
    this.setState({
      status: '',
    });
  };

  handleChecked = ({ target }, id) => {
    this.setState(prevState => {
      const newSchedule = [...prevState.schedule];
      newSchedule[Number(id) - 1].pivot.working = Number(target.checked);
      return {
        schedule: newSchedule,
      };
    });
  };

  handleChangeTime = ({ target }, id, time_type, pm) => {
    if (target.getAttribute('name') === 'howOclockFormat') {
      this.setState(prevState => {
        const newSchedule = [...prevState.schedule];
        const time = prevState.schedule[Number(id) - 1].pivot[time_type];
        let hours = time.slice(0, time.indexOf(':'));
        const newHours =
          Number(hours) < 12
            ? (Number(hours) + 12).toString()
            : (Number(hours) - 12).toString().length === 1
              ? `0${(Number(hours) - 12).toString()}`
              : (Number(hours) - 12).toString();
        const newTime = time.replace(/^\d{2}/, newHours);
        newSchedule[Number(id) - 1].pivot[time_type] = newTime;
        return {
          schedule: newSchedule,
        };
      });
    } else if (target.getAttribute('name') === 'hours') {
      this.setState(prevState => {
        const newSchedule = [...prevState.schedule];
        const hours = pm ? (Number(target.value) + 12).toString() : target.value;
        const time = prevState.schedule[Number(id) - 1].pivot[time_type].replace(/^\d{2}/, hours);
        newSchedule[Number(id) - 1].pivot[time_type] = time;
        return {
          schedule: newSchedule,
        };
      });
    } else {
      this.setState(prevState => {
        const newSchedule = [...prevState.schedule];
        const time = prevState.schedule[Number(id) - 1].pivot[time_type].replace(/\d{2}$/, target.value);
        newSchedule[Number(id) - 1].pivot[time_type] = time;
        return {
          schedule: newSchedule,
        };
      });
    }
  };

  handleCloseWindows = (e) => {
    const name = e.target.classList[0];
    if (
      name === 'date-drop' ||
      name === 'date-drop__item' ||
      name === 'date-drop__body' ||
      name === 'date-select__ico' ||
      name === 'date-select' ||
      name === 'date-select' ||
      name === 'date-choise' ||
      name === 'date-separator' ||
      name === 'date-drop__footer' ||
      name === 'date-check' ||
      name === 'date-select__item' ||
      name === 'checkbox-date__am' ||
      name === 'checkbox-date__label' ||
      name === 'checkbox-date' ||
      name === 'field-wrap_input' ||
      name === 'checkbox-date__pm'
    ) {
      return false;
    } else {
      this.setState({
        openTime: !this.state.openTime
      })
    }
  };


  render() {
    const { status, schedule, openTime } = this.state;
    const { t } = this.props;
    return (
      <div className="main-container__content" onClick={(e) => this.handleCloseWindows(e)}>
        {status === 'success' && <Notification text={t('notifications.changesSaved')} onClose={this.setStatusClosed} />}
        {status === 'error' && (
          <Notification text={t('notifications.somethingWasWrong')} onClose={this.setStatusClosed} />
        )}

        <h2>{t('shopPrivate.schedule.workingDaysHours')}</h2>

        <div className="border mt-4 p-4 schedule-row">
          {schedule.map((day) => (
            <DayField
              key={day.id}
              day={day}
              openTime={openTime}
              handleChangeTime={this.handleChangeTime}
              handleChecked={this.handleChecked}
            />
          ))}
        </div>

        <AppButton className="mt-3" theme="primary" onClick={this.sendData}>
          {t('action.save')}
        </AppButton>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user_id: state.auth.user.id,
    schedule: state.ShopReducer.shopData.schedule,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    {
      userDataSelect: saveUserSettings,
      updateSchedule,
      getShopInformation: shopData,
    }
  )(Schedule)
);
