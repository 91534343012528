import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';

import AspectImage from '../../../Common/AspectImage/AspectImage';
import PhotoViewer from '../../../Common/PhotoViewer/PhotoViewer';


class ShopMainSlider extends Component {
  state = {
    isPhotoViewerOpened: false,
    activePhotoIndex: 0,
  };

  sliderSettings = {
    // className: "slider-preview",
    dots: false,
    // dotsClass: 'slick-dots slick-thumb',
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: true,
    // customPaging: i => (
    //   <img
    //     src={photos[i].url}
    //     style={{ cursor: 'pointer' }}
    //     name={i}
    //     onClick={handleClickOnImage}
    //     alt={`slider_photo_boottom_${i}`}
    //   />
    // ),
  };

  openPhotoViewer = (activePhotoIndex) => {
    this.setState({
      isPhotoViewerOpened: true,
      activePhotoIndex,
    });
  };

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
    });
  };

  render() {
    const {
      photos,
    } = this.props;
    const {
      isPhotoViewerOpened,
      activePhotoIndex,
    } = this.state;

    const tempPhoto = (
      <div className="slider-preview temp-cover temp-cover--no-border">
        <span className="temp-ico">
          <i className="fas fa-camera" />
        </span>
      </div>
    );

    return (
      <>
        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={photos}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        <div className="product-slider shop-main__slider">
          {/* <div className="product-slider__main temp-cover temp-cover--no-border"> */}
          <Slider {...this.sliderSettings}>
            {photos instanceof Array && photos.length > 0
              ? photos.map((photo, index) => (
                  <div key={index} className="slider-preview temp-cover temp-cover--no-border">
                    <AspectImage aspectRatio="16:9">
                      <img
                        src={photo.url}
                        alt={`slider_photo${index}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.openPhotoViewer(index)}
                      />
                    </AspectImage>
                  </div>
                ))
              : tempPhoto}
          </Slider>
          {/* </div> */}

          <div className="product-slider__box">
            {photos instanceof Array && photos.length > 0 ? (
              photos.map((photo, index) => (
                <div key={index} className="slider-preview temp-cover temp-cover--no-border">
                  <AspectImage aspectRatio="16:9">
                    <img
                      src={photo.url}
                      name={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.openPhotoViewer(index)}
                    />
                  </AspectImage>
                </div>
              ))
            ) : (
              <Fragment>
                {tempPhoto}
                {tempPhoto}
                {tempPhoto}
                {tempPhoto}
              </Fragment>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ShopMainSlider;
