import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';


import styles from './BannerCarousel.module.css';


const sliderSettings = {
  dots: true,
  dotsClass: `slick-dots ${styles.dotList}`,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  swipeToSlide: true,
  pauseOnHover: false,
};


const BannerCarousel = ({
  banners,
}) => {

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    setPhotos((banners || []).map(banner => banner.image));
  }, [banners]);

  return (
    <section className={styles.root}>
      {photos.length > 0 && (
        <Slider {...sliderSettings}>
          {photos.map((photo, index) => (
            <a
              href={photo.link || '/'}
              key={index}
              className={styles.slide}
              target="_blank"
            >
              <img
                alt={photo.alt || ''}
                className={styles.bannerImg}
                src={photo.url}
              />
            </a>
          ))}
        </Slider>
      )}
    </section>
  );
};


BannerCarousel.propTypes = {

  banners: PropTypes.array.isRequired,

};

export default BannerCarousel;
