import React, { useMemo } from 'react';


interface Props {
  className?: string;
  disabled?: boolean;
  iconAtRight?: boolean;
  max?: string;
  min?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateInput = ({
  className = '',
  disabled = false,
  iconAtRight = false,
  max,
  min,
  name = '',
  placeholder = '',
  required = false,
  value,
  onChange,
}: Props) => {
  const inputClassName = useMemo(() => {
    return `${className} form-item ${
      iconAtRight ? 'form-item--ico-right' : 'form-item--ico-left'
    }`;
  }, [className, iconAtRight]);

  return (
    <input
      className={inputClassName}
      disabled={disabled}
      max={max}
      min={min}
      name={name}
      placeholder={(!disabled && placeholder) || ''}
      // required={required}
      type="date"
      value={value || ''}
      onChange={onChange}
    />
  );
};

export default DateInput;
