import React, { Suspense } from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider as ReduxProvider } from 'react-redux';

import ReactGA from 'react-ga';

import GeneralStore from '../src/Redux/store/GeneralStore';
import { checkToken } from './Redux/actions/authActions/signInActions';

import ConfirmDialogProvider from './Providers/ConfirmDialogProvider';

import Spinner from './Components/Common/Spinner/Spinner';

import App from './App';
import './i18n';

import './dist/css/main_04_app.css';
import './dist/css/shop_admin.css';
import './dist/css/custom.css';

const store = GeneralStore();
store.dispatch(checkToken()); // On app (page) launches, reauthenticate user

ReactGA.initialize('UA-143973509-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const render = () => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <BrowserRouter onUpdate={logPageView}>
        <Suspense fallback={<Spinner />}>
          <ConfirmDialogProvider>
            <App />
          </ConfirmDialogProvider>
        </Suspense>
      </BrowserRouter>
    </ReduxProvider>,
    document.getElementById('root')
  );
};

if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}

render();
