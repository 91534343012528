import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import _uniqueId from 'lodash/uniqueId';

import api from '../../api';
import notification from '../Common/notification';

import { AppButton } from '../Common/Button';
import { TextInputField } from '../Common/Form';
import Spinner from '../Common/Spinner/Spinner';

function collectError(source) {
  if (source instanceof Array) {
    return source.join('\n');
  }
  return source;
}

function ChangePasswordForm() {
  const { t } = useTranslation();

  const [showPlainPasswordCheckboxId] = useState(_uniqueId());
  const [showPlainPassword, setShowPlainPassword] = useState(false);

  const [dirtyOldPassword, setDirtyOldPassword] = useState('');
  const [dirtyNewPassword, setDirtyNewPassword] = useState('');
  const [dirtyNewPasswordRetype, setDirtyNewPasswordRetype] = useState('');

  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState('');
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
  const [newPasswordRetypeErrorMessage, setNewPasswordRetypeErrorMessage] =
    useState('');

  const [isChangingPassword, setChangingPassword] = useState(false);

  const isFormDataSufficient = useMemo(
    () => !!dirtyOldPassword && !!dirtyNewPassword && !!dirtyNewPasswordRetype,
    [dirtyOldPassword, dirtyNewPassword, dirtyNewPasswordRetype]
  );

  const togglePlainPassword = () => {
    setShowPlainPassword((val) => !val);
  };

  const handleValidationError = (response) => {
    if (response.data?.old_password) {
      setOldPasswordErrorMessage(collectError(response.data.old_password));
    } else if (response.message != 'incorrect data') {
      setOldPasswordErrorMessage(response.message);
    }

    if (response.data?.password) {
      setNewPasswordErrorMessage(collectError(response.data.password));
    }

    if (response.data?.password_confirmation) {
      setNewPasswordRetypeErrorMessage(
        collectError(response.data.password_confirmation)
      );
    }
  };

  const changePassword = (e) => {
    e.preventDefault();

    setOldPasswordErrorMessage('');
    setNewPasswordErrorMessage('');
    setNewPasswordRetypeErrorMessage('');

    setChangingPassword(true);

    const data = {
      old_password: dirtyOldPassword,
      password: dirtyNewPassword,
      password_confirmation: dirtyNewPasswordRetype,
    };

    api.account.changePassword(data)
      .then((res) => res.data)
      .then((response) => {
        if (response.status === 422) {
          // validation failed
          handleValidationError(response);
        } else if (response.status === 200) {
          notification(t('notifications.passChanged'));

          setDirtyOldPassword('');
          setDirtyNewPassword('');
          setDirtyNewPasswordRetype('');
        } else {
          notification(response.message || t('notifications.somethingWasWrong'));
        }
      })
      .catch((error) => {
        notification(t('notifications.somethingWasWrong'));
        console.error(error);
      })
      .finally(() => {
        setChangingPassword(false);
      });
  };

  return (
    <form onSubmit={changePassword}>
      <fieldset disabled={isChangingPassword}>
        <div className="settings-title">
          {t('shopPrivate.general.changePass')}
        </div>

        <div className="row">
          <div className="col-xl-6">
            {/* Setting autoComplete="off" is a security measure; preventing any suspicious person
             using user's computer's browser to autofill an old password. */}
            <TextInputField
              autoComplete="off"
              errorMessage={oldPasswordErrorMessage}
              iconClass="fas fa-lock"
              label={t('auth.oldPassword.label')}
              name="old_password"
              required
              type={showPlainPassword ? 'text' : 'password'}
              value={dirtyOldPassword}
              onChange={(e) => setDirtyOldPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6">
            <TextInputField
              autoComplete="new-password"
              errorMessage={newPasswordErrorMessage}
              iconClass="fas fa-lock"
              label={t('auth.newPassword.label')}
              name="password"
              required
              type={showPlainPassword ? 'text' : 'password'}
              value={dirtyNewPassword}
              onChange={(e) => setDirtyNewPassword(e.target.value)}
            />
          </div>

          <div className="col-xl-6">
            <TextInputField
              autoComplete="new-password"
              errorMessage={newPasswordRetypeErrorMessage}
              iconClass="fas fa-lock"
              label={t('auth.passwordRetype.label')}
              name="password_confirmation"
              required
              type={showPlainPassword ? 'text' : 'password'}
              value={dirtyNewPasswordRetype}
              onChange={(e) => setDirtyNewPasswordRetype(e.target.value)}
            />
          </div>

          <div className="col-12">
            <input
              className="checkbox"
              name="showPassword"
              id={showPlainPasswordCheckboxId}
              type="checkbox"
              onClick={togglePlainPassword}
            />

            <label htmlFor={showPlainPasswordCheckboxId}>
              <span className="terms-check">
                {t('shopPrivate.general.showPass')}
              </span>
            </label>
          </div>
        </div>

        <AppButton
          className="mt-2"
          theme="primary"
          disabled={!isFormDataSufficient}
          type="submit"
        >
          {isChangingPassword ? (
            <Spinner />
          ) : (
            t('shopPrivate.general.changePass')
          )}
        </AppButton>
      </fieldset>
    </form>
  );
}

export default ChangePasswordForm;
