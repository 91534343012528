import { AxiosResponse } from 'axios';

import {
  BaseResponse,
  ValidationErrors,
} from 'src/models/api/response/BaseResponse';

/**
 *
 * Collect and join all validation failure error message into a single string, separated by newline character
 * @param dataMap
 * @returns
 */
export const collectValidationError = (dataMap: ValidationErrors) => {
  return Object.values(dataMap)
    .map((errors: string[]) => errors.join('\n'))
    .join('\n');
};

function promptError422(response: BaseResponse) {
  setTimeout(() => {
    if (response && response.errors) {
      alert(collectValidationError(response.errors));
    }
  }, 0);
}

function promptError422Legacy(
  response: BaseResponse & { data?: ValidationErrors }
) {
  setTimeout(() => {
    if (response && response.data) {
      alert(collectValidationError(response.data));
    }
  }, 0);
}

export const apiErrorSweeper = (e: any) => {
  try {
    if (e.response) {
      const axiosResponse: AxiosResponse = e.response;
      if (axiosResponse.status === 404) {
        alert('The requested resource cannot be found.');
        return;
      }
      else if (axiosResponse.status === 422) {
        promptError422(axiosResponse.data);
        return;
      } else {
        // any bad request
        const response = axiosResponse.data;
        console.warn(response);

        setTimeout(() => {
          if (response?.message) {
            alert(response.message);
          } else {
            alert('Something is wrong...');
          }
        }, 0);
      }
    } else {
      // Legacy logic
      const parsed = JSON.parse(e.message);
      if (!parsed) {
        throw e;
      }

      const response: BaseResponse = parsed;

      if (response.status === 422) {
        console.log('apiErrorSweeper captured 422');
        // validation error
        promptError422Legacy(response);
      } else {
        console.log(`Captured status ${response.status}`);
        // any bad request
        setTimeout(() => {
          if (response.message) {
            alert(response.message);
          } else {
            alert('Something is wrong...');
          }
        }, 0);
      }
    }
  } catch (error) {
    // unable to handle the error, simply log it to console
    console.error(error);
  }
};
