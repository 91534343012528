import {
  ADMIN_PAYMENT_METHOD_WILL_FETCH,
  ADMIN_PAYMENT_METHOD_DID_FETCH,
  ADMIN_PAYMENT_METHOD_WILL_CREATE,
  ADMIN_PAYMENT_METHOD_DID_CREATE,
  ADMIN_PAYMENT_METHOD_WILL_UPDATE,
  ADMIN_PAYMENT_METHOD_DID_UPDATE,
} from "../../constants/constants";


/**
 * This state helps manage one particular `PaymentMethod`.
 */
const initialState = {
  isFetchingPaymentMethod: false,

  isCreatingPaymentMethod: false,

  isUpdatingPaymentMethod: false,

  paymentMethod: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_PAYMENT_METHOD_WILL_FETCH:
      return {
        ...state,
        isFetchingPaymentMethod: true,
      };

    case ADMIN_PAYMENT_METHOD_DID_FETCH:
      return {
        ...state,
        isFetchingPaymentMethod: false,
        paymentMethod: action.payload,
      };

    case ADMIN_PAYMENT_METHOD_WILL_CREATE:
      return {
        ...state,
        isCreatingPaymentMethod: true,
      };

    case ADMIN_PAYMENT_METHOD_DID_CREATE:
      return {
        ...state,
        isCreatingPaymentMethod: false,
      };

    case ADMIN_PAYMENT_METHOD_WILL_UPDATE:
      return {
        ...state,
        isUpdatingPaymentMethod: true,
      };

    case ADMIN_PAYMENT_METHOD_DID_UPDATE:
      return {
        ...state,
        isUpdatingPaymentMethod: false,
        paymentMethod: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
