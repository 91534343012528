import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import notification from '../../../Common/notification';

import { getShopStaffScaleList } from '../../../../Redux/actions/definitionActions/shopStaffScaleActions';

import { AppButton } from '../../../Common/Button';
import { FormField, Textarea, TextInputField } from '../../../Common/Form';
import PhotoPickerDialog from '../../../Common/Photo/PhotoPickerDialog';
import { ShopAvatar } from '../../../Common/Shop';
import Spinner from '../../../Common/Spinner/Spinner';

import ChangePasswordForm from '../../../Auth/ChangePasswordForm';


function GeneralPage({
  isLoadingShopStaffScaleList,
  shopStaffScaleList,

  avatar,
  about,
  avatarUploadStatus,
  errorPhoto,
  upload,
  setAvatarStatus,
  handleChange,
  handleClick,
  enableBookingSystem,
  name,
  chineseName,
  countStaffInShop_id,
  businessRegistrationNumber,
  prevAbout,
  prevEnableBookingSystem,
  prevCountStaffInShop_id,
  prevBusinessRegistrationNumber,
  prevChineseName,
  prevName,
  isUploadModal,
  getImage,
  toggleUploadModal,
  shopData,

  sendData,
  getShopStaffScaleList,
}) {

  const { t, i18n } = useTranslation();

  const isChinese = useMemo(
    () => !i18n.language.startsWith('en'),
    [i18n.language]
  );

  useEffect(() => {
    getShopStaffScaleList();
  }, []);

  const checkAboutChanges = () => {
    if (
      prevAbout === about &&
      prevEnableBookingSystem === enableBookingSystem &&
      Number(prevCountStaffInShop_id) === Number(countStaffInShop_id) &&
      prevBusinessRegistrationNumber === businessRegistrationNumber &&
      prevChineseName === chineseName &&
      prevName === name
    ) {
      notification(t('notifications.noChanges'));
    } else {
      sendData();
    }
  };

  return (
    <div className="main-container__content">
      {isUploadModal && (
        <PhotoPickerDialog
          onClose={toggleUploadModal}
          getImage={getImage}
          imageWidth={250}
          imageHeight={250}
          propsImage={avatar.length > 0 ? avatar[0] : shopData.avatar}
        />
      )}

      <div className="container-fluid pb-3">
        <div className="row">

          <div className="col-12 col-lg-2">
            <div className="shop-avatar">
              {!avatarUploadStatus ? (
                <ShopAvatar src={avatar.length > 0 ? avatar[0] : shopData.avatar} />
              ) : (
                <input
                  // type="file"
                  type="text"
                  style={{ display: 'none' }}
                  id="avatar"
                  // accept="image/*"
                  // onChange={upload}
                  onClick={toggleUploadModal}
                />
              )}
              <label className="shop-avatar__btn" htmlFor="avatar">
                <i className="fas fa-plus-circle" onClick={setAvatarStatus} />
              </label>
              {errorPhoto && <span className="error-text">{t('shopPrivate.general.errMxSize', { size: 5 })}</span>}
            </div>
          </div>

          <div className="col">
            <div className="row">
              <div className="col-12 col-lg">
                {/* About Shop */}
                <FormField label={t('shopPrivate.general.aboutShop')}>
                  <Textarea
                    name="about"
                    value={about}
                    onChange={handleChange}
                  />
                </FormField>
              </div>

              <div className="col-12 col-lg-3 text-lg-center">
                {/* Whether to turn on booking system */}
                <h6>{t('shopPrivate.general.bookingSystem')}</h6>
                <div className={`onoffswitch ${isChinese ? 'onoffswitch--chinese' : ''}`}>
                  <input
                    type="checkbox"
                    name="onoffswitch"
                    className="onoffswitch-checkbox"
                    id="myonoffswitch"
                    checked={enableBookingSystem}
                    onChange={handleClick}
                  />
                  <label className="onoffswitch-label" htmlFor="myonoffswitch">
                    <span className="onoffswitch-inner onoffswitch-inner--type2" />
                    <span className="onoffswitch-switch" />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                {/* Chinese name */}
                <TextInputField
                  disabled
                  iconClass="fas fa-user"
                  label={t('shop.nameInChinese.label')}
                  name="chineseName"
                  placeholder={t('shop.nameInChinese.placeholder')}
                  value={chineseName}
                  onChange={handleChange}
                />
              </div>

              <div className="col-xl-6">
                {/* English name */}
                <TextInputField
                  disabled
                  iconClass="fas fa-user"
                  label={t('shop.nameInEnglish.label')}
                  name="name"
                  placeholder={t('shop.nameInEnglish.placeholder')}
                  value={name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                {/* Number of staff / staff scale */}
                <span className="field-label">{t('shopPrivate.general.howMany')}</span>
                {isLoadingShopStaffScaleList ? (
                  <Spinner />
                ) : (
                  <div className="row">
                    {shopStaffScaleList.map((option, index) => (
                      <div className="col-12 col-xl-2" key={index}>
                        <div className="d-flex">
                          <input
                            type="radio"
                            className="theme-radio"
                            id={`c${index + 1}`}
                            value={option.id}
                            name="countStaffInShop_id"
                            defaultChecked={countStaffInShop_id == option.id}
                            onClick={handleChange}
                          />
                          <label htmlFor={`c${index + 1}`}>
                            <span>{option.name}</span>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col">
                {/* Business Registration Number */}
                <TextInputField
                  disabled
                  label={t('general.label.businessRegistrationNumber')}
                  name="businessRegistrationNumber"
                  value={businessRegistrationNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            <AppButton
              className="mt-4"
              disabled={
                (prevAbout === about &&
                  prevEnableBookingSystem === enableBookingSystem &&
                  Number(prevCountStaffInShop_id) === Number(countStaffInShop_id) &&
                  prevBusinessRegistrationNumber === businessRegistrationNumber &&
                  prevChineseName === chineseName &&
                  prevName === name) ||
                  (!name || !chineseName || !businessRegistrationNumber)
              }
              theme="primary"
              onClick={checkAboutChanges}
            >
              {t('action.save')}
            </AppButton>
          </div>
        </div>
      </div>

      <hr />

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10">
            <ChangePasswordForm />
          </div>
        </div>
      </div>

    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoadingShopStaffScaleList: state.shopStaffScaleReducer.isLoadingShopStaffScaleList,
    shopStaffScaleList: state.shopStaffScaleReducer.shopStaffScaleList,
    shopData: state.ShopReducer.shopData,
  };
}

export default connect(
  mapStateToProps,
  {
    getShopStaffScaleList,
  }
)(GeneralPage);
