import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';


import AspectImage from '../../../Common/AspectImage/AspectImage';
import { EllipsizeBlock } from '../../../Common/Text';
import ExternalFeedThumbnail from '../../../Common/ExternalFeed/ExternalFeedThumbnail';


function NextArrow({ onClick }) {
  return (
    <div className="mob-slider-control mob-slider-control--next">
      <button onClick={onClick}>
        <i className="fas fa-angle-right" aria-hidden="true" />
      </button>
    </div>
  );
}

function PrevArrow({ onClick }) {
  return (
    <div className="mob-slider-control">
      <button onClick={onClick}>
        <i className="fas fa-angle-left" aria-hidden="true" />
      </button>
    </div>
  );
}


class ShopMainNewsCardboard extends Component {

  sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  redirectToNews = id => {
    this.props.history.push(`${this.props.url.slice(0, this.props.url.lastIndexOf('main'))}news/${id}`);
  };

  render() {
    const {
      className,
      newsList,
      url,
      t,
    } = this.props;

    return (
      <div className={`shop-group ${className}`}>
        <p className="theme-subtitle">{t('shopPublic.overview.news')}</p>

        <Slider {...this.sliderSettings}>
          {newsList && newsList.map((shopNews) => (
            <div className="shop-news" key={shopNews.id}>
              <div onClick={() => this.redirectToNews(shopNews.id)} style={{ cursor: 'pointer' }}>
                <h5>
                  <Link
                    to={`${url.slice(0, url.lastIndexOf('/'))}/news/${shopNews.id}`}
                    className="news-title"
                    onClick={e => e.stopPropagation()}
                  >
                    {shopNews.title}
                  </Link>
                </h5>

                <EllipsizeBlock
                  className="news-content"
                  maxlength={300}
                  text={shopNews.description}
                />
              </div>

              <Link to={`${url.slice(0, url.lastIndexOf('/'))}/news/${shopNews.id}`} className="more-link">
                {t('shopNews.viewCompleteContent')}
              </Link>

              <div className="shop-news-attachment-box">
                {shopNews.externalFeeds && shopNews.externalFeeds.slice(0, 1).map((feed, index) => (
                  <div
                    key={index}
                    className="shop-news-attachment-thumb"
                    onClick={() => this.redirectToNews(shopNews.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <ExternalFeedThumbnail feed={feed} />
                  </div>
                ))}

                {shopNews.photos && shopNews.photos.slice(0, 3).map((photo) => (
                  <div
                    key={photo.id}
                    className="shop-news-attachment-thumb"
                    onClick={() => this.redirectToNews(shopNews.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <AspectImage aspectRatio="1:1">
                      <img src={photo.url} alt="news image" />
                    </AspectImage>
                  </div>
                ))}
              </div>

              <div className="news-share">
              {/*
                <a href="#" className="news-share__item">
                  <i className="fab fa-facebook-square" />
                </a>
              */}
                <div
                  className="news-share__item"
                  data-href={`${window.location.origin}/shop/news/${shopNews.id}`}
                  data-layout="button"
                  data-size="small"
                  data-mobile-iframe="true"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${
                      window.location.origin
                    }/api/shop/news/facebook/${shopNews.shop_id}/${shopNews.id}/${
                      shopNews.title
                    }/${shopNews.description.slice(0, 75)}?${
                      shopNews.photos[0] ? 'image=' + shopNews.photos[0].url : ''
                    }`}
                    className="news-share__item"
                  >
                    <i className="fab fa-facebook-square" />
                  </a>
                </div>
                {/*
                <a href="#" className="news-share__item">
                  <i className="fab fa-twitter-square" />
                </a>
                */}
                <div id="bt_twitter" data-network="twitter" className="news-share__item">
                  <a
                    className="news-share__item"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://twitter.com/intent/tweet?original_referer=${
                      window.location.origin
                    }/shop/news/${shopNews.id}&screen_name=SalonfinderH&text=${window.location.origin}/shop/news/${
                      shopNews.id
                    }`}
                  >
                    <i className="fab fa-twitter-square" />
                  </a>
                </div>
                {/*
                <a href="#" className="news-share__item">
                  <i className="fab fa-linkedin-square" />
                </a>
                */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

ShopMainNewsCardboard.defaultProps = {
  newsList: [],
  url: {},
  history: {},
};

export default withTranslation()(ShopMainNewsCardboard);
