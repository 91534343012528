import { useEffect, useState } from 'react';
import Rating from 'react-rating';

import styles from './RatingStars.module.css';

interface Props {
  rating: number;
  showNumeric?: boolean;
}

function RatingStars({ rating, showNumeric = false }: Props) {
  const [numeric, setNumeric] = useState(0);
  const [formatted, setFormatted] = useState('');

  useEffect(() => {
    setNumeric(Number(rating));
    setFormatted(Number(rating).toFixed(1));
  }, [rating]);

  return (
    <div>
      <Rating
        initialRating={numeric}
        emptySymbol={<i className={`far fa-star ${styles.star}`} />}
        fullSymbol={<i className={`fas fa-star ${styles.star}`} />}
        fractions={2}
        readonly={true}
        start={0}
        stop={5}
      />

      {showNumeric && <span className={`ml-1 ${styles.val}`}>{formatted}</span>}
    </div>
  );
}

export default RatingStars;
