import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import { saveUserSettings } from '../../../../Redux/actions/features/changeMedia';
import { shopData } from '../../../../Redux/actions/shopActions/shopData';

import { AppButton } from '../../../Common/Button';
import { FormField, TextInputField } from '../../../Common/Form';

// import GoogleSearchInput from '../../StaffPrivatePage/generalSettings/GoogleSearchInput';
import ModalChangeContacts from './ModalChangeContacts';
import Notification from '../../Notification';

class Contacts extends Component {
  componentDidMount() {
    this.props.setCitiesDistricts();
  }

  // handlePhoneChange = ({ target }) => {
  //   let regExp = /^\d+$/;
  //   if (target.value === '+852' || regExp.test(target.value.substring(4))) {
  //     this.setState({
  //       phone: target.value.substring(4),
  //       errors: {
  //         ...this.state.errors,
  //         phone_error: false,
  //       },
  //     });
  //   } else if (target.value === '+852') {
  //     this.setState({
  //       phone: '',
  //       errors: {
  //         ...this.state.errors,
  //         phone_error: true,
  //       },
  //     });
  //   }
  // };

  render() {
    const {
      code,
      status,
      message,
      address,
      email,
      errorEmail,
      phone,
      errorPhone,
      website,
      modalStatus,
      sendCode,
      handleChange,
      handleChangeAddress,
      handleSelectAddress,
      sendContactsData,
      onClose,
      prevWebsite,
      prevAddress,
      prevEmail,
      prevPhone,
      cities,
      districts,
      handleClickOnCity,
      handleClickOnDistrict,
      selectedCity,
      selectedDistrict,
      adminName,
      adminPhone,
      prevAdminName,
      prevAdminPhone,
      prevSelectedCity,
      prevSelectedDistrict,
      t,
    } = this.props;

    return (
      <>
        {(status === 'success' || status === 'error') && (
          <Notification text={message} onClose={this.setStatusClosed} />
        )}

        {modalStatus && (
          <ModalChangeContacts
            code={code}
            sendCode={sendCode}
            onChange={handleChange}
            onClose={onClose}
          />
        )}

        <div className="main-container__content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <TextInputField
                  label={t('general.label.website')}
                  name="website"
                  type="url"
                  value={website}
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-6">
                <TextInputField
                  disabled
                  label={t('generalFields.email.label')}
                  value={email}
                />
              </div>

              <div className="col-lg-6">
                <TextInputField
                  disabled
                  label={t('shop.contactNumber.label')}
                  value={phone && '+852' + phone.substring(4)}
                />
              </div>

              <div className="col-lg-12">
                <TextInputField
                  disabled
                  label={t('shop.businessAddress.label')}
                  help={(
                    <div className="form-text text-muted">
                      * {t('shopPrivate.contacts.pleaseSelect')}
                    </div>
                  )}
                  value={address}
                />

                {/*
                <GoogleSearchInput
                  handleChangeAddress={handleChangeAddress}
                  handleSelectAddress={handleSelectAddress}
                  address={address}
                  disabled
                />
                */}
              </div>

              <div className="col-lg-6">
                <FormField label={t('shopPrivate.contacts.city')}>
                  <select
                    className="form-item"
                    onChange={handleClickOnCity}
                    value={selectedCity}
                    disabled
                  >
                    <option value="">
                      {t('action.selectOneFromEnumeration')}
                    </option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </FormField>
              </div>

              <div className="col-lg-6">
                <FormField label={t('shopPrivate.contacts.district')}>
                  <select
                    className="form-item"
                    disabled
                    value={selectedDistrict}
                    onChange={handleClickOnDistrict}
                  >
                    <option value="">
                      {t('action.selectOneFromEnumeration')}
                    </option>
                    {districts.map((district) => (
                      <option key={district.id} value={district.id}>
                        {district.name}
                      </option>
                    ))}
                  </select>
                </FormField>
              </div>

              <div className="col-lg-6">
                <TextInputField
                  disabled
                  iconClass="fas fa-user"
                  label={t('shop.personInChargeName.label')}
                  value={adminName}
                />
              </div>

              <div className="col-lg-6">
                <TextInputField
                  disabled
                  label={t('shop.personInChargePhone.label')}
                  type="tel"
                  value={adminPhone && '+852' + adminPhone.substring(4)}
                />
              </div>
            </div>

            <AppButton
              className="mt-3"
              disabled={
                prevEmail === email &&
                prevPhone === phone &&
                prevAddress === address &&
                prevWebsite === website &&
                prevAdminName === adminName &&
                prevAdminPhone === adminPhone &&
                prevSelectedCity === selectedCity &&
                prevSelectedDistrict === selectedDistrict
              }
              theme="primary"
              onClick={sendContactsData}
            >
              {t('action.save')}
            </AppButton>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(
  connect(
    null,
    { saveUserSettings, shopData }
  )(Contacts)
);
