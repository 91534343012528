import {
  SITE_LATEST_REVIEW_WILL_FETCH,
  SITE_LATEST_REVIEW_DID_FETCH,
} from "../../constants/constants";


const initialState = {
  /**
   * Whether the client is fetching a list of site-latest-review
   */
  isFetchingSiteLatestReview: false,

  /**
   * The last time when a list of site-latest-review is fetched
   */
  siteLatestReviewLastFetchedAt: null,

  /**
   * a list of site-latest-review
   */
  siteLatestReviews: [],
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SITE_LATEST_REVIEW_WILL_FETCH:
      return {
        ...state,
        isFetchingSiteLatestReview: true,
      };

    case SITE_LATEST_REVIEW_DID_FETCH:
      return {
        ...state,
        isFetchingSiteLatestReview: false,
        siteLatestReviewLastFetchedAt: new Date(),
        siteLatestReviews: action.payload,
      };

    default:
      break;
  }

  return state;
};
