import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { CS_WHATSAPP_NUMBER } from '../../../environment';

import useAccount from '../../../hooks/auth/useAccount';

import playButton from '../../../dist/svg/play-button.svg';
import salon from '../../../dist/svg/salon.svg';
import support from '../../../dist/svg/support.svg';
import newspaper from '../../../dist/svg/newspaper.svg';

import { FILTER_SESSION_KEY } from './SearchShops/SearchShops.component';


function NavItemContent({
  // iconClass,
  iconSrc,
  children,
}) {
  return (
    <>
      <span className="home-link__ico">
        {/* {iconClass && <i className={iconClass} />} */}
        {iconSrc && <img className="main-icon" src={iconSrc} />}
      </span>
      <span className="home-link__text">{children}</span>
    </>
  );
}

function NavItem({
  // iconClass,
  iconSrc,
  children,
  to,
  onClick,
}) {
  const isExternal = useMemo(() => /^http/.test(to), [to]);

  const anchorClass = 'main-icon-parent home-link';

  return isExternal ? (
    <a
      href={to}
      target="_blank"
      className={anchorClass}
      rel="nofollow noopener noreferrer"
    >
      <NavItemContent iconSrc={iconSrc}>{children}</NavItemContent>
    </a>
  ) : (
    <Link to={to} className={anchorClass} onClick={onClick}>
      <NavItemContent iconSrc={iconSrc}>{children}</NavItemContent>
    </Link>
  );
}

const resetShopListFilter = () => {
  // Remove the cached storage of user's applied filters on shop list
  sessionStorage.removeItem(FILTER_SESSION_KEY);
}

const MainPageNav = ({
  // from patent:
  landingPage,
}) => {

  const { t, i18n } = useTranslation();

  const { privateUrl } = useAccount();

  const [desiredDefaultServiceName, setDesiredDefaultServiceName] = useState('');

  useEffect(() => {
    if (landingPage && landingPage.defaultServices) {
      const rootLevelServices = landingPage.defaultServices.filter(defaultService => defaultService.isRoot);

      if (rootLevelServices.length === 1) {
        // Only pick the *only one* root level default service
        const service = rootLevelServices[0];
        setDesiredDefaultServiceName(
          i18n.language.startsWith('en') ? service.name : service.chineseName
        );
        return;
      }
    }

    setDesiredDefaultServiceName('');
  }, [landingPage, i18n.language]);

  return (
    <div className="home-links mb-5">
      <NavItem
        // iconClass="fas fa-suitcase"
        iconSrc={salon}
        // TODO (WIP) Temporarily disable the pre-specified default service filter via url param for shop list @e0dd114
        // to={`/shops/${desiredDefaultServiceName}`}
        to="/shops"
        onClick={resetShopListFilter}
      >
        {t('homePage.shopListLink')}
      </NavItem>

      <NavItem
        // iconClass="fas fa-play-circle"
        iconSrc={playButton}
        to="/video"
      >
        {t('homePage.videoLink')}
      </NavItem>

      {privateUrl && (
        <NavItem
          // iconClass="fas fa-user-circle"
          iconSrc={newspaper}
          to={privateUrl}
        >
          {t('homePage.profileLink')}
        </NavItem>
      )}

      <NavItem
        // iconClass="fab fa-whatsapp"
        iconSrc={support}
        to={`https://wa.me/${CS_WHATSAPP_NUMBER}?text=我係客人，想查詢服務！`}
      >
        {t('homePage.whatsappLink')}
      </NavItem>

      {/*
      <NavItem
        iconClass="fas fa-shopping-cart"
        to="/promotion"
      >
        {t('homePage.promotionLink')}
      </NavItem>
      */}
    </div>
  );
}

export default MainPageNav;
