import React from 'react';
import { withTranslation } from 'react-i18next';

const SelectTypeEdit = ({
  serviceTypes,
  isValid,
  value,
  name,
  loading,
  handleServiceTypeChange,
  toggleServiceTypeAdd,
  t,
}) => {
  return (
    <td data-label={`${t('shopPrivate.servicePriceList.serviceType')}`}>
      <div className="service-type">
        <div className={isValid ? 'error-field service-type__field' : 'service-type__field'}>
          <select className="form-item" onChange={handleServiceTypeChange}>
            <option value={value}>{name}</option>
            {!loading &&
              serviceTypes.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
          </select>
          {isValid && (
            <>
              <span className="error-ico error-ico--left">
                <i className="fas fa-info-circle" />
              </span>
              <span className="error-text">{isValid}</span>
            </>
          )}
        </div>
        <button className="service-type__btn" onClick={toggleServiceTypeAdd}>
          <i className="fas fa-plus" />
        </button>
      </div>
    </td>
  );
};

export default withTranslation()(SelectTypeEdit);
