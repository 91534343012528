import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LocalizeWeekday } from '../../../../Functions/datetimeHelpers';

import RatingStars from '../../../Common/RatingStars/RatingStars';
import { ShopAvatar, ShopName, ShopStatusDot } from '../../../Common/Shop';
import { Ellipsize } from '../../../Common/Text';

import styles from './ShopCard.module.css';

const ShopCard = ({ shop }) => {
  const { t } = useTranslation();

  const shopData = useMemo(() => {
    return shop?.user_type_data;
  }, [shop]);

  const averageNumber = shop.user_type_data.average_rating;

  return (
    <Link to={`/shop/${shop.id}`} className="shop-card">
      <span className={styles.status}>
        <ShopStatusDot shop={shop} />
      </span>
      <div className={`m-auto ${styles.avatar}`}>
        <ShopAvatar src={shop.avatar} />
      </div>
      <div
        className={`d-flex flex-column justify-content-center text-center ${styles.shopName}`}
      >
        <ShopName shop={shop} />
      </div>
      <div className="shop-card__city">
        <Ellipsize
          text={shop?.user_type_data?.address?.string_address}
          maxlength={30}
        />
      </div>
      <div className={`mt-1 ${styles.schedule}`}>
        <span className="far fa-clock" />
        {shop.schedule?.map((item) => (
          <div key={item.id}>
            <LocalizeWeekday name={item.name} />:{' '}
            {item.pivot && item.pivot.working === 1
              ? `${item.pivot && item.pivot.time_from} - ${
                  item.pivot && item.pivot.time_to
                }`
              : t('dayOff')}
          </div>
        ))}
      </div>
      <RatingStars rating={averageNumber} showNumeric />
      <div className="mt-2 d-flex justify-content-center align-items-center">
        <span className={styles.status}>
          <ShopStatusDot shop={shop} />
        </span>
        <span className={styles.onlineRegLabel}>
          {t('shopList.bookService')}
        </span>
        <span
          className={`${styles.onlineReg} ${
            shopData?.enableBookingSystem === 1 ? '' : styles.off
          }`}
        >
          {shopData?.enableBookingSystem === 1
            ? t('shopList.yes')
            : t('shopList.no')}
        </span>
      </div>{' '}
    </Link>
  );
};

export default ShopCard;
