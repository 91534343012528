import styles from './SocialShareBar.module.css';

function SocialShareBar({ shop, url }) {
  return (
    <div className={styles.root}>
      <a
        className={`${styles.anchor} ${styles.facebook}`}
        href={`https://www.facebook.com/sharer/sharer.php?u=${
          window.location.origin
        }/api/shop/news/facebook/${shop.id}/${shop.user_type_data.name}?image=${
          shop.general_media.other1 && shop.general_media.other1
        }`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fab fa-facebook-f" />
      </a>

      <a
        className={`${styles.anchor} ${styles.twitter}`}
        href={`https://twitter.com/intent/tweet?original_referer=${window.location.origin}${url}&screen_name=SalonfinderH&text=${window.location.origin}${url}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <i className="fab fa-twitter" />
      </a>
    </div>
  );
}

export default SocialShareBar;
