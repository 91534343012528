import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';


import notification from '../notification';


interface Props {
  contentLabel?: string;
  contentToCopy: string;
}

function CopyButton({ contentLabel = '', contentToCopy }: Props) {
  const { t } = useTranslation();

  const tooltip = useMemo(() => {
    if (contentLabel) {
      return t('action.copyContent', {
        content: contentLabel,
      });
    } else {
      return t('action.copy');
    }
  }, [contentLabel]);

  const copyUrl = () => {
    try {
      navigator.clipboard.writeText(contentToCopy); // copy content url to clipboard
      if (contentLabel) {
        notification(
          t('notifications.copiedContent', {
            content: contentLabel,
          })
        );
      } else {
        notification(t('notifications.copied'));
      }
    } catch (err) {
      notification(t('notifications.somethingWasWrong'));
    }
  };

  return contentToCopy ? (
    <Tooltip placement="top" title={tooltip}>
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={copyUrl}
      >
        <i className="fas fa-copy" />
      </button>
    </Tooltip>
  ) : null;
}

export default CopyButton;
