import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from './AppButtonTheme.module.css';
import {
  ButtonShape,
  ButtonSize,
  ButtonTheme,
  ButtonVariant,
  shapeClass,
  sizeClass,
  themeClass,
  variantClass,
} from './AppButton-utils';

interface Props {
  children?: React.ReactNode;
  className?: string;
  elevated?: boolean;
  shape?: ButtonShape;
  size?: ButtonSize;
  theme?: ButtonTheme;
  to: string;
  variant?: ButtonVariant;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const AppButtonLink = ({
  children,
  className,
  elevated = false,
  shape = 'rect',
  size = 'normal',
  theme = 'muted',
  to,
  variant = 'filled',
  onClick = () => {},
}: Props) => {
  const optionClasses = useMemo(() => {
    const classes = [];

    classes.push(shapeClass(shape));
    classes.push(sizeClass(size));
    classes.push(themeClass(theme));
    classes.push(variantClass(variant));

    if (elevated) {
      classes.push(styles.elevated);
    }

    return classes.join(' ');
  }, [shape, size, theme, variant, elevated]);

  return (
    <Link
      className={`${className} ${styles.button} ${optionClasses}`}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default AppButtonLink;
