import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

import Spinner from '../../../Common/Spinner/Spinner';

const SaveIconButton = ({
  className,
  isBusy,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip className={className} placement="top" title={t('action.save')}>
      <button
        className="btn btn-sm btn-outline-success mx-1"
        disabled={isBusy}
        type="button"
        onClick={onClick}
      >
        {isBusy ? <Spinner className="m-0" /> : <i className="fas fa-save" />}
      </button>
    </Tooltip>
  );
};

SaveIconButton.propTypes = {
  className: PropTypes.string,
  isBusy: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SaveIconButton.defaultProps = {
  className: '',
  isBusy: false,
};

export default SaveIconButton;
