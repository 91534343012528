import moment from "moment";

import {
  SITE_MOST_REVIEWED_SHOP_WILL_FETCH,
  SITE_MOST_REVIEWED_SHOP_DID_FETCH,
} from "../../constants/constants";
import api from "../../../api";


export const fetchMostReviewedShop = () => (dispatch, getState) => {
  const prevState = getState().siteMostReviewedShopReducer;

  if (prevState.isFetchingMostReviewedShop) {
    // console.log('fetching most-reviewed-shop. No need to fire a fetch request.');
    return Promise.resolve({ res: null, access: false });
  }
  else if (prevState.mostReviewedShopLastFetchedAt && moment().diff(prevState.mostReviewedShopLastFetchedAt, 'minutes', true) <= 30) {
    // console.log('most-reviewed-shop list is still fresh. No need to fire a fetch request.');
    return Promise.resolve({ res: null, access: false });
  }

  dispatch(willFetchMostReviewShop());

  return api.mainPage.mostReviewedShops().then(res => {
    if (res.data.status === 200) {
      dispatch(didFetchMostReviewShop(res.data.data));
      return { res: res.data, access: true };
    }
    return { res: null, access: false };
  });
};

const willFetchMostReviewShop = () => {
  return {
    type: SITE_MOST_REVIEWED_SHOP_WILL_FETCH,
  };
};

const didFetchMostReviewShop = (data) => {
  return {
    type: SITE_MOST_REVIEWED_SHOP_DID_FETCH,
    payload: data,
  };
};
