import {
  ALBUM_PHOTO_WILL_FETCH,
  GET_ALBUM_PHOTOS,
  ALBUM_WILL_FETCH,
  SHOW_ALL_ALBUMS,
  DELETE_ALBUM,
  NEXT_PAGE_ALBUM,
} from '../../constants/constants';

const initialState = {
  /**
   * Whether the client is fetching a list of albums
   */
  isFetchingAlbum: false,

  albums: [],


  /**
   * Whether the client is fetching a list of album photos
   */
  isFetchingAlbumPhoto: false,

  /**
   * The array of photos is actually at `photos.photos`
   */
  photos: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALBUM_WILL_FETCH: {
      return {
        ...state,
        isFetchingAlbum: true,
      };
    }

    case SHOW_ALL_ALBUMS: // TODO Rename to ALBUM_DID_FETCH
      return {
        ...state,
        isFetchingAlbum: false,
        albums: action.payload,
      };

    case ALBUM_PHOTO_WILL_FETCH: {
      return {
        ...state,
        isFetchingAlbumPhoto: true,
      };
    }

    case GET_ALBUM_PHOTOS:
      return {
        ...state,
        isFetchingAlbumPhoto: false,
        photos: action.payload,
      };

      case DELETE_ALBUM:
      return {
        ...state,
        ...action.payload,
      };

      case NEXT_PAGE_ALBUM:
      return {
        ...state,
        albums: action.payload,
      };
    default:
      return state;
  }
};
