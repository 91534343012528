import moment from 'moment';


import {
  DEFINITION_DEFAULT_SERVICES_WILL_FETCH,
  DEFINITION_DEFAULT_SERVICES_DID_FETCH,

  ADMIN_DEFAULT_SERVICE_DID_CREATE,
  ADMIN_DEFAULT_SERVICE_DID_UPDATE,
  ADMIN_DEFAULT_SERVICES_DID_REORDER,
} from "../../constants/constants";


const initialState = {
  /**
   * Whether the client is fetching array of Default Services
   */
  isFetchingDefaultServices: false,

  /**
   * The last time when the array of Default Services is fetched
   */
  defaultServicesLastFetchedAt: null,

  /**
   * Array of *root level* Default Services
   */
  defaultServices: [],
};


export default (state = initialState, action) => {
  switch (action.type) {
    case DEFINITION_DEFAULT_SERVICES_WILL_FETCH:
      return {
        ...state,
        isFetchingDefaultServices: true,
      };

    case DEFINITION_DEFAULT_SERVICES_DID_FETCH:
      return {
        ...state,
        isFetchingDefaultServices: false,
        defaultServices: action.payload.defaultServices,
        defaultServicesLastFetchedAt: moment(),
      };

    case ADMIN_DEFAULT_SERVICE_DID_CREATE:
    case ADMIN_DEFAULT_SERVICE_DID_UPDATE:
    case ADMIN_DEFAULT_SERVICES_DID_REORDER:
        // Important! When any default service is created or updated,
        // invalidate any previously fetched data:
      return {
        ...state,
        defaultServices: [],
        defaultServicesLastFetchedAt: null,
      };

    default:
      return {
        ...state,
      };
  }
}
