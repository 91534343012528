import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';

import InnerHTML from 'dangerously-set-html-content';


import {
  TARGET_DEVICE_DESKTOP,
  TARGET_DEVICE_PHONE,
} from '../../../Functions/bannerHelper';

import useAuthenticated from '../../../hooks/auth/useAuthenticated';

import { fetchLandingPage } from '../../../Redux/actions/mainPage/landingPageActions';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import notification from '../../Common/notification';
import SiteContainer from '../Global/SiteContainer/SiteContainer';

import ShopSettingPageSideNav from '../ShopPrivatePage/ShopSettingPageSideNav';

// import FeatureCard from '../MainPage/FeatureCard';
import MainPageNav from '../MainPage/MainPageNav';
// import Section from '../MainPage/Section';
import SiteLatestReviews from '../MainPage/SiteLatestReview/SiteLatestReviews';
import SiteMostReviewedShops from '../MainPage/SiteMostReviewedShop/SiteMostReviewedShops';

import BannerCarousel from './BannerCarousel';


const PageContent = ({
  landingPage,
}) => {

  return (
    <>
      {landingPage && (
        <Helmet>
          <title>{landingPage.title}</title>
          <meta property="og:title" id="og-title" content={landingPage.title} />
          <meta name="description" property="og:description" id="og-description" content={landingPage.metaDescription} />
          <meta name="keywords" content={landingPage.metaKeyword} />

          <link rel="canonical" href={window.location.origin + '/' + landingPage.alias} />
        </Helmet>
      )}

      {landingPage && landingPage.banners && landingPage.banners.length > 0 && (
        <BannerCarousel banners={landingPage.banners} />
      )}

      <MainPageNav landingPage={landingPage} />

      {/* <Section /> */}

      {landingPage && landingPage.alias === '' && (
        <SiteMostReviewedShops />
      )}

      {/* <FeatureCard /> was removed on 2020/11/30. */}
      {/* <FeatureCard /> */}

      {landingPage && landingPage.alias === '' && (
        <SiteLatestReviews />
      )}

      {landingPage && landingPage.content && (
        <div className="container mb-5">
          <InnerHTML html={landingPage.content} />
        </div>
      )}

      {/* Advertisement, always visible */}
      <section className="mt-3" style={{ backgroundColor: '#f0f0f0' }}>
        <div className="my-3">
          <AdvertisementView name="home_page" />
        </div>
      </section>
    </>
  );
}


const LandingPage = ({
  // from mapStateToProps:
  desiredLandingPageAlias,

  isFetchingLandingPage,
  landingPage,

  // redux actions:
  fetchLandingPage,
}) => {

  const { t } = useTranslation();

  const {
    isShop,
    user,
  } = useAuthenticated();

  useEffect(() => {
    if (document.querySelector('#ie-support-container') && document.querySelector('#ie-support__close')) {
      document.querySelector('#ie-support__close').addEventListener('click', () => {
        document.querySelector('#ie-support').innerHTML = '';
      })
    }

    if (window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1) {
      notification(t('notifications.usingIE'));
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    // See also BannerCarousel.css for the breakpoints on screen width
    let targetDevice = TARGET_DEVICE_DESKTOP; // desktop
    if (window.innerWidth < 767) { // mobile phone
      targetDevice = TARGET_DEVICE_PHONE;
    }

    // Fetch landing page from remote server
    fetchLandingPage(desiredLandingPageAlias, targetDevice);
  }, [desiredLandingPageAlias]);

  useEffect(() => {
    if (!isFetchingLandingPage && landingPage) {
      if (desiredLandingPageAlias !== landingPage.alias) {
        // if the returned landing page does not conform to the desired alias
        notification(t('pageNotFound'));
      }
    }
  }, [isFetchingLandingPage, landingPage]);

  useEffect(() => {
    if (!desiredLandingPageAlias) {
      if (isShop && user.user_type_data?.is_approved_shop === 0) {
        notification(t('notifications.waitingAdmin'));
      }
    }
  }, [isShop, user]);

  return (
    <SiteContainer>
      {isShop ? (
        <div className="adminWrapper">
          <div className="adminBox">
            <ShopSettingPageSideNav />

            <div className="mainPanel">
              <div className="main-container__content">
                <PageContent landingPage={landingPage} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="content">
          <PageContent landingPage={landingPage} />
        </div>
      )}
    </SiteContainer>
  );
}


function mapStateToProps(state, props) {
  const desiredLandingPageAlias = props.match.params.landingPageAlias || '';

  let landingPage = null;

  const landingPageFromState = state.landingPageReducer.landingPage;
  const isFetchingLandingPage = state.landingPageReducer.isFetchingLandingPage;

  if (!isFetchingLandingPage && landingPageFromState) {
    if (
      !desiredLandingPageAlias && !landingPageFromState.alias // empty alias => homepage
      ||

      // non-empty alias
      desiredLandingPageAlias.length > 0 && (
        landingPageFromState.alias === desiredLandingPageAlias // the desired landing page with non-empty alias
        || !landingPageFromState.alias // or fallback to homepage (with empty alias)
      )
    ) {
      landingPage = landingPageFromState; // adopt state from redux store
    }
  }

  return {
    desiredLandingPageAlias,

    isFetchingLandingPage,
    landingPage,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchLandingPage,
  }
)(LandingPage);
