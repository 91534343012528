/**
 * The values comes from server's database
 */
export enum BookingStatus {
  New = 1,
  Confirmed = 2,
  Completed = 3,
  Cancelled = 4,
  NotCheckOut = 5,
  Absent = 6,
}

interface BookingStatusOption {
  key: BookingStatus;
  i18nLabel: string;
}

export const bookingStatusOptions: BookingStatusOption[] = [
  {
    key: BookingStatus.New,
    i18nLabel: 'shopPrivate.bookings.waitConf',
  },
  {
    key: BookingStatus.Confirmed,
    i18nLabel: 'shopPrivate.bookings.upcoming',
  },
  {
    key: BookingStatus.Cancelled,
    i18nLabel: 'shopPrivate.bookings.cancelled',
  },
  {
    key: BookingStatus.NotCheckOut,
    i18nLabel: 'shopPrivate.bookings.notCheckout',
  },
  {
    key: BookingStatus.Absent,
    i18nLabel: 'shopPrivate.bookings.absent',
  },
  {
    key: BookingStatus.Completed,
    i18nLabel: 'shopPrivate.bookings.completed',
  },
];

export function translateBookingStatus(status: BookingStatus) {
  const option = bookingStatusOptions.find((item) => item.key === status);

  return option?.i18nLabel ?? '';
}
