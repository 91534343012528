import { useMemo } from 'react';

import { BookingReview, Shop } from 'src/models/entity';

import ShopAnchor from './ShopAnchor';

interface Props {
  className?: string;
  review: BookingReview;
}

const ShopAnchorFromBookingReview = ({ className = '', review }: Props) => {
  const shop = useMemo<Shop>(() => {
    return {
      id: review.shop_id,
      active: 1, // TODO
      user_type_data: {
        chineseName: review.shop_chinese_name,
        name: review.shop_name,
      },
    } as Shop;
  }, [review]);

  return shop ? <ShopAnchor className={className} shop={shop} /> : null;
};

export default ShopAnchorFromBookingReview;
