import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { AppButton } from '../../Button';

import styles from './TabNavigation.module.css';
import { Anchor } from '../TabBar/TabBar';


interface Props {
  anchors: Anchor[];
  className?: string;
  heading?: React.ReactNode;
  mobileLabel?: React.ReactNode;
}

const TabNavigation = ({
  // from parent:
  anchors,
  className,
  heading,
  mobileLabel,
}: Props) => {
  const location = useLocation();

  const [activeAnchor, setActiveAnchor] = useState<Anchor>();
  const [isNavMenuOpened, setNavMenuOpened] = useState(false);

  useEffect(() => {
    anchors.forEach((anchor) => {
      if (anchor.to === location.pathname) {
        setActiveAnchor(anchor);
      }
    });
  }, [anchors, location]);

  const toggleNavMenu = () => {
    setNavMenuOpened((isNavMenuOpened) => !isNavMenuOpened);
  };

  const handleClickTab = () => {
    setNavMenuOpened(false);
  };

  return (
    <div className={`${className} ${styles.root}`}>
      <div>
        {heading ? (
          heading
        ) : activeAnchor ? (
          <h2 className="m-0 page-title">{activeAnchor.text}</h2>
        ) : (
          ''
        )}
      </div>

      <div className={`${isNavMenuOpened ? styles.stateOpened : ''}`}>
        <AppButton
          className={styles.mobileHandle}
          elevated
          theme="primary"
          onClick={toggleNavMenu}
        >
          {mobileLabel}
        </AppButton>

        <div className={styles.tabs}>
          {anchors.map((anchor) => (
            <NavLink
              key={anchor.text}
              activeClassName={styles.active}
              className={styles.tab}
              to={anchor.to}
              onClick={handleClickTab}
            >
              {anchor.text}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabNavigation;
