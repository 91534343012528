import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { Chip } from '@material-ui/core';


export const TARGET_DEVICE_DESKTOP = 'D';
export const TARGET_DEVICE_PHONE = 'P';

export const STATUS_ACTIVE = 'A';
export const STATUS_INACTIVE = 'I';

export const VISIBILITY_CURRENTLY_INVISIBLE = 0;
export const VISIBILITY_CURRENTLY_VISIBLE = 1;
export const VISIBILITY_PENDING_VISIBLE = 2;


export function determineCurrentVisibility(banner) {
  if (banner.status != STATUS_ACTIVE || !banner.publishedAt) {
    // not published
    return VISIBILITY_CURRENTLY_INVISIBLE;
  }

  const now = moment();
  if (moment(banner.publishedAt).isBefore(now)) {
    if (!banner.expiredAt || moment(banner.expiredAt).isAfter(now)) {
      // currently visible
      return VISIBILITY_CURRENTLY_VISIBLE;
    }
    else {
      // expired
      return VISIBILITY_CURRENTLY_INVISIBLE;
    }
  }

  // To be visible at a future time
  return VISIBILITY_PENDING_VISIBLE;
}


export const ScheduledPublishIcon = () => <i className="fas fa-eye" style={{ opacity: 0.4 }}></i>;

export const CurrentlyVisibleIcon = () => <i className="fas fa-eye"></i>;

export const CurrentlyHiddenIcon = () => <i className="fas fa-eye-slash"></i>;


export const BannerTargetDesktopChip = ({
  withText,
}) => {
  const { t } = useTranslation();

  return (
    <Chip
      className="m-1"
      color="default"
      variant="outlined"
      label={
        <span className="text-primary">
          <i className="fas fa-desktop"></i> {withText && t('banner.targetDevice.desktop')}
        </span>
      }
    />
  );
};


export const BannerTargetPhoneChip = ({
  withText,
}) => {
  const { t } = useTranslation();

  return (
    <Chip
      className="m-1"
      color="default"
      variant="outlined"
      label={
        <span className="text-success">
          <i className="fas fa-mobile-alt"></i> {withText && t('banner.targetDevice.phone')}
        </span>
      }
    />
  );
};
