import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import ic_play_video from '../../../dist/img/ic_play_video.gif';

import AspectImage from 'src/Components/Common/AspectImage/AspectImage';


class ExternalFeedThumbnail extends Component {

  render() {
    const {
      feed,
    } = this.props;

    const isVideo = feed.resourceType === 'video';

    return (
      feed.thumbnailUrl ? (
        <AspectImage aspectRatio="1:1" withPlayIcon={isVideo}>
          <img
            src={feed.thumbnailUrl}
            name=""
            alt="thumbnail"
          />
        </AspectImage>
      ) : (
        <AspectImage aspectRatio="1:1">
          {isVideo && (
            <img src={ic_play_video} alt="Play" />
          )}
        </AspectImage>
      )
    );
  }
}

ExternalFeedThumbnail.defaultProps = {

  feed: {},

};

export default withTranslation()(
  withRouter(
    connect(
      null,
      {
      }
    )(ExternalFeedThumbnail)
  )
);
