import React from 'react';
import { useTranslation } from 'react-i18next';


import { TableHeaderCell } from '../../../../Common/DataTable';


const TableHead = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <TableHeaderCell>{t('shopPrivate.calendar.serviceName')}</TableHeaderCell>
        <TableHeaderCell>{t('shopPrivate.servicePriceList.serviceType')}</TableHeaderCell>
        <TableHeaderCell>
          {t('shopPrivate.calendar.time')} <br />
          {t('shopPrivate.servicePriceList.process')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('shopPrivate.servicePriceList.service_descr')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('shopPrivate.servicePriceList.availability')}
        </TableHeaderCell>
        <TableHeaderCell>{t('shopPublic.product.price')}</TableHeaderCell>
        <TableHeaderCell>{t('shopPrivate.servicePriceList.staffSelect')}</TableHeaderCell>
      </tr>
    </thead>
  );
};

export default TableHead;
