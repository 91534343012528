import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { fetchMostReviewedShop } from '../../../../Redux/actions/mainPage/siteMostReviewedShopActions';

import SiteMostReviewedShopCard from './SiteMostReviewedShopCard';

import styles from './SiteMostReviewedShops.module.css';


const VISIBLE_SHOP_COUNT = 4;

const SiteMostReviewedShops = ({
  // redux states:
  isFetchingMostReviewedShop,
  mostReviewedShops,

  // redux actions:
  fetchMostReviewedShop,
}) => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(0);

  const [visibleShops, setVisibleShops] = useState([]);

  useEffect(() => {
    fetchMostReviewedShop();
  }, []);

  useEffect(() => {
    setVisibleShops(mostReviewedShops.slice(activeIndex, activeIndex + VISIBLE_SHOP_COUNT));
  }, [activeIndex, mostReviewedShops]);

  const goNext = () => {
    setActiveIndex((activeIndex + VISIBLE_SHOP_COUNT) % mostReviewedShops.length);
  };

  const goPrev = () => {
    setActiveIndex((activeIndex - VISIBLE_SHOP_COUNT + mostReviewedShops.length) % mostReviewedShops.length);
  };

  return (
    <div className="container mb-4">
      <div className="section-title">
        <h1>{t('homePage.mostReviewed')}</h1>

        {mostReviewedShops.length > VISIBLE_SHOP_COUNT && (
          <div className={styles.control}>
            <button className={styles.arrow} type="button" onClick={goPrev}>
              <i className="fas fa-arrow-left" />
            </button>
            <button className={styles.arrow} type="button" onClick={goNext}>
              <i className="fas fa-arrow-right" />
            </button>
          </div>
        )}
      </div>

      {!isFetchingMostReviewedShop && (
        <div className={styles.slider}>
          <div className="row">
            {visibleShops.map((shop) => (
              <SiteMostReviewedShopCard key={shop.id} shop={shop} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isFetchingMostReviewedShop: state.siteMostReviewedShopReducer.isFetchingMostReviewedShop,
    mostReviewedShops: state.siteMostReviewedShopReducer.mostReviewedShops || [],
  };
}

export default connect(
  mapStateToProps,
  {
    fetchMostReviewedShop,
  }
)(SiteMostReviewedShops);
