import moment from "moment";

import {
  SHOP_NEWS_LIST_WILL_INVALIDATE,
  SHOP_NEWS_WILL_FETCH,
  SHOP_NEWS_DID_FETCH,
  SHOP_NEWS_WILL_GET,
  SHOP_NEWS_DID_GET,
  SHOP_NEWS_DID_CREATE,
  SHOP_NEWS_DID_UPDATE,
  SHOP_NEWS_DID_DESTROY,
} from "../../constants/constants";
import api from "../../../api";

export const invalidateShopNewsListFromState = () => dispatch => {
  dispatch(invalidateShopNewsList());
}

/**
 * Fetch a list of shop news
 * @param {int} shopId
 * @param {bool} forceFetch
 */
export const fetchShopNews = (shopId, forceFetch = false) => (dispatch, getState) => {
  if (!forceFetch) {
    const prevState = getState().shopNewsReducer;

    if (prevState.shopWithNewsList && prevState.shopWithNewsList.id == shopId) {
      // The specified shop's news list has been fetched  previously and is still in state.

      if (prevState.isFetchingNews) {
        // console.log('fetching news. No need to fire a fetch request.');
        return Promise.resolve({ res: null, access: false });
      }
      else if (prevState.lastFetchedAt && moment().diff(prevState.lastFetchedAt, 'minutes', true) <= 5) {
        // console.log('shop news list is still fresh. No need to fire a fetch request.');
        return Promise.resolve({ res: null, access: false });
      }
    }
  }

  dispatch(willFetchShopNews());

  // console.log('Firing a new fetch request...');
  return api.shopNews.fetch(shopId).then((response) => {
    dispatch(GetNews(response.data));
    return { res: response, access: true };
  }).catch(() => {
    return { res: null, access: false };
  });
}

/**
 * Get one piece of shop news
 * @param {*} data
 */
export const getShopNews = (data) => dispatch => {
  dispatch(willGetShopNews(data.id));

  return api.shopNews.get(data).then((response) => {
    dispatch(didGetShopNews(response.data));
    return { res: response, access: true };
  }).catch(() => {
    return { res: null, access: false };
  });
}

/**
 * Create one piece of shop news
 * @param {*} data
 */
export const createShopNews = (data) => dispatch =>
  api.shopNews.create(data).then(response => {
    dispatch(didCreateShopNews(response.data));
    return { res: response, access: true };
  }).catch((e) => {
    return { res: null, access: false };
  });

/**
 * Update one piece of shop news
 * @param {*} data
 */
export const updateShopNews = (data) => dispatch =>
  api.shopNews.update(data).then(response => {
    dispatch(didUpdateShopNews(response.data));
    return { res: response, access: true };
  }).catch(() => {
    return { res: null, access: false };
  });

/**
 * Delete one piece of shop news, by shop owner
 * @param {*} data
 */
export const destroyShopNews = (newsId) => dispatch =>
  api.shopNews.destroy(newsId).then(response => {
    dispatch(didDestroyShopNews(newsId));
    return { res: response, access: true };
  }).catch(() => {
    return { res: null, access: false };
  });


const invalidateShopNewsList = () => ({
  type: SHOP_NEWS_LIST_WILL_INVALIDATE,
});

const willFetchShopNews = () => ({
  type: SHOP_NEWS_WILL_FETCH,
});

// TODO Rename it to didFetchShopNews and not to export it
export const GetNews = (shopWithNewsList) => ({
  type: SHOP_NEWS_DID_FETCH,
  payload: {
    shopWithNewsList,
  }
});

const didCreateShopNews = (news) => ({
  type: SHOP_NEWS_DID_CREATE,
  payload: {
    news,
  }
});

const didUpdateShopNews = (news) => ({
  type: SHOP_NEWS_DID_UPDATE,
  payload: {
    news,
  }
});

const didDestroyShopNews = (newsId) => ({
  type: SHOP_NEWS_DID_DESTROY,
  payload: {
    newsId,
  }
});

const willGetShopNews = (newsId) => ({
  type: SHOP_NEWS_WILL_GET,
  payload: {
    newsId,
  }
});

const didGetShopNews = (news) => ({
  type: SHOP_NEWS_DID_GET,
  payload: {
    news,
  }
});
