import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { injectStripe, CardElement } from 'react-stripe-elements';

import axios from 'axios';

import { getCard } from '../../../../Redux/actions/features/bankCardsActions';

import { AppButton } from '../../../Common/Button';

class PaymentDetail extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.getCard();
  }

  submit(e) {
    e.preventDefault();
    this.props.stripe.createToken().then(payload => {
      axios.post(`/api/store-credit-card`, { token: payload.token.id });
    });
  }

  deleteCard = () => {
    this.props.stripe.createToken().then(payload => {
      axios.post(`/api/delete-credit-card`, { token: payload.token.id });
    });
    // this.props.getCard()
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <h4 className="settings-title">{t('staffPrivate.settings.paymentDetail')}</h4>

        <div className="row">
          <i className="fas fa-credit-card mx-1" />
          {this.props.bankCardsReducer.cards.brand}: **** **** **** {this.props.bankCardsReducer.cards.last_four}
          <AppButton theme="danger" variant="outlined" onClick={this.deleteCard}>
            {t('action.delete')}
          </AppButton>
        </div>

        {this.props.bankCardsReducer.cards.last_four === null && (
          <div id="card-element">
            <CardElement />
          </div>
        )}

        <AppButton className="mt-3" theme="primary" onClick={this.submit}>
          {t('action.save')}
        </AppButton>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    bankCardsReducer: state.bankCardsReducer,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    { getCard }
  )(injectStripe(PaymentDetail))
);
