import { BookingStatus } from 'src/models/enum/BookingStatus';

import { BookingReview } from './BookingReview';
import { Customer } from './Customer';
import { Service } from './Service';
import { Shop } from './Shop';

interface BookingCustomerName {
  first_name: string;
  last_name: string;
}

interface BookingCustomerPhone {
  phone: string;
}

interface NonMemberCustomer {
  name: string;
  phone: string;
}

export interface BookingRating {
  environment: number;
  skill: number;
  attitude: number;
  idea: number;
}

interface BookingStaffChangeHistory {
  from_staff_nickname: string;
  to_staff_nickname: string;
}

interface ShopName {
  name: string;
  chinese_name: string;
}

export const BookingMaxlength = {
  message: 250,
  providerRemarks: 250,
}

export interface Booking {
  id: string;

  shop_id: string;

  staff_id: string;

  customer_id: string;

  /**
   * YYYY-MM-DD
   */
  date: string;

  /**
   * HH:mm
   */
  time: string;

  /**
   * numeric string
   */
  price: string;

  staff_charge: number;

  /**
   * Message given by customer
   */
  message?: string;

  /**
   * Remarks given by service provider
   */
  providerRemarks?: string;

  booking_rating: BookingRating;

  booking_status_id: BookingStatus;
  /**
   * @deprecated Use translate function from `src/models/enum/BookingStatus.ts`
   */
  status?: string;

  service_only_name?: string;
  service_name?: string;

  customer_name?: BookingCustomerName;
  customer_phone?: BookingCustomerPhone;

  customer_other?: NonMemberCustomer;

  customer?: Customer;

  service?: Service;

  /**
   * @deprecated
   */
  shop_name?: ShopName;

  shop?: Shop;

  staff_full_name?: string;
  staff_nickname?: string;

  last_staff_change?: BookingStaffChangeHistory;

  checkoutNumber?: string;

  /**
   * Expected an ISO 8601 date time string or falsy value
   */
  created_at: string;

  reviews?: BookingReview[];
}
