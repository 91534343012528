import { SEARCH_SHOPS } from '../../constants/constants';
import api from '../../../api';

export const fetchShops = data => dispatch =>
  api.search.search(data)
    .then(response => {
      dispatch(SearchShops(response.data));
      return response;
    });

export const SearchShopByName = data => dispatch =>
  api.search.searchByName(data).then(res => {
    if (res.data.message === 'success') {
      dispatch(SearchShops(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

export const SearchShops = data => ({
  type: SEARCH_SHOPS,
  payload: data,
});
