import {GET_PROMOTION_LIST, GET_PROMOTION_PAGE, SEND_PROMTION_REQUEST} from "../../constants/constants";

const initialState = {
    promotion: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROMOTION_PAGE:
            return {
                ...state,
                promotion: action.payload
            };
        case GET_PROMOTION_LIST:
            return {
                ...state,
                promotion: action.payload
            };
        case SEND_PROMTION_REQUEST:
            return {
                ...state,
                ...action.payload
            };
        default:
            return {
                ...state
            }
    }
};
