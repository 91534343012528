import React from 'react';
import { withTranslation } from 'react-i18next';

const SetTime = ({ isValidTime, time, timeString, handleChangeBoolean, changeTimeWindowOpen, handleChangeTime, t }) => {
  return (
    <td data-label={t('shopPrivate.calendar.time')}>
      <div className={'table-field ' + (!isValidTime ? 'field-wrap' : 'error-field')}>
        <input
          type="text"
          name="changeTimeWindowOpen"
          onClick={handleChangeBoolean}
          value={timeString}
          className="form-item"
          placeholder="2:00"
          readOnly
        />
        {changeTimeWindowOpen && (
          <div className="date-drop">
            <div className="date-drop__item" onClick={e => e.stopPropagation()}>
              <div className="date-drop__body">
                <div className="date-select">
                  <span className="date-select__ico">
                    <i className="fas fa-caret-down" />
                  </span>
                  <select name="hours" value={time.hours} onChange={handleChangeTime} className="date-select__item">
                    <option value="00">0</option>
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                </div>
                <span className="date-separator">:</span>
                <div className="date-select">
                  <span className="date-select__ico">
                    <i className="fas fa-caret-down" />
                  </span>
                  <select name="minutes" value={time.minutes} onChange={handleChangeTime} className="date-select__item">
                    <option value="00">0</option>
                    <option value="05">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="35">35</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                    <option value="55">55</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {isValidTime && <span className="error-text">{isValidTime}</span>}
      </div>
    </td>
  );
};

export default withTranslation()(SetTime);
