import React, { useState } from 'react';
import PropTypes from 'prop-types';


import PhotoViewer from '../PhotoViewer/PhotoViewer';
import PhotoCell from '../PhotoCell/PhotoCell';


import styles from './PhotoGrid.module.css';

function PhotoGrid({
  aspectRatio,
  photos,
}) {

  const [isPhotoViewerOpened, setPhotoViewerOpened] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);

  const openPhotoViewer = (activePhotoIndex) => {
    setPhotoViewerOpened(true);
    setActivePhotoIndex(activePhotoIndex);
  };

  const closePhotoViewer = () => {
    setPhotoViewerOpened(false);
  };

  return (
    <div className={`py-5 px-3 ${styles.root}`}>
      {isPhotoViewerOpened && (
        <PhotoViewer
          photos={photos}
          initialIndex={activePhotoIndex}
          onClose={closePhotoViewer}
        />
      )}

      <div className="row">
        {photos.map((photo, index) => (
          <div className={`col mb-4 ${styles.cellContainer}`} key={index}>
            <PhotoCell
              aspectRatio={aspectRatio}
              photo={photo}
              showCopyUrlButton={false}
              showDeleteButton={false}
              onClick={() => openPhotoViewer(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

PhotoGrid.propTypes = {
  aspectRatio: PropTypes.string,
  photos: PropTypes.array.isRequired,
};

PhotoGrid.defaultProps = {

  aspectRatio: '1:1',

  /**
   * Schema of each item follows `Photo` on remote server
   */
  photos: [],

};

export default PhotoGrid;
