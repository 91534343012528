import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import { createService, getAllServices } from '../../../../../Redux/actions/shopActions/serviceAction';
import { fetchShopStaff } from '../../../../../Redux/actions/shopActions/shopStaffActions';
import { getCreationServiceTypes, createServiceType } from '../../../../../Redux/actions/features/serviceTypeAction';

import Notification from '../../../Notification';
import notification from '../../../../Common/notification';
import { AppButton, AppButtonLink } from '../../../../Common/Button';

import SelectType from './SelectType';
import ServiceTypeModal from './ServiceTypeModal';
import SetTime from './SetTime';
import StaffCharge from './StaffCharge';
import TableHead from './TableHead';


class Creation extends Component {
  state = {
    errorMessage: '',
    disabled: '',
    toggleStaffCharge: false,
    charge: '',
    name: '',
    description: '',
    price_fixed: '',
    price_from: '',
    price_to: '',
    serviceName: '',
    available: 1,
    existName: false,
    disableField: true,
    toggleServiceType: false,
    service_type_id: [],
    isValidDescription: '',
    isValidName: '',
    isValidTime: '',
    isValidPrice: '',
    isValidServiceType: '',
    loading: true,
    staff: {},
    changeTimeWindowOpen: false,
    time: {
      hours: '00',
      minutes: '00',
    },
  };

  componentDidMount() {
    if (this.props.auth.user.id) {
      this.props.fetchShopStaff({ id: this.props.auth.user.id });
    }

    if (this.props.services) {
      this.props.services.filter(id => {
        return id.madeBy.filter(id => {
          return this.setState({ charge: id.pivot.charge });
        });
      });
    }

    // this.props.getAllServiceTypes({ id: this.props.auth.user.id }).then(res => {
    this.props.getCreationServiceTypes({ id: this.props.auth.user.id }).then(res => {
      if (res.res.data.status === 'success') {
        this.setState({
          loading: false,
        });
      }
    });
  }

  toggleStaffCharge = () => {
    this.setState({
      toggleStaffCharge: !this.state.toggleStaffCharge,
    });
  };

  toggleServiceTypeAdd = () => {
    this.setState({
      toggleServiceType: !this.state.toggleServiceType,
    });
  };

  toggleAvailability = e => {
    const availValue = e.target.checked ? 0 : 1;
    this.setState({
      available: availValue,
    });
  };

  disableInput = () => {
    this.setState({
      disableField: !this.state.disableField,
      price_fixed: '',
      price_from: '',
      price_to: '',
    });
  };

  sendData = () => {
    const {
      service_type_id,
      description,
      name,
      time: { hours, minutes },
      price_fixed,
      price_from,
      price_to,
      available,
      staff,
    } = this.state;
    const timeToSend = `${hours}:${minutes}`;
    this.props.createService({
        name: name,
        service_type_id: service_type_id,
        description: description,
        price_fixed: price_fixed !== '' ? Number(price_fixed) : '',
        price_from: price_from !== '' ? Number(price_from) : '',
        price_to: price_to !== '' ? Number(price_to) : '',
        time: timeToSend,
        available: available,
        staff: staff,
      })
      .then(res => {
        const data = res.res && res.res.data && res.res.data.data;
        if (
          (data instanceof String && data.toLowerCase() === 'service type with this name already exists.') ||
          data === '這個產品分類已經刪除'
        ) {
          this.setState({
            existName: true,
          });
        } else if (data && data.staff) {
          notification(data.staff);
        } else {
          this.setState({
            existName: false,
          });
        }
        if (res.res.data.message === 'incorrect data') {
          this.setState({
            isValidDescription: data.description ? data.description : null,
            isValidName: data.name ? data.name : null,
            isValidTime: data.time ? data.time : null,
            isValidServiceType: data.service_type_id ? data.service_type_id : null,
            isValidPrice: data === 'Fill price field(s).' || data === '固定價格' ? data : null,
          });
        }
        if (res.res.data.message === 'success') {
          this.props.getAllServices({ id: this.props.auth.user.id });
          this.setState({
            status: 'success',
          });
          setTimeout(() => {
            this.setState({
              status: '',
            });

            this.props.history.push('/shop-settings/service-price/service/list');
          }, 1500);
        } else {
          this.setState({
            status: 'error',
            errorMessage: data.staff ? data.staff : this.props.t('notifications.somethingWentWrong'),
          });
          setTimeout(() => {
            this.setState({
              status: '',
              errorMessage: '',
            });
          }, 3000);
        }
      });
  };

  handleChangeStaff = (charge, id, showField) => {
    if (showField) {
      this.setState({
        staff: {
          ...this.state.staff,
          [id]: {
            charge: Number(charge),
          },
        },
      });
    } else {
      const filteredStaff = this.state.staff;
      delete filteredStaff[id];
      this.setState({
        staff: {
          ...filteredStaff,
        },
      });
    }

    if (this.state.price_fixed !== '') {
      this.setState({
        disabled: false,
      });
    }
  };

  handleServiceTypeChange = e => {
    this.setState({
      service_type_id: e.target.value,
    });
  };

  sendServiceTypeData = () => {
    this.setState({
      loading: true,
    });
    this.props.createServiceType({ name: this.state.serviceName }).then(res => {
      if (res.res.data.message === 'success') {
        this.toggleServiceTypeAdd();
        this.setState({
          serviceName: '',
        });

        this.props.getCreationServiceTypes({ id: this.props.auth.user.id }).then(res => {
          if (res.res.data.status === 'success') {
            this.props.getAllServices({ id: this.props.auth.user.id });
          }
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
      }
    });
  };

  handleChange = ({ target: { name, value } }) => {
    if (name === 'price_fixed' || name === 'price_from' || name === 'price_to') {
      const regExp = /^\d+\.?\d*$/;
      if ((Number(value) !== '' && regExp.test(value)) || value === '') {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleChangeBoolean = ({ target }) => {
    this.setState(prevState => ({
      [target.name]: !prevState.changeTimeWindowOpen,
    }));
  };

  handleChangeTime = ({ target }) => {
    if (target.name === 'hours') {
      this.setState(prevState => ({
        time: {
          ...prevState.time,
          [target.name]: target.value,
          hours:
            Number(target.value).toString().length > 1
              ? Number(target.value).toString()
              : `0${Number(target.value).toString()}`,
        },
      }));
    } else {
      this.setState(prevState => ({
        time: {
          ...prevState.time,
          [target.name]: target.value,
        },
      }));
    }
  };

  // handleChangeTime = ({ target }) => {
  //   if (target.getAttribute('name') === 'howOclockFormat') {
  //     this.setState(prevState => ({
  //       time: {
  //         ...prevState.time,
  //         hours:
  //           Number(prevState.time.hours) < 12
  //             ? (Number(prevState.time.hours) + 12).toString()
  //             : (Number(prevState.time.hours) - 12).toString().length === 1
  //               ? `0${(Number(prevState.time.hours) - 12).toString()}`
  //               : (Number(prevState.time.hours) - 12).toString(),
  //       },
  //       pm: !this.state.pm,
  //     }));
  //   } else if (target.getAttribute('name') === 'hours') {
  //     this.setState(prevState => ({
  //       time: {
  //         ...prevState.time,
  //         [target.name]: target.value,
  //         hours: this.state.pm
  //           ? (Number(target.value) + 12).toString()
  //           : Number(target.value).toString().length > 1
  //             ? Number(target.value).toString()
  //             : `0${Number(target.value).toString()}`,
  //       },
  //     }));
  //   } else {
  //     this.setState(prevState => ({
  //       time: {
  //         ...prevState.time,
  //         [target.name]: target.value,
  //       },
  //     }));
  //   }
  // };

  setStatusClosed = () => {
    this.setState({
      status: '',
    });
  };

  closeTimeWindow = ({ target }) => {
    if (this.state.changeTimeWindowOpen && target.name !== 'hours' && target.name !== 'minutes') {
      this.setState({
        changeTimeWindowOpen: false,
        // pm: false,
      });
    }
  };

  render() {
    const {
      serviceTypes,
      shopStaffDataList,
      t,
    } = this.props;

    return (
      <>
        <div className="main-container__content" name="changeTimeWindowClose" onClick={this.closeTimeWindow}>
          {this.state.status === 'success' && (
            <Notification text={t('notifications.serviceCreated')} onClose={this.setStatusClosed} />
          )}

          {this.state.status === 'error' && (
            <Notification text={this.state.errorMessage} onClose={this.setStatusClosed} />
          )}

          <div className="page-head">
            <h2 className="page-title">
              {t('shopPrivate.servicePriceList.createService')}
            </h2>
          </div>

          <div className="tableWrap tableWrap--auto-height">
            <table className="adminTable">
              <colgroup>
                <col style={{ minWidth: 300 }} />
                <col style={{ minWidth: 200 }} />
                <col style={{ minWidth: 120 }} />
                <col style={{ minWidth: 300 }} />
                <col style={{ minWidth: 200 }} />
                <col style={{ minWidth: 300 }} />
                <col style={{ width: 150 }} />
              </colgroup>
              <TableHead />
              <tbody>
                <tr>
                  <td data-label={t('shopPrivate.calendar.serviceName')}>
                    <div className={'table-field ' + (!this.state.isValidName ? 'field-wrap' : 'error-field')}>
                      <input
                        type="text"
                        onChange={this.handleChange}
                        name="name"
                        className="form-item"
                        placeholder={t('shopPublic.overview.createBooking.name')}
                      />
                      {this.state.isValidName && <span className="error-text">{this.state.isValidName}</span>}
                      {this.state.existName && (
                        <span className="error-text">{t('shopPrivate.servicePriceList.serviceNameExist')}</span>
                      )}
                    </div>
                  </td>
                  {!this.state.loading && (
                    <SelectType
                      toggleServiceTypeAdd={this.toggleServiceTypeAdd}
                      loading={this.state.loading}
                      isValid={this.state.isValidServiceType}
                      serviceTypes={serviceTypes}
                      handleServiceTypeChange={this.handleServiceTypeChange}
                    />
                  )}
                  <SetTime
                    isValidTime={this.state.isValidTime}
                    handleChangeBoolean={this.handleChangeBoolean}
                    timeString={`${this.state.time.hours}:${this.state.time.minutes}`}
                    time={this.state.time}
                    changeTimeWindowOpen={this.state.changeTimeWindowOpen}
                    handleChangeTime={this.handleChangeTime}
                  />
                  <td data-label={t('shopPrivate.servicePriceList.service_descr')}>
                    <div className={'table-field ' + (!this.state.isValidDescription ? 'field-wrap' : 'error-field')}>
                      <input
                        type="text"
                        name="description"
                        onChange={this.handleChange}
                        className="form-item"
                        placeholder={t('shopPublic.overview.createBooking.name')}
                      />
                      {this.state.isValidDescription && (
                        <span className="error-text">{this.state.isValidDescription}</span>
                      )}
                    </div>
                  </td>
                  <td data-label={t('shopPrivate.servicePriceList.availability')}>
                    <div className="switch-box">
                      <input
                        type="checkbox"
                        className="switch-checkbox"
                        id="checkbox"
                        onChange={this.toggleAvailability}
                        value={this.state.available}
                        checked={!this.state.available}
                      />
                      <label htmlFor="checkbox">
                        <span className="first-opt">{t('shopPrivate.servicePriceList.available')}</span>
                        <span className="second-opt">{t('shopPrivate.servicePriceList.closed')}</span>
                      </label>
                    </div>
                  </td>
                  <td data-label={t('shopPublic.product.price')}>
                    <form>
                      <div className="service-price">
                        <div className="service-price__check">
                          <input
                            type="radio"
                            defaultChecked
                            className="checkbox"
                            id="c1"
                            onChange={this.disableInput}
                            name="priceFlag"
                          />
                          <label htmlFor="c1">
                            <span>{t('shopPrivate.servicePriceList.fixedPrice')}</span>
                          </label>
                        </div>
                        <div
                          className={
                            this.state.isValidPrice ? 'error-field service-price__field' : 'service-price__field'
                          }
                        >
                          {this.state.isValidDescription && (
                            <span className="error-text error-text--top">{this.state.isValidDescription}</span>
                          )}
                          <input
                            type="text"
                            value={this.state.price_fixed || ''}
                            name="price_fixed"
                            className="form-item"
                            onChange={this.handleChange}
                            disabled={!this.state.disableField}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="service-price">
                        <div className="service-price__check">
                          <input
                            type="radio"
                            className="checkbox"
                            id="c2"
                            name="priceFlag"
                            onChange={this.disableInput}
                            value="range"
                          />
                          <label htmlFor="c2">
                            <span>{t('shopPrivate.servicePriceList.rangePrice')}</span>
                          </label>
                        </div>
                        <div className="service-price__field service-price__field--range">
                          <input
                            type="text"
                            className="px-2 form-item"
                            onChange={this.handleChange}
                            disabled={this.state.disableField}
                            name="price_from"
                            value={this.state.price_from || ''}
                          />
                        </div>
                        <span className="service-price__help">{t('shopPrivate.schedule.to')}</span>
                        <div className="service-price__field service-price__field--range">
                          <input
                            type="text"
                            className="px-2 form-item"
                            onChange={this.handleChange}
                            disabled={this.state.disableField}
                            name="price_to"
                            value={this.state.price_to || ''}
                          />
                        </div>
                      </div>
                      {this.state.isValidPrice}
                    </form>
                  </td>
                  <td data-label={t('shopPrivate.servicePriceList.staffSelect')}>
                    <AppButton size="small" theme="primary" onClick={this.toggleStaffCharge}>
                      {t('shopPrivate.servicePriceList.chooseStaff')}
                    </AppButton>
                  </td>
                </tr>
              </tbody>
            </table>

            {this.state.toggleServiceType && (
              <ServiceTypeModal
                serviceName={this.state.serviceName}
                handleChange={this.handleChange}
                sendServiceTypeData={this.sendServiceTypeData}
                toggleServiceTypeAdd={this.toggleServiceTypeAdd}
              />
            )}
          </div>
        </div>

        {this.state.toggleStaffCharge && (
          <StaffCharge
            shopStaffDataList={shopStaffDataList}
            handleChange={this.handleChangeStaff}
          />
        )}

        <div className="mt-2 px-3">
          <AppButton theme="primary" disabled={this.state.disabled} onClick={this.sendData}>
            {t('shopPrivate.servicePriceList.confirm')}
          </AppButton>
          <AppButtonLink theme="muted" to="/shop-settings/service-price/service/list">
            {t('action.cancel')}
          </AppButtonLink>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    serviceTypes: state.serviceTypesReducer.serviceTypes,
    services: state.servicesReducer.services || [],
    shopStaffDataList: state.GetStuffInformationReducer.shopStaffDataList,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    {
      createService,
      fetchShopStaff,
      getAllServices,
      getCreationServiceTypes,
      createServiceType,
    }
  )(Creation)
);
