import React from 'react';

import serviceAvatar from '../../../../../dist/depositphotos_148852191_stock_illustration.png';

import { Textarea } from '../../../../Common/Form';

const ReacommendedItemServicesUnchecked = ({
  service,
  recommendedDataService,
  i,
  onChangeServices,
}) => {
  return service ? (
    <div className="mb-3 mx-2 border pb-3 bg-white price-item">
      <div className="px-3 py-2">
        <input
          type="checkbox"
          checked={service.recommended}
          className="checkbox checkbox--type2"
          onChange={(e) => onChangeServices(e, i, service.id)}
          id={`${service.name}=${service.id}`}
        />
        <label htmlFor={`${service.name}=${service.id}`}>
          <span />
        </label>
      </div>
      <p className="mt-2 px-3 py-1 text-center price-item__name">
        {service.name}
      </p>
      <div className="price-item__img">
        <p className="w-75 price-sicker">
          {service.price_fixed
            ? service.price_fixed
            : `${service.price_from} - ${service.price_to}`}{' '}
          $
        </p>
        <img src={serviceAvatar} alt="" />
      </div>
      <Textarea
        className="price__descr"
        disabled
        value={service.description}
      />
    </div>
  ) : null;
};

export default ReacommendedItemServicesUnchecked;
