import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';


import AspectImage from '../../../Common/AspectImage/AspectImage';
import PhotoViewer from '../../../Common/PhotoViewer/PhotoViewer';
import { EllipsizeBlock } from '../../../Common/Text';
import ExternalFeedThumbnail from '../../../Common/ExternalFeed/ExternalFeedThumbnail';


function NextArrow({ onClick }) {
  return (
    <div className="mob-slider-control mob-slider-control--next">
      <button onClick={onClick}>
        <i className="fas fa-angle-right" aria-hidden="true" />
      </button>
    </div>
  );
}

function PrevArrow({ onClick }) {
  return (
    <div className="mob-slider-control">
      <button onClick={onClick}>
        <i className="fas fa-angle-left" aria-hidden="true" />
      </button>
    </div>
  );
}

class ShopMainNewsSlider extends Component {

  state = {
    isPhotoViewerOpened: false,
    viewablePhotos: [],
    activePhotoIndex: 0,
  };

  sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  openPhotoViewer = (newsIdx, photoIdx) => {
    this.setState({
      isPhotoViewerOpened: true,
      viewablePhotos: this.props.newsList[newsIdx].photos,
      activePhotoIndex: photoIdx,
    });
  }

  closePhotoViewer = () => {
    this.setState({
      isPhotoViewerOpened: false,
      viewablePhotos: [],
      activePhotoIndex: 0,
    });
  }

  redirectToNews = id => {
    this.props.history.push(`${this.props.url.slice(0, this.props.url.lastIndexOf('main'))}news/${id}`);
  };

  render() {
    const {
      className,
      newsList,
      url,
      t,
    } = this.props;

    const {
      isPhotoViewerOpened,
      viewablePhotos,
      activePhotoIndex,
    } = this.state;

    return (
      <>
        {isPhotoViewerOpened && (
          <PhotoViewer
            photos={viewablePhotos}
            initialIndex={activePhotoIndex}
            onClose={this.closePhotoViewer}
          />
        )}

        <div className={`shop-group ${className}`}>
          <Link to={`${url.slice(0, url.lastIndexOf('main'))}news`}>
            <p className="theme-subtitle">{t('shopPublic.overview.news')}</p>
          </Link>

          <Slider {...this.sliderSettings}>
            {newsList && newsList.map((news, newsIdx) => (
              <div key={newsIdx} className="shop-news">
                <div onClick={() => this.redirectToNews(news.id)} style={{ cursor: 'pointer' }}>
                  <h5>
                    <Link
                      to={`${url.slice(0, url.lastIndexOf('/'))}/news/${news.id}`}
                      className="news-title"
                      onClick={e => e.stopPropagation()}
                    >
                      {news.title}
                    </Link>
                  </h5>

                  <EllipsizeBlock
                    className="news-content"
                    maxlength={300}
                    text={news.description}
                  />
                </div>

                <Link to={`${url.slice(0, url.lastIndexOf('/'))}/news/${news.id}`} className="more-link">
                  {t('shopNews.viewCompleteContent')}
                </Link>

                <div className="shop-news-attachment-box">
                  <div className="news-share">
                    <div
                      className="news-share__item"
                      data-href={`${window.location.origin}/shop/news/${news.id}`}
                      data-layout="button"
                      data-size="small"
                      data-mobile-iframe="true"
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${
                          window.location.origin
                        }/api/shop/news/facebook/${window.location.pathname.split('/')[2]}/${news.id}/${
                          news.title
                        }/${news.description.slice(0, 75)}?${news.photos[0] ? 'image=' + news.photos[0].url : ''}`}
                        className="news-share__item"
                      >
                        <i className="fab fa-facebook-square" />
                      </a>
                    </div>
                    <div id="bt_twitter" data-network="twitter" className="news-share__item">
                      <a
                        className="news-share__item"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://twitter.com/intent/tweet?original_referer=${
                          window.location.origin
                        }/shop/news/${news.id}&screen_name=SalonfinderH&text=${window.location.origin}/shop/news/${
                          news.id
                        }`}
                      >
                        <i className="fab fa-twitter-square" />
                      </a>
                    </div>
                  </div>

                  {news.externalFeeds && news.externalFeeds.slice(0, 1).map((feed, feedIdx) => (
                    <div key={feedIdx} className="shop-news-attachment-thumb">
                      <ExternalFeedThumbnail feed={feed} />
                    </div>
                  ))}

                  {news.photos && news.photos.slice(0, 2).map((photo, photoIdx) => (
                    <div key={photoIdx} className="shop-news-attachment-thumb">
                      <AspectImage aspectRatio="1:1">
                        <img
                          src={photo.url}
                          alt="news image"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.openPhotoViewer(newsIdx, photoIdx)}
                        />
                      </AspectImage>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}

ShopMainNewsSlider.defaultProps = {
  newsList: [],
  url: {},
  history: {},
};

export default withTranslation()(ShopMainNewsSlider);
