import React from 'react';

import styles from './BookingInfoRow.module.css';

interface Props {
  centered?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
  title: React.ReactNode;
}

const BookingInfoRow = ({
  centered = false,
  children,
  fullWidth = false,
  title,
}: Props) => {
  return (
    <div className={`row mb-2 ${centered ? 'text-center' : ''} ${styles.root}`}>
      {title && (
        <div className={`${fullWidth ? 'col-12' : 'col-xl-4'} ${styles.title}`}>
          {title}
        </div>
      )}

      <div className={`col ${styles.body}`}>{children}</div>
    </div>
  );
};

export default BookingInfoRow;
