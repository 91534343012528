import {
  SITE_LINKABLE_ARTICLE_WILL_FETCH,
  SITE_LINKABLE_ARTICLE_DID_FETCH,
  SITE_LINKABLE_LANDING_WILL_FETCH,
  SITE_LINKABLE_LANDING_DID_FETCH,
} from '../../constants/constants';

const initialState = {
  isFetchingArticles: false,

  /**
   * The last time when list of feature article is fetched
   */
  articleLastFetchedAt: null,

  articles: [],

  isFetchingLandings: false,

  /**
   * The last time when list of landing pages is fetched
   */
  landingLastFetchedAt: null,

  landings: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SITE_LINKABLE_ARTICLE_WILL_FETCH:
      return {
        ...state,
        isFetchingArticles: true,
      };

    case SITE_LINKABLE_ARTICLE_DID_FETCH:
      return {
        ...state,
        isFetchingArticles: false,
        articleLastFetchedAt: new Date(),
        articles: action.payload,
      };

    case SITE_LINKABLE_LANDING_WILL_FETCH:
      return {
        ...state,
        isFetchingLandings: true,
      };

    case SITE_LINKABLE_LANDING_DID_FETCH:
      return {
        ...state,
        isFetchingLandings: false,
        landingLastFetchedAt: new Date(),
        landings: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
