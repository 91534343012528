import React, { useMemo } from 'react';

import FormField from './FormField';
import TextInput, { TextInputProps } from './TextInput';

interface Props extends TextInputProps {
  className?: string;
  errorMessage?: React.ReactNode;
  help?: React.ReactNode;
  hint?: React.ReactNode;
  isBusy?: boolean;
  iconClass?: string;
  label?: React.ReactNode;
}

const TextInputField = ({
  className,
  errorMessage,
  help,
  hint,
  isBusy,
  iconAtRight,
  iconClass,
  label,
  required,
  type = 'text',
  ...props
}: Props) => {

  const resolvedIconClass = useMemo(() => {
    if (!iconClass) {
      if (type === 'email') {
        return 'fas fa-envelope';
      }
      else if (type === 'password') {
        return 'fas fa-lock';
      }
      else if (type === 'tel') {
        return 'fas fa-phone-alt';
      }
    }

    return iconClass;
  }, [type, iconClass]);

  return (
    <FormField
      className={className}
      errorMessage={errorMessage}
      help={help}
      hint={hint}
      isBusy={isBusy}
      iconAtRight={iconAtRight}
      iconClass={resolvedIconClass}
      label={label}
      required={required}
    >
      <TextInput
        iconAtRight={iconAtRight}
        hasIcon={!!resolvedIconClass}
        required={required}
        type={type}
        {...props}
      />
    </FormField>
  );
};

export default TextInputField;
