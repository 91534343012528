import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

interface Props {
  className?: string;
  disabled?: boolean;
  outlined?: boolean;
  onClick: () => void;
}

const EditIconButton = ({
  className = '',
  disabled = false,
  outlined = false,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  const buttonExtraClass = useMemo(() => {
    if (outlined) {
      return (
        'mx-0 ' + (disabled ? 'btn-outline-secondary' : 'btn-outline-primary')
      );
    } else {
      return 'btn-link';
    }
  }, [disabled, outlined]);

  return (
    <Tooltip
      className={className}
      placement="top"
      title={`${t('action.edit')}`}
    >
      <button
        className={`btn btn-sm ${buttonExtraClass}`}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        <i className="fas fa-pencil-alt" />
      </button>
    </Tooltip>
  );
};

export default EditIconButton;
