import React, { useMemo } from 'react';

export interface NumberInputProps {
  className?: string;
  disabled?: boolean;
  hasIcon?: boolean;
  iconAtRight?: boolean;
  max?: number;
  min?: number;
  name?: string;
  placeholder?: string;
  required?: boolean;
  step?: number;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberInput = ({
  className = '',
  disabled = false,
  hasIcon = false,
  iconAtRight = false,
  max,
  min,
  name = '',
  placeholder = '',
  required = false,
  step,
  value,
  onChange = () => {},
}: NumberInputProps) => {
  const inputClassName = useMemo(() => {
    return `${className} form-item ${
      hasIcon
        ? iconAtRight
          ? 'form-item--ico-right'
          : 'form-item--ico-left'
        : ''
    }`;
  }, [className, hasIcon, iconAtRight]);

  return (
    <input
      autoComplete="off"
      className={inputClassName}
      disabled={disabled}
      max={max}
      min={min}
      name={name}
      placeholder={(!disabled && placeholder) || ''}
      // required={required}
      step={step}
      type="number"
      value={value || ''}
      onChange={onChange}
    />
  );
};

export default NumberInput;
