import React from 'react';
import { useTranslation } from 'react-i18next';

import { LocalizeWeekday } from '../../../../Functions/datetimeHelpers';

const ScheduleDay = ({ working, name, time_from, time_to }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between shop-schelude">
      <span className="shop-schelude-weekday">
        <LocalizeWeekday name={name} />
      </span>
      <span className="shop-schelude-workinghour">
        {working !== 0 ? `${time_from} - ${time_to}` : t('dayOff')}
      </span>
    </div>
  );
};

export default ScheduleDay;
