import moment from 'moment';

import {
  DEFINITION_PAYMENT_METHODS_WILL_FETCH,
  DEFINITION_PAYMENT_METHODS_DID_FETCH,
} from '../../constants/constants';
import api from '../../../api';


export const fetchPaymentMethods = (forceFetch = false) => (dispatch, getState) => {
  if (!forceFetch) {
    const prevState = getState().paymentMethodReducer;

    if (prevState.isFetchingPaymentMethods) {
      // console.log('fetching payment methods. No need to fire a fetch request.');
      return;
    }
    else if (prevState.paymentMethodsLastFetchedAt && moment().diff(prevState.paymentMethodsLastFetchedAt, 'minutes', true) <= 15) {
      // console.log('list of payment methods is still fresh. No need to fire a fetch request.');
      return;
    }
  }

  dispatch(paymentMethodsWillFetch());

  api.paymentMethod.fetchList()
    .then(response => {
      dispatch(paymentMethodsDidFetch(response.data));
  });
}

const paymentMethodsWillFetch = () => ({
  type: DEFINITION_PAYMENT_METHODS_WILL_FETCH,
});

const paymentMethodsDidFetch = (paymentMethods) => ({
  type: DEFINITION_PAYMENT_METHODS_DID_FETCH,
  payload: {
    paymentMethods,
  },
});
