import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import { getShopNews, updateShopNews } from '../../../../../Redux/actions/features/shopNewsAction';

import Breadcrumb from '../../../../Common/Breadcrumb/Breadcrumb';
import notification from '../../../../Common/notification';
import { AppButton } from '../../../../Common/Button';
import { FormField, Textarea, TextInputField } from '../../../../Common/Form';
import Spinner from '../../../../Common/Spinner/Spinner';

import NewsImageGrid from './NewsImageGrid';
import NewsExternalFeedList from './NewsExternalFeedList';


class NewsUpdateForm extends React.Component {

  _isMounted = false;

  state = {
    isLoading: false,
    isSaving: false,

    title: '',
    description: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this._isMounted = true;

    this.setState({
      isLoading: true,
    });

    this.props.getShopNews({ id: this.props.match.params.newsId }).then(result => {
      if (result && result.res) { // result.res is ajax response
        const shopNews = result.res.data;

        this.setState({
          title: shopNews.title,
          description: shopNews.description,
        });
      }
    })
    .finally(() => {
      if (!this._isMounted) return;

      this.setState({
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { title, description } = this.state;

    const data = {
      id: this.props.match.params.newsId,
      title,
      description,
    };

    this.setState({
      isSaving: true,
    });

    this.props.updateShopNews(data).then(result => {
      if (result && result.res) { // result.res is ajax response
        notification(this.props.t('notifications.changesSaved'));

        this.props.history.push('/shop-settings/news');
      }
    })
    .finally(() => {
      if (!this._isMounted) return;

      this.setState({
        isSaving: false,
      });
    });
  };

  render() {
    const { t } = this.props;
    const {
      isLoading,
      isSaving,

      description,
      title,
    } = this.state;

    const routes = [
      { title: t('shopPrivate.shopLeftMenu.news'), path: '/shop-settings/news' },
      { title: t('action.edit'), path: this.props.match.path },
    ];

    const newsId = this.props.match.params.newsId;

    const isFormBusy = isLoading || isSaving;

    return (
      <div className="mainPanel">
        <h2 className="categoryTitle">
          <i className="fas fa-newspaper" aria-hidden="true" />
          {t('shopPrivate.shopLeftMenu.news')}
        </h2>

        <Breadcrumb routes={routes} buttonExist />

        <div className="main-container">
          <div className="page-head">
            <h2 className="page-title">
              {t('shopPrivate.shopLeftMenu.news')}
              {isFormBusy && <Spinner />}
            </h2>
          </div>

          <div className="main-container__content">
            <form className="mb-5" onSubmit={this.handleSubmit}>
              <fieldset disabled={isFormBusy}>{/* Able to disable child input fields when saving form */}

                {/* Title */}
                <TextInputField
                  label={t('shopPrivate.news.title')}
                  name="title"
                  required
                  value={title}
                  onChange={this.handleChange}
                />

                {/* Description */}
                <FormField
                  label={t('shopPrivate.news.descr')}
                  required
                >
                  <Textarea
                    className="content-field__item"
                    name="description"
                    value={description}
                    onChange={this.handleChange}
                  />
                </FormField>
              </fieldset>

              <AppButton className="mt-2" theme="primary" type="submit" disabled={isFormBusy}>
                {isFormBusy ? <Spinner /> : t('action.save')}
              </AppButton>
            </form>

            <hr />

            <NewsExternalFeedList
              newsId={newsId}
            />

            <hr />

            <NewsImageGrid
              newsId={newsId}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shopNews: state.shopNewsReducer.shopNews,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        getShopNews,
        updateShopNews,
      }
    )(NewsUpdateForm)
  )
);
