import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import axios from 'axios';

import { apiErrorSweeper, shopBookingAPI } from 'src/utils/api';

import notification from '../../../Common/notification';

import DeclineMessage from '../Bookings/BookingsTemplate/DeclineMessage';

class CalendarEventWindow extends Component {
  state = {
    toggleDeclineMessage: false,
    disabled: true,
    message: '',
    staff: '',
    time: '',
    changeTimeWindowOpen: false,
    pm: false,
  };

  componentDidMount() {
    const { time, staff_id } = this.props;
    const hours = Number(time.slice(0, time.indexOf(':')));
    const hoursPmAm =
      hours > 12
        ? hours > 21
          ? `${(hours - 12).toString()}`
          : `0${(hours - 12).toString()}`
        : hours !== 0
          ? hours < 10
            ? `0${hours.toString()}`
            : hours.toString()
          : '00';
    this.setState({
      time: time,
      pm: hours < 12 ? false : true,
      hours: hoursPmAm,
      staff: staff_id,
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleServiceChange = e => {
    this.setState({
      staff: e.target.value,
    });
  };

  handleChangeTime = ({ target }) => {
    if (target.getAttribute('name') === 'howOclockFormat') {
      this.setState(prevState => {
        const hours = prevState.time.slice(0, prevState.time.indexOf(':'));
        const newHours =
          Number(hours) < 12
            ? (Number(hours) + 12).toString()
            : (Number(hours) - 12).toString().length === 1
              ? `0${(Number(hours) - 12).toString()}`
              : (Number(hours) - 12).toString();
        const hoursPmAm =
          Number(hours) > 12
            ? Number(hours) > 21
              ? `${(Number(hours) - 12).toString()}`
              : `0${(Number(hours) - 12).toString()}`
            : Number(hours) !== 0
              ? Number(hours) < 10
                ? `0${Number(hours).toString()}`
                : Number(hours).toString()
              : '00';
        const newTime = prevState.time.replace(/^\d{2}/, newHours);
        return {
          time: newTime,
          hours: hoursPmAm,
          pm: !this.state.pm,
        };
      });
    } else if (target.getAttribute('name') === 'hours') {
      this.setState(prevState => {
        const newTime = prevState.time.replace(/^\d{2}/, target.value);
        return {
          time: newTime,
          hours: target.value,
          pm: Number(target.value) < 12 ? false : true,
        };
      });
    } else if (target.getAttribute('name') === 'minutes') {
      this.setState(prevState => {
        const newTime = prevState.time.replace(/\d{2}$/, target.value);
        return {
          time: newTime,
        };
      });
    }
  };

  handleChangeBoolean = ({ target }) => {
    this.setState(prevState => ({
      [target.name]: !prevState.changeTimeWindowOpen,
    }));
  };

  declineBooking = bookingId => {
    const { view, dateContext, selectedStaff } = this.props;

    shopBookingAPI
      .cancelBooking(bookingId, { providerRemarks: this.state.message })
      .then(() => {
        if (view === 'week') {
          this.props.getShopBookingsForCalendar({
            staff_id: selectedStaff,
            filter: { week: { week: dateContext.week(), year: dateContext.year() } },
          });
        } else if (view === 'day') {
          this.props.getShopBookingsForCalendar({
            staff_id: selectedStaff,
            filter: { day: { day: dateContext.dayOfYear(), year: dateContext.year() } },
          });
        } else {
          this.props.getShopBookingsForCalendar({
            staff_id: selectedStaff,
            filter: { month: { month: dateContext.month() + 1, year: dateContext.year() } },
          });
        }

        notification(this.props.t('bookingManagement.cancelBooking.succeeded'));
        this.props.closeWindow();
      })
      .catch(apiErrorSweeper);
  };

  toggleDeclineModal = () => {
    this.setState(prevState => ({
      toggleDeclineMessage: !prevState.toggleDeclineMessage,
    }));
  };

  readWriteAccess = () => {
    this.setState(prevState => ({
      disabled: !prevState.disabled,
      time: this.props.time,
      changeTimeWindowOpen: false,
    }));
  };

  changeBookingData = () => {
    const { view, dateContext, selectedStaff, event_date } = this.props;
    const { staff } = this.state;
    axios
      .put(`/api/shop-bookings/${this.props.id}?time=${this.state.time}&staff_id=${staff}&date=${event_date}`)
      .then(res => {
        if (res.data.message === 'success') {
          if (view === 'week') {
            this.props.getShopBookingsForCalendar({
              staff_id: selectedStaff,
              filter: { week: { week: dateContext.week(), year: dateContext.year() } },
            });
          } else if (view === 'day') {
            this.props.getShopBookingsForCalendar({
              staff_id: selectedStaff,
              filter: { day: { day: dateContext.dayOfYear(), year: dateContext.year() } },
            });
          } else {
            this.props.getShopBookingsForCalendar({
              staff_id: selectedStaff,
              filter: { month: { month: dateContext.month() + 1, year: dateContext.year() } },
            });
          }
          notification(this.props.t('notifications.changesSaved'));
        } else {
          notification(res.data.data || this.props.t('notifications.somethingWasWrong'));
        }
      })
      .catch(() => notification(this.props.t('notifications.somethingWasWrong')));
    this.setState({
      changeTimeWindowOpen: !this.state.changeTimeWindowOpen,
    });
    this.props.closeWindow();
  };

  render() {
    const {
      id,
      serviceName,
      price,
      staff_name,
      staff_id,
      closeWindow,
      customerFirstName,
      customerLastName,
      view,
      days,
      status,
      staffList,
      t,
    } = this.props;
    const { time, pm, message } = this.state;
    const hours = Number(time.slice(0, time.indexOf(':')));

    return (
      <div
        className={`event active ${
          view === 'day' || view === 'week'
            ? hours < 12
              ? 'event--bottom'
              : 'event--top'
            : days < 16
              ? 'event--bottom'
              : 'event--top'
        }`}
      >
        <button className="event__close" onClick={closeWindow}>
          <i className="fas fa-times" />
        </button>
        <div className="event-block">
          <div className="event-info">
            <p>{t('shopPrivate.calendar.time')}:</p>
            {/* <span>
              <input
                type="time"
                name="time"
                disabled={this.state.disabled}
                onChange={this.handleChange}
                className="form-item"
                defaultValue={time}
              />
            </span> */}
            <div className="field-wrap">
              <input
                // id={id}
                type="time"
                name="changeTimeWindowOpen"
                value={time}
                onClick={this.handleChangeBoolean}
                className="form-item"
                placeholder="15:25"
                disabled={this.state.disabled}
                readOnly
              />
              <span className="field-ico field-ico--to-right">
                <i className="fas fa-clock" />
              </span>
              {this.state.changeTimeWindowOpen && (
                <div className="date-drop">
                  <div className="date-drop__item">
                    <div className="date-drop__body">
                      <div className="date-select">
                        <span className="date-select__ico">
                          <i className="fas fa-caret-down" />
                        </span>
                        <select
                          name="hours"
                          onChange={this.handleChangeTime}
                          className="date-select__item"
                          value={this.state.hours}
                        >
                          <option value="00">12</option>
                          <option value="01">1</option>
                          <option value="02">2</option>
                          <option value="03">3</option>
                          <option value="04">4</option>
                          <option value="05">5</option>
                          <option value="06">6</option>
                          <option value="07">7</option>
                          <option value="08">8</option>
                          <option value="09">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                        </select>
                      </div>
                      <span className="date-separator">:</span>
                      <div className="date-select">
                        <span className="date-select__ico">
                          <i className="fas fa-caret-down" />
                        </span>
                        <select
                          name="minutes"
                          onChange={this.handleChangeTime}
                          className="date-select__item"
                          value={time.slice(time.indexOf(':') + 1)}
                        >
                          <option value="00">0</option>
                          <option value="05">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="30">30</option>
                          <option value="35">35</option>
                          <option value="40">40</option>
                          <option value="45">45</option>
                          <option value="50">50</option>
                          <option value="55">55</option>
                        </select>
                      </div>
                    </div>
                    <div className="date-drop__footer">
                      <div className="date-choise">
                        <span>AM</span>
                        <div className="date-check">
                          <input
                            type="checkbox"
                            className="checkbox-date"
                            name="howOclockFormat"
                            checked={pm}
                            id={`howOclockFormat${id}`}
                            onChange={e => {
                              this.handleChangeTime(e);
                              // this.handleChangePm();
                            }}
                          />
                          <label
                            // onClick={this.handleChangeTime}
                            htmlFor={`howOclockFormat${id}`}
                          />
                        </div>
                        <span>PM</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="event-info">
            <p>{t('shopPrivate.bookings.selectedStylist')}:</p>
            {this.state.disabled ? (
              <span>
                <input
                  type="text"
                  name="staff"
                  value={staff_name}
                  onChange={this.handleChange}
                  disabled={this.state.disabled}
                  className="form-item"
                />
              </span>
            ) : (
              <select className="form-item" name="staff" onChange={this.handleChange}>
                <option />
                {staffList.map((staffData, index) => (
                  <option key={index} value={staffData.user_id} selected={staff_id === staffData.user_id}>
                    {staffData.first_name} {staffData.last_name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="event-info">
            <p>{t('shopPrivate.calendar.serviceName')}:</p>
            <span>{serviceName}</span>
          </div>
        </div>
        <div className="event-block">
          <div className="event-extra">
            <p>{t('shopPublic.product.price')}:</p>
            <span>{price}</span>
          </div>
          <div className="event-extra">
            <p>{t('shopPrivate.bookings.custName')}:</p>
            <span>
              {customerFirstName} {customerLastName}
            </span>
          </div>
        </div>
        <div className="event-block">
          <div className="event-control">
            {!this.state.disabled && (
              <button className="event-control__item" onClick={this.changeBookingData}>
                <i className="fas fa-check" />
              </button>
            )}
            {status === 'Confirmed' && (
              <Fragment>
                <button className="event-control__item" onClick={this.readWriteAccess}>
                  <i className="fas fa-pen" />
                </button>
                <button className="event-control__item" onClick={this.toggleDeclineModal}>
                  <i className="fas fa-trash" />
                </button>
              </Fragment>
            )}
            {this.state.toggleDeclineMessage && (
              <DeclineMessage
                toggleModalDecline={this.toggleDeclineModal}
                declineBooking={this.declineBooking}
                handleChange={this.handleChange}
                id={id}
                message={message}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CalendarEventWindow);
