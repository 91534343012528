import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppButton } from '../../../../Common/Button';
import { TextInputField } from '../../../../Common/Form';

const ServiceTypeModal = ({
  name,
  action,
  stateId,
  onChangeName,
  onCreate,
  onUpdate,
  onClose,
}) => {
  const { t } = useTranslation();

  const onUpdateFn = () => {
    onUpdate(stateId, name);
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-wrapper__inner">
        <div className="theme-modal theme-modal--album">
          <div className="theme-modal__header theme-modal__header--code">
            <h2>
              {action === 'create'
                ? t('shopPrivate.servicePriceList.addServiceType')
                : t('shopPrivate.servicePriceList.serviceTypes')}
            </h2>
            <button className="close-modal" onClick={onClose}>
              <i className="fas fa-times" />
            </button>
          </div>

          <div className="theme-modal__content">
            <div className="modal-inner-content">
              <TextInputField
                label={t('shopPrivate.servicePriceList.typeName')}
                name="name"
                required
                value={name}
                onChange={onChangeName}
              />
            </div>
          </div>

          <div className="confirm-code-btn">
            {action === 'create' ? (
              <AppButton
                disabled={!(name || '').trim()}
                theme="primary"
                onClick={onCreate}
              >
                {t('action.create')}
              </AppButton>
            ) : (
              <AppButton
                disabled={!(name || '').trim()}
                theme="primary"
                onClick={onUpdateFn}
              >
                {t('action.save')}
              </AppButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeModal;
