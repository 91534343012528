import { useTranslation } from 'react-i18next';

import Spinner from '../Spinner/Spinner';

interface Props {
  isLoading?: boolean;
  itemCount?: number;
}

function DataTableStatus({ isLoading = false, itemCount = 0 }: Props) {
  const { t } = useTranslation();

  return isLoading ? (
    <Spinner display="block" />
  ) : itemCount === 0 ? (
    <div className="nothing-text">{t('general.message.listEmpty')}</div>
  ) : null;
}

export default DataTableStatus;
