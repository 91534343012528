import { useMemo } from 'react';

import useAuthorize from './useAuthorize';

/**
 * This hook accepts permission code(s) and returns a boolean, stating whether
 * current user is granted any of the specified permission(s).
 *
 * @param requiredPermissions
 * @returns
 */
function useAuthorized(requiredPermissions: string | string[]) {
  const { isAuthorized } = useAuthorize();

  const result = useMemo(
    () => isAuthorized(requiredPermissions),
    [isAuthorized, requiredPermissions]
  );

  return result;
}

export default useAuthorized;
