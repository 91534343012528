import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Tooltip,
} from '@material-ui/core';


const EditIconLink = ({
  to,
}) => {

  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={t('action.edit')}>
      <Link className="btn btn-sm btn-outline-primary mx-1" to={to}>
        <i className="fas fa-pencil-alt" />
      </Link>
    </Tooltip>
  );
}

export default EditIconLink;
