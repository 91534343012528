import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';


import { signIn } from '../../Redux/actions/authActions/signInActions';

// import AuthWithFacebookTwitter from './AuthWithFacebookTwitter';
import {
  AppButton,
  AppButtonLink,
} from '../Common/Button';
import { TextInputField } from '../Common/Form';

import RecoverPasswordDialog from './RecoverPasswordDialog';


const SignInDialog = ({
  match,

  // from parent:
  isPendingBooking,
  onClose,
  onRequestSignUp,

  // redux actions:
  signIn,
}) => {

  const { t, i18n } = useTranslation();

  /**
   * This state helps prevent user from accidentally / unintentionally dismissing the dialog.
   *
   * A `true` value => User has NOT ever interacted with the form.
   */
  const [isPristine, setPristine] = useState(true);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isForgotPasswordWindowOpened, setForgotPasswordWindowOpened] = useState(false);

  const [emailFieldErrMsg, setEmailFieldErrMsg] = useState('');
  const [passwordFieldErrMsg, setPasswordFieldErrMsg] = useState('');
  const [signInErrorMessage, setSignInErrorMessage] = useState(null);

  const handleDialogClose = (e, reason) => {
    if (isPristine) {
      onClose();
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);

    setPristine(false);
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value);

    setPristine(false);
  }

  const handleSubmit = e => {
    e.preventDefault();

    const data = {
      email,
      password,
      locale: i18n.language === 'en' ? 'en' : 'zh-HK',
    };

    signIn(data)
      .then(response => {
        if (response.status === 422) { // validation failed
          setEmailFieldErrMsg(response.data.email || null);
          setPasswordFieldErrMsg(response.data.password || null);
          setSignInErrorMessage(null);
        } else if (response.status !== 200) {
          // Sign in failed
          setEmailFieldErrMsg(null);
          setPasswordFieldErrMsg(null);
          setSignInErrorMessage(response.message || t('notifications.somethingWentWrong'));
        } else {
          // Sign in successfully
          setEmailFieldErrMsg(null);
          setPasswordFieldErrMsg(null);
          setSignInErrorMessage(null);

          onClose();
        }
      });
  };

  const storeRedirectUrlAfterSignup = () => {
    // Store url of current page to sessionStorage.
    // Pending to be redirected back to such page after successful signup
    sessionStorage.setItem('redirectUrlAfterSignup', match.url);
  }

  return !isForgotPasswordWindowOpened ? (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={handleDialogClose}
      aria-labelledby="signin-dialog-title"
    >
      <DialogTitle id="signin-dialog-title">
        <div className="d-flex align-items-center">
          <h2 className="flex-fill m-0">
            {isPendingBooking
              ? t('shopPrivate.bookings.loginFirst')
              : t('account.action.signIn')}
          </h2>

          <button className="btn btn-link" onClick={onClose}>
            <i className="fas fa-times text-muted" />
          </button>
        </div>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          {/* Email */}
          <TextInputField
            autoComplete="username"
            errorMessage={emailFieldErrMsg}
            label={t('generalFields.email.label')}
            name="email"
            placeholder={t('generalFields.email.label')}
            type="email"
            required
            value={email}
            onChange={handleChangeEmail}
          />

          {/* Password */}
          <TextInputField
            autoComplete="current-password"
            label={t('auth.password.label')}
            type="password"
            name="password"
            value={password}
            placeholder={t('auth.password.label')}
            required
            errorMessage={passwordFieldErrMsg}
            onChange={handleChangePassword}
          />

          {signInErrorMessage && (
            <div className="alert alert-danger">{signInErrorMessage}</div>
          )}

          <div className="row flex-column flex-md-row justify-content-between align-items-center mt-5">
            <div className="col-md-5">
              <AppButton size="block" theme="primary" type="submit">
                {t('account.action.signIn')}
              </AppButton>
            </div>

            <div className="col-md-5 mt-4 mt-md-0">
              {isPendingBooking ? (
                <AppButtonLink
                  to="/register-customer-member"
                  className="mt-2 mt-md-0"
                  size="block"
                  theme="muted"
                  onClick={storeRedirectUrlAfterSignup}
                >
                  {t('account.action.signUpAsCustomer')}
                </AppButtonLink>
              ) : (
                <AppButton size="block" theme="muted" onClick={onRequestSignUp}>
                  {t('account.action.callForSignUp')}
                </AppButton>
              )}
            </div>
          </div>

          {/* It aligns right on mobile, so as not to be covered by chat widget button. */}
          <div className="mt-3 text-right text-md-left">
            <button
              className="forgot-pass"
              type="button"
              onClick={(e) => setForgotPasswordWindowOpened(true)}
            >
              {t('signIn.forgotPassword')}
            </button>
          </div>

          {/*
            <AuthWithFacebookTwitter authSocNet={authSocNet} />
          */}
        </form>
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  ) : (
    <RecoverPasswordDialog onClose={() => setForgotPasswordWindowOpened(false)} />
  );
}

SignInDialog.defaultProps = {
  onRequestSignUp: () => {},
};

export default withRouter(
  connect(
    null,
    {
      signIn,
    }
  )(SignInDialog)
);
