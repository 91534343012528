import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';


import { Shop } from 'src/models/entity';

import { shopAPI } from 'src/utils/api';

import { CopyButton } from 'src/Components/Common/Button';


interface Props {
  appearance?: 'link' | 'button';
  shop?: Shop;
  shopId?: string;
  withCopyButton?: boolean;
}

function FastCheckoutLink({
  appearance = 'link',
  shop,
  shopId,
  withCopyButton = false,
}: Props) {
  const { t } = useTranslation();

  const [checkoutUrl, setCheckoutUrl] = useState('');

  const refreshCheckoutUrl = async () => {
    if (checkoutUrl) {
      return checkoutUrl;
    }

    const url = await shopAPI.getCheckoutUrl({ shop, shopId });
    setCheckoutUrl(url);
    return url;
  };

  const openCheckoutPage = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    // Lazily refresh checkout url on demand
    refreshCheckoutUrl()
      .then((url) => {
        if (url) {
          window.open(url);
        } else {
          alert('Missing checkout token');
        }
      });
  };

  useEffect(() => {
    if (withCopyButton) {
      // If copy button is needed, immediately refresh checkout url
      refreshCheckoutUrl();
    }
  }, [withCopyButton]);

  return (
    <>
      {appearance === 'button' ? (
        <Tooltip
          placement="top"
          title={`${t('shopPrivate.bookings.checkout')}`}
        >
          <button
            className="btn btn-sm btn-primary"
            type="button"
            onClick={openCheckoutPage}
          >
            <i className="fas fa-money-bill-alt" />
          </button>
        </Tooltip>
      ) : (
        <a
          href={checkoutUrl || '#'}
          className="px-2"
          onClick={openCheckoutPage}
        >
          <i className="mx-1 fas fa-money-bill-alt" />
          {t('shopManagement.fastCheckoutPage.label')}
        </a>
      )}

      {withCopyButton && (
        <CopyButton
          contentLabel={t('shopManagement.fastCheckoutPage.pageUrl')}
          contentToCopy={checkoutUrl}
        />
      )}
    </>
  );
}

export default FastCheckoutLink;
