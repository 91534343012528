import React from 'react';
import { withTranslation } from 'react-i18next';

function ManageRightsItem({ staff, rights, staffRight, blockNum, handleChangeOnCheckbox, t }) {
  return (
    <tr data-label-tr={`${t('block')} #${blockNum}`}>
      <td data-label={t('shopPrivate.manageRights.user')}>
        {staff.nickname}
      </td>
      <td data-label={t('shopPrivate.manageRights.rights')}>
        {rights.map((elem, index) => {
          let flag = false;
          staffRight.map(id => {
            if (id === elem.id) {
              flag = true;
            }
            return id;
          });
          return (
            <div key={+index} className="d-flex align-items-center">
              <input
                type="checkbox"
                className="checkbox checkbox--rights-type"
                name={staff.user_id}
                onChange={handleChangeOnCheckbox({ staff: staff, rightId: elem.id })}
                checked={flag}
                id={`r${staff.user_id}-${index}`}
              />
              <label htmlFor={`r${staff.user_id}-${index}`}>
                <span>{elem.name}</span>
              </label>
            </div>
          );
        })}
      </td>
    </tr>
  );
}

export default withTranslation()(ManageRightsItem);
