import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { fetchShops } from '../../../../Redux/actions/features/searchAction';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import Breadcrumb from '../../../Common/Breadcrumb/Breadcrumb';
import Pagination from '../../../Common/Pagination';
import SiteContainer from '../../Global/SiteContainer/SiteContainer';

import DefaultServiceCheckables from './DefaultServiceCheckable/DefaultServiceCheckables';
import FilterCheck from './Item/FilterCheck.component';
import PaymentMethodCheckables from './PaymentMethodCheckable/PaymentMethodCheckables';
import SearchPanel from './Item/SearchPanel.component';
import ShopCard from './Item/ShopCard';
import LocationFilter from './LocationFilter/LocationFilter';
import SectionToolbar from '../../../Common/Layout/SectionToolbar';
import FormControlBox from '../../../Common/Form/FormControlBox';


export const FILTER_SESSION_KEY = 'shopListState';

const SearchShops = ({
  // from mapStateToProps:
  defaultServices,
  shops,

  // redux actions:
  fetchShops,

  match,
}) => {

  const { t } = useTranslation();

  const [routes, setRoutes] = useState([]);

  // This state helps *prevent* the component from fetching items before the component finishes initializing
  const [isInitialized, setInitialized] = useState(false);

  // Whether the app has finished restoring (any) filters from sessionStorage
  const [isFilterRestored, setFilterRestored] = useState(false);

  // "Respected" means whether the url param `desiredDefaultServiceName` has been adopted or ignored.
  const [isDesiredDefaultRespected, setDesiredDefaultRespected] = useState(false);

  // true => User has NOT interacted with shop list (no changes on filters nor page)
  const [isPristine, setPristine] = useState(true);

  const [isFiltered, setFiltered] = useState(false);

  const [filterIsOpenNow, setFilterIsOpenNow] = useState(false);
  const [filterIsBookingEnabled, setFilterIsBookingEnabled] = useState(false);

  const [selectedDefaultServiceIds, setSelectedDefaultServiceIds] = useState([]);
  const [selectedPaymentMethodIds, setSelectedPaymentMethodIds] = useState([]);

  const [sortOrder, setSortOrder] = useState('desc');

  const [selectedCityId, setSelectedCityId] = useState('');
  const [selectedDistrictId, setSelectedDistrictId] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);


  useEffect(() => {
    // On component mounts

    try {
      // Restore filters from sessionStorage
      const filters = JSON.parse(sessionStorage.getItem(FILTER_SESSION_KEY));

      if (filters) {
        setPristine(!!filters.isPristine);

        if (filters.filterIsOpenNow) setFilterIsOpenNow(filters.filterIsOpenNow);
        if (filters.filterIsBookingEnabled) setFilterIsBookingEnabled(filters.filterIsBookingEnabled);

        if (filters.selectedDefaultServiceIds) setSelectedDefaultServiceIds(filters.selectedDefaultServiceIds);
        if (filters.selectedPaymentMethodIds) setSelectedPaymentMethodIds(filters.selectedPaymentMethodIds);

        if (filters.sortOrder) setSortOrder(filters.sortOrder);

        if (filters.selectedCityId) setSelectedCityId(filters.selectedCityId);
        if (filters.selectedDistrictId) setSelectedDistrictId(filters.selectedDistrictId);

        if (filters.currentPage) setCurrentPage(filters.currentPage);
      }
    } catch (error) {
    }

    setFilterRestored(true);
  }, []);

  useEffect(() => {
    // This check defers the execution of such callback
    if (!isFilterRestored) return;

    // This check ensures this callback is executed once only
    if (isDesiredDefaultRespected) return;

    if (defaultServices.length > 0) {
      // App has finished fetching list of default services option from remote server

      if (isPristine) {
        // Note that the desired default service (on URL params) is *adopted* only when app is *pristine*

        if (false) {
          // TODO (WIP) Temporarily disable the pre-specified default service filter via url param for shop list @e0dd114
          const { desiredDefaultServiceName } = match.params;
          if (desiredDefaultServiceName) {
            const desiredItem = defaultServices.find(item => item.name === desiredDefaultServiceName || item.chineseName == desiredDefaultServiceName);
            if (desiredItem) {
              setSelectedDefaultServiceIds([desiredItem.id]);
            }
          }
        }
      }
      else {
        // otherwise the desired default service (on URL params) is simply ignored.
      }

      setDesiredDefaultRespected(true);
    }
  }, [isFilterRestored, defaultServices]);

  useEffect(() => {
    // App initialization is considered finished only if:
    // - the app finished restoring (any) filters from sessionStorage; and
    // - the desired default service (on URL params) has been "respected"
    setInitialized(isFilterRestored && isDesiredDefaultRespected);
  }, [isFilterRestored, isDesiredDefaultRespected]);

  useEffect(() => {
    // On component mounts

    setRoutes([
      { title: t('shopList.navShopList'), path: window.location.pathname }
    ]);
  }, []);

  useEffect(() => {
    // whenever any filters are changed

    if (!isInitialized) {
      // Not to proceed before the component finishes restoring filters from sessionStorage
      return;
    }

    // Store filters into sessionStorage
    const filters = {
      isPristine,
      filterIsOpenNow,
      filterIsBookingEnabled,
      selectedDefaultServiceIds,
      selectedPaymentMethodIds,
      sortOrder,
      selectedCityId,
      selectedDistrictId,
      currentPage,
    };

    sessionStorage.setItem(FILTER_SESSION_KEY, JSON.stringify(filters));


    setFiltered(
      !!filterIsOpenNow ||
      !!filterIsBookingEnabled ||
      selectedDefaultServiceIds.length > 0 ||
      selectedPaymentMethodIds.length > 0 ||
      !!selectedCityId ||
      !!selectedDistrictId
    );

    // Refresh list
    fetchItems();
  }, [
    isInitialized,
    filterIsOpenNow,
    filterIsBookingEnabled,
    selectedDefaultServiceIds,
    selectedPaymentMethodIds,
    sortOrder,
    selectedCityId,
    selectedDistrictId,
    currentPage,
  ]);

  /**
   *
   * @param {number} page 1-based page number
   */
  const fetchItems = (page) => {
    const data = {
      sort_by: sortOrder,
      open_now: filterIsOpenNow,
      booking_system: filterIsBookingEnabled,
      payment: selectedPaymentMethodIds,
      default_service: selectedDefaultServiceIds,
      city: selectedCityId,
      district: selectedDistrictId,
      current_page: page || currentPage,
    };

    fetchShops(data)
      .then(response => {
        setLastPage(response.data.last_page);
      });
  };

  const handleChangeDefaultService = (defaultService, checked) => {
    setPristine(false);
    setCurrentPage(1); // whenever user refines filter, fetch first page

    if (checked) {
      setSelectedDefaultServiceIds(prevItems => (
        [...prevItems, defaultService.id]
      ));
    } else {
      setSelectedDefaultServiceIds(prevItems => {
        const nextItems = prevItems.filter(defaultServiceId => defaultServiceId != defaultService.id);

        // Uncheck all its children too
        if (defaultService.isRoot && defaultService.children) {
          return nextItems.filter(
            (defaultServiceId) =>
              defaultService.children.findIndex(
                (child) => child.id === defaultServiceId
              ) === -1
          );
        }

        return nextItems;
      });
    }
  }

  const handleChangeSortOrder = ({ target }) => {
    setPristine(false);
    setCurrentPage(1); // whenever user refines filter, fetch first page

    setSortOrder(target.value);
  };

  const handleChangeFilterIsOpenNow = (checked) => {
    setPristine(false);
    setCurrentPage(1); // whenever user refines filter, fetch first page

    setFilterIsOpenNow(checked);
  };

  const handleChangeFilterIsBookingEnabled = (checked) => {
    setPristine(false);
    setCurrentPage(1); // whenever user refines filter, fetch first page

    setFilterIsBookingEnabled(checked);
  };

  const handleChangePaymentMethod = (paymentMethod, checked) => {
    setPristine(false);
    setCurrentPage(1); // whenever user refines filter, fetch first page

    if (checked) {
      setSelectedPaymentMethodIds(prevItems => (
        [...prevItems, paymentMethod.id]
      ));
    } else {
      setSelectedPaymentMethodIds(prevItems => (
        prevItems.filter(item => item != paymentMethod.id)
      ));
    }
  }

  const handleChangeCity = (selectedCity) => {
    setPristine(false);
    setCurrentPage(1); // whenever user refines filter, fetch first page

    if (selectedCity) {
      setSelectedCityId(selectedCity.id);
    } else {
      setSelectedCityId('');
    }

    setSelectedDistrictId('');
  };

  const handleChangeDistrict = (selectedDistrict) => {
    setPristine(false);
    setCurrentPage(1); // whenever user refines filter, fetch first page

    if (selectedDistrict) {
      setSelectedDistrictId(selectedDistrict.id);
    } else {
      setSelectedDistrictId('');
    }
  };

  const handlePageChange = ({ selected }) => {
    setPristine(false);
    setCurrentPage(selected + 1);

    window.scrollTo(0, 0);
  };

  return (
    <SiteContainer>
      <section className="book-section">
        <div className="container">
          <Breadcrumb routes={routes} />

          {/* List of checkboxes on Default Services */}
          <DefaultServiceCheckables
            selectedItemIds={selectedDefaultServiceIds}
            onChange={handleChangeDefaultService}
          />

          <SectionToolbar
            className="mt-3 px-3 d-none d-md-block"
            heading={
              <div className="font-weight-bold">
                {t('shopList.showRes', { num: shops.length })}
              </div>
            }
          >
            <div className="row justify-content-end">
              <div className="col-lg-6">
                <FormControlBox label={t('shopList.sortBy')}>
                  <select
                    className="form-item"
                    onChange={handleChangeSortOrder}
                  >
                    <option value="desc">
                      {t('shopList.sortHigh')}
                    </option>
                    <option value="asc">
                      {t('shopList.sortLow')}
                    </option>
                  </select>
                </FormControlBox>
              </div>
            </div>
          </SectionToolbar>

          <section className="book-section">
            <div className="border-top pt-2 pb-4">
              <div className="row">
                <div className="col-lg-3 col-xl-3">
                  <SearchPanel
                    isOpenNowFilter={
                      <div className="filter-item">
                        <FilterCheck
                          textForLabel={t('shopList.openNow')}
                          name="filterIsOpenNow"
                          value={true}
                          checked={filterIsOpenNow}
                          onChange={handleChangeFilterIsOpenNow}
                        />
                      </div>
                    }
                    isBookingEnabledFilter={
                      <div className="filter-item">
                        <FilterCheck
                          textForLabel={t('shopList.bookSys')}
                          name="filterIsBookingEnabled"
                          value={true}
                          checked={filterIsBookingEnabled}
                          onChange={handleChangeFilterIsBookingEnabled}
                        />
                      </div>
                    }
                    paymentMethodFilter={
                      <PaymentMethodCheckables
                        selectedItemIds={selectedPaymentMethodIds}
                        onChange={handleChangePaymentMethod}
                      />
                    }
                    locationFilter={
                      <LocationFilter
                        selectedCityId={selectedCityId}
                        onChangeCity={handleChangeCity}
                        selectedDistrictId={selectedDistrictId}
                        onChangeDistrict={handleChangeDistrict}
                      />
                    }
                  />
                </div>

                <div className="col-lg-7 col-xl-7">
                  <div className="d-flex flex-row flex-wrap justify-content-start mt-3">
                    {shops.map((shop) => (
                      <ShopCard key={shop.id} shop={shop} />
                    ))}
                  </div>
                </div>

                <div className="col-lg-2 d-none d-lg-block">
                  <div className="my-4">
                    <AdvertisementView name="shop_list_page" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div className="mt-3 d-lg-none">
        <AdvertisementView name="shop_list_page" />
      </div>

      {lastPage > 1 && (
        <Pagination
          currentPage={currentPage}
          lastPage={lastPage}
          pageChange={handlePageChange}
        />
      )}
    </SiteContainer>
  );
}

function mapStateToProps(state) {
  return {
    defaultServices: state.defaultServiceReducer.defaultServices,
    shops: state.searchReducer.results && state.searchReducer.results.data || [],
  };
}

export default connect(
  mapStateToProps,
  {
    fetchShops,
  }
)(SearchShops);
