import { useMemo } from 'react';

import { ellipsize } from '../../../Functions/textHelpers';

interface Props {
  maxlength?: number;
  text: string;
}

/**
 * It is specially designed that the rendered content returned from
 * this component is not wrapped with any tags.
 */
function Ellipsize({ maxlength = 50, text }: Props) {
  const transformed = useMemo(() => {
    return ellipsize(text, maxlength);
  }, [maxlength, text]);

  return <>{transformed}</>;
}

export default Ellipsize;
