import {SAVE_USER_SETTINGS} from '../../constants/constants'
import api from "../../../api";


export const changeMediaData = (data) => dispatch =>
    api.shopSettings.changeMediaData(data).then((res) =>{
        if (res.data.message === 'success') {
            dispatch(saveUserSettings(res.data.data));
            return {res, access: true}
        }
        return {res, access:false}
    });


export const saveUserSettings = (data) => ({
    type: SAVE_USER_SETTINGS,
    payload: data
});










