import { useEffect, useState } from 'react';

import Marquee from 'react-fast-marquee';


import { Announcement } from 'src/models/entity';

import { announcementAPI } from 'src/utils/api';


function AnnouncementMarquee() {

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    announcementAPI.fetchAnnouncements().then((remoteAnnouncements) => {
      setAnnouncements(remoteAnnouncements);
    });
  }, []);

  return announcements.length > 0 ? (
    <Marquee
      className="mt-1 alert alert-info text-body"
      delay={2}
      gradient={false}
      pauseOnHover
      speed={40}
    >
      {announcements.map((announcement) => (
        <div key={announcement.id} className="mx-4">
          {announcement.title}

          {!!announcement.url && (
            <a href={announcement.url} className="mx-1">
              {!!announcement.anchor ? (
                announcement.anchor
              ) : (
                <i className="far fa-play-circle"></i>
              )}
            </a>
          )}
        </div>
      ))}
    </Marquee>
  ) : null;
}

export default AnnouncementMarquee;
