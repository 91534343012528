import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { extractShopStaffList } from '../../../../Redux/actions/shopActions/shopStaffActions';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import Spinner from '../../../Common/Spinner/Spinner';
import { StaffBadge } from '../../../Common/Staff';

function ShopRightMenu({ isFetchingShopStaffDataList, staffDataList, t }) {

  return (
    <>
      <div className="pb-3">
        <h6 className="mb-3">{t('shopPublic.overview.staffList')}</h6>

        {isFetchingShopStaffDataList ? (
          <Spinner display="block" />
        ) : (
          staffDataList.map((staffData) => (
            <StaffBadge
              key={staffData.id}
              avatarSize="small"
              horizontal
              staffData={staffData}
            />
          ))
        )}
      </div>

      <div className="mt-3">
        <AdvertisementView name="shop_page" delay={3000} />
      </div>
    </>
  );
}

function mapStateToProps(state, props) {
  const user = state.auth.user;

  const { match } = props;

  const resolvedShopId = match.params.id || (state.auth.isShop ? user.id : '');

  const { isFetchingShopStaffDataList, staffDataList } = extractShopStaffList(
    state,
    resolvedShopId
  );

  return {
    isFetchingShopStaffDataList,
    staffDataList,
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps)(ShopRightMenu))
);
