import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import api from '../../api';

import { AppButton } from '../Common/Button';
import { TextInputField } from '../Common/Form';


const RecoverPasswordDialog = ({
  onClose,
}) => {

  const { t } = useTranslation();

  /**
   * This state helps prevent user from accidentally / unintentionally dismissing the dialog.
   *
   * A `true` value => User has NOT ever interacted with the form.
   */
  const [isPristine, setPristine] = useState(true);

  const [requestProcessed, setRequestProcessed] = useState(false);

  const [email, setEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const handleDialogClose = (e, reason) => {
    if (isPristine) {
      onClose();
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);

    setPristine(false);
  };

  const changePasswordResetMessage = e => {
    e.preventDefault();

    api.changePassword.forgotPass({ email })
      .then(res => res.data)
      .then(response => {
        if (response.status === 422) { // validation failed
          setErrorMessage(response.data.email[0]);
        }
        else if (response.status === 200) {
          setRequestProcessed(true);
        }
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={handleDialogClose}
      aria-labelledby="recover-password-dialog-title"
    >
      <DialogTitle id="recover-password-dialog-title">
        <div className="d-flex align-items-center">
          <h2 className="flex-fill m-0">{t('signIn.setNewPassword')}</h2>

          <button className="btn btn-link" onClick={onClose}>
            <i className="fas fa-times text-muted" />
          </button>
        </div>
      </DialogTitle>

      <DialogContent>
        {!requestProcessed ? (
          <form onSubmit={changePasswordResetMessage}>
            {/* Email */}
            <TextInputField
              label={t('generalFields.email.label')}
              type="email"
              name="email"
              value={email}
              placeholder={t('generalFields.email.label')}
              required
              errorMessage={errorMessage}
              onChange={handleChangeEmail}
            />

            <AppButton size="block" type="submit" theme="primary">
              {t('signIn.getNewPassword')}
            </AppButton>
          </form>
        ) : (
          <>
            <h3>
              <i className="fas fa-envelope" /> {t('signIn.checkYourEmail')}
            </h3>
            <p>{t('auth.forgotPassword.requestProcessed')}</p>
          </>
        )}
      </DialogContent>

      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default RecoverPasswordDialog;
