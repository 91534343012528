import React from 'react';

import FormField from './FormField';
import NumberInput, { NumberInputProps } from './NumberInput';

interface Props extends NumberInputProps {
  className?: string;
  errorMessage?: React.ReactNode;
  help?: React.ReactNode;
  hint?: React.ReactNode;
  iconClass?: string;
  label?: React.ReactNode;
}

const NumberInputField = ({
  className,
  errorMessage,
  disabled,
  help,
  hint,
  iconAtRight,
  iconClass,
  label,
  required,
  ...props
}: Props) => {
  return (
    <FormField
      className={className}
      errorMessage={errorMessage}
      help={help}
      hint={hint}
      iconAtRight={iconAtRight}
      iconClass={iconClass}
      label={label}
      required={required}
    >
      <NumberInput
        disabled={disabled}
        iconAtRight={iconAtRight}
        hasIcon={!!iconClass}
        required={required}
        {...props}
      />
    </FormField>
  );
};

export default NumberInputField;
