import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import InnerHTML from 'dangerously-set-html-content';


import api from '../../../api';

import { useAuthenticated } from '../../../hooks/auth';

import { determineCurrentVisibility, VISIBILITY_CURRENTLY_VISIBLE } from '../../../Functions/featureArticleHelper';

import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import SiteContainer from '../Global/SiteContainer/SiteContainer';
import Spinner from '../../Common/Spinner/Spinner';


const FeatureArticleDetail = () => {

  const featureArticleUrlPrefix = '/美容-美髮-按摩優惠';

  const history = useHistory();

  const { alias } = useParams();

  const { t, i18n } = useTranslation();

  const { isAdmin } = useAuthenticated();

  const [routes, setRoutes] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [featureArticle, setFeatureArticle] = useState(null);

  const [canonicalUrl, setCanonicalUrl] = useState('');

  const [isPreviewPurposeHelpVisible, setIsPreviewPurposeHelpVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    getData(alias);
  }, [alias]);

  useEffect(() => {
    setIsPreviewPurposeHelpVisible(false);

    const routes = [
      { title: t('featureArticle.title'), path: featureArticleUrlPrefix },
    ];

    if (featureArticle) {
      routes.push({
        title: featureArticle.title,
        path: `${featureArticleUrlPrefix}/${featureArticle.alias}`,
      });

      if (isAdmin) {
        // if current user is admin
        if (
          featureArticle.status &&
          determineCurrentVisibility(featureArticle) !=
            VISIBILITY_CURRENTLY_VISIBLE
        ) {
          setIsPreviewPurposeHelpVisible(true);
        }
      }
    }

    setRoutes(routes);
  }, [i18n.language, isAdmin, featureArticle]);

  /**
   *
   * @param {string} alias Feature Article's alias
   */
  const getData = (alias) => {
    setLoading(true);

    api.featureArticle.get(alias).then(response => {
      setFeatureArticle(response.data);

      setCanonicalUrl(
        window.location.origin + featureArticleUrlPrefix + '/' + response.data.alias
      );
    })
    .catch(() => {
      history.push(featureArticleUrlPrefix);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <SiteContainer>
      <div className="container" style={{ maxWidth: 800 }}>
        <Breadcrumb routes={routes} />

        {isLoading ? (
          <Spinner display="block" />
        ) : (
          featureArticle && (
            <>
              {isPreviewPurposeHelpVisible && (
                <div className="alert alert-warning">
                  <i className="fas fa-exclamation-triangle"></i>{' '}
                  {t('featureArticle.previewPurposeHelp')}
                </div>
              )}

              <Helmet>
                <title>{featureArticle.title}</title>
                <meta property="og:title" id="og-title" content={featureArticle.title} />
                {featureArticle.coverImgUrl && <meta property="og:image" id="og-image" content={featureArticle.coverImgUrl} />}
                <meta name="description" property="og:description" id="og-description" content={featureArticle.metaDescription} />
                <meta name="keywords" content={featureArticle.metaKeyword} />

                <link rel="canonical" href={canonicalUrl} />
              </Helmet>

              <InnerHTML html={featureArticle.content} />
            </>
          )
        )}

      </div>
    </SiteContainer>
  );
}


export default FeatureArticleDetail;
