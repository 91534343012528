import React from 'react';

import { EditIconButton } from 'src/Components/AdminPage/Common/Button';

import { LineBreakable } from '../Text';

import styles from './MessageBubble.module.css';

interface Props {
  authorBadge: React.ReactNode;
  caption?: React.ReactNode;

  /**
   * Whether the user viewing the message is the author of the message
   */
  isAuthor?: boolean;

  message: React.ReactNode;

  onRequestEdit?: () => void;
}

/**
 * Adopted from https://freefrontend.com/css-speech-bubbles/
 */
const MessageBubble = ({
  authorBadge,
  caption,
  isAuthor = false,
  message,
  onRequestEdit,
}: Props) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <div
        className={`mx-1 ${isAuthor ? 'order-2' : 'order-0'}`}
        style={{ width: 60 }}
      >
        {authorBadge}
      </div>

      <div
        className={`flex-fill order-1 ${styles.messageBubble} ${
          isAuthor ? styles.right : styles.left
        }`}
      >
        <div className="d-flex flex-row">
          <div className="flex-fill">
            <LineBreakable>{message}</LineBreakable>
          </div>

          {onRequestEdit && (
            <EditIconButton onClick={onRequestEdit} />
          )}
        </div>

        {caption && <div className={styles.messageCaption}>{caption}</div>}
      </div>
    </div>
  );
};

export default MessageBubble;
