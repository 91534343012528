import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Tooltip,
} from '@material-ui/core';


const ViewIconLink = ({
  target,
  to,
}) => {

  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={t('action.view')}>
      <Link
        className="btn btn-sm btn-outline-primary mx-1"
        target={target}
        to={to}
      >
        <i className="fas fa-eye" />
      </Link>
    </Tooltip>
  );
}

ViewIconLink.propTypes = {
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
};

ViewIconLink.defaultProps = {
};

export default ViewIconLink;
