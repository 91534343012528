import React, { useEffect, useMemo, useState } from 'react';

import _uniqueId from 'lodash/uniqueId';

import moment from 'moment';

import { ClickAwayListener } from '@material-ui/core';

import FormField from './FormField';

const padMinute = (val: number) => (val < 10 ? `0${val}` : `${val}`);


interface Props {
  className?: string;
  defaultValue?: Date;
  disabled?: boolean;
  errorMessage?: React.ReactNode;
  label?: React.ReactNode;
  required?: boolean;
  onChange: (val: Date) => void;
}

function TimePickerField({
  className = '',
  defaultValue,
  disabled = false,
  errorMessage,
  label = '',
  required = false,
  onChange,
}: Props) {
  const [meridiemCheckboxId] = useState(_uniqueId());

  const [isTimePickerOpened, setIsTimePickerOpened] = useState(false);

  const [timeValue, setTimeValue] = useState<Date>();

  const formattedTimeValue = useMemo(
    () => (timeValue ? moment(timeValue).format('h:mm A') : ''),
    [timeValue]
  );

  const [hourOptions, setHourOptions] = useState<number[]>([]);
  const [minuteOptions, setMinuteOptions] = useState<string[]>([]);

  const [isPM, setIsPM] = useState(false);

  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');

  useEffect(() => {
    setHourOptions(
      Array(12)
        .fill(0)
        .map((val, idx) => (idx === 0 ? 12 : idx))
    );

    setMinuteOptions(
      Array(12)
        .fill(0)
        .map((val, idx) => idx * 5)
        .map((val) => padMinute(val))
    );

    const initialTimeMoment = defaultValue ? moment(defaultValue) : moment();

    setTimeValue(initialTimeMoment.toDate());
    setIsPM(initialTimeMoment.hour() >= 12);
    setSelectedHour(`${initialTimeMoment.hour() % 12}`);
    setSelectedMinute(padMinute(initialTimeMoment.minute()));
  }, []);

  useEffect(() => {
    if (!disabled) {
      setIsTimePickerOpened(false);
    }
  }, [disabled]);

  useEffect(() => {
    const nextTimeValueMoment = moment(timeValue); // make a clone
    nextTimeValueMoment.hour(+selectedHour + (isPM ? 12 : 0));
    nextTimeValueMoment.minute(+selectedMinute);

    const nextTimeValue = nextTimeValueMoment.toDate();

    setTimeValue(nextTimeValue);
  }, [isPM, selectedHour, selectedMinute]);

  const handleChangeByUser = (
    newIsPM: boolean,
    newHour: string,
    newMinute: string
  ) => {
    const nextTimeValueMoment = moment(timeValue); // make a clone
    nextTimeValueMoment.hour(+newHour + (newIsPM ? 12 : 0));
    nextTimeValueMoment.minute(+newMinute);

    const nextTimeValue = nextTimeValueMoment.toDate();
    setTimeValue(nextTimeValue);

    onChange(nextTimeValue);
  };

  const handleChangeMeridiem = (newIsPM: boolean) => {
    setIsPM(newIsPM);

    handleChangeByUser(newIsPM, selectedHour, selectedMinute);
  };

  const handleChangeHour = (newHour: string) => {
    setSelectedHour(newHour);

    handleChangeByUser(isPM, newHour, selectedMinute);
  };

  const handleChangeMinute = (newMinute: string) => {
    setSelectedMinute(newMinute);

    handleChangeByUser(isPM, selectedHour, newMinute);
  };

  return (
    <FormField
      className={className}
      errorMessage={isTimePickerOpened ? null : errorMessage}
      iconClass="fas fa-clock"
      label={label}
      required={required}
    >
      <div
        className="form-item form-item--ico-left"
        role={!disabled ? 'button' : undefined}
        onClick={() => {
          if (!disabled) {
            setIsTimePickerOpened((val) => !val);
          }
        }}
      >
        <span className="form-item__text">{formattedTimeValue}</span>
      </div>
      {isTimePickerOpened && (
        <ClickAwayListener onClickAway={() => setIsTimePickerOpened(false)}>
          <div className="date-drop">
            <div className="date-drop__item">
              <div className="date-drop__body">
                <div className="date-select">
                  <span className="date-select__ico">
                    <i className="fas fa-caret-down" />
                  </span>
                  <select
                    className="date-select__item"
                    value={selectedHour}
                    onChange={(e) => handleChangeHour(e.target.value)}
                  >
                    {hourOptions.map((hour, idx) => (
                      <option key={hour} value={idx}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div>
                <span className="date-separator">:</span>
                <div className="date-select">
                  <span className="date-select__ico">
                    <i className="fas fa-caret-down" />
                  </span>
                  <select
                    className="date-select__item"
                    value={selectedMinute}
                    onChange={(e) => handleChangeMinute(e.target.value)}
                  >
                    {minuteOptions.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="date-drop__footer">
                <div className="date-choise">
                  <span>AM</span>
                  <div className="date-check">
                    <input
                      checked={isPM}
                      className="checkbox-date"
                      id={meridiemCheckboxId}
                      name="meridiem"
                      readOnly
                      type="checkbox"
                      onChange={(e) => handleChangeMeridiem(e.target.checked)}
                    />
                    <label htmlFor={meridiemCheckboxId} />
                  </div>
                  <span>PM</span>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </FormField>
  );
}

export default TimePickerField;
