import { Shop, StaffData } from 'src/models/entity';

import { GetResponse } from 'src/models/api/response/GetResponse';
import { ListResponse } from 'src/models/api/response/ListResponse';

import { apiGet } from '../api-utils';

export const getShop = (shopId: string, fields?: string[]) =>
  apiGet<GetResponse<Shop>>(`shop?id=${shopId}`, {
    fields: fields?.join(','),
  }).then((response) => response.data);

export const getShopStaffList = (shopId: string) =>
  apiGet<ListResponse<StaffData>>(`shop/${shopId}/staff`).then(
    (response) => response.data
  );

// ========== ========== Checkout booking ========== ==========

const formatCheckoutUrl = (checkoutToken: string) =>
  window.location.origin + '/checkout-booking/' + checkoutToken;

interface GetCheckoutUrlArgs {
  shop?: Shop;
  shopId?: string;
}

export const getCheckoutUrl = async ({ shop, shopId }: GetCheckoutUrlArgs) => {
  if (shop?.user_type_data?.checkoutToken) {
    return formatCheckoutUrl(shop.user_type_data.checkoutToken);
  }

  shopId = shopId || shop?.id;
  if (shopId) {
    shop = await getShop(shopId);

    if (shop?.user_type_data?.checkoutToken) {
      return formatCheckoutUrl(shop.user_type_data.checkoutToken);
    }
  }

  return '';
};

