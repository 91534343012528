import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


import { getAllReviews } from "../../../../Redux/actions/features/reviewActions";
import { shopData } from "../../../../Redux/actions/shopActions/shopData";

import ReviewItem from "./ReviewItem";


class ReviewList extends Component {

  componentDidMount() {
    this.props.getPageName(this.props.match.path);
  }

  redirectToView = reviewId => {
    const {
      auth,
      shopId,
      history,
    } = this.props;
    if (shopId === auth.user.id) {
      history.push(`/shop/review/${reviewId}`);
    } else {
      history.push(`/shop/${shopId}/review/${reviewId}`);
    }
  };

  render() {
    const {
      auth,
      reviews,
      shopAvatar,
    } = this.props;

    return (
      <div
        className={
          auth.isShop && this.props.match.params.id === auth.user.id ? '' : 'container'
        }
      >
        {reviews.map(review => (
          <ReviewItem
            key={review.id}
            review={review}
            shopAvatar={shopAvatar}
            onRedirect={this.redirectToView}
          />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    reviews: (state.ReviewReducer.reviews || []).filter(review => {
      return review.approved === 1;
    }),
    shopAvatar: state.ShopReducer.shopData.avatar,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { getAllReviews, shopData }
  )(ReviewList)
);
