import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import axios from 'axios';


import { SearchShops } from '../../../../Redux/actions/features/searchAction';


import Footer from './Footer';
import MainSearch from './MainSearch';
import NavBar from './NavBar/NavBar';


class SiteContainer extends Component {

  state = {
    searchName: '',
    searchAddress: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.searchName !== '' &&
      this.state.searchAddress === '' &&
      prevState.searchName !== this.state.searchName
    ) {
      axios.get(`/api/global-shops-search?name=${this.state.searchName}`).then(res => {
        this.props.SearchShops(res.data.data);
      });
    }
    else if (
      this.state.searchAddress !== '' &&
      this.state.searchName === '' &&
      prevState.searchAddress !== this.state.searchAddress
    ) {
      axios.get(`/api/global-shops-search?address=${this.state.searchAddress}`).then(res => {
        this.props.SearchShops(res.data.data);
      });
    }
  }

  clearSearchField = () => {
    this.setState({
      searchName: '',
      searchAddress: '',
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    sessionStorage.setItem(e.target.name, e.target.value);
  };

  render() {
    const {
      children,
    } = this.props;
    const {
      searchName,
      searchAddress,
    } = this.state;

    const isSearching = searchName || searchAddress;

    return (
      <>
        <NavBar
          clearSearchField={this.clearSearchField}
          onChange={this.handleChange}
        />

        {isSearching && (
          <MainSearch />
        )}

        {/**
          * The children would contain large DOM elements.
          * During searching, simply "hide" the children, rather then un-mounting the whole children.
          */}
        <div className={isSearching ? 'd-none' : ''}>
          {children}
        </div>

        <Footer />
      </>
    );
  }
}

export default withTranslation()(
  connect(
    null,
    {
      SearchShops,
    }
  )(SiteContainer)
);
