import { Advertisement } from 'src/models/entity';

import { useAdvertisementDimensions } from 'src/Functions/advertisementHelpers';

import styles from './LocalAdvertisement.module.css';


interface Props {
  advertisement: Advertisement;
}

function LocalAdvertisement({ advertisement }: Props) {
  const { isLandscape } = useAdvertisementDimensions(advertisement);

  return advertisement.url ? (
    <div
      className={`mx-auto ${styles.adContainer} ${
        isLandscape ? styles.landscape : styles.portrait
      }`}
    >
      {advertisement.link ? (
        <a href={advertisement.link} target="_blank">
          <img src={advertisement.url} />
        </a>
      ) : (
        <img src={advertisement.url} />
      )}
    </div>
  ) : null;
}

export default LocalAdvertisement;
