import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './RouteItem.module.css';

export interface RouterItem {
  path: string;
  title: string;
}

interface Props {
  item: RouterItem;
}

const RouteItem = ({ item }: Props) => {
  const { t, i18n } = useTranslation();

  const title = useMemo(() => {
    switch (item.title.toLowerCase()) {
      case 'home':
        return t('shopList.navHome');

      case 'messages':
        return t('staffPrivate.settings.messages');

      case 'share':
        return t('staffPrivate.share');

      case 'staff private profile':
        return t('staffPrivate.staffPrivateProfile');

      case 'calendar':
        return t('shopPrivate.shopLeftMenu.calendar');

      case 'bookings':
        return t('shopPrivate.shopLeftMenu.bookings');

      case 'waiting confirm':
        return t('shopPrivate.bookings.waitConf');

      case 'general':
        return t('staffPrivate.settings.general');

      case 'gallery':
        return t('staffPrivate.gallery');

      case 'main':
        return t('shopPublic.navMain');

      case 'news':
        return t('shopPublic.tabs.news');

      case 'review':
        return t('shopPublic.tabs.review');

      case 'price-list':
        return t('shopPublic.tabs.priceList');

      case 'stylist':
        return t('shopPublic.tabs.stylist');

      case 'product':
        return t('shopPublic.tabs.product');

      case 'contacts':
        return t('shopPrivate.tabs.contacts');

      case 'media':
        return t('shopPrivate.tabs.media');

      case 'schedule':
        return t('shopPrivate.tabs.schedule');

      case 'payments':
        return t('shopPrivate.tabs.payments');

      case 'service-types':
        return t('shopPrivate.tabs.serviceTypes');

      case 'recommended':
        return t('shopPrivate.tabs.recommended');

      case 'upcoming':
        return t('shopPrivate.bookings.upcoming');

      case 'cancelled':
        return t('shopPrivate.bookings.cancelled');

      case 'have not checkout':
        return t('shopPrivate.bookings.notCheckout');

      case 'absent':
        return t('shopPrivate.bookings.absent');

      case 'completed':
        return t('shopPrivate.bookings.completed');

      case 'reviews':
        return t('staffPublic.reviews');

      case 'album':
        return t('shopPrivate.photoGallery.album');

      case 'sharings':
        return t('staffPublic.sharings');

      case 'settings':
        return t('shopPrivate.shopLeftMenu.settings');

      case 'dollars':
        return t('customerPrivate.navigation.bookingDollars');

      case 'notifications':
        return t('staffPrivate.notifications');

      case 'staff':
        return t('shopPrivate.shopLeftMenu.staff');

      case 'all news':
        return t('shopPublic.tabs.news');

      case 'sharing':
        return t('staffPublic.sharings');

      case 'overview':
        return t('staffPublic.overview');

      default:
        return item.title;
    }
  }, [i18n.language, item.title]);

  return (
    <span className={styles.root}>
      <i className="fas fa-angle-right mr-3" />
      <Link to={item.path}>{title}</Link>
    </span>
  );
};

export default RouteItem;
