import React from 'react';
import Alert from 'react-s-alert';
import { Helmet } from 'react-helmet';


import AlertTemplate from './Components/Common/AlertTemplate/AlertTemplate';
import MainComponent from './Components/MainComponent';


const App = () => {

  const metaTitle = '搵唔Hardsell美容院 | 髮型屋 | 美甲店 | 防止美容院黑店 | 單次收費美容院推介、邊間髮型屋好、邊間按摩推薦、邊間美甲好。| SalonFinder邊間好？';
  const metaDescription = '入黎睇下最新美容、髮型屋、按摩、美甲優惠，仲有最低價保証！性價比高服務邊間做得好又唔HardSell，仲有邊間有單次收費美容、價目清晰、按摩服務、植睫毛、美甲、泰式按摩、中式按摩、收費清晰，仲有美容院推介！';
  const metaKeyword = '美容院推薦 單次收費美容院推介  按摩spa優惠 美甲店推介';

  return (
    <div className="App">

      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" id="og-title" content={metaTitle} />
        <meta name="description" property="og:description" id="og-description" content={metaDescription} />
        <meta name="keywords" content={metaKeyword} />
      </Helmet>

      <Alert
        contentTemplate={AlertTemplate}
        stack={{ limit: 5 }}
        effect="scale"
        html
        timeout={3000}
        position="top-right"
        offset={50}
      />

      <MainComponent />
    </div>
  );
};

export default App;
