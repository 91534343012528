import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const ClearButton = ({
  disabled = false,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={`${t('action.clear')}`}>
      <button
        className="btn btn-outline-secondary"
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        {/* <i className="far fa-times-circle"></i> */}
        {t('action.clear')}
      </button>
    </Tooltip>
  );
};

export default ClearButton;
