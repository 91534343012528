import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class StaffItemEdit extends Component {
  state = {
    showField: false,
    charge: 0,
  };

  componentDidMount() {
    this.setState({
      charge: this.props.staff_charge ? this.props.staff_charge : 0,
      showField: !!this.props.staff_charge,
    });
  }

  toggleInput = () => {
    const { staff_id, handleChange } = this.props;
    const { charge } = this.state;
    this.setState(
      prevState => ({
        showField: !prevState.showField,
      }),
      () => handleChange(charge, staff_id, this.state.showField)
    );
  };

  handleChangeCharge = ({ target: { value } }) => {
    const { staff_id, handleChange } = this.props;
    const { showField } = this.state;
    const regExp = /^\d+\.?\d*$/;
    if ((Number(value) !== '' && regExp.test(value)) || value === '') {
      this.setState({
        charge: value,
      });
    }
    handleChange(value, staff_id, showField);
  };

  render() {
    const { blockNum, nickname, modalStatus, staff_id, t } = this.props;
    const { showField, charge } = this.state;
    return (
      <tr data-label-tr={`${t('shopPrivate.shopLeftMenu.staff')} #${blockNum}`}>
        <td data-label={t('shopPrivate.shopLeftMenu.staff')}>
          <div className="table-check">
            <input
              type="checkbox"
              className="checkbox"
              id={`id${staff_id}`}
              onChange={this.toggleInput}
              checked={showField}
            />
            <label htmlFor={`id${staff_id}`}>
              <span>{nickname}</span>
            </label>
          </div>
        </td>
        <td data-label={t('shopPrivate.servicePriceList.extraCharge')}>
          {modalStatus !== staff_id ? (
            <div className="table-field">
              <input
                type="text"
                name={`${staff_id}`}
                onChange={this.handleChangeCharge}
                className="form-item"
                disabled={!showField}
                value={charge}
              />
            </div>
          ) : (
            <div className="table-field">
              <input
                type="text"
                name={`${staff_id}`}
                onChange={this.handleChangeCharge}
                className="form-item"
                disabled={!showField}
                value={charge}
              />
            </div>
          )}
        </td>
      </tr>
    );
  }
}

export default withTranslation()(StaffItemEdit);
