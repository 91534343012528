import React, { useEffect, useState } from 'react';

import moment from 'moment';


import { timeToAmPm } from '../../../../Functions/datetimeHelpers';

import CalendarEventWindow from './CalendarEventWindow';


const CalendarMonthItem = ({
  days,
  eventId,
  dateContext,
  bookings,
  toggleMobileSizeEvent,
  toggleEventWindow,
  closeWindow,
  view,
  getShopBookingsForCalendar,
  staffList,
  selectedStaff,
}) => {

  const [matchingBookings, setMatchingBookings] = useState([]);

  useEffect(() => {
    if (bookings && dateContext && days) {
      const targetDate = moment(dateContext).date(days);

      setMatchingBookings(bookings.filter(booking => moment(booking.date).isSame(targetDate, 'day')));
    } else {
      setMatchingBookings([]);
    }
  }, [bookings, dateContext, days]);

  return (
    <td className="number-td active">
      <div className="calendar-inner">
        {view === 'month' && <span className="calendar-number">{days}</span>}

        {matchingBookings.map(event => {
          const time = timeToAmPm(event.time);
          return (
            <div
              className={`calendar-event
              ${
                event.creator_type_id === 3 && event.type_booking === 'basic_booking'
                  ? 'calendar-event--type2'
                  : ''
              }
              ${
                event.creator_type_id === 2 && event.type_booking === 'other_booking'
                  ? 'calendar-event--type3'
                  : ''
              }
              ${
                event.creator_type_id === 3 && event.type_booking === 'other_booking'
                  ? 'calendar-event--type4'
                  : ''
              }
            `}
              id={event.id}
              onClick={toggleMobileSizeEvent ? toggleEventWindow(event.id) : null}
              key={event.id}
            >
              <p onClick={toggleEventWindow(event.id)}>{time} </p>
              <span onClick={toggleEventWindow(event.id)}>
                {event.staff_nickname ? `${event.service_name} (${event.staff_nickname})` : event.service_name}
              </span>

              {eventId === event.id && (
                <CalendarEventWindow
                  serviceName={event.service_name}
                  price={event.price}
                  time={event.time}
                  staff_name={event.staff_nickname}
                  staff_id={event.staff_id}
                  selectedStaff={selectedStaff}
                  closeWindow={closeWindow}
                  id={event.id}
                  customerFirstName={
                    (event.customer &&
                      event.customer.user_type_data &&
                      event.customer.user_type_data.first_name) ||
                    (event.customer_other && event.customer_other.name)
                  }
                  customerLastName={
                    event.customer && event.customer.user_type_data && event.customer.user_type_data.last_name
                  }
                  getShopBookingsForCalendar={getShopBookingsForCalendar}
                  dateContext={dateContext}
                  view={view}
                  days={days}
                  booking_status_id={event.booking_status_id}
                  staffList={staffList}
                  event_date={event.date}
                />
              )}
            </div>
          );
        })}
      </div>
    </td>
  );
};

export default CalendarMonthItem;
