import Alert from 'react-s-alert';

const notification = (message = '', timeout = 0) => {
  Alert.success(null, {
    timeout: timeout || 4000,
    effect: 'scale',
    customFields: {
      message: message || 'Something went wrong',
    },
  });
};

export default notification;
