/**
 * It offer a responsive layout for section toolbar
 */
function SectionToolbar({ children, className, heading }) {
  return (
    <div className={`${className}`}>
      <div className="row align-items-lg-center">
        <div className="col-12 col-lg-4">{heading}</div>

        <div className="mt-2 col-12 mt-lg-0 col-lg-8">{children}</div>
      </div>
    </div>
  );
}

SectionToolbar.defaultProps = {
  className: '',
};

export default SectionToolbar;
