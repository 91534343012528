import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Permission } from 'src/models/entity';

/**
 * This hook returns a method for checking authorization
 * @returns
 */
function useAuthorize() {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );

  const userRights = useSelector<Permission[], Permission[]>(
    (state: any) => state.auth.userRights
  );

  const isAuthorized = useCallback(
    /**
     * Check whether current user has any one of the specified permissions
     * @param requiredPermissions
     * @returns
     */
    (requiredPermissions: string | string[]) => {
      if (!isAuthenticated) {
        return false;
      }

      if (!requiredPermissions?.length) {
        return true;
      }

      if (!Array.isArray(requiredPermissions)) {
        requiredPermissions = [requiredPermissions];
      }

      return userRights.some((permission) =>
        requiredPermissions.includes(permission.code)
      );
    },
    [isAuthenticated, userRights]
  );

  return {
    isAuthorized,
  };
}

export default useAuthorize;
