import React from 'react';

/**
 * @deprecated Use `src/Components/Common/notification.js` instead.
 */
const Notification = ({ text, onClose }) => {
  return (
    <div className="notification-window">
      <div className="notification-block">
        <button className="close-notification">
          <i className="fas fa-times" onClick={onClose} />
        </button>
        <div className="notification">
          <div className="notification-ico">
            <i className="fas fa-bell" />
          </div>
          <div className="notification__inner">
            <div className="notification__text">
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
