import React, { useEffect, useState } from 'react';


import PhotoViewer from './PhotoViewer';


/**
 */
const SinglePhotoViewable = ({
  children,
  thumbnailUrl,
  fullUrl,
}) => {

  const [isPhotoViewerOpened, setPhotoViewerOpened] = useState(false);
  const [photos, setPhotos] = useState({});

  useEffect(() => {
    setPhotos([
      { // only 1 entry
        url: thumbnailUrl,
        url_full: fullUrl,
      }
     ]);
  }, [thumbnailUrl, fullUrl]);

  return (
    <>
      {isPhotoViewerOpened && (
        <PhotoViewer
          photos={photos}
          initialIndex={0}
          onClose={() => setPhotoViewerOpened(false)}
        />
      )}

      <a onClick={() => setPhotoViewerOpened(true)} style = {{ cursor: 'pointer' }}>
        {children}
      </a>
    </>
  );
}

export default SinglePhotoViewable;
