import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import styles from './LanguageSwitch.module.css';

interface Props {
  className?: string;
}

const LanguageSwitch = ({ className = '' }: Props) => {
  const { i18n } = useTranslation();

  const isChinese = useMemo(
    () => !i18n.language.startsWith('en'),
    [i18n.language]
  );

  const toggleLanguage = () => {
    if (i18n.language.startsWith('en')) {
      // Change language to 'zh'
      i18n.changeLanguage('zh');
      sessionStorage.setItem('language', 'zh');
      axios.get(`/api/lang/zh-HK`); // TODO Revise it i18n
    } else {
      // Change language to 'en'
      i18n.changeLanguage('en');
      sessionStorage.setItem('language', 'en');
      axios.get(`/api/lang/en`); // TODO Revise it i18n
    }
  };

  return (
    <button
      className={`${className} ${styles.langSwitch}`}
      onClick={toggleLanguage}
    >
      {isChinese ? 'ENG' : '繁'}
    </button>
  );
};

export default LanguageSwitch;
