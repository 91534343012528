import { useMemo } from 'react';

import _startCase from 'lodash/startCase';

interface Props {
  text: string;
}

/**
 * It is specially designed that the rendered content returned from
 * this component is not wrapped with any tags.
 */
const StartCase = ({ text }: Props) => {
  const transformed = useMemo(() => _startCase(text), [text]);

  return <>{transformed}</>;
};

export default StartCase;
