import React from 'react';
import PropTypes from 'prop-types';

import {
  Tooltip,
} from '@material-ui/core';


const HintedButton = ({
  children,
  className,
  hint,
  name,
  onClick,
}) => {

  return (
    <Tooltip placement="top" title={hint}>
      <button
        className={`btn btn-sm btn-outline-secondary ${className}`}
        name={name}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    </Tooltip>
  );
}

HintedButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

HintedButton.defaultProps = {
  className: '',
};

export default HintedButton;
