import { CS_WHATSAPP_NUMBER } from '../../../../environment';

import styles from './CustomerServiceButton.module.css';

function CustomerServiceButton() {
  return (
    <a
      href={`https://wa.me/${CS_WHATSAPP_NUMBER}?text=我係客人，想查詢服務！`}
      className={`d-flex align-items-center p-1 p-md-2 ${styles.anchor}`}
    >
      <div className="m-1">
        <i className="fab fa-whatsapp" />
      </div>
      <div className="flex-fill text-center">
        <p>即時查詢</p>
        <p>真人客服</p>
      </div>
    </a>
  );
}

export default CustomerServiceButton;
