import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';


import { useConfirmationDialog } from '../../../../Providers/ConfirmDialogProvider';

import { deleteStuffFromShop, fetchShopStaff } from '../../../../Redux/actions/shopActions/shopStaffActions';

import notification from '../../../Common/notification';
import { AppButton } from '../../../Common/Button';


const ConfirmRemoveStaffButton = ({
  onConfirm,
}) => {

  const { t } = useTranslation();

  const { openConfirmationDialog } = useConfirmationDialog();

  const handleClick = () => {
    openConfirmationDialog({
      message: t('shopPrivate.staff.removeStaff.askForConfirm'),
      onConfirm,
    });
  }

  return (
    <AppButton size="small" theme="danger" variant="outlined" onClick={handleClick}>
      <i className="fas fa-user-times mr-1" />
      {t('shopPrivate.staff.removeStaff.action')}
    </AppButton>
  );
}


class StaffItem extends Component {

  deleteStaff = () => {
    this.props.deleteStuffFromShop({ id: [this.props.staffData.user_id] }).then(res => {
      if (res.res.data.message === 'success') {
        this.props.fetchShopStaff({ id: this.props.currentShopId, fields: 'staff_requests' });
        notification(res.res.data.data ? res.res.data.data : this.props.t('notifications.staffDeleted'));
      } else {
        notification(res.res.data.message ? res.res.data.message : this.props.t('notifications.somethingWentWrong'));
      }
    });
  };

  redirect = staffId => {
    this.props.history.push(`/staff/${staffId}`);
  };

  render() {
    const {
      indexNum,
      staffData,
      t,
    } = this.props;
    return (
      <tr data-label-tr={`${t('block')} #${indexNum}`}>
        <td
          data-label={t('generalFields.humanFirstName.label')}
          style={{ cursor: 'pointer' }}
          onClick={() => this.redirect(staffData.user_id)}
        >
          {staffData.first_name}
          <span />
        </td>
        <td data-label={t('generalFields.humanLastName.label')}>
          <span>{staffData.last_name}</span>
        </td>
        <td data-label={t('staff.nickName.label')}>
          <span>{staffData.nickname}</span>
        </td>
        <td data-label={t('shopPrivate.staff.phoneNumber')}>
          <span>{staffData.phone}</span>
        </td>
        <td data-label={t('generalFields.email.label')}>
          <span>{staffData.email}</span>
        </td>
        <td data-label={t('shopPrivate.reviewModer.action')}>
          <ConfirmRemoveStaffButton onConfirm={this.deleteStaff} />
        </td>
      </tr>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      null,
      {
        deleteStuffFromShop,
        fetchShopStaff,
      }
    )(StaffItem)
  )
);
