import React from 'react';

import Spinner from '../Spinner/Spinner';

interface Props {
  children?: React.ReactNode;
  className?: string;
  help?: React.ReactNode;
  hint?: React.ReactNode;
  errorMessage?: React.ReactNode;
  isBusy?: boolean;
  iconAtRight?: boolean;
  iconClass?: string;
  label?: React.ReactNode;
  required?: boolean;
}

const FormField = ({
  children,
  className = '',
  help = '',
  hint = '',
  errorMessage = '',
  isBusy = false,
  iconAtRight = false,
  iconClass,
  label = '',
  required = false,
}: Props) => {
  return (
    <div className={`field ${className}`}>
      {(label || hint) && (
        <div className="field-label">
          <div className="d-flex justify-content-between align-items-center">
            {label && (
              <div className={required ? 'app-required' : ''}>{label}</div>
            )}

            {hint && <div>{hint}</div>}

            {isBusy && <Spinner />}
          </div>
        </div>
      )}

      <div className={`field-wrap ${errorMessage ? 'error-field' : ''}`}>
        {children}

        {/* Icon is position absolutely. It needs to be placed after children so that is it visible */}
        {iconClass && (
          <span
            className={`field-ico ${iconAtRight ? 'field-ico--to-right' : ''} ${
              errorMessage ? 'error-ico' : ''
            }`}
          >
            <i className={iconClass} />
          </span>
        )}

        {errorMessage && (
          <>
            <span className="error-ico">
              <i className="fas fa-info-circle" />
            </span>
            <span className="error-text">{errorMessage}</span>
          </>
        )}
      </div>

      {help && <div className="mt-1 field-help">{help}</div>}
    </div>
  );
};

export default FormField;
