import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

import { useConfirmationDialog } from './ConfirmDialogProvider';

interface Props {
  onConfirmDelete: () => void;
}

/**
 * This is a fast hand button for launching a confirmation dialog before actually trigger a `onDelete` procedure.
 */
const ConfirmDeleteIconButton = ({ onConfirmDelete }: Props) => {
  const { t } = useTranslation();

  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const handleClick = () => {
    openConfirmDeleteDialog(onConfirmDelete);
  };

  return (
    <Tooltip placement="top" title={`${t('action.delete')}`}>
      <button
        className="btn btn-sm btn-outline-danger mx-1"
        onClick={handleClick}
      >
        <i className="fas fa-trash-alt" />
      </button>
    </Tooltip>
  );
};

export default ConfirmDeleteIconButton;
