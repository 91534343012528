import { useEffect, useState } from 'react';

import { Advertisement, AdvertisementName } from 'src/models/entity';

import api from 'src/api';

import GoogleAdvertisement from './GoogleAdvertisement';
import LocalAdvertisement from './LocalAdvertisement';

interface Props {
  name: AdvertisementName;
  delay?: number;
}

function AdvertisementView({ name, delay = 0 }: Props) {
  const [advertisement, setAdvertisement] = useState<Advertisement>();

  useEffect(() => {
    const displayType =
      window.innerWidth < 768
        ? 'mobile'
        : window.innerWidth < 992
        ? 'tablet'
        : 'desktop';

    setTimeout(() => {
      api.advertisement
        .fetchList({ name, type: displayType })
        .then((res) => res.data)
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.length > 0
          ) {
            return response.data[0];
          }

          throw new Error();
        })
        .then((advertisement) => {
          setAdvertisement(advertisement);
        })
        .catch((e) => {});
    }, delay);
  }, []);

  return advertisement ? (
    advertisement?.isGoogleAds ? (
      <GoogleAdvertisement advertisement={advertisement} />
    ) : (
      <LocalAdvertisement advertisement={advertisement} />
    )
  ) : null;
}

export default AdvertisementView;
