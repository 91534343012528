import { useTranslation } from 'react-i18next';

import { BookingReviewRating } from 'src/models/entity';

import OverallRatingRow from './OverallRatingRow';
import RatingRow from './RatingRow';


interface Props {
  ratings: BookingReviewRating;
}

function BookingReviewRatings({ ratings }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <p className="theme-subtitle">{t('shopPublic.review.rate')}</p>

      <OverallRatingRow rating={ratings.overall_rating} />

      <RatingRow
        label={t('shopPublic.overview.leftBar.environment')}
        rating={ratings.environment}
      />

      <RatingRow
        label={t('shopPublic.overview.leftBar.skill')}
        rating={ratings.skill}
      />

      <RatingRow
        label={t('shopPublic.overview.leftBar.attitude')}
        rating={ratings.attitude}
      />

      <RatingRow
        label={t('shopPublic.overview.leftBar.idea')}
        rating={ratings.idea}
      />
    </div>
  );
}

export default BookingReviewRatings;
