import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { useConfirmationDialog } from '../../../Providers/ConfirmDialogProvider';

import AspectImage from 'src/Components/Common/AspectImage/AspectImage';
import { AppButton, AppButtonLink } from '../Button';


import styles from './AlbumCell.module.css';

function AlbumCell({
  album,
  albumName,
  coverPhoto,
  linkTo,
  showDeleteButton,
  showEditButton,
  showViewButton,
  onDelete,
}) {

  const { t } = useTranslation();

  const { openConfirmDeleteDialog } = useConfirmationDialog();

  const resolvedAlbumName = album.id ? album.name : albumName;

  const handleClickDelete = () => {
    openConfirmDeleteDialog(onDelete);
  }

  return (
    <div className={styles.root}>
      <Link to={linkTo} className={styles.coverAnchor}>
        {coverPhoto ? (
          <AspectImage aspectRatio="1:1">
            <img alt={resolvedAlbumName} src={coverPhoto.url} />
          </AspectImage>
        ) : (
          <AspectImage aspectRatio="1:1">
            <div className="app-absolute-center">
              <i className="fas fa-folder-open" />
            </div>
          </AspectImage>
        )}
      </Link>

      <div className={styles.title}>{resolvedAlbumName}</div>

      <div className="row no-gutters">
        {!!showEditButton && (
          <div className="col-6">
            <AppButtonLink size="block" theme="primary" to={linkTo}>
              {t('action.edit')}
            </AppButtonLink>
          </div>
        )}

        {!!showViewButton && (
          <div className="col-6">
            <AppButtonLink size="block" to={linkTo} theme="primary">
              {t('action.view')}
            </AppButtonLink>
          </div>
        )}

        {showDeleteButton && (
          <div className="col-6">
            <AppButton
              size="block"
              theme="muted"
              variant="outlined"
              onClick={handleClickDelete}
            >
              {t('action.delete')}
            </AppButton>
          </div>
        )}
      </div>
    </div>
  );
}

AlbumCell.defaultProps = {

  album: {},

  /**
   * Used only when `album` is not available
   */
  albumName: '',

  linkTo: '',

  showDeleteButton: false,

  showEditButton: false,

  showViewButton: false,

  // onDelete: (albumId) => {},

};

export default AlbumCell;
