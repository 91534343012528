import {
  ADMIN_USERS_DID_FETCH,
  ADMIN_USER_DID_UPDATE,
  GET_INVOICE_BOOKINGS,
  GET_USER_BOOKINGS,
} from "../../constants/constants";


const initialState = {
  users: [],
  userPagination: {
    currentPage: 1,
    lastPage: 1,
    total: 0,
  },

  bookings: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_USERS_DID_FETCH:
      return {
        ...state,
        users: action.payload.users,
        userPagination: action.payload.userPagination,
      };
    case ADMIN_USER_DID_UPDATE:
      return {
        ...state,
        users: state.users.map(user => (user.id === action.payload.user.id ? action.payload.user : user)),
      };
    case GET_USER_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      };
    case GET_INVOICE_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      };
    default:
      return {
        ...state
      }
  }
};
