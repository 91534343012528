import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import api from '../../../../../api';

import ExternalFeedListEditable from '../../../../Common/ExternalFeedListEditable/ExternalFeedListEditable';

import {
  invalidateShopNewsListFromState,
} from '../../../../../Redux/actions/features/shopNewsAction';

class NewsExternalFeedList extends Component {

  _isMounted = false;

  state = {
    remoteItems: [],

    isFetchingItems: false,
  };

  componentDidMount() {
    this._isMounted = true;

    if (this.props.newsId) {
      this.fetchFeedList();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchFeedList = () => {
    this.setState({
      isFetchingItems: true,
    });

    api.shopNewsExternalFeed.fetch(this.props.newsId).then(response => {
      if (!this._isMounted) return;

      this.setState({
        remoteItems: response.data,
      });
    })
    .finally(() => {
      if (!this._isMounted) return;

      this.setState({
        isFetchingItems: false,
      });
    });
  }

  handleCreateFeed = resourceUrl => {
    this.setState({
      isFetchingItems: true,
    });

    return api.shopNewsExternalFeed.create(this.props.newsId, {
      resourceUrl,
    })
    .then(response => {
      this.props.invalidateShopNewsListFromState();

      this.setState({
        remoteItems: [
          response.data,
        ],
      });

      return response.data;
    })
      .finally(() => {
        if (!this._isMounted) return;

        this.setState({
          isFetchingItems: false,
        });
      });
  }

  handleDeleteRemoteFeed = (feed) => {
    this.setState({
      isFetchingItems: true,
    });

    return api.shopNewsExternalFeed.destroy(this.props.newsId, feed.id).then(response => {
      this.props.invalidateShopNewsListFromState();

      this.setState(prevState => {
        const remoteItems = prevState.remoteItems.slice(); // make a copy

        const idx = remoteItems.findIndex(item => item.id == feed.id);
        if (idx > -1) {
          remoteItems.splice(idx, 1);
        }

        return {
          remoteItems,
        };
      });

      return response;
    })
      .finally(() => {
        if (!this._isMounted) return;

        this.setState({
          isFetchingItems: false,
        });
      });
  }

  render() {
    const {
      newsId,
    } = this.props;
    const {
      isFetchingItems,
      remoteItems,
    } = this.state;

    return (
      <ExternalFeedListEditable
        autoUpload={newsId > 0}
        isFetchingItems={isFetchingItems}
        maxFeedLimit={1}
        remoteItems={remoteItems}

        onCreateFeed={this.handleCreateFeed}
        onDeleteRemoteFeed={this.handleDeleteRemoteFeed}
      />
    );
  }
}

NewsExternalFeedList.defaultProps = {

};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        invalidateShopNewsListFromState,
      },
    )(NewsExternalFeedList),
  ),
);
