import {
  LANDING_PAGE_WILL_FETCH,
  LANDING_PAGE_DID_FETCH,
  LANDING_PAGE_WILL_INVALIDATE,
} from "../../constants/constants";


const initialState = {

  activeParams: null,

  /**
   * Whether the client is fetching a landing page
   */
  isFetchingLandingPage: false,

  /**
   * The last time when the landing page is fetched
   */
  landingPageLastFetchedAt: null,

  landingPage: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case LANDING_PAGE_WILL_FETCH:
      return {
        ...state,
        activeParams: action.payload,
        isFetchingLandingPage: true,
        landingPageLastFetchedAt: null,
        landingPage: null,
      };

    case LANDING_PAGE_DID_FETCH:
      return {
        ...state,
        isFetchingLandingPage: false,
        landingPageLastFetchedAt: new Date(),

        landingPage: action.payload || null,
      };

    case LANDING_PAGE_WILL_INVALIDATE:
      return {
        ...state,
        landingPageLastFetchedAt: null,
        landingPage: null,
      };
  }

  return state;
};
