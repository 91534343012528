import moment from 'moment';

import {
  DEFINITION_DEFAULT_SERVICES_WILL_FETCH,
  DEFINITION_DEFAULT_SERVICES_DID_FETCH,
} from '../../constants/constants';
import api from '../../../api';


export const fetchDefaultServices = (forceFetch = false) => (dispatch, getState) => {
  if (!forceFetch) {
    const prevState = getState().defaultServiceReducer;

    if (prevState.isFetchingDefaultServices) {
      // console.log('fetching default services. No need to fire a fetch request.');
      return;
    }
    else if (prevState.defaultServicesLastFetchedAt && moment().diff(prevState.defaultServicesLastFetchedAt, 'minutes', true) <= 15) {
      // console.log('default services are still fresh. No need to fire a fetch request.');
      return;
    }
  }

  dispatch(defaultServicesWillFetch());

  api.defaultService.fetchList()
    .then(response => {
      dispatch(defaultServicesDidFetch(response.data));
  });
}

const defaultServicesWillFetch = () => ({
  type: DEFINITION_DEFAULT_SERVICES_WILL_FETCH,
});

const defaultServicesDidFetch = (defaultServices) => ({
  type: DEFINITION_DEFAULT_SERVICES_DID_FETCH,
  payload: {
    defaultServices,
  },
});
