import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// import NotificationBadge, { Effect } from 'react-notification-badge';

import MenuIcon from '@material-ui/icons/Menu';

import TabNavigation from '../../../Common/Navigation/TabNavigation/TabNavigation';

const ShopBookingNav = ({ waitingCount }) => {
  const { t, i18n } = useTranslation();

  const anchors = useMemo(() => {
    const base = '/shop-settings/bookings/';

    return [
      {
        to: `${base}waiting_confirm`,
        text: (
          <>
            {t('shopPrivate.bookings.waitConf')}
            {waitingCount > 0 && (
              <span className="badge badge-pill badge-danger mx-1">
                {waitingCount}
              </span>
              // <NotificationBadge count={waitingCount} effect={Effect.SCALE} />
            )}
          </>
        ),
      },
      {
        to: `${base}upcoming`,
        text: t('shopPrivate.bookings.upcoming'),
      },
      {
        to: `${base}cancelled`,
        text: t('shopPrivate.bookings.cancelled'),
      },
      {
        to: `${base}have_not_checkout`,
        text: t('shopPrivate.bookings.notCheckout'),
      },
      {
        to: `${base}absent`,
        text: t('shopPrivate.bookings.absent'),
      },
      {
        to: `${base}completed`,
        text: t('shopPrivate.bookings.completed'),
      },
    ];
  }, [i18n.language, waitingCount]);

  return (
    <TabNavigation
      anchors={anchors}
      heading={
        <h2 className="m-0 page-title">{t('shopPrivate.shopLeftMenu.bookings')}</h2>
      }
      mobileLabel={
        <>
          {t('shopPrivate.tabs.menu')}
          <MenuIcon />
        </>
      }
    />
  );
};

export default ShopBookingNav;
