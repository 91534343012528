import React, { Component } from 'react';

import styles from './SetTime.module.css';


class SetTime extends Component {
  state = {
    changeTimeWindowOpenTo: false,
    changeTimeWindowOpenFrom: false,
    pm: false,
  };

  componentDidMount() {
    const hours = Number(this.props.time.slice(0, this.props.time.indexOf(':')));
    this.setState({
      pm: hours < 12 ? false : true,
    });
  }

  handleChangeBoolean = () => {
    const { time_type } = this.props;
    if (time_type === 'time_to') {
      this.setState(prevState => ({
        changeTimeWindowOpenTo: !prevState.changeTimeWindowOpenTo,
      }));
    } else {
      this.setState(prevState => ({
        changeTimeWindowOpenFrom: !prevState.changeTimeWindowOpenFrom,
      }));
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if(this.props.openTime !== nextProps.openTime) {
      this.closeWindows();
    }
  }

  handleChangePm = () => {
    this.setState(prevState => ({
      pm: !prevState.pm,
    }));
  };

  closeWindows = () => {
    this.setState({
      changeTimeWindowOpenTo: false,
      changeTimeWindowOpenFrom: false,
    });
  };



  render() {
    const { id, time, time_type, handleChangeTime } = this.props;
    const { changeTimeWindowOpenTo, changeTimeWindowOpenFrom, pm } = this.state;
    const changeTimeWindowOpen = time_type === 'time_to' ? changeTimeWindowOpenTo : changeTimeWindowOpenFrom;
    const hours = Number(this.props.time.slice(0, this.props.time.indexOf(':')));
    const timeParse = time.split(':');
    const minutes = time.slice(time.indexOf(':') + 1);
    const hoursPmAm =
      hours > 12
        ? hours > 21
          ? `${(hours - 12).toString()}`
          : `0${(hours - 12).toString()}`
        : hours !== 0
          ? hours < 10
            ? `0${hours.toString()}`
            : hours.toString()
          : '00';
    return (
      <div className={`field-wrap_input field-wrap ${styles.scheduleField}`}>
        <div
          id={id}
          onClick={this.handleChangeBoolean}
          className="field-wrap_input form-item"
          placeholder="15:25"
        >
          <span className="field-wrap_input">{timeParse[0] <= 12 ? timeParse[0] : timeParse[0] - 12} : {timeParse[1]} {pm ? 'PM' : 'AM'}</span>
        </div>
        <span className="field-ico field-ico--to-right">
          <i className="fas fa-clock" />
        </span>
        {changeTimeWindowOpen && (
          <div
            className="date-drop"
            // onBlur={this.closeWindows}
          >
            <div className="date-drop__item">
              <div className="date-drop__body">
                <div className="date-select">
                  <span className="date-select__ico">
                    <i className="fas fa-caret-down" />
                  </span>
                  <select
                    name="hours"
                    onChange={e => handleChangeTime(e, id, time_type, pm)}
                    className="date-select__item"
                    value={hoursPmAm}
                    autoFocus
                  >
                    <option value="00">12</option>
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                  </select>
                </div>
                <span className="date-separator">:</span>
                <div className="date-select">
                  <span className="date-select__ico">
                    <i className="fas fa-caret-down" />
                  </span>
                  <select
                    name="minutes"
                    onChange={e => handleChangeTime(e, id, time_type, pm)}
                    className="date-select__item"
                    value={minutes}
                  >
                    <option value="00">0</option>
                    <option value="05">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="35">35</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                    <option value="55">55</option>
                  </select>
                </div>
              </div>
              <div className="date-drop__footer">
                <div className="date-choise">
                    <span className="checkbox-date__am">AM</span>
                  <div className="date-check">
                    <input
                      type="checkbox"
                      name="howOclockFormat"
                      className="checkbox-date"
                      id={`${id}${time_type}`}
                      checked={pm}
                      onChange={e => {
                        handleChangeTime(e, id, time_type);
                        this.handleChangePm();
                      }}
                    />
                    <label className="checkbox-date__label" htmlFor={`${id}${time_type}`} />
                  </div>
                  <span className="checkbox-date__pm">PM</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SetTime;
