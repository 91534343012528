import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import { createAlbum, showAllAlbums } from '../../../../Redux/actions/shopActions/galleryShopActions';

import { AppButton } from '../../../Common/Button';
import { TextInputField } from '../../../Common/Form';


class AlbumPopUpCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albumName: '',
    };
  }

  handleChange = e => {
    this.setState({
      albumName: e.target.value,
    });
  };

  handleClick = () => {
    this.props.createAlbum({ name: this.state.albumName }).then(res => {
      if (res.res.data.message === 'success') {
        this.props.toggleAlbumCreation();
        this.props.showAllAlbums({ id: this.props.auth.user.id });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { albumName } = this.state;
    return (
      <div className="modal-wrapper" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
        <div className="modal-wrapper__inner">
          <div className="theme-modal theme-modal--album">
            <div className="theme-modal__header theme-modal__header--code">
              <h2>{t('shopPrivate.photoGallery.createAlbum')}</h2>
            </div>
            <div className="theme-modal__content">
              <div className="modal-inner-content">
                <TextInputField
                  label={t('shopPrivate.photoGallery.nameAlbum')}
                  placeholder={t('shopPrivate.bookings.text')}
                  value={albumName}
                  required
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="confirm-code-btn">
              <AppButton disabled={!(albumName||'').trim()} theme="primary" onClick={this.handleClick}>
                {t('action.confirm')}
              </AppButton>
              <AppButton theme="muted" onClick={this.props.toggleAlbumCreation}>
                {t('action.cancel')}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(
  connect(
    null,
    { createAlbum, showAllAlbums }
  )(AlbumPopUpCreation)
);
