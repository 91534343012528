import React from 'react';

import { Textarea } from '../../../../Common/Form';

const RecommendedProductItemUnchecked = ({ product, i, onChangeProduct }) => {
  return (
    <div className="mb-3 mx-2 border pb-3 bg-white price-item">
      <div className="px-3 py-2">
        <input
          type="checkbox"
          name="product_id"
          checked={product.recommended}
          onChange={(e) => onChangeProduct(e, i, product.id)}
          className="checkbox checkbox--type2"
          id={`${product.name}=${product.id}`}
        />
        <label htmlFor={`${product.name}=${product.id}`}>
          <span />
        </label>
      </div>
      <p className="mt-2 px-3 py-1 text-center price-item__name">{product.name}</p>
      <div className="price-item__img">
        <p className="w-75 price-sicker">{product.price} $</p>
        {product.photos instanceof Array && product.photos.length > 0 && (
          <img src={product.photos[0] && product.photos[0].url} alt="product" />
        )}
      </div>
      <Textarea
        className="price__descr"
        disabled
        value={product.description}
      />
    </div>
  );
};

export default RecommendedProductItemUnchecked;
