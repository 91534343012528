import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import InnerHTML from 'dangerously-set-html-content';


class ExternalFeed extends Component {

  componentDidMount() {
    const {
      feed,
    } = this.props;

    if (feed.providerName === 'instagram') {
      // It is observed that the instagram feed cannot always load autoatically (on component mount?).
      // So here explicitly init the feed, as instructed by Facebook SDK.
      // See the section "Embed JS" on https://developers.facebook.com/docs/instagram/oembed
      setTimeout(() => {
        try {
          window.instgrm.Embeds.process();
        } catch (error) {
          console.error('instagram error', error);
        }
      }, 150);
    }
  }

  render() {
    const {
      feed,
    } = this.props;

    return (
      <>
        {feed.html && (
          <>
            {feed.providerName === 'facebook' && (
              // The html code for rendering facebook video requires explicit declaration of width and height.
              // We should NOT wrap it with className `video-container-s` which would applied an aspect ratio to the container.
              <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: feed.html }} />
            )}

            {feed.providerName === 'instagram' && (
              <InnerHTML html={feed.html} />
            )}

            {feed.providerName === 'youtube' && (
              <div className="video-container-s" dangerouslySetInnerHTML={{ __html: feed.html }} />
            )}
          </>
        )}
      </>
    );
  }
}

ExternalFeed.defaultProps = {

  feed: {},

};

export default withTranslation()(
  withRouter(
    connect(
      null,
      {
      }
    )(ExternalFeed)
  )
);
