import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import api from '../../../../../api';

import { createService, showService, getAllServices } from '../../../../../Redux/actions/shopActions/serviceAction';
import { fetchShopStaff } from '../../../../../Redux/actions/shopActions/shopStaffActions';
import { getAllServiceTypes, createServiceType } from '../../../../../Redux/actions/features/serviceTypeAction';

import Notification from '../../../Notification';
import notification from '../../../../Common/notification';
import { AppButton } from '../../../../Common/Button';
import Spinner from '../../../../Common/Spinner/Spinner';

import TableHead from '../Creation/TableHead';
import ServiceTypeModal from '../Creation/ServiceTypeModal';
import SetTime from '../Creation/SetTime';

import SelectTypeEdit from './SelectTypeEdit';
import StaffChargeEdit from './StaffChargeEdit';


class EditService extends Component {

  state = {
    disabled: '',
    toggleStaffCharge: false,
    charge: '',
    name: '',
    description: '',
    price_fixed: '',
    price_from: '',
    price_to: '',
    // time: '',
    serviceName: '',
    available: 1,
    existName: false,
    disableField: true,
    staff_id: [],
    service_name: '',
    toggleServiceType: false,
    service_type_id: '',
    isValidDescription: '',
    isValidName: '',
    isValidTime: '',
    isValidPrice: '',
    isValidServiceType: '',
    loading: true,
    modalStatus: '',
    message: '',
    staff: {},
    changeTimeWindowOpen: false,
    time: {
      hours: '00',
      minutes: '00',
    },
  };

  componentDidMount() {
    this.props.showService({ id: this.props.match.params.serviceId }).then(res => {
      const time = res.res.data.data.time;
      const hours = time.slice(0, time.indexOf(':'));
      const minutes = time.slice(time.indexOf(':') + 1);
      if (res.res.data.message === 'success') {
        const staff = {};
        res.res.data.data.madeBy.map(item => {
          staff[item.id] = {};
          staff[item.id].charge = item.pivot.charge;
        });
        this.setState({
          name: res.res.data.data.name,
          service_type_id: res.res.data.data.service_type.id,
          service_name: res.res.data.data.service_type.name,
          description: res.res.data.data.description,
          time: {
            hours: hours,
            minutes: minutes,
          },
          available: res.res.data.data.available,
          price_fixed: res.res.data.data.price_fixed,
          price_from: res.res.data.data.price_from,
          price_to: res.res.data.data.price_to,
          staff: staff,
          disableField: res.res.data.data.price_fixed > 0 ? true : false,
        });
      }
    });

    this.props.getAllServiceTypes({ id: this.props.auth.user.id }).then(res => {
      if (res.res.data.message === 'success') {
        this.setState({
          loading: false,
        });
      }
    });

    this.props.fetchShopStaff({ id: this.props.auth.user.id, fields: 'charge' });
  }

  // setIndex = (value, id) => {
  //   if (Array.isArray(this.state.staffChargeValue)) {
  //     let index = this.state.staffChargeValue.indexOf(value);
  //     return this.state.staffChargeValue[index];
  //   } else {
  //     return this.state.staffChargeValue[id];
  //   }
  // };

  toggleStaffCharge = () => {
    this.setState({
      toggleStaffCharge: !this.state.toggleStaffCharge,
    });
  };

  toggleServiceTypeAdd = () => {
    this.setState({
      toggleServiceType: !this.state.toggleServiceType,
    });
  };

  toggleAvailability = e => {
    const availValue = e.target.checked ? 0 : 1;
    this.setState({
      available: availValue,
    });
  };

  disableInput = () => {
    this.setState({
      disableField: !this.state.disableField,
      price_fixed: '',
      price_from: '',
      price_to: '',
    });
  };

  handleChangeBoolean = ({ target }) => {
    this.setState(prevState => ({
      [target.name]: !prevState.changeTimeWindowOpen,
    }));
  };

  handleChangeStaff = (charge, id, showField) => {
    if (showField) {
      this.setState({
        staff: {
          ...this.state.staff,
          [id]: {
            charge: charge,
          },
        },
      });
    } else {
      const filteredStaff = this.state.staff;
      delete filteredStaff[id];
      this.setState({
        staff: {
          ...filteredStaff,
        },
      });
    }
  };

  // toggleEditStaffCharge = id => {
  //   if (this.state.modalStatus === '') {
  //     this.setState({
  //       modalStatus: id,
  //     });
  //   } else {
  //     this.setState({
  //       modalStatus: '',
  //     });
  //   }
  // };

  sendData = () => {
    const timeToSend = `${this.state.time.hours}:${this.state.time.minutes}`;
    api.ShopServices.updateSpecificService({
      id: this.props.match.params.serviceId,
      name: this.state.name,
      service_type_id: this.state.service_type_id,
      description: this.state.description,
      price_fixed: this.state.price_fixed,
      price_from: this.state.price_from,
      price_to: this.state.price_to,
      time: timeToSend,
      available: this.state.available,
      staff: this.state.staff,
    }).then(res => {
      const data = res.data && res.data.data;
      if (
        (data && data.toLowerCase() === 'service type with this name already exists.') ||
        data === '這個產品分類已經刪除'
      ) {
        this.setState({
          existName: true,
        });
      } else {
        this.setState({
          existName: false,
        });
      }
      if (res.data.message === 'incorrect data') {
        if (data.description) {
          this.setState({
            isValidDescription: data.description,
          });
        } else {
          this.setState({
            isValidDescription: null,
          });
        }
        if (data.name) {
          this.setState({
            isValidName: data.name,
          });
        } else {
          this.setState({
            isValidName: null,
          });
        }
        if (data.time) {
          this.setState({
            isValidTime: data.time,
          });
        } else {
          this.setState({
            isValidTime: null,
          });
        }
        if (data.service_type_id) {
          this.setState({
            isValidServiceType: data.service_type_id,
          });
        } else {
          this.setState({
            isValidServiceType: null,
          });
        }
        if ((data && data.toLowerCase() === 'fill price field(s).') || data === '固定價格') {
          this.setState({
            isValidPrice: data,
          });
        } else {
          this.setState({
            isValidPrice: null,
          });
        }
        if (
          (data && data.toLowerCase() === `you can't delete service: this service is on promotion page.`) ||
          data === `您不能刪除此商品，因為已經在優惠頁。`
        ) {
          this.setState({
            available: 1,
          });
          notification(data);
        }
      } else {
        this.props.getAllServices({ id: this.props.auth.user.id }).then(res => {
          if (res.res.data.message === 'success') {
            this.setState({
              message: this.props.t('notifications.serviceUpdated'),
            });
            setTimeout(() => {
              this.setState({
                message: '',
              });

              // refresh necessary data
              // TODO It is bad fix. Pending for better data flow
              this.props.history.push('/shop-settings/service-price');
              this.props.fetchShopStaff({ id: this.props.auth.user.id, fields: 'charge' }, true);
            }, 3000);
          }
        });
      }
    });
  };

  handleChange = ({ target: { name, value } }) => {
    if (name === 'price_fixed' || name === 'price_from' || name === 'price_to') {
      const regExp = /^\d+\.?\d*$/;
      if ((Number(value) !== '' && regExp.test(value)) || value === '') {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
    if (this.state.price_fixed !== '') {
      this.setState({
        disabled: false,
      });
    }
  };

  checkKey = e => {
    if (e.keyCode === 69) {
      e.preventDefault();
    }
  };

  handleServiceTypeChange = e => {
    this.setState({
      service_type_id: e.target.value,
    });
  };

  handleChangeTime = ({ target }) => {
    if (target.getAttribute('name') === 'hours') {
      this.setState(prevState => ({
        time: {
          ...prevState.time,
          [target.name]: target.value,
          hours:
            Number(target.value).toString().length > 1
              ? Number(target.value).toString()
              : `0${Number(target.value).toString()}`,
        },
      }));
    } else {
      this.setState(prevState => ({
        time: {
          ...prevState.time,
          [target.name]: target.value,
        },
      }));
    }
  };

  sendServiceTypeData = () => {
    this.setState({
      loading: true,
    });
    this.props.createServiceType({ name: this.state.serviceName }).then(res => {
      if (res.res.data.message === 'success') {
        this.toggleServiceTypeAdd();
        this.props.getAllServiceTypes({ id: this.props.auth.user.id }).then(res => {
          if (res.res.data.message === 'success') {
            this.setState({
              loading: false,
            });
          }
        });
      }
    });
  };

  redirect = () => {
    this.props.history.push('/shop-settings/service-price');
  };

  render() {
    const { shopStaffDataList, service_id, serviceTypes, t } = this.props;
    const { time, loading } = this.state;

    return (
      <div className="mainPanel">
        {this.state.message !== '' && <Notification text={this.state.message} onClose={this.setStatusClosed} />}
        {this.state.status === 'error' && (
          <Notification text={t('notifications.somethingWasWrong')} onClose={this.setStatusClosed} />
        )}
        <h2 className="categoryTitle">
          <i className="fas fa-wrench" aria-hidden="true" />
          {t('shopPrivate.servicePriceList.servicePriceList')}
        </h2>

        <div className="main-container">
          <div className="page-head">
            <h2 className="page-title">{t('shopPrivate.servicePriceList.servicePriceList')}</h2>
          </div>
          <div className="main-container__content">
            {loading ? (
              <Spinner display="block" />
            ) : (
              <div className="tableWrap tableWrap--auto-height">
                <table className="adminTable">
                  <colgroup>
                    <col style={{ minWidth: 300 }} />
                    <col style={{ minWidth: 200 }} />
                    <col style={{ minWidth: 120 }} />
                    <col style={{ minWidth: 300 }} />
                    <col style={{ minWidth: 200 }} />
                    <col style={{ minWidth: 300 }} />
                    <col style={{ width: 150 }} />
                  </colgroup>
                  <TableHead />
                  <tbody>
                    <tr data-label-tr={`${t('block')} #1`}>
                      <td data-label={t('shopPrivate.calendar.serviceName')}>
                        <div className={'table-field ' + (!this.state.isValidName ? 'field-wrap' : 'error-field')}>
                          <input
                            type="text"
                            onChange={this.handleChange}
                            name="name"
                            value={this.state.name}
                            className="form-item"
                            placeholder={t('shopPublic.overview.createBooking.name')}
                          />
                          {this.state.isValidName && <span className="error-text">{this.state.isValidName}</span>}
                          {this.state.existName && (
                            <span className="error-text">{t('shopPrivate.servicePriceList.serviceNameExist')}</span>
                          )}
                        </div>
                      </td>
                      {!this.state.loading && (
                        <SelectTypeEdit
                          toggleServiceTypeAdd={this.toggleServiceTypeAdd}
                          loading={this.state.loading}
                          value={this.state.service_type_id}
                          name={this.state.service_name}
                          isValid={this.state.isValidServiceType}
                          serviceTypes={serviceTypes}
                          handleServiceTypeChange={this.handleServiceTypeChange}
                        />
                      )}
                      <SetTime
                        isValidTime={this.state.isValidTime}
                        handleChangeBoolean={this.handleChangeBoolean}
                        timeString={`${this.state.time.hours}:${this.state.time.minutes}`}
                        time={time}
                        changeTimeWindowOpen={this.state.changeTimeWindowOpen}
                        handleChangeTime={this.handleChangeTime}
                      />
                      <td data-label={t('shopPrivate.servicePriceList.service_descr')}>
                        <div
                          className={'table-field ' + (!this.state.isValidDescription ? 'field-wrap' : 'error-field')}
                        >
                          <input
                            type="text"
                            name="description"
                            onChange={this.handleChange}
                            value={this.state.description}
                            className="form-item"
                            placeholder={t('shopPublic.overview.createBooking.name')}
                          />
                          {this.state.isValidDescription && (
                            <span className="error-text">{this.state.isValidDescription}</span>
                          )}
                        </div>
                      </td>
                      <td data-label={t('shopPrivate.servicePriceList.availability')}>
                        <div className="switch-box">
                          <input
                            type="checkbox"
                            value={this.state.available}
                            className="switch-checkbox"
                            id="checkbox"
                            onChange={this.toggleAvailability}
                            checked={!this.state.available}
                          />
                          <label htmlFor="checkbox">
                            <span className="first-opt">{t('shopPrivate.servicePriceList.available')}</span>
                            <span className="second-opt">{t('shopPrivate.servicePriceList.closed')}</span>
                          </label>
                        </div>
                      </td>
                      <td data-label={t('shopPublic.product.price')}>
                        <form>
                          <div className="service-price">
                            <div className="service-price__check">
                              <input
                                type="radio"
                                checked={this.state.disableField}
                                className="checkbox"
                                id="c1"
                                onChange={this.disableInput}
                                name="priceFlag"
                              />
                              <label htmlFor="c1">
                                <span>{t('shopPrivate.servicePriceList.fixedPrice')}</span>
                              </label>
                            </div>
                            <div
                              className={
                                this.state.isValidPrice ? 'error-field service-price__field' : 'service-price__field'
                              }
                            >
                              {this.state.isValidDescription && (
                                <span className="error-text error-text--top">{this.state.isValidDescription}</span>
                              )}
                              <input
                                type="text"
                                value={this.state.price_fixed ? this.state.price_fixed : ''}
                                name="price_fixed"
                                className="form-item"
                                onKeyDown={this.checkKey}
                                onChange={this.handleChange}
                                disabled={!this.state.disableField}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="service-price">
                            <div className="service-price__check">
                              <input
                                type="radio"
                                className="checkbox"
                                id="c2"
                                name="priceFlag"
                                onChange={this.disableInput}
                                value="range"
                                checked={!this.state.disableField}
                              />
                              <label htmlFor="c2">
                                <span>{t('shopPrivate.servicePriceList.rangePrice')}</span>
                              </label>
                            </div>
                            <div className="service-price__field service-price__field--range">
                              <input
                                type="text"
                                className="form-item"
                                onKeyDown={this.checkKey}
                                onChange={this.handleChange}
                                disabled={this.state.disableField}
                                name="price_from"
                                value={this.state.price_from ? this.state.price_from : ''}
                              />
                            </div>
                            <span className="service-price__help">{t('shopPrivate.schedule.to')}</span>
                            <div className="service-price__field service-price__field--range">
                              <input
                                type="text"
                                className="form-item"
                                onKeyDown={this.checkKey}
                                onChange={this.handleChange}
                                disabled={this.state.disableField}
                                name="price_to"
                                value={this.state.price_to ? this.state.price_to : ''}
                              />
                            </div>
                          </div>
                          {this.state.isValidPrice}
                        </form>
                      </td>
                      <td data-label={t('shopPrivate.shopLeftMenu.staff')}>
                        <AppButton size="small" theme="primary" onClick={this.toggleStaffCharge}>
                          {t('shopPrivate.bookings.selectStaff')}
                        </AppButton>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {this.state.toggleServiceType && (
                  <ServiceTypeModal
                    handleChange={this.handleChange}
                    sendServiceTypeData={this.sendServiceTypeData}
                    toggleServiceTypeAdd={this.toggleServiceTypeAdd}
                  />
                )}
              </div>
            )}
          </div>

          {this.state.toggleStaffCharge && (
            <StaffChargeEdit
              handleChange={this.handleChangeStaff}
              shopStaffDataList={shopStaffDataList}
              service_id={service_id}
              modalStatus={this.state.modalStatus}
            />
          )}

          <div className="mt-2 px-3">
            <AppButton theme="primary" disabled={this.state.disabled} onClick={this.sendData}>
              {t('action.save')}
            </AppButton>
            <AppButton theme="muted" onClick={this.redirect}>
              {t('action.cancel')}
            </AppButton>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    serviceTypes: state.serviceTypesReducer.serviceTypes,
    service_id: state.servicesReducer.service.id,
    shopStaffDataList: state.GetStuffInformationReducer.shopStaffDataList,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        createService,
        getAllServices,
        fetchShopStaff,
        showService,
        getAllServiceTypes,
        createServiceType,
      }
    )(EditService)
  )
);
