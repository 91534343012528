import {CREATE_SERVICE, DELETE_SERVICE, GET_ALL_SERVICES, SHOW_SERVICE, GET_FIXED_PRICE_SERVICES, GET_BOOKING_SERVICES} from "../../constants/constants";

const initialState = {
	services: [],
	service: [],
	create: [],
};


export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ALL_SERVICES:
			return {
				...state,
				services: action.payload
			};
		case GET_FIXED_PRICE_SERVICES:
			return {
				...state,
				services: action.payload
			};
		case SHOW_SERVICE:
			return {
				...state,
				service: action.payload
			};
		case DELETE_SERVICE:
			return {
				...state,
				create: action.payload
			};
		case CREATE_SERVICE:
			return {
				...state,
				create: action.payload
			}
		case GET_BOOKING_SERVICES:
			return {
				...state,
				services: action.payload
			}

		default:
			return {
				...state
			}

	}
}