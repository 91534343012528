import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import {
  savePhotoIntoAlbum,
  showAllAlbums,
  showSpecificAlbum,
  nextPageAlbum,
} from '../../../../Redux/actions/shopActions/galleryShopActions';

import Pagination from '../../../Common/Pagination';
import notification from '../../../Common/notification';
import PhotoGridEditable from '../../../Common/PhotoGridEditable/PhotoGridEditable';

// TODO Split it
class UpdateAlbum extends Component {

  componentDidMount() {
    if (this.props.id) {
      this.props.showSpecificAlbum({ id: this.props.id });
    }
  }

  componentWillUnmount() {
    if (this.props.id) {
      this.props.showAllAlbums({ id: this.props.auth.user.id });
    }
  }

  handlePageChange = data => {
    this.props.nextPageAlbum(data.selected + 1);
  };

  handleUploadImageFile = file => {
    // upload a new image
    return this.props.savePhotoIntoAlbum({
        id: this.props.id, // album id
        name: this.props.name,
        photos: { new: file },
      })
      .then(result => {
        result.res.data && notification(result.res.data); // result.res is ajax response
        this.props.showSpecificAlbum({ id: this.props.id });
        return result.res.data;
      });
  }

  handleDeleteRemotePhoto = (photo) => {
    return this.props.savePhotoIntoAlbum({
      id: this.props.id, // album id
      name: this.props.name,
      photos: { [photo.id]: null },
    })
      .then(result => {
        this.props.showSpecificAlbum({ id: this.props.id });
        return result.res; // result.res is ajax response
      });
  }

  render() {
    const {
      isFetchingAlbum,
      albums,

      isFetchingAlbumPhoto,
      photos,

      match,
      t,
    } = this.props;

    const albumPhotos = (photos && photos.photos) || [];

    const reviewAlbumPhotos = (albums && albums.review_album && albums.review_album.data) || [];

    return (
      <>
        {match.path !== '/shop-settings/gallery/review' ? (
          <PhotoGridEditable
            headerText={t('shopPrivate.media.uplPhotos')}
            isFetchingItems={isFetchingAlbumPhoto}
            // maxPhotoLimit={?} // TODO
            remoteItems={albumPhotos}
            showCopyUrlButton={false}
            onUploadImageFile={this.handleUploadImageFile}
            onDeleteRemotePhoto={this.handleDeleteRemotePhoto}
          />
        ) : (
          <>
            <PhotoGridEditable
              headerText={t('shopPrivate.media.uplPhotos')}
              isFetchingItems={isFetchingAlbum}
              maxPhotoLimit={0}
              remoteItems={reviewAlbumPhotos}
              showCopyUrlButton={false}
              showDeleteButton={false}
            />

            {Number(albums.review_album && albums.review_album.last_page) > 1 && (
              <Pagination
                pageChange={this.handlePageChange}
                currentPage={albums.review_album && albums.review_album.current_page}
                lastPage={albums.review_album && albums.review_album.last_page}
              />
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetchingAlbum: state.galleryReducer.isFetchingAlbum,
    albums: state.galleryReducer.albums,

    isFetchingAlbumPhoto: state.galleryReducer.isFetchingAlbumPhoto,
    photos: state.galleryReducer.photos,

    auth: state.auth,
    limit: state.ShopReducer.shopData.photo_limit && state.ShopReducer.shopData.photo_limit.limit,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      { showSpecificAlbum, savePhotoIntoAlbum, showAllAlbums, nextPageAlbum },
    )(UpdateAlbum),
  ),
);
