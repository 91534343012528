import {GET_CUSTOMER_INFORMATION} from "../../constants/constants";


const initialState = {
    customer: {}
};


export default (state = initialState, action)   => {
    switch (action.type) {
        case GET_CUSTOMER_INFORMATION: {
            return {
                ...state,
                customer: action.payload
            }
        }
        default:
            return {
                ...state
            }
    }

}

