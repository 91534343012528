import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import AspectImage from '../../../Common/AspectImage/AspectImage';
import { CustomerAvatar } from '../../../Common/Customer';
import { EllipsizeBlock } from '../../../Common/Text';
import Localize from '../../../Common/LanguageSwitch/Localize';
import RatingStars from '../../../Common/RatingStars/RatingStars';

import styles from './SiteLatestReviewCard.module.css';


const SiteLatestReviewCard = ({
  // from parent:
  review,

  // from mapStateToProps:
  auth,
}) => {

  const [cardLinkTo, setCardLinkTo] = useState('');
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (review.shop_id === auth.user.id) {
      setCardLinkTo(`/shop/review/${review.id}`);
    } else {
      setCardLinkTo(`/shop/${review.shop_id}/review/${review.id}`);
    }
  }, [review.id]);

  useEffect(() => {
    setPhotos((review.photos || []).slice(0, 3)); // take first X items
  }, [review.photos]);

  return (
    <div className="col-lg-4">
      <div className={`px-3 py-4 ${styles.card}`}>
        <Link
          to={`/customer/${review.customer.alias}`}
          className={styles.customerAvatar}
        >
          <CustomerAvatar src={review.customer_avatar} />
        </Link>

        <Link to={cardLinkTo}>
          <RatingStars
            rating={review.review_average_rating.overall_rating}
            showNumeric
          />

          <p className={styles.shopName}>
            <Localize ch={review.shop_chinese_name} en={review.shop_name} />
          </p>

          <EllipsizeBlock
            className={styles.review}
            maxlength={100}
            text={review.review}
          />

          <div className={styles.footer}>
            <div>{review.customer_full_name.replace(/ .*/, '')}</div>
            <div className={styles.footRow}>
              {photos.map((photo) => (
                <div className={`px-1 ${styles.footCell}`} key={photo.id}>
                  <AspectImage aspectRatio="1:1">
                    <img src={photo.url} alt="review photo" />
                  </AspectImage>
                </div>
              ))}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(
  mapStateToProps
)(SiteLatestReviewCard);
