import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Shop } from 'src/models/entity';

interface Props {
  shop: Shop;
}

const ShopName = ({
  shop,
}: Props) => {
  const { i18n } = useTranslation();

  const shopName = useMemo(() => {
    const shopData = shop.user_type_data;

    return (
      (i18n.language.startsWith('en')
        ? shopData?.name
        : shopData?.chineseName) ||
      shopData?.name ||
      ''
    );
  }, [shop, i18n.language]);

  return <>{shopName}</>;
}

export default ShopName;
