import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';


import { AppButton } from 'src/Components/Common/Button';


interface Props {
  onClose: () => void;
}

const BookingCreatedDialog = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={onClose}
      aria-labelledby="created-booking-dialog-title"
    >
      <DialogTitle id="created-booking-dialog-title">
        <div className="d-flex align-items-center">
          <h2 className="flex-fill m-0">SalonFinder</h2>

          <button className="btn btn-link" onClick={onClose}>
            <i className="fas fa-times text-muted" />
          </button>
        </div>
      </DialogTitle>

      <DialogContent>
        <p>{t('notifications.bookingFormSent')}</p>
      </DialogContent>

      <DialogActions>
        <AppButton size="block" theme="primary" onClick={onClose}>
          {t('action.confirm')}
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

export default BookingCreatedDialog;
