export const extractDomain = (url: string) => {
  const matches = url.match(/^https?:\/\/([^/]+)/i);
  return matches ? matches[1] : '';
};

export const currentDomain = () => {
  return extractDomain(window.location.origin);
};

export const isCurrentDomain = (url: string) => {
  const domain = extractDomain(url);
  return domain === currentDomain();
};
