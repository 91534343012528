import { SEARCH_SHOPS } from "../constants/constants";


const initialState = {
  results: {
    data: []
  },
  sortResults: []
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SHOPS:
      return {
        ...state,
        results: action.payload
      };
    default:
      return {
        ...state
      }
  }
}
