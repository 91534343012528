import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import AspectImage from '../../../Common/AspectImage/AspectImage';
import ExternalFeedThumbnail from '../../../Common/ExternalFeed/ExternalFeedThumbnail';
import { ShopBadge } from '../../../Common/Shop';
import { Ellipsize, LineBreakable } from '../../../Common/Text';


const NewsItem = ({
  shop,
  news,
  history,
}) => {

  const redirectView = () => {
    history.push(`/shop/${shop.id}/news/${news.id}`);
  };

  return (
    <div
      className="news-item-container"
      role="button"
      onClick={redirectView}
    >
      <div className="row">
        <div className="col-xl-2">
          <ShopBadge shop={shop} />
        </div>
        <div className="col-xl-10">
          <Link
            to={`/shop/${shop.id}/news/${news.id}`}
            className="news-title"
            onClick={(e) => e.stopPropagation()}
          >
            {news.title}
          </Link>

          <p className="news-info">{news.created_at}</p>

          <LineBreakable className="news-content">
            <Ellipsize text={news.description} maxlength={255} />
          </LineBreakable>

          <div className="row align-items-center">
            {news.externalFeeds &&
              news.externalFeeds.map((feed, index) => (
                <div className="col-6 col-md-3 mb-2" key={index}>
                  <ExternalFeedThumbnail feed={feed} />
                </div>
              ))}

            {news.photos &&
              news.photos.map((photo) => (
                <div className="col-6 col-md-3 mb-2" key={photo.id}>
                  <AspectImage aspectRatio="1:1">
                    <img src={photo.url} alt="news image" />
                  </AspectImage>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewsItem);
