import { useMemo } from 'react';

import defaultAvatar from '../../../dist/img/default-avatar-customer.png';

import { Customer } from 'src/models/entity';

import AspectImage from 'src/Components/Common/AspectImage/AspectImage';

interface Props {
  customer?: Customer;
  src?: string;
}

/**
 * For displaying customer avatar image.
 */
const CustomerAvatar = ({ customer, src }: Props) => {
  const imgSrc = useMemo(() => {
    if (customer?.avatar) {
      return customer?.avatar;
    }

    if (src) {
      return src;
    }

    return defaultAvatar;
  }, [customer, src]);

  return (
    <AspectImage className="rounded-circle">
      <img src={imgSrc} alt="customer avatar" />
    </AspectImage>
  );
};

export default CustomerAvatar;
