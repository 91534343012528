import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppButton } from '../Button';

import RouteItem, { RouterItem } from './RouteItem';


interface Props {
  buttonExist?: boolean;
  className?: string;
  routes: RouterItem[];
};

const Breadcrumb = ({ buttonExist = false, className = '', routes }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div className={`${className} py-2 mt-3 mt-lg-4 d-flex align-items-center`}>
      {buttonExist && (
        <AppButton
          className="mr-4"
          theme="muted"
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="fas fa-arrow-circle-left mr-1" />
          {t('action.back')}
        </AppButton>
      )}

      <div>
        <RouteItem item={{ path: '/', title: 'home' }} />

        {routes.map((item, index) => (
          <RouteItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
