import { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { Booking, Shop } from 'src/models/entity';

import axios from 'axios';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import Breadcrumb from 'src/Components/Common/Breadcrumb/Breadcrumb';
import { RouterItem } from 'src/Components/Common/Breadcrumb/RouteItem';
import notification from 'src/Components/Common/notification';
import { AppButton } from 'src/Components/Common/Button';
import Spinner from 'src/Components/Common/Spinner/Spinner';

import { getBookingServices } from 'src/Redux/actions/shopActions/serviceAction';
import { getShopBookingsPagination } from 'src/Redux/actions/features/paginationAction';
import { fetchShopStaff } from 'src/Redux/actions/shopActions/shopStaffActions';

import BookingCreationByProviderDialog from 'src/Components/Common/Booking/BookingCreationByProviderDialog';
import FastCheckoutLink from 'src/Components/Common/Shop/FastCheckoutLink';

import BookingEditByProviderDialog from 'src/Components/Common/Booking/BookingEditByProviderDialog';

import ShopBookingList from './BookingsTemplate/ShopBookingList';
import ShopBookingNav from './ShopBookingNav';


interface Props {
  // from mapStateToProps:
  shop: Shop;

  // redux actions:
  fetchShopStaff: (data: any) => void;
  getShopBookingsPagination: (data: any) => Promise<any>;
  getBookingServices: (data: any) => Promise<any>;
}

function ShopBookings({
  shop,
  fetchShopStaff,
  getBookingServices,
  getShopBookingsPagination,
}: Props) {
  const { t, i18n } = useTranslation();

  const baseUrl = '/shop-settings/bookings/';

  const [isFetchingItems, setFetchingItems] = useState(false);

  const [subjectBooking, setSubjectBooking] = useState<Booking>();

  const [isBookingCreationDialogOpened, setBookingCreationDialogOpened] =
    useState(false);

  const [isEditBookingDialogOpened, setEditBookingDialogOpened] =
    useState(false);

  const [bookingType, setBookingType] = useState('');

  const [count, setCount] = useState(0);

  const routes = useMemo<RouterItem[]>(() => {
    const path = window.location.pathname;
    const lastI = path.lastIndexOf('/');

    return [
      {
        title: t('shopPrivate.shopLeftMenu.bookings'),
        path: `${path.slice(0, lastI)}/waiting_confirm_bookings`,
      },
      {
        title: path.slice(lastI + 1).replace(/[_]/g, ' '),
        path: path,
      },
    ];
  }, [i18n.language]);

  useEffect(() => {
    setFetchingItems(true);

    fetchShopStaff({ id: shop.id, fields: 'charge' });

    getBookingServices({ id: shop.id })
      .then((res) => {})
      .catch((e) => {
        notification(t('notifications.somethingWentWrong'));
      })
      .finally(() => {
        setFetchingItems(false);
      });

    // window.setInterval(function () {
    //    getCount();
    // }.bind(this), 5000);
  }, []);

  const handleRequestEdit = (booking: Booking, type: string) => {
    setEditBookingDialogOpened((val) => !val);
    setBookingType(type);
    setSubjectBooking(booking);
  };

  const getCount = () => {
    axios
      .get('/api/shop-new-bookings-count/' + shop.id)
      .then(async (res) => {
        setCount(res.data.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBookingEdited = () => {
    const data = {
      user: 'shop-bookings',
      type: bookingType,
      page: 1,
    };

    getShopBookingsPagination(data).catch((error) => {
      console.error(error);
    });

    setEditBookingDialogOpened(false);
  };

  return (
    <div className="mainPanel">
      <h2 className="categoryTitle">
        <i className="fas fa-calendar-check" aria-hidden="true" />
        {t('shopPrivate.bookings.booking')}
      </h2>

      <Breadcrumb routes={routes} buttonExist />

      <div className="my-3">
        <AdvertisementView name="shop_waiting_confirm" />
      </div>

      {isFetchingItems ? (
        <Spinner display="block" />
      ) : (
        <div className="main-container">
          <div className="d-flex flex-row justify-content-between">
            {/* Button to create booking */}
            <AppButton
              theme="primary"
              onClick={() => {
                setBookingCreationDialogOpened(true);
              }}
            >
              <i className="fas fa-calendar-plus mr-2"></i>
              {t('shopPrivate.bookings.btnAdd')}
            </AppButton>

            <div className="d-flex flex-row align-items-center">
              <FastCheckoutLink shop={shop} withCopyButton />
            </div>
          </div>

          <ShopBookingNav waitingCount={count} />

          <div className="main-container__content">
            <Switch>
              <Route
                path={baseUrl + 'waiting_confirm'}
                render={() => (
                  <ShopBookingList
                    bookingType="new_bookings"
                    onRequestEdit={handleRequestEdit}
                  />
                )}
              />

              <Route
                path={baseUrl + 'upcoming'}
                render={() => (
                  <ShopBookingList
                    bookingType="confirmed_bookings"
                    onRequestEdit={handleRequestEdit}
                  />
                )}
              />
              <Route
                path={baseUrl + 'cancelled'}
                render={() => (
                  <ShopBookingList bookingType="cancelled_bookings" />
                )}
              />
              <Route
                path={baseUrl + 'completed'}
                render={() => (
                  <ShopBookingList bookingType="completed_bookings" />
                )}
              />
              <Route
                path={baseUrl + 'absent'}
                render={() => <ShopBookingList bookingType="absent_bookings" />}
              />
              <Route
                path={baseUrl + 'have_not_checkout'}
                render={() => (
                  <ShopBookingList bookingType="not_check_out_bookings" />
                )}
              />

              {/* Default / Fallback route */}
              <Redirect to={baseUrl + 'waiting_confirm'} />
            </Switch>
          </div>
        </div>
      )}

      {isBookingCreationDialogOpened && (
        <BookingCreationByProviderDialog
          shopId={shop.id}
          onCreated={() => {
            // TODO Refresh appropriate tab
            setBookingCreationDialogOpened(false);
          }}
          onClose={() => {
            setBookingCreationDialogOpened(false);
          }}
        />
      )}

      {isEditBookingDialogOpened && subjectBooking && (
        <BookingEditByProviderDialog
          booking={subjectBooking}
          onClose={() => {
            setEditBookingDialogOpened(false);
            setSubjectBooking(undefined);
          }}
          onEdited={handleBookingEdited}
        />
      )}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    shop: state.auth.user,
  };
}

export default withRouter(
    connect(
      mapStateToProps,
      {
        fetchShopStaff,
        getShopBookingsPagination,
        getBookingServices,
      }
    )(ShopBookings)
);
