import React from 'react';
import { useTranslation } from 'react-i18next';


import { AppButton } from '../../../../Common/Button';
import { TextInputField } from '../../../../Common/Form';

const ServiceTypeModal = ({ serviceName, handleChange, toggleServiceTypeAdd, sendServiceTypeData }) => {

  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <div className="content">
        <div className="modal-wrapper">
          <div className="modal-wrapper__inner">
            <div className="theme-modal theme-modal--album">
              <div className="theme-modal__header theme-modal__header--code">
                <h2>{t('shopPrivate.servicePriceList.addServiceType')}</h2>
                <button className="close-modal" onClick={toggleServiceTypeAdd}>
                  <i className="fas fa-times" />
                </button>
              </div>

              <div className="theme-modal__content">
                <div className="modal-inner-content">
                  {/* TODO i18n bad text */}
                  <TextInputField
                    label={t('shopPrivate.news.title')}
                    name="serviceName"
                    placeholder={t('shopPrivate.bookings.text')}
                    required
                    value={serviceName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="confirm-code-btn">
                <AppButton
                  disabled={!(serviceName||'').trim()}
                  theme="primary"
                  onClick={sendServiceTypeData}
                >
                  {t('action.create')}
                </AppButton>
                <AppButton theme="muted" onClick={toggleServiceTypeAdd}>
                  {t('action.cancel')}
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeModal;
