import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

interface Props {
  disabled?: boolean;
  isSpinning?: boolean;
  onClick: () => void;
}

const RefreshButton = ({
  disabled = false,
  isSpinning = false,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={`${t('action.refresh')}`}>
      <button
        className="mx-1 btn btn-outline-secondary"
        disabled={disabled || isSpinning}
        type="button"
        onClick={onClick}
      >
        {isSpinning ? (
          <div className="spinner-border spinner-border-sm"></div>
        ) : (
          <i className="fas fa-redo"></i>
        )}
      </button>
    </Tooltip>
  );
};

export default RefreshButton;
