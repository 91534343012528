import React from 'react';
import { useTranslation } from 'react-i18next';

const SearchInput = ({ name, placeholder, onChange }) => (
  <div className="field-wrap">
    <input
      type="text"
      name={name}
      onChange={onChange}
      className="nav-search__field"
      placeholder={placeholder}
    />
    <span className="field-ico">
      <i className="fas fa-search" />
    </span>
  </div>
);

const SearchBox  = ({
  onChange,
}) => {

  const { t } = useTranslation();

  return (
    <form className="row align-items-center">
      {/* Address */}
      <div className="col-lg-6 px-1 mb-2 mb-lg-0">
        <SearchInput
          name="searchAddress"
          placeholder={t('navBar.searchAddress')}
          onChange={onChange}
        />
      </div>

      {/* Name */}
      <div className="col-lg-6 px-1">
        <SearchInput
          name="searchName"
          placeholder={t('navBar.searchName')}
          onChange={onChange}
        />
      </div>
    </form>
  );
}

export default SearchBox;
