import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AspectImage from '../../../../Common/AspectImage/AspectImage';
import RatingStars from '../../../../Common/RatingStars/RatingStars';
import { ShopAvatar, ShopName, ShopStatusDot } from '../../../../Common/Shop';

import styles from './ShopCard.module.css';

function ShopCard({ shop }) {
  const { t } = useTranslation();

  const shopData = useMemo(() => {
    return shop?.user_type_data;
  }, [shop]);

  return (
    <Link className="shop-card" to={`/shop/${shop.id}`}>
      <div className={`d-none d-md-block m-auto ${styles.avatar}`}>
        <ShopAvatar src={shop.avatar} />
      </div>

      {/* Shown only on mobile screen */}
      <AspectImage aspectRatio="16:9" className="d-block d-md-none">
        {shop.photos instanceof Array && shop.photos[0] && (
          <img src={shop.photos[0].url} alt="cover" />
        )}
      </AspectImage>

      <div
        className={`d-flex flex-column justify-content-center text-center ${styles.shopName}`}
      >
        <ShopName shop={shop} />
      </div>

      <div className={styles.labels}>
        {shop.label?.map((item) => (
          <span className={styles.label} key={item.id}>
            {item.name}
          </span>
        ))}
      </div>

      {/* <div className="shop-card__city">{shop.user_type_data.address.string_address || shop.user_type_data.address}</div> */}

      {shopData?.district?.name && (
        <div className="shop-card__city">{shopData.district.name}</div>
      )}

      {shopData?.city?.name && (
        <div className="shop-card__city">{shopData.city.name}</div>
      )}

      {shopData?.average_rating > 0 && (
        <RatingStars rating={shopData?.average_rating} showNumeric />
      )}

      <div className="mt-2 d-flex justify-content-center align-items-center">
        <span className={styles.status}>
          <ShopStatusDot shop={shop} />
        </span>
        <span className={styles.onlineRegLabel}>
          {t('shopList.bookService')}
        </span>
        <span
          className={`${styles.onlineReg} ${
            shopData?.enableBookingSystem === 1 ? '' : styles.off
          }`}
        >
          {shopData?.enableBookingSystem === 1
            ? t('shopList.yes')
            : t('shopList.no')}
        </span>
      </div>
    </Link>
  );
}

export default ShopCard;
