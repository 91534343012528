interface Props {
  children: React.ReactNode;
}

const TableHeaderCell = ({ children }: Props) => (
  <td>
    <span className="td-title">{children}</span>
  </td>
);

export default TableHeaderCell;
