import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { Booking } from 'src/models/entity';
import { BookingStatus } from 'src/models/enum/BookingStatus';

import { timeToAmPm } from 'src/Functions/datetimeHelpers';

import { useConfirmationDialog } from 'src/Providers/ConfirmDialogProvider/ConfirmDialogProvider';

import {
  AppButton,
  AppButtonLink,
} from 'src/Components/Common/Button';
import { CurrencyAmount } from 'src/Components/Common/Text';

import { CustomerBadge } from 'src/Components/Common/Customer';
import BookingCustomerMessageBubble from 'src/Components/Common/Booking/BookingCustomerMessageBubble';
import BookingInfoRow from 'src/Components/Common/Booking/BookingInfoRow';
import BookingProviderRemarkBubble from 'src/Components/Common/Booking/BookingProviderRemarkBubble';


interface Props {
  booking: Booking;
  bookingType: string;

  onRequestCancelBooking: (booking: Booking) => void;
  onConfirmBooking: (booking: Booking) => void;
  onRequestMarkAbsent: (booking: Booking) => void;
  onRequestEdit?: (booking: Booking, bookingType: string) => void;
};

const ShopBookingItem = ({
  booking,
  bookingType,
  onRequestCancelBooking,
  onConfirmBooking,
  onRequestMarkAbsent,
  onRequestEdit,
}: Props) => {

  const { t, i18n } = useTranslation();

  const isChinese = useMemo(
    () => !i18n.language.startsWith('en'),
    [i18n.language]
  );

  const { openConfirmationDialog } = useConfirmationDialog();

  const [customerPhone, setCustomerPhone] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

  const canConfirmBooking = useMemo(
    () => booking.booking_status_id === BookingStatus.New,
    [booking.booking_status_id]
  );

  const canUpdateBooking = useMemo(
    () =>
      booking.booking_status_id === BookingStatus.New ||
      booking.booking_status_id === BookingStatus.Confirmed,
    [booking.booking_status_id]
  );

  const canCancelBooking = useMemo(
    () =>
      booking.booking_status_id === BookingStatus.New ||
      booking.booking_status_id === BookingStatus.Confirmed,
    [booking.booking_status_id]
  );

  const canCheckoutOrMarkAbsent = useMemo(
    () => booking.booking_status_id === BookingStatus.NotCheckOut,
    [booking.booking_status_id]
  );

  useEffect(() => {
    setCustomerPhone('');
    setCustomerEmail('');

    if (
      booking.booking_status_id === BookingStatus.Confirmed ||
      booking.booking_status_id === BookingStatus.NotCheckOut
    ) {
      // Customer's contacts are visible on above listed booking types (tabs)
      if (booking.customer_phone) {
        setCustomerPhone(booking.customer_phone.phone);
      } else if (booking.customer_other) {
        setCustomerPhone(booking.customer_other.phone);
      }

      setCustomerEmail(booking.customer?.email || '');
    }
  }, [booking]);

  const handleClickMarkAbsent = () => {
    openConfirmationDialog({
      message: t('bookingManagement.markAbsent.confirmation'),
      onConfirm: () => {
        onRequestMarkAbsent(booking);
      },
    });
  }

  return (
    <div className="mx-1 mb-2 border p-3 h-100">
      <div className="row no-gutters mb-2 align-items-center">
        <div className="col-9 col-lg-8">
          <h4>{booking.service_name}</h4>
        </div>
        <div className="col-3 col-lg-4">
          <BookingInfoRow
            centered
            fullWidth
            title={t('shopPrivate.bookings.totPrice')}
          >
            <div className="text-nowrap" style={{ fontSize: '1.6rem' }}>
              <CurrencyAmount amount={booking.price} />
            </div>
          </BookingInfoRow>
        </div>
      </div>

      {booking.customer && (
        <CustomerBadge
          avatarSize="small"
          customer={booking.customer}
          horizontal
        />
      )}

      <BookingInfoRow title={t('shopPrivate.bookings.dateTime')}>
        {booking.date}, {timeToAmPm(booking.time)}
      </BookingInfoRow>

      <BookingInfoRow title={t('shopPrivate.bookings.custName')}>
        {booking.customer_name
          ? `${booking.customer_name.first_name} ${booking.customer_name.last_name}`
          : booking.customer_other?.name}
      </BookingInfoRow>

      <BookingInfoRow title={t('shopPrivate.bookings.selectedStylist')}>
        {booking.staff_nickname ? booking.staff_nickname : 'No staff'}

        {booking.last_staff_change && (
          <p className="mt-2 staff-was-changed">
            {isChinese ? (
              <>
                {t('shopPrivate.bookings.staffChangedFrom')}
                <span className="mx-1">
                  ({booking.last_staff_change.from_staff_nickname})
                </span>
                {t('shopPrivate.bookings.staffChangedTo')}
                <span className="mx-1">
                  ({booking.last_staff_change.to_staff_nickname})
                </span>
              </>
            ) : (
              <>
                <span className="mx-1">
                  {booking.last_staff_change.from_staff_nickname}
                </span>
                {t('shopPrivate.bookings.staffChangedFrom')}
                <span className="mx-1">
                  {booking.last_staff_change.to_staff_nickname}
                </span>
                {t('shopPrivate.bookings.staffChangedTo')}
              </>
            )}
          </p>
        )}
      </BookingInfoRow>

      <BookingInfoRow title={t('shopPrivate.bookings.staffCharge')}>
        <CurrencyAmount amount={booking.staff_charge} />
      </BookingInfoRow>

      {customerPhone && (
        <BookingInfoRow title={t('shopPrivate.bookings.contNum')}>
          {customerPhone}
        </BookingInfoRow>
      )}

      {customerEmail && (
        <BookingInfoRow title={t('generalFields.email.label')}>
          {customerEmail}
        </BookingInfoRow>
      )}

      {/* Customer's remark to shop */}
      <BookingCustomerMessageBubble booking={booking} />

      {/* Shop's remark */}
      <BookingProviderRemarkBubble booking={booking} />

      {/* Action buttons */}
      <div className="row">
        {canConfirmBooking && (
          <div className="col-md-6">
            <AppButton
              size="block"
              theme="success"
              onClick={() => onConfirmBooking(booking)}
            >
              {t('shopPrivate.bookings.accept')}
            </AppButton>
          </div>
        )}

        {canCheckoutOrMarkAbsent && (
          <>
            <div className="col-md-6">
              <AppButtonLink
                size="block"
                theme="primary"
                to={`/shop-settings/checkout/${booking.id}`}
              >
                <i className="fas fa-money-bill-alt mr-1" />
                {t('shopPrivate.bookings.checkout')}
              </AppButtonLink>
            </div>

            <div className="col-md-6">
              <AppButton
                name={booking.id}
                size="block"
                theme="muted"
                variant="outlined"
                onClick={handleClickMarkAbsent}
              >
                {t('bookingManagement.markAbsent.action')}
              </AppButton>
            </div>
          </>
        )}

        {canUpdateBooking && onRequestEdit && (
          <div className="col-md-6">
            <AppButton
              name={booking.id}
              size="block"
              theme="secondary"
              onClick={() => {
                onRequestEdit(booking, bookingType);
              }}
            >
              {t('shopPrivate.bookings.edit')}
            </AppButton>
          </div>
        )}

        {canCancelBooking && (
          <div className="col-md-6">
            <AppButton
              name={booking.id}
              size="block"
              theme="muted"
              variant="outlined"
              onClick={() => onRequestCancelBooking(booking)}
            >
              {t('bookingManagement.cancelBooking.action')}
            </AppButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopBookingItem;
