import RatingStars from './RatingStars';

import styles from './RatingRow.module.css';

interface Props {
  label: React.ReactNode;
  rating: number;
  showNumeric?: boolean;
}

function RatingRow({ label, rating, showNumeric = false }: Props) {
  return (
    <div className={styles.root}>
      <span>{label}</span>
      <RatingStars rating={rating} showNumeric={showNumeric} />
    </div>
  );
}

export default RatingRow;
