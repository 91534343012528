import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import PhotoPickerDialog from '../../../../Common/Photo/PhotoPickerDialog';

import { changeMediaData } from '../../../../../Redux/actions/features/changeMedia';
import { shopData } from '../../../../../Redux/actions/shopActions/shopData';

import MediaItem from './MediaItem.component';


class Media extends Component {

  render() {
    const {
      general_media,
      general_mediaFull,
      isUploadModal,
      deleteOther,
      toggleUploadModal,
      openUploadModal,
      getOtherImage,
      t,
    } = this.props;

    return (
      <div className="main-container__content">
        {isUploadModal && (
          <PhotoPickerDialog
            onClose={toggleUploadModal}
            getImage={getOtherImage}
            imageAdaptiveWidth={window.innerWidth < 768 ? 250 : window.innerWidth - 120}
            imageAdaptiveHeight={window.innerHeight < 768 ? 250 : window.innerHeight - 400}
            imageWidth={640}
            imageHeight={360}
            text={t('shopPrivate.media.photosExtensions')}
          />
        )}

        <div className="upload-box">
          <h2>{t('shopPrivate.media.slider')}</h2>

          <div className="row">
            {general_media && (
              <>
                <MediaItem
                  index="other1"
                  thumbnailUrl={general_media.other1}
                  fullUrl={general_mediaFull.other1}
                  onDelete={deleteOther}
                  openUploadModal={openUploadModal}
                />

                <MediaItem
                  index="other2"
                  thumbnailUrl={general_media.other2}
                  fullUrl={general_mediaFull.other2}
                  onDelete={deleteOther}
                  openUploadModal={openUploadModal}
                />

                <MediaItem
                  index="other3"
                  thumbnailUrl={general_media.other3}
                  fullUrl={general_mediaFull.other3}
                  onDelete={deleteOther}
                  openUploadModal={openUploadModal}
                />

                <MediaItem
                  index="other4"
                  thumbnailUrl={general_media.other4}
                  fullUrl={general_mediaFull.other4}
                  onDelete={deleteOther}
                  openUploadModal={openUploadModal}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    general_media: state.ShopReducer.shopData.general_media,
    general_mediaFull: state.ShopReducer.shopData.general_media_full,
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    { changeMediaData, shopData }
  )(Media)
);
