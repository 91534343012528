import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import axios from 'axios';


import { createServiceType } from '../../../../../Redux/actions/features/serviceTypeAction';

import { AppButton } from '../../../../Common/Button';
import { DataTableStatus, TableHeaderCell } from '../../../../Common/DataTable';

import ServiceTypeItem from './ServiceTypeItem';
import ServiceTypeModal from './ServiceTypeModal';


function ServiceTypeList({
  shopId,

  createServiceType,
}) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [action, setAction] = useState('');
  const [name, setName] = useState('');
  const [id, setId] = useState('');

  const [isModalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    axios
      .get('/api/all-shop-service-types')
      .then((res) => {
        if (res.data.status === 'success') {
          setServiceTypes(
            (res.data.data || []).filter((item) => item.shop_id === shopId)
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeStatus = (services_type_id, visible) => {
    axios
      .post(`/api/default-service-types-check`, { services_type_id, visible })
      .then((res) => {
        if (res.data.message === 'success') {
          getData();
        }
      });
  };

  const handleUpdate = (services_type_id, name) => {
    axios
      .put(`/api/default-service-types`, {
        services_type_id,
        name,
      })
      .then((res) => {
        if (res.data.message === 'success') {
          getData();
        }
      });

    setModalStatus('');
  };

  const setModalStatus = (action, name, id) => {
    setAction(action);
    setModalOpened((val) => !val);
    setName(name);
    setId(id);
  };

  const handleChangeName = ({ target: { value } }) => {
    setName(value);
  };

  const handleCreate = () => {
    createServiceType({ name }).then((res) => {
      if (res.res.data.message === 'success') {
        getData();
        onCloseModal();
      }
    });
  };

  const onCloseModal = () => {
    setModalOpened(false);
  };

  const openCreateDialog = () => {
    setAction('create');
    setModalOpened(true);
    setId('');
    setName(name);
  };

  return (
    <>
      {isModalOpened && (
        <ServiceTypeModal
          name={name}
          stateId={id}
          action={action}
          onChangeName={handleChangeName}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onClose={onCloseModal}
        />
      )}

      <div className="page-head">
        <div className="page-add">
          <AppButton theme="primary" onClick={openCreateDialog}>
            {t('shopPrivate.servicePriceList.createServiceType')}
          </AppButton>
        </div>
      </div>

      <div className="tableWrap">
        <table className="adminTable">
          <colgroup>
            <col style={{ width: 100 }} />
            <col />
            <col style={{ width: 150 }} />
            <col style={{ width: 200 }} />
          </colgroup>
          <thead>
            <tr>
              <TableHeaderCell>ID</TableHeaderCell>
              <TableHeaderCell>{t('shopPrivate.servicePriceList.serviceType')}</TableHeaderCell>
              <TableHeaderCell>{t('shopPrivate.servicePriceList.visibility')}</TableHeaderCell>
              <TableHeaderCell>{t('shopPrivate.servicePriceList.action')}</TableHeaderCell>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {serviceTypes?.map((item, index) => (
                <ServiceTypeItem
                  key={item.id}
                  indexNum={index + 1}
                  item={item}
                  changeStatus={changeStatus}
                  onEdit={setModalStatus}
                />
              ))}
            </tbody>
          )}
        </table>

        <DataTableStatus
          isLoading={isLoading}
          itemCount={serviceTypes?.length}
        />
      </div>
    </>
  );
}

function mapSateToProps(state) {
  return {
    shopId: state.auth.user.id,
  };
}
export default connect(mapSateToProps, {
  createServiceType,
})(ServiceTypeList);
