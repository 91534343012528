import { Service, ServiceType } from 'src/models/entity';

import { GetResponse } from 'src/models/api/response/GetResponse';
import { ListResponse } from 'src/models/api/response/ListResponse';

import { apiGet } from '../api-utils';

export const getShopServiceTypes = (shopId: string) =>
  apiGet<ListResponse<ServiceType>>(
    `shop-service-types?shop_id=${shopId}`
  ).then((response) => response.data);

export const getShopService = (serviceId: string) =>
  apiGet<GetResponse<Service>>(`shop-services/${serviceId}`).then(
    (response) => response.data
  );
